<div class="full-page">
  <div class="unit-enquiry-thank-you-page two-column-layout">
    <!-- First Column -->
    <div class="column first-column">
      <h1>Thank you for contacting <br />The Travel Whisperer</h1>
      <p>
        <b>Please keep an eye on your email</b>, and don’t forget to check your
        spam box for our response. Our Personal Travel Designers are available
        for consultations daily from 8am until 5pm (Mountain Time). We are
        looking forward to helping you plan an amazing trip.
      </p>
    </div>
    <!-- Second Column -->
    <div class="column second-column new-brand-background">
      <div class="icon-container">
        <a href="tel:+19706885193">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="80px"
            viewBox="0 -960 960 960"
            width="80px"
            fill="#FFFFFF"
          >
            <path
              d="M165-120q-19.29 0-32.14-12.86Q120-145.71 120-165v-135q0-14.91 10-26.96Q140-339 155-343l119-26q12.7-2 25.85 2Q313-363 323-352l95 98q45-24 86-55.5t78-69.5q35-34 64-72t55-82l-99.77-100.77Q593-642 590.5-653.5t-.43-25.86L617-805q4-15 14-25t24-10h140q19.29 0 32.14 12.86Q840-814.29 840-795q0 116-56 236.5T625-335Q522-232 401.5-176T165-120Zm566-468q25-60 37-106.5t12-85.5H673l-23 110 81 82ZM362-225l-79-83-103 21v107q45-2 93-14t89-31Zm369-363ZM362-225Z"
            />
          </svg>
        </a>
      </div>
      <p class="contact-text">
        If you’re in the zone and planning your trip this very second, give us a
        call and one of our PTD’s can assist immediately
      </p>
      <a class="call-link" href="tel:+19706885193">Call: +1-970-688-5193</a>
    </div>
  </div>
</div>
