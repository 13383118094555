<twbooking-top-actions></twbooking-top-actions>
<br />
<div class="main-container">
  <div>
    <h1 class="title-heading">
      <span class="brand-colored">Luxurious homes</span> in the Mountains,<br />
      find your next vacation home today
    </h1>
  </div>
  @for (location of locationUnits; track location.Destination){
  <div>
    <div *ngIf="location.Units && location.Units.length > 0">
      <div class="header-separator-left">
        <h2 class="title-heading match-title">{{ location.Destination }}</h2>
        <div class="separator"></div>
      </div>

      <div
        *ngIf="
          mainPage &&
          location.FeaturedCategories &&
          location.FeaturedCategories.length > 0
        "
        class="featured-categories-container"
      >
        <mat-grid-list
          class="ttw-grid"
          [cols]="columnNum"
          [rowHeight]="gridSize"
          gutterSize="4%"
        >
          @for (featuredCategory of location.FeaturedCategories | slice : 0 :
          columnNum ; track featuredCategory.Name; let index = $index){
          <mat-grid-tile class="unit-summary-card-shadow">
            <ng-container
              *ngIf="
                featuredCategory && featuredCategory.navigateTo;
                else internalBlock
              "
            >
              <a
                [routerLink]="[featuredCategory.navigateTo]"
                routerLinkActive="router-link-active"
                [queryParams]="featuredCategory.searchQuery"
                class="additional-link"
              >
                <mat-card
                  appearance="outlined"
                  class="additional-card flex-container-column"
                >
                  <div class="image-container">
                    <div
                      *ngIf="!featuredCategory.ImageLink__c"
                      [@fadeOut]="featuredCategory.ImageLink__c"
                      class="ghost-image"
                    ></div>

                    <div
                      *ngIf="featuredCategory.ImageLink__c"
                      class="additional-image"
                      [@fadeIn]="featuredCategory.ImageLink__c"
                    >
                      <img
                        mat-card-image
                        src="{{ featuredCategory.ImageLink__c }}"
                        alt="{{ featuredCategory.Caption__c }}"
                      />
                    </div>
                  </div>
                  <div class="content-container">
                    <div
                      *ngIf="
                        featuredCategory.Description__c && featuredCategory.Name
                      "
                      [@fadeIn]="
                        featuredCategory.Description__c && featuredCategory.Name
                      "
                    >
                      <h4
                        class="additional-header uppercase-header new-brand-color"
                      >
                        {{ featuredCategory.Name | uppercase }}
                      </h4>
                      <p>
                        {{ featuredCategory.Description__c }}
                      </p>
                    </div>
                    <div
                      *ngIf="!featuredCategory.Description__c"
                      class="lines"
                      [@fadeOut]="featuredCategory.Description__c"
                    >
                      <p class="full-width brand-dark-background"></p>
                      <p class="half-width brand-dark-background"></p>
                    </div>
                  </div>
                </mat-card>
              </a>
            </ng-container>

            <ng-template #internalBlock>
              <a
                href="{{ featuredCategory.Link__c }}"
                target="_blank"
                class="additional-link"
              >
                <mat-card
                  appearance="outlined"
                  class="additional-card flex-container-column"
                >
                  <div class="image-container">
                    <div
                      *ngIf="!featuredCategory.ImageLink__c"
                      [@fadeOut]="featuredCategory.ImageLink__c"
                      class="ghost-image"
                    ></div>

                    <div
                      *ngIf="featuredCategory.ImageLink__c"
                      class="additional-image"
                      [@fadeIn]="featuredCategory.ImageLink__c"
                    >
                      <img
                        mat-card-image
                        src="{{ featuredCategory.ImageLink__c }}"
                        alt="{{ featuredCategory.Caption__c }}"
                      />
                    </div>
                  </div>
                  <div class="content-container">
                    <div
                      *ngIf="
                        featuredCategory.Description__c && featuredCategory.Name
                      "
                      [@fadeIn]="
                        featuredCategory.Description__c && featuredCategory.Name
                      "
                    >
                      <h4
                        class="additional-header uppercase-header new-brand-color"
                      >
                        {{ featuredCategory.Name | uppercase }}
                      </h4>
                      <p>
                        {{ featuredCategory.Description__c }}
                      </p>
                    </div>
                    <div
                      *ngIf="!featuredCategory.Description__c"
                      class="lines"
                      [@fadeOut]="featuredCategory.Description__c"
                    >
                      <p class="full-width brand-dark-background"></p>
                      <p class="half-width brand-dark-background"></p>
                    </div>
                  </div>
                </mat-card>
              </a>
            </ng-template>
          </mat-grid-tile>
          }
        </mat-grid-list>
      </div>

      <div
        *ngIf="
          location.Units && !(location.Complexes && location.Complexes.length)
        "
      >
        <mat-grid-list
          class="ttw-grid"
          [cols]="columnNum"
          rowHeight="38:46"
          gutterSize="4%"
        >
          @for ( unit of location.Units | slice : 0 : columnNum; track
          identifyUnit($index, unit); let index = $index) {
          <mat-grid-tile class="unit-summary-card-shadow">
            <div class="unit-summary-container">
              <twbooking-unit-summary
                *ngIf="unit"
                [@fadeIn]="unit"
                [unit]="unit"
                [columnNum]="columnNum"
                [searchQuery]="null"
              >
              </twbooking-unit-summary>
              <twbooking-summary-ghost
                *ngIf="!unit"
                [@fadeOut]="unit"
              ></twbooking-summary-ghost>
            </div>
          </mat-grid-tile>
          }
        </mat-grid-list>
        <br />
        <br />
        <a
          *ngIf="mainPage"
          [routerLink]="['/search-results']"
          routerLinkActive="router-link-active"
          [queryParams]="{ Destination: location.Destination }"
          class="show-more-nav"
        >
          <span>More homes in {{ location.Destination }}</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
        <a
          *ngIf="!mainPage"
          [routerLink]="['/search-results']"
          routerLinkActive="router-link-active"
          [queryParams]="{ Village: location.Destination }"
          class="show-more-nav"
        >
          <span>More homes in {{ location.Destination }}</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
        <br />
        <br />
        <mat-divider></mat-divider>
        <br />
        <br />
      </div>

      <div *ngIf="location.Complexes && location.Complexes.length">
        <mat-grid-list
          class="ttw-grid"
          [cols]="columnNum"
          rowHeight="38:43"
          gutterSize="4%"
        >
          @for ( complex of location.Complexes | slice : 0 : columnNum; track
          identifyUnit($index, complex); let index = $index) {
          <mat-grid-tile class="unit-summary-card-shadow">
            <div class="unit-summary-container">
              <twbooking-complex-summary
                *ngIf="complex"
                [@fadeIn]="complex"
                [complex]="complex"
                [columnNum]="columnNum"
                [searchQuery]="null"
              >
              </twbooking-complex-summary>
              <twbooking-summary-ghost
                *ngIf="!complex"
                [@fadeOut]="complex"
              ></twbooking-summary-ghost>
            </div>
          </mat-grid-tile>
          }
        </mat-grid-list>
        <br />
        <br />
        <a
          *ngIf="mainPage"
          [routerLink]="['/search-results']"
          routerLinkActive="router-link-active"
          [queryParams]="{ Destination: location.Destination }"
          class="show-more-nav"
        >
          <span>More homes in {{ location.Destination }}</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
        <a
          *ngIf="!mainPage"
          [routerLink]="['/search-results']"
          routerLinkActive="router-link-active"
          [queryParams]="{ Village: location.Destination }"
          class="show-more-nav"
        >
          <span>More homes in {{ location.Destination }}</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
        <br />
        <br />
        <mat-divider></mat-divider>
        <br />
        <br />
      </div>
    </div>
    <!--END let location of locationUnits-->
  </div>
  }
</div>

<br />
<div class="extended-footer">
  <div class="know-us-section">
    <div
      class="know-us-container"
      [class.desktop]="columnNum > 1"
      [class.mobile]="columnNum === 1"
    >
      <div class="half-know-us">
        <p class="know-why uppercase-header new-brand-color">
          WHY THE TRAVEL WHISPERER SHOULD BE TOP CHOICE FOR YOUR VACATION?
        </p>
        <a
          href="https://thetravelwhisperer.com/about-us/"
          class="know-us-button dark-uncolored-button"
          >Get to know us</a
        >
      </div>
      <div class="half-know-us">
        <p class="new-brand-color">
          The Travel Whisperer has helped over twenty thousand travelers find
          their perfect vacation home or resort. But we offer these
          holidaymakers so much more than accommodation choices. Our team of
          Personal Travel Designers have a combined 35 years of knowledge and
          experience in the industry so we are fully able to assist with every
          aspect of planning your stay; from the perfect lodging to fit your
          budget, to airport transfers, vehicle hire and all the other elements
          that transform a chosen vacation into a memorable experience.
        </p>
        <img src="/assets/logo.svg" alt="TTW logo" style="width: 50%" />
      </div>
    </div>
  </div>
  <!-- END know us section -->
  <div
    *ngIf="mainPage"
    class="villages-section villages-color brand-offwhite-background"
  >
    <mat-grid-list
      class="ttw-grid"
      [cols]="columnNum2"
      gutterSize="3vw"
      [rowHeight]="columnNum > 1 ? '164px' : '124px'"
    >
      <mat-grid-tile class="village-main-tile">
        <div class="title-tile">
          <div class="main-title uppercase-header new-brand-color">
            VILLAGES
          </div>
        </div>
      </mat-grid-tile>
      @for (village of villagesDescription | slice : 0 : columnNum2 - 1; track
      village.value){
      <mat-grid-tile></mat-grid-tile>
      } @for (village of villagesDescription; track village.value){
      <mat-grid-tile class="village-grid-tile grid-tile">
        <a
          [routerLink]="['/search-results']"
          routerLinkActive="router-link-active"
          [queryParams]="{ Village: village.value }"
          class="village-link new-brand-color"
        >
          <div class="village-tile">
            <div class="village-title uppercase-header">
              {{ village.value | uppercase }}
            </div>
            <div class="village-description standard-text">
              {{ village.description }}
            </div>
          </div>
        </a>
      </mat-grid-tile>
      }
    </mat-grid-list>
  </div>
  <!-- END villages section -->

  <div *ngIf="mainPage" class="villages-section property-type">
    <mat-grid-list
      class="ttw-grid"
      [cols]="columnNum2"
      gutterSize="3vw"
      rowHeight="60px"
    >
      <mat-grid-tile class="village-main-tile">
        <div class="title-tile">
          <div class="main-title uppercase-header new-brand-color">
            PROPERTY TYPE
          </div>
        </div>
      </mat-grid-tile>
      @for (option of propertyTypeOptions | slice : 0 : columnNum2 - 1; track
      option.value){
      <mat-grid-tile></mat-grid-tile>
      } @for (option of propertyTypeOptions; track option.value){
      <mat-grid-tile class="village-grid-tile grid-tile">
        <a
          [routerLink]="['/search-results']"
          routerLinkActive="router-link-active"
          [queryParams]="{ PropertyType: option.value }"
          class="village-link brand-dark-color"
        >
          <div class="village-tile">
            <div class="village-title uppercase-header new-brand-color">
              {{ option.viewValue | uppercase }}
            </div>
          </div>
        </a>
      </mat-grid-tile>
      }
    </mat-grid-list>
  </div>

  <!-- END property-type section -->
</div>

<twbooking-footer></twbooking-footer>
