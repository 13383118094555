import {
  Component,
  HostListener,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
  Renderer2,
} from '@angular/core';
import { GoogleMapsLoaderService } from 'src/app/services/google-maps-loader.service';
import {
  MapAdvancedMarker,
  MapAnchorPoint,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';

import { UnitsService } from 'src/app/services/units.service';
import { GeoAvailabilityQuery } from 'src/app/store/models/geoavailabilityquery.model';
import { svgHome1 } from './marker-icons';
import { UnitGeoSummary } from 'src/app/store/models/unitgeosummary.model';
import {
  ActivatedRoute,
  NavigationExtras,
  Params,
  Router,
} from '@angular/router';
import { SearchEventParamsGA } from 'src/app/store/models/events-ga.model';
import * as moment from 'moment';
import { TTWMapMarker } from 'src/app/store/models/ttwmapmarker.model';
import { SearchParamsService } from 'src/app/services/search-params.service';
import { SearchQuery } from 'src/app/store/models/search-query.model';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { amenities } from 'src/app/store/models/search-options';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'twbooking-map-search',
  templateUrl: './maps-search.component.html',
  styleUrls: ['./maps-search.component.scss', './unit-geo-card.scss'],
})
export class MapSearchComponent implements OnInit, AfterViewInit {
  @ViewChild('googleMap', { static: false }) googleMapElement: ElementRef;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  infoContent: any = null;

  dx = 0.01;
  dzoom = 0.5;

  total = 0;

  subMedia: Subscription;
  isMobile = false;
  version = environment.version;

  readonly amenities = amenities;
  selectedAmenities = [];

  panmap = false;

  useMargin = false;

  lat = 39.62299590966844;
  lng = -106.56906157001953;
  title = 'A102 Hummingbird Lodge';
  image = 'url://';
  caption = 'A102 Hummingbird Lodge';
  showMap = false;
  width = 800;
  height = 600;
  markerInfo = null;

  latNumber: number = null;
  lngNumber: number = null;

  mapOptions: google.maps.MapOptions = {
    mapId: '96e5c3c65d08bfec',
    gestureHandling: 'auto',
    isFractionalZoomEnabled: true,
    minZoom: 5,
  };

  zoom: number = 15; // Adjust as needed
  oldzoom: number = 15;

  initialized = false;
  widthset = false;

  mapinteraction: boolean = false;

  /*************** SEARCH PARAMS *****************/
  searchQuery: GeoAvailabilityQuery = {};
  bounds: google.maps.LatLngBounds = null;
  mmOnly = false;
  pageSize = 100;
  offset = 0; //TODO implement pagination
  actualQuery: any;

  /*************** MARKERS *******************/
  loadingMarkers = true;
  readonly svgHome1 = svgHome1;

  selectedMarker: {
    title: string;
    image: string;
    caption: string;
    link: string;
  } = {
    title: null,
    image: null,
    caption: null,
    link: null,
  };

  private markersSubject = new BehaviorSubject<TTWMapMarker[]>([]);
  public markers$ = this.markersSubject.asObservable();
  markersPage: TTWMapMarker[] = [];
  private unitsSubject = new BehaviorSubject<UnitGeoSummary[]>([]);
  public units$ = this.unitsSubject.asObservable();
  unitsPage: UnitGeoSummary[] = [];
  pageEvent: BehaviorSubject<PageEvent>;
  pageSubscription: Subscription;
  pageIndex = 0;

  destinationZoomChange = false; //set true if zoom is changed programmatically. Then don't need to remove Destination from searchQuery

  constructor(
    private googleMapsService: GoogleMapsLoaderService,
    private unitsService: UnitsService,
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private searchService: SearchParamsService,
    private breakpointObserver: BreakpointObserver,
    private eventService: EventService
  ) {
    this.pageEvent = new BehaviorSubject<PageEvent>({
      pageIndex: 0,
      previousPageIndex: 0,
      pageSize: this.pageSize,
      length: 1,
    });

    this.subMedia = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result) => {
        this.isMobile =
          result.breakpoints[Breakpoints.XSmall] ||
          result.breakpoints[Breakpoints.Small];
        this.pageSize = this.isMobile ? 20 : 100;
        this.pageEvent = new BehaviorSubject<PageEvent>({
          pageIndex: 0,
          previousPageIndex: 0,
          pageSize: this.pageSize,
          length: 1,
        });
      });
    this.googleMapsService.scriptLoaded$.subscribe((loaded) => {
      if (loaded) {
        this.initializeMapProperties();
        this.showMap = true;
      }
    });
    if (window.location.hostname.includes('mountainmanagement.com')) {
      this.mmOnly = true;
      this.zoom = 13;
    } else {
      this.mmOnly = false;
    }
  }

  ngAfterViewInit() {
    this.adjustMapSize();
  }

  ngOnInit(): void {
    this.latNumber = +this.lat;
    this.lngNumber = +this.lng;
    this.markerInfo = {
      title: this.title,
      image: this.image,
      caption: this.caption,
    };

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.mapinteraction = false;
      // Defaults to 0 if no query param provided.
      this.searchQuery = {};

      //TODO: GA search event
      //let gaSearch: SearchEventParamsGA = {};

      if (params.UnitName) {
        this.searchQuery = { UnitName: params.UnitName, ...this.searchQuery };
      }

      let sleepsCount = 0;
      if (params.Adults) {
        this.searchQuery = { Adults: params.Adults, ...this.searchQuery };
        sleepsCount += +params.Adults;
      }
      if (params.Children) {
        this.searchQuery = { Children: params.Children, ...this.searchQuery };
        sleepsCount += +params.Children;
      }
      if (sleepsCount > 0) {
        this.searchQuery = {
          SleepsCount: sleepsCount,
          ...this.searchQuery,
        };
      }
      if (params.StartDate && params.EndDate) {
        const today = moment.utc(new Date());
        const arrival = moment.utc(params.StartDate, 'YYYY-MM-DD');
        const departure = moment.utc(params.EndDate, 'YYYY-MM-DD');
        if (arrival.isAfter(today) && departure.isAfter(arrival)) {
          this.searchQuery = {
            StartDate: params.StartDate,
            EndDate: params.EndDate,

            ...this.searchQuery,
          };
        }
      }
      if (params.Bedrooms) {
        this.searchQuery = { Bedrooms: params.Bedrooms, ...this.searchQuery };
      }
      if (params.City) {
        this.searchQuery = { City: params.City, ...this.searchQuery };
      }
      if (params.State) {
        this.searchQuery = { State: params.State, ...this.searchQuery };
      }

      if (params.Village) {
        this.searchQuery = { Village: params.Village, ...this.searchQuery };
      }

      if (params.PetFriendly) {
        this.searchQuery = {
          PetFriendly: params.PetFriendly,
          ...this.searchQuery,
        };
      }

      if (params.Destination) {
        const tmp = params.Destination.split(', ');
        if (tmp && tmp.length && tmp.length > 1) {
          const City = params.Destination.split(', ')[0];
          const State = params.Destination.split(', ')[1];
          this.searchQuery = {
            City: City,
            State: State,
            ...this.searchQuery,
          };
        }
      }

      if (params.PropertyType) {
        if (params.PropertyType !== 'All') {
          this.searchQuery = {
            PropertyType: params.PropertyType,
            ...this.searchQuery,
          };
        }
      }
      if (params.PropertyCollection) {
        if (params.PropertyCollection !== 'All') {
          this.searchQuery = {
            PropertyCollection: params.PropertyCollection,
            ...this.searchQuery,
          };
        }
      }

      for (const key in params) {
        if (key.startsWith('amenity')) {
          this.searchQuery[key] = params[key];
          let amenity = this.findAmenity(params[key]);
          this.selectedAmenities.push({ key: key, ...amenity });
        }
      }
      this.searchService.update(this.searchQuery);
      if (this.bounds) {
        if (Object.keys(this.searchQuery).length === 0) {
          this.loadNewMarkers(this.bounds, this.searchQuery, true);
        } else {
          this.loadNewMarkers(this.bounds, this.searchQuery, false);
        }
      }
    });

    this.pageSubscription = this.pageEvent.subscribe((pageevent) => {
      if (pageevent.length > 0) {
        if (this.pageIndex + 1 == pageevent.pageIndex) {
          this.openNextPage(pageevent.pageIndex);
        } else if (this.pageIndex - 1 == pageevent.pageIndex) {
          this.openPreviousPage(pageevent.pageIndex);
        } else if (this.pageIndex !== pageevent.pageIndex) {
          this.openSpecificPage(pageevent.pageIndex * pageevent.pageSize);
        }
        this.pageIndex = pageevent.pageIndex;
        //        this.complexPageSize = pageevent.pageSize;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.debouncedUpdateDimensions();
  }

  private adjustMapSize() {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;
    if (!this.isMobile) {
      if (innerWidth >= 1000) {
        this.width = innerWidth / 2 - 40;
      } else {
        this.width = innerWidth - 490;
      }

      this.height = innerHeight - 300;
    } else {
      this.width = innerWidth;
      this.height = innerHeight - 120;
    }

    this.widthset = true;
    if (this.initialized && this.widthset) {
      this.showMap = true;
    }
  }

  debouncedUpdateDimensions = this.debounce(this.adjustMapSize.bind(this), 200);

  debounce(func: Function, delay: number): Function {
    let timeoutId: any;
    return function (...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  findAmenity(selectedAmenity) {
    return amenities.find((amenity) => amenity.value === selectedAmenity);
  }

  mymap: google.maps.Map;

  onMapReady(mapInstance: google.maps.Map): void {
    this.mymap = mapInstance; // Save the map instance

    this.mymap.addListener('tilesloaded', () => {
      if (!this.bounds) {
        this.bounds = this.mymap.getBounds();

        if (Object.keys(this.searchQuery).length === 0) {
          //initial load of the map.

          this.loadNewMarkers(this.bounds, this.searchQuery, true); //if no search filters set yet, use initial default bounds to avoind loading all
        } else {
          this.loadNewMarkers(this.bounds, this.searchQuery, false); //If there are search params, then load all actual markers
        }
      }
    });

    // Add a 'dragend' listener to the map
    this.mymap.addListener('dragend', () => {
      let newbounds: google.maps.LatLngBounds = this.mymap.getBounds();
      let newcorner: google.maps.LatLng = newbounds.getSouthWest();
      let oldcorner: google.maps.LatLng = this.bounds.getSouthWest();

      if (
        Math.abs(newcorner.lat() - oldcorner.lat()) > this.dx ||
        Math.abs(newcorner.lng() - oldcorner.lng()) > this.dx
      ) {
        this.bounds = this.mymap.getBounds();

        if (this.searchQuery['City'] && this.searchQuery['State']) {
          delete this.searchQuery['City'];
          delete this.searchQuery['State'];
        }
        if (this.searchQuery['Destination']) {
          delete this.searchQuery['Destination'];
        }

        if (this.searchQuery['Village']) {
          delete this.searchQuery['Village'];
        }
        this.mapinteraction = true;

        this.loadNewMarkers(this.bounds, this.searchQuery, this.mapinteraction); //interaction with the map
      }
    });

    // Add a 'zoom_changed' listener to the map
    this.mymap.addListener('zoom_changed', (event) => {
      this.zoom = this.mymap.getZoom();

      if (Math.abs(this.oldzoom - this.zoom) > this.dzoom) {
        this.oldzoom = this.zoom;

        this.bounds = this.mymap.getBounds();

        if (!this.destinationZoomChange && !this.panmap) {
          if (this.searchQuery['City'] && this.searchQuery['State']) {
            delete this.searchQuery['City'];
            delete this.searchQuery['State'];
          }
          if (this.searchQuery['Destination']) {
            delete this.searchQuery['Destination'];
          }
          if (this.searchQuery['Village']) {
            delete this.searchQuery['Village'];
          }
          this.mapinteraction = true;

          this.loadNewMarkers(
            this.bounds,
            this.searchQuery,
            this.mapinteraction
          ); //interaction with the map
        } else {
          if (this.panmap) {
            this.panmap = false;
          }
        }
      } else {
        if (this.panmap) {
          this.panmap = false;
        }
      }
    });
  }

  removeDestinationFromURL() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.searchQuery,
      queryParamsHandling: '', // Merge with existing query params
      replaceUrl: true, // This will not add a new entry to the browser's history
    });
    this.searchService.update(this.searchQuery);
  }

  backToListing() {
    this.router.navigate(['search-results'], {
      queryParams: this.searchQuery,
    });
  }

  removeAmenity(key: string) {
    const index = this.selectedAmenities.findIndex(
      (amenity) => amenity.key === key
    );
    if (index !== -1) {
      this.selectedAmenities.splice(index, 1);
    }
  }

  initializeMapProperties() {
    this.latNumber = +this.lat;
    this.lngNumber = +this.lng;
    this.mapOptions = {
      mapId: '96e5c3c65d08bfec',
      gestureHandling: 'auto',
      zoom: this.zoom,
      isFractionalZoomEnabled: true,
      minZoom: 5,
    };
    this.initialized = true;
    if (this.initialized && this.widthset) {
      this.showMap = true;
    }
  }

  async loadNewMarkers(
    bounds: google.maps.LatLngBounds,
    searchQuery: GeoAvailabilityQuery,
    mapInteraction: boolean //if it is mapInteraction, then use bounds coordinates. Else use only searchCriteria
  ) {
    this.loadingMarkers = true;
    this.unitsSubject.next([]);
    this.markersSubject.next([]);
    this.unitsPage = [];
    this.markersPage = [];
    this.pageIndex = 0;
    this.pageEvent.next({
      pageIndex: 0,
      previousPageIndex: 0,
      pageSize: this.pageSize,
      length: 0,
    });
    this.total = 0;

    let boundsGeo: { south; west; north; east } = bounds.toJSON();
    /**
     * Returns a string of the form &quot;lat_lo,lng_lo,lat_hi,lng_hi&quot; for
     * this bounds, where &quot;lo&quot; corresponds to the southwest corner of
     * the bounding box, while &quot;hi&quot; corresponds to the northeast
     * corner of that box.
     */

    let geoQuery: GeoAvailabilityQuery = {
      ...searchQuery,
    };

    if (mapInteraction) {
      geoQuery = {
        ...geoQuery,
        minLon: boundsGeo.west,
        minLat: boundsGeo.south,
        maxLon: boundsGeo.east,
        maxLat: boundsGeo.north,
      };
    }
    let adjustedQuery = this.parseAmenities(geoQuery);
    this.actualQuery = adjustedQuery;

    this.unitsService
      .geoSearch(adjustedQuery, this.mmOnly, this.pageSize, this.offset)
      .subscribe((data) => {
        this.loadingMarkers = false;
        let units = [];
        let markers = [];

        if (data.total && data.total.length && data.total.length > 0) {
          this.total = data.total[0].count ? data.total[0].count : 0;

          if (!mapInteraction) {
            let queryForAnalytics = {
              ...adjustedQuery,
              count: this.total,
              mapSearch: true,
            };
            if (queryForAnalytics['minLon']) {
              delete queryForAnalytics['minLon'];
            }
            if (queryForAnalytics['minLat']) {
              delete queryForAnalytics['minLat'];
            }
            if (queryForAnalytics['maxLon']) {
              delete queryForAnalytics['maxLon'];
            }
            if (queryForAnalytics['maxLat']) {
              delete queryForAnalytics['maxLat'];
            }

            this.eventService.triggerSearchEvent(
              queryForAnalytics,
              this.mmOnly
            );
          }

          this.pageIndex = 0;
          this.pageEvent.next({
            pageIndex: 0,
            previousPageIndex: 0,
            pageSize: this.pageSize,
            length: this.total,
          });
        }
        if (data && data.result && data.result.length > 0) {
          for (let i = 0; i < data.result.length; i++) {
            let geo = data.result[i];
            if (geo.latitude && geo.longitude) {
              const lat = +geo.latitude;
              const lng = +geo.longitude;

              const glyphImgUnit = document.createElement('img');

              glyphImgUnit.src = '/assets/home_glyph.svg';
              const glyphImgComplex = document.createElement('img');
              glyphImgComplex.src = '/assets/complex_glyph.svg';

              let cp = new google.maps.marker.PinElement({
                background: '#90d7de',
                borderColor: '#103753',
                glyph: geo.isComplex ? glyphImgComplex : glyphImgUnit,
                scale: geo.isComplex ? 1.5 : 1.2,
              });
              // cp.element.style.transform = 'translateY(50%)';

              let newMarker = new google.maps.marker.AdvancedMarkerElement({
                map: this.mymap,
                position: { lat: lat, lng: lng },
                content: cp.element,
                title: geo.MarketingHeadline__c,
              });

              /** create duplicate of custom pin, but without style transform */

              markers.push({
                position: { lat: lat, lng: lng },
                title: geo.MarketingHeadline__c,
                image: geo.SeasonThumbnail,
                caption: geo.SeasonCaption,
                link: geo.isComplex
                  ? '/complex-details/' + geo.UnitSlug__c
                  : '/unit-details/' + geo.UnitSlug__c,
                selected: false,
                advMarker: newMarker,
                customPin: cp.element,
              });

              units.push({
                externalId: geo.externalId,
                Name: geo.Name,
                MarketingHeadline__c: geo.MarketingHeadline__c,
                Bedrooms__c: geo.Bedrooms__c,
                Bathrooms__c: geo.Bathrooms__c,
                Sleeps__c: geo.Sleeps__c,
                SeasonThumbnail: geo.SeasonThumbnail,
                SeasonCaption: geo.SeasonCaption,
                PropertyManager__c: geo.PropertyManager__c,
                UnitSlug__c: geo.UnitSlug__c,
                Pets_Allowed__c: geo.Pets_Allowed__c,
                isComplex: geo.isComplex,
                link: geo.isComplex
                  ? '/complex-details/' + geo.UnitSlug__c
                  : '/unit-details/' + geo.UnitSlug__c,
                latitude: geo.latitude,
                longitude: geo.longitude,
                unitsSummary: geo.unitsSummary,
              });
            }
          }
          this.unitsSubject.next(units);
          this.markersSubject.next(markers);
          this.unitsPage = units;
          this.markersPage = markers;
        }
        if (!mapInteraction) {
          if (
            this.markersSubject.value &&
            this.markersSubject.value.length > 0
          ) {
            this.setCenter(this.markersPage);
          }
        }
        this.cdRef.detectChanges();
      });
  }

  async loadNextPage() {
    this.loadingMarkers = true;

    this.offset += this.pageSize;

    this.unitsService
      .geoSearch(this.actualQuery, this.mmOnly, this.pageSize, this.offset)
      .subscribe((data) => {
        this.loadingMarkers = false;

        if (data && data.result && data.result.length > 0) {
          let units = [];
          let markers = [];
          for (let i = 0; i < data.result.length; i++) {
            let geo = data.result[i];
            if (geo.latitude && geo.longitude) {
              const lat = +geo.latitude;
              const lng = +geo.longitude;

              const glyphImgUnit = document.createElement('img');

              glyphImgUnit.src = '/assets/home_glyph.svg';
              const glyphImgComplex = document.createElement('img');
              glyphImgComplex.src = '/assets/complex_glyph.svg';

              let cp = new google.maps.marker.PinElement({
                background: '#90d7de',
                borderColor: '#103753',
                glyph: geo.isComplex ? glyphImgComplex : glyphImgUnit,
                scale: geo.isComplex ? 1.5 : 1.2,
              });
              // cp.element.style.transform = 'translateY(50%)';

              let newMarker = new google.maps.marker.AdvancedMarkerElement({
                map: this.mymap,
                position: { lat: lat, lng: lng },
                content: cp.element,
                title: geo.MarketingHeadline__c,
              });

              /** create duplicate of custom pin, but without style transform */

              markers.push({
                position: { lat: lat, lng: lng },
                title: geo.MarketingHeadline__c,
                image: geo.SeasonThumbnail,
                caption: geo.SeasonCaption,
                link: geo.isComplex
                  ? '/complex-details/' + geo.UnitSlug__c
                  : '/unit-details/' + geo.UnitSlug__c,
                selected: false,
                advMarker: newMarker,
                customPin: cp.element,
              });

              units.push({
                externalId: geo.externalId,
                Name: geo.Name,
                MarketingHeadline__c: geo.MarketingHeadline__c,
                Bedrooms__c: geo.Bedrooms__c,
                Bathrooms__c: geo.Bathrooms__c,
                Sleeps__c: geo.Sleeps__c,
                SeasonThumbnail: geo.SeasonThumbnail,
                SeasonCaption: geo.SeasonCaption,
                PropertyManager__c: geo.PropertyManager__c,
                UnitSlug__c: geo.UnitSlug__c,
                Pets_Allowed__c: geo.Pets_Allowed__c,
                isComplex: geo.isComplex,
                latitude: geo.latitude,
                longitude: geo.longitude,
                unitsSummary: geo.unitsSummary,
              });
            }
          }
          const currentElements = this.unitsSubject.value;
          const currentMarkers = this.markersSubject.value;

          //TODO !!!!! ensure that indexes match, no duplicates

          this.unitsSubject.next([...currentElements, ...units]);
          this.markersSubject.next([...currentMarkers, ...markers]);
          this.unitsPage = units;
          this.markersPage = markers;

          if (this.markersPage && this.markersPage.length > 0) {
            this.setCenter(this.markersPage);
          }
        }

        /*** Block to redraw the map and detect changes ***/
        this.destinationZoomChange = true;
        this.mymap.setZoom(this.mymap.getZoom());
        this.cdRef.detectChanges();
        this.destinationZoomChange = false;
        /*** End block to redraw the map and detect changes ***/
      });
  }

  openNextPage(pageIndex: number) {
    let allUnits = this.unitsSubject.value;
    let allMarkers = this.markersSubject.value;
    if (
      pageIndex * this.pageSize >= allUnits.length &&
      pageIndex * this.pageSize < this.total
    ) {
      this.loadNextPage();
    } else {
      this.unitsPage = allUnits.slice(
        pageIndex * this.pageSize,
        (pageIndex + 1) * this.pageSize
      );
      this.markersPage = allMarkers.slice(
        pageIndex * this.pageSize,
        (pageIndex + 1) * this.pageSize
      );

      if (this.markersSubject.value && this.markersSubject.value.length > 0) {
        this.setCenter(this.markersPage);
      }
      /*** Block to redraw the map and detect changes ***/
      this.destinationZoomChange = true;
      this.mymap.setZoom(this.mymap.getZoom());
      this.cdRef.detectChanges();
      this.destinationZoomChange = false;
      /*** End block to redraw the map and detect changes ***/
    }
  }

  openPreviousPage(pageIndex: number) {
    let allUnits = this.unitsSubject.value;
    let allMarkers = this.markersSubject.value;

    //TODO: check me

    this.unitsPage = allUnits.slice(
      pageIndex * this.pageSize,
      (pageIndex + 1) * this.pageSize
    );

    this.markersPage = allMarkers.slice(
      pageIndex * this.pageSize,
      (pageIndex + 1) * this.pageSize
    );

    if (this.markersPage && this.markersPage.length > 0) {
      this.setCenter(this.markersPage);
    }
    /*** Block to redraw the map and detect changes ***/
    this.destinationZoomChange = true;
    this.mymap.setZoom(this.mymap.getZoom());
    this.cdRef.detectChanges();
    this.destinationZoomChange = false;
    /*** End block to redraw the map and detect changes ***/
  }

  openSpecificPage(pageIndex: number) {
    //Set center for markers from next page
  }

  setCenter(markers) {
    let north;
    let south;
    let east;
    let west;

    for (let marker of markers) {
      // set the coordinates to marker's lat and lng on the first run.
      // if the coordinates exist, get max or min depends on the coordinates.
      north =
        north !== undefined
          ? Math.max(north, marker.position.lat)
          : marker.position.lat;
      south =
        south !== undefined
          ? Math.min(south, marker.position.lat)
          : marker.position.lat;
      east =
        east !== undefined
          ? Math.max(east, marker.position.lng)
          : marker.position.lng;
      west =
        west !== undefined
          ? Math.min(west, marker.position.lng)
          : marker.position.lng;
    }

    if (markers.length === 1) {
      let latCenter = north + (south - north) / 2;
      let lngCenter = east + (west - east) / 2;
      this.latNumber = +latCenter;
      this.lngNumber = +lngCenter;
      this.destinationZoomChange = true;
      this.mymap.setZoom(12);

      this.destinationZoomChange = false;
    } else {
      this.destinationZoomChange = true;
      this.mymap.fitBounds(
        new google.maps.LatLngBounds(
          { lat: south, lng: west },
          { lat: north, lng: east }
        )
      );
      this.destinationZoomChange = false;
    }

    return true;
  }

  openInfoWindowHTML(mymarker: any, index: number) {
    this.infoContent = this.generateMapInfoWindowContent(
      this.markersPage[index]
    );
    this.useMargin = false;

    this.infoWindow.openAdvancedMarkerElement(
      mymarker.advancedMarker,
      this.infoContent
    );
  }

  public closeInfoWindow(event) {
    this.infoWindow.close();
  }

  openInfoWindowForElement(
    myMarker: google.maps.marker.AdvancedMarkerElement,
    index: number
  ) {
    this.infoContent = this.generateMapInfoWindowContent(
      this.markersPage[index]
    );

    this.useMargin = true;

    this.infoWindow.openAdvancedMarkerElement(myMarker, this.infoContent);
  }

  generateMapInfoWindowContent(marker: TTWMapMarker) {
    let mapInfoWindowContent = `
      <style>
        .custom-content {
          position: relative;
          display: flex;
          flex-direction: column;
          width: ${this.isMobile ? '150px' : '200px'};
          pointer-events: all !important;
          z-index: 40;
          margin-top: -50px;
        }

        .custom-content img {
          width: 100%;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
          
        .custom-content .unit-name {
            margin-top: 10px;
            color: #103753 !important;
            font-size: 15px;
            line-height: 23px;
            text-decoration: none;
            outline: none !important;
            padding-left: 8px !important;
            padding-right: 8px !important;
            padding-bottom: 8px !important;
        }
      </style>

      <div class="custom-content" id="content">
          <img
            src="${marker.image}"
            alt="${marker.caption}"
            width="${this.isMobile ? '150px' : '200px'}"
          />
          <a class="unit-name" href="${marker.link}"`;

    if (!this.isMobile) {
      mapInfoWindowContent += `target="_blank"`;
    }

    mapInfoWindowContent += `>
          ${marker.title}
          </a>
       <div>
    `;

    return mapInfoWindowContent;
  }

  openMarker(index: number) {
    this.markersPage[index].selected = true;
    this.markersPage.forEach((marker, i) => {
      if (i !== index && marker.selected) {
        /* Previous seleced marker is not selected anymore */
        marker.selected = false;
      }
    });

    this.openInfoWindowForElement(this.markersPage[index].advMarker, index);
  }

  parseAmenities(params: any): any {
    let resultQuery = {};
    const amenities: string[] = [];

    for (const key in params) {
      if (params.hasOwnProperty(key) && key.startsWith('amenity')) {
        amenities.push(params[key]);
      } else {
        resultQuery[key] = params[key];
      }
    }
    if (amenities && amenities.length > 0) {
      resultQuery['amenities'] = amenities;
    }

    return resultQuery;
  }

  panMap(event: any) {
    if (this.showMap && this.mymap) {
      this.panmap = true;

      this.zoom = this.mymap.getZoom();
      this.mymap.panBy(0, event == 'above' ? 200 : -200);
      this.mymap.moveCamera({
        zoom: event == 'above' ? this.zoom - 0.5 : this.zoom + 0.5,
      });
      //this.zoom = event == 'above' ? this.zoom - 0.5 : this.zoom + 1;
    }
  }

  generateLink(link: string): string {
    // Get current query parameters
    const queryParams = this.activatedRoute.snapshot.queryParams;

    // Construct the navigation extras
    const navigationExtras: NavigationExtras = {
      queryParams,
    };

    // Generate the URL
    const urlTree = this.router.createUrlTree([link], navigationExtras);
    return this.router.serializeUrl(urlTree);
  }

  openLinkInNewTab(link: string): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const navigationExtras: NavigationExtras = {
      queryParams,
    };

    const urlTree = this.router.createUrlTree([link], navigationExtras);
    const fullUrl = this.router.serializeUrl(urlTree);

    // Try to open in a new tab
    const newTab = window.open(fullUrl, '_blank');

    if (newTab) {
      // If the new tab was successfully opened and is in focus
      newTab.focus();
    } else {
      // If new tabs are forbidden, navigate using Angular's router
      this.router.navigateByUrl(urlTree);
    }
  }
}
