<div class="unit-rates-inner-container">
  <form class="" [formGroup]="form" novalidate>
    <div>
      <mat-accordion class="example-headers-align brand-light-background">
        <mat-expansion-panel
          class="panel"
          [expanded]="step === 0"
          (opened)="setStep(0)"
          hideToggle
        >
          <mat-expansion-panel-header class="brand-light-background">
            <mat-panel-title>
              <mat-icon class="new-brand-color">date_range</mat-icon>
              Booking
            </mat-panel-title>
            <mat-panel-description>
              Information about your booking
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngIf="quote && !editMode" class="column-with-padding">
            <div class="booking-info" *ngIf="mode === 'LodgingQuote'">
              <span> <mat-icon>home</mat-icon> &nbsp; </span>
              <span>{{ unit.Name }}</span>
            </div>
            <div class="booking-info" *ngIf="arrivalString && departureString">
              <span> <mat-icon>credit_card</mat-icon> &nbsp; </span>
              <span
                >{{ arrivalString }} &nbsp;&mdash;&nbsp;
                {{ departureString }}</span
              >
            </div>

            <div *ngIf="errorMessage" class="column-with-padding">
              {{ errorMessage }}
            </div>
            <div
              *ngIf="lodgingQuoteAvailabilityError"
              class="column-with-padding"
            >
              {{ lodgingQuoteAvailabilityError }}
            </div>
            <div class="rent-info" *ngIf="loadingAvailability">
              <span> Checking availability... </span>
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
            <div
              *ngIf="
                !lodgingQuoteAvailabilityError &&
                !errorMessage &&
                !loadingAvailability
              "
              class="full-width"
            >
              <div class="booking-info">
                <span> <mat-icon>date_range</mat-icon> &nbsp; </span>
                <span matTooltip="Average rate per night"
                  >{{ myStay }} nights &#64; ${{
                    Rent / myStay | number : "1.0-0"
                  }}/night *</span
                >
              </div>

              <div class="booking-info">
                <span> <mat-icon>people</mat-icon> &nbsp; </span>
                <span> {{ quote.adults }} adults </span>
                <span *ngIf="quote.children > 0">
                  , {{ quote.children }} children
                </span>
              </div>

              <div
                *ngIf="
                  (quote.pets > 0 &&
                    (unit.Pets_Allowed__c || unit.Dogs_Allowed__c)) ||
                  (quote.pets > 0 && mode === 'LodgingQuote')
                "
                class="booking-info"
              >
                <span> <mat-icon>pets</mat-icon> &nbsp; </span>
                <span>{{ quote.pets }} pets</span>
              </div>
              <br />
              <mat-divider></mat-divider>

              <div *ngIf="quote.lodgingQuote">
                <div class="rent-info">
                  <span class="rent-info"> Rent </span>
                  <span>{{
                    quote.lodgingQuote.LodgingNightlyRate__c | currency
                  }}</span>
                </div>

                <div class="rent-info">
                  <span class="rent-info">
                    <span>Taxes and Fees:</span>
                  </span>
                  <span>
                    {{
                      quote.lodgingQuote.LodgingTaxAmount__c | currency
                    }}</span
                  >
                </div>

                <div class="rent-info-total brand-offwhite-background">
                  <span> Subtotal </span>
                  <span>{{ quote.lodgingQuote.Subtotal | currency }}</span>
                </div>
                <br />

                <div
                  class="optable-fee"
                  *ngIf="
                    this.quote.lodgingQuote &&
                    this.quote.lodgingQuote.LodgingDamageProtectionFee__c > 0
                  "
                >
                  <div class="rent-info">
                    <span> Vacation Rental Damage Protection </span>
                    <span>
                      <mat-checkbox
                        name="damageProtection"
                        formControlName="damageProtection"
                        [checked]="damageProtection == true"
                        [disabled]="booked"
                        (change)="
                          damageProtectionChangeLodging(
                            $event,
                            quote.lodgingQuote.Subtotal
                          )
                        "
                        >{{
                          this.quote.lodgingQuote.LodgingDamageProtectionFee__c
                            | currency
                        }}
                      </mat-checkbox>
                    </span>
                  </div>
                  <div class="optable-details">
                    View complete
                    <a
                      class="new-brand-color"
                      href="https://www.csatravelprotection.com/certpolicy.do?product=G-20VRD"
                      target="_blank"
                      >Plan Details</a
                    >
                  </div>
                  <div class="optable-disclosures brand-light-color">
                    The plan cost includes the insurance premium and assistance
                    services fee. Insurance coverages are underwritten by:
                    Generali U.S. Branch, New York, NY; NAIC # 11231, for the
                    operating name used in certain states, and other important
                    information about the Insurance & Assistance Services Plan,
                    please see
                    <a
                      class="new-brand-color"
                      href="https://www.generalitravelinsurance.com/customer/disclosures.html"
                      target="_blank"
                      >Important Disclosures.</a
                    >
                  </div>
                  <div class="optable-disclosures brand-light-color">
                    Please note, reservations that opt out of the Damage
                    Protection Policy require a refundable security deposit.
                  </div>
                </div>

                <div
                  class="optable-fee"
                  *ngIf="
                    this.quote.lodgingQuote &&
                    this.quote.lodgingQuote.LodgingTravelInsurance__c
                  "
                >
                  <div class="rent-info">
                    <span> Travel Protection Plan </span>
                    <span>
                      <mat-checkbox
                        name="insurance"
                        formControlName="insurance"
                        [checked]="insurance == true"
                        [disabled]="booked"
                        (change)="
                          insuranceChange(
                            this.quote.lodgingQuote.LodgingTravelInsurance__c,
                            $event,
                            quote.lodgingQuote.Subtotal
                          )
                        "
                        >{{
                          this.quote.lodgingQuote.LodgingTravelInsurance__c
                            | currency
                        }}
                      </mat-checkbox>
                    </span>
                  </div>
                  <div class="optable-details">
                    View complete
                    <a
                      class="new-brand-color"
                      href="https://www.csatravelprotection.com/certpolicy.do?product=GR330"
                      target="_blank"
                      >Plan Details</a
                    >
                  </div>
                  <div class="optable-disclosures brand-light-color">
                    The plan cost includes the travel insurance premium and
                    assistance services fee. Travel insurance coverages are
                    underwritten by: Generali U.S. Branch, New York, NY; NAIC #
                    11231, for the operating name used in certain states, and
                    other important information about the Travel Insurance &
                    Assistance Services Plan, please see
                    <a
                      class="new-brand-color"
                      href="https://www.generalitravelinsurance.com/customer/disclosures.html"
                      target="_blank"
                      >Important Disclosures</a
                    >.
                  </div>
                </div>

                <div class="optable-fee">
                  <div
                    class="rent-info"
                    *ngIf="
                      quote.lodgingQuote &&
                      quote.lodgingQuote.LodgingSecurityDeposit__c +
                        quote.lodgingQuote.DamageProtectionDeposit__c >
                        0
                    "
                  >
                    <span> Refundable Security Deposit </span>
                    <span>{{
                      quote.lodgingQuote.LodgingSecurityDeposit__c +
                        quote.lodgingQuote.DamageProtectionDeposit__c | currency
                    }}</span>
                  </div>
                  <div
                    *ngIf="
                      this.quote.lodgingQuote.DamageProtectionDeposit__c &&
                      this.quote.lodgingQuote.DamageProtectionDeposit__c > 0
                    "
                    class="optable-disclosures brand-light-color"
                  >
                    A refundable security deposit has been applied because you
                    opted out of Damage Protection.
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  !quote.lodgingQuote &&
                  !unit.isInntopiaUnit__c &&
                  quote.quoteMM
                "
              >
                <div class="rent-info">
                  <span
                    [class.striked-out]="
                      quote.quoteMM.Discount__c &&
                      quote.quoteMM.Discount__c > 0 &&
                      quote.quoteMM.Discounted_Rent__c &&
                      quote.quoteMM.Discounted_Rent__c > 0
                    "
                  >
                    Rent
                  </span>
                  <span
                    [class.striked-out]="
                      quote.quoteMM.Discount__c &&
                      quote.quoteMM.Discount__c > 0 &&
                      quote.quoteMM.Discounted_Rent__c &&
                      quote.quoteMM.Discounted_Rent__c > 0
                    "
                    >{{ quote.quoteMM.TotalRent__c | currency }}</span
                  >
                </div>
                <div
                  *ngIf="
                    quote.quoteMM.Discount__c &&
                    quote.quoteMM.Discount__c > 0 &&
                    quote.quoteMM.Discounted_Rent__c &&
                    quote.quoteMM.Discounted_Rent__c > 0
                  "
                  class="rent-info"
                >
                  <span> Discounted rent</span>
                  <span>{{ quote.quoteMM.Discounted_Rent__c | currency }}</span>
                </div>

                <div class="rent-info">
                  <span class="rent-info">
                    <span>Taxes and Fees:</span>
                    <span *ngIf="!showTaxes">
                      <mat-icon
                        (click)="showTaxes = !showTaxes"
                        class="expand-arrow"
                        >keyboard_arrow_down</mat-icon
                      >
                    </span>
                    <span *ngIf="showTaxes">
                      <mat-icon
                        (click)="showTaxes = !showTaxes"
                        class="expand-arrow"
                        >keyboard_arrow_up</mat-icon
                      >
                    </span>
                  </span>
                  <span>
                    {{
                      quote.quoteMM.CleaningService__c +
                        quote.quoteMM.GuestServicePackage__c +
                        quote.quoteMM.PetFee__c +
                        quote.quoteMM.TAX__c | currency
                    }}
                  </span>
                </div>
                <div *ngIf="showTaxes">
                  <div class="rent-info-expanded">
                    <span> Cleaning Service </span>
                    <span>{{
                      quote.quoteMM.CleaningService__c | currency
                    }}</span>
                  </div>
                  <div class="rent-info-expanded">
                    <span> Guest Service Package </span>
                    <span>{{
                      quote.quoteMM.GuestServicePackage__c | currency
                    }}</span>
                  </div>
                  <div
                    *ngIf="quote.quoteMM.PetFee__c"
                    class="rent-info-expanded"
                  >
                    <span> Pet Fee </span>
                    <span>{{ quote.quoteMM.PetFee__c | currency }}</span>
                  </div>
                  <div class="rent-info-expanded">
                    <span> Tax</span>
                    <span> {{ quote.quoteMM.TAX__c | currency }}</span>
                  </div>
                </div>

                <div
                  class="rent-info-total brand-offwhite-background"
                  *ngIf="quote.quoteMM.Subtotal && quote.quoteMM.Subtotal > 0"
                >
                  <span> Subtotal </span>
                  <span>{{ quote.quoteMM.Subtotal | currency }}</span>
                </div>
                <br />
                <h3 class="quote-h3">
                  Select a damage deposit option to finalize your quote
                </h3>
                @if(!quote.quoteMM.RefundableDeposit__c ){
                <p>
                  This luxury estate requires a security deposit of ${{
                    this.quote.quoteMM.DamageProtectionFee__c
                  }}
                  on all bookings. This security deposit will be collected in
                  full at the time of final payment. Guests have the option to
                  purchase a Vacation Rental Damage Protection Policy in lieu of
                  a security deposit.
                </p>

                } @else {
                <p>
                  This luxury estate requires a security deposit of ${{
                    quote.quoteMM.RefundableDeposit__c +
                      this.getDamageProtectionDeposit(
                        this.quote.quoteMM.DamageProtectionFee__c
                      )
                  }}
                  on all bookings. This security deposit will be collected in
                  full at the time of final payment. Guests have the option to
                  purchase a Vacation Rental Damage Protection Policy to reduce
                  the security deposit by the Policy coverage limit.
                </p>
                }

                <mat-radio-group
                  aria-label="Vacation Rental Damage Protection"
                  formControlName="damageProtection"
                  [disabled]="booked"
                >
                  <div
                    class="optable-fee"
                    *ngIf="
                      quote &&
                      quote.quoteMM &&
                      (quote.quoteMM.RefundableDeposit__c +
                        quote.quoteMM.DamageProtectionDeposit__c >
                        0 ||
                        this.unit.PropertyManager__c == 'Mountain Management')
                    "
                  >
                    <div class="rent-info">
                      <span> Refundable Security Deposit </span>
                      <span>
                        <mat-radio-button [value]="false">
                          <span>{{
                            this.getDamageProtectionDeposit(
                              this.quote.quoteMM.DamageProtectionFee__c
                            ) | currency
                          }}</span>
                        </mat-radio-button>
                      </span>
                    </div>
                  </div>
                  <div
                    class="optable-fee"
                    *ngIf="
                      quote &&
                      quote.quoteMM &&
                      quote.quoteMM.DamageProtectionFee__c &&
                      quote.quoteMM.DamageProtectionFee__c > 0
                    "
                  >
                    <div class="rent-info">
                      <span> Vacation Rental Damage Protection </span>
                      <span>
                        <mat-radio-button [value]="true"
                          >{{ quote.quoteMM.DamageProtectionFee__c | currency }}
                        </mat-radio-button>
                      </span>
                    </div>
                    <div class="optable-details">
                      View complete
                      <a
                        class="new-brand-color"
                        href="https://www.csatravelprotection.com/certpolicy.do?product=G-20VRD"
                        target="_blank"
                        >Plan Details</a
                      >
                    </div>
                    <div class="optable-disclosures brand-light-color">
                      The plan cost includes the insurance premium and
                      assistance services fee. Insurance coverages are
                      underwritten by: Generali U.S. Branch, New York, NY; NAIC
                      # 11231, for the operating name used in certain states,
                      and other important information about the Insurance &
                      Assistance Services Plan, please see
                      <a
                        class="new-brand-color"
                        href="https://www.generalitravelinsurance.com/customer/disclosures.html"
                        target="_blank"
                        >Important Disclosures.</a
                      >
                    </div>
                    @if(quote.quoteMM.RefundableDeposit__c >0){
                    <div class="optable-disclosures brand-light-color">
                      Guests who opt to purchase the Vacation Rental Damage
                      Protection Policy will reduce the security deposit amount
                      by the Policy coverage limit. This Vacation Rental Damage
                      Protection policy can cover up to ${{
                        this.getDamageProtectionDeposit(
                          this.quote.quoteMM.DamageProtectionFee__c
                        )
                      }}
                      of qualifying accidental damage to the residence.
                    </div>
                    } @else {
                    <div class="optable-disclosures brand-light-color">
                      Guests who opt to purchase the Vacation Rental Damage
                      Protection Policy in lieu of the security deposit are
                      selecting a Damage Protection policy that can cover up to
                      ${{
                        this.getDamageProtectionDeposit(
                          this.quote.quoteMM.DamageProtectionFee__c
                        )
                      }}
                      of qualifying accidental damage to the residence.
                    </div>
                    }
                  </div>
                </mat-radio-group>

                @if(quote.quoteMM.RefundableDeposit__c >0){
                <div class="rent-info">
                  <span> Plus, Refundable Security Deposit </span>
                  <mat-checkbox [disabled]="true" [checked]="true">{{
                    quote.quoteMM.RefundableDeposit__c | currency
                  }}</mat-checkbox>
                </div>
                } @if(quote && quote.quoteMM && quote.quoteMM.Insurance__c){
                <h3 class="quote-h3">
                  Protect your vacation with Travel Insurance
                </h3>

                <div class="optable-details">
                  View complete
                  <a
                    class="new-brand-color"
                    href="https://www.csatravelprotection.com/certpolicy.do?product=GR330"
                    target="_blank"
                    >Plan Details</a
                  >
                </div>
                <div class="optable-disclosures brand-light-color">
                  The plan cost includes the travel insurance premium and
                  assistance services fee. Travel insurance coverages are
                  underwritten by: Generali U.S. Branch, New York, NY; NAIC #
                  11231, for the operating name used in certain states, and
                  other important information about the Travel Insurance &
                  Assistance Services Plan, please see
                  <a
                    class="new-brand-color"
                    href="https://www.generalitravelinsurance.com/customer/disclosures.html"
                    target="_blank"
                    >Important Disclosures</a
                  >.
                </div>

                <mat-radio-group
                  aria-label="Travel Protection Plan"
                  formControlName="insurance"
                  [disabled]="booked"
                >
                  <div class="optable-fee">
                    <div class="rent-info">
                      <span>
                        Yes, I wish to purchase a Travel Protection Plan
                      </span>
                      <span>
                        <mat-radio-button [value]="true"
                          >{{ quote.quoteMM.Insurance__c | currency }}
                        </mat-radio-button>
                      </span>
                    </div>
                  </div>
                  <div class="optable-fee">
                    <div class="rent-info">
                      <span>
                        No, I do not wish to purchase to Protect this trip
                      </span>
                      <span>
                        <mat-radio-button [value]="false"
                          >{{ 0 | currency }}
                        </mat-radio-button>
                      </span>
                    </div>
                  </div>
                </mat-radio-group>
                }
              </div>
              <!-- end *ngIf="!unit.isInntopiaUnit__c"-->

              <div
                *ngIf="
                  !quote.lodgingQuote &&
                  unit.isInntopiaUnit__c &&
                  quote.quoteInntopia
                "
              >
                <div
                  *ngIf="quote.quoteInntopia.savingsAmount > 0"
                  class="rent-info striked-out"
                >
                  <span> Rent</span>
                  <span>
                    {{ quote.quoteInntopia.baseDisplayRent | currency }}</span
                  >
                </div>
                <div class="rent-info">
                  <span *ngIf="quote.quoteInntopia.savingsAmount === 0"
                    >Rent</span
                  >
                  <span *ngIf="quote.quoteInntopia.savingsAmount > 0"
                    >Discounted Rent</span
                  >
                  <span> {{ quote.quoteInntopia.DisplayRent | currency }}</span>
                </div>
                <div class="rent-info">
                  <span> Taxes</span>
                  <span>
                    {{ quote.quoteInntopia.DisplayTaxes | currency }}</span
                  >
                </div>
                <div class="rent-info">
                  <span> Fees</span>
                  <span> {{ quote.quoteInntopia.DisplayFees | currency }}</span>
                </div>
                <div
                  class="rent-info"
                  *ngIf="taxesWarning"
                  style="font-style: italic"
                >
                  Please note that additional taxes and fees could be applied
                </div>

                <div class="rent-info-total brand-offwhite-background">
                  <span> Subtotal </span>
                  <span>{{
                    quote.quoteInntopia.DisplayRent +
                      quote.quoteInntopia.DisplayTaxes +
                      quote.quoteInntopia.DisplayFees | currency
                  }}</span>
                </div>
                <br />

                <div
                  class="optable-fee"
                  *ngIf="
                    quote &&
                    quote.quoteInntopia.insurance &&
                    quote.quoteInntopia.insurance > 0
                  "
                >
                  <div class="rent-info">
                    <span> Travel Protection Plan </span>
                    <span>
                      <mat-checkbox
                        name="insurance"
                        formControlName="insurance"
                        [checked]="insurance == true"
                        [disabled]="booked"
                        (change)="
                          insuranceChange(
                            quote.quoteInntopia.insurance,
                            $event,
                            quote.quoteInntopia.DisplayRent +
                              quote.quoteInntopia.DisplayTaxes +
                              quote.quoteInntopia.DisplayFees
                          )
                        "
                        >{{ quote.quoteInntopia.insurance | currency }}
                      </mat-checkbox>
                    </span>
                  </div>
                  <div class="optable-details">
                    View complete
                    <a
                      class="new-brand-color"
                      href="https://www.csatravelprotection.com/certpolicy.do?product=GR330"
                      target="_blank"
                      >Plan Details</a
                    >
                  </div>
                  <div class="optable-disclosures brand-light-color">
                    The plan cost includes the travel insurance premium and
                    assistance services fee. Travel insurance coverages are
                    underwritten by: Generali U.S. Branch, New York, NY; NAIC #
                    11231, for the operating name used in certain states, and
                    other important information about the Travel Insurance &
                    Assistance Services Plan, please see
                    <a
                      class="new-brand-color"
                      href="https://www.generalitravelinsurance.com/customer/disclosures.html"
                      target="_blank"
                      >Important Disclosures</a
                    >.
                  </div>
                </div>
              </div>
              <!-- end ngIf="unit.isInntopiaUnit__c-->

              <mat-divider></mat-divider>

              @if((quote && quote.quoteMM &&
              this.form.controls.damageProtection.value !== null) ||
              !quote.quoteMM){

              <div class="rent-info-total brand-offwhite-background">
                <span> Total </span>
                <span>{{ formTotalCost | currency }}</span>
              </div>
              <mat-divider></mat-divider>
              <div *ngIf="fullPaymentDue !== null">
                <div class="rent-info-right">
                  Your first payment is only
                  {{ formTotalDeposit | currency }}
                </div>
                <div class="rent-info-right-colored brand-show-more-color">
                  Full payment is due on {{ fullPaymentDue }}
                </div>
              </div>
              <div class="rent-info-right" *ngIf="fullPaymentDue === null">
                Your payment is {{ formTotalDeposit | currency }}
              </div>
              } @if(damageProtectionNotSetWarning){
              <p class="alert-warning">
                Please select either Vacation Rental Damage Protection, or
                Optional Security Deposit to continue.
              </p>
              } @if(travelInsuranceNotSetWarning){
              <p class="alert-warning">
                Please select Travel Protection Plan to continue.
              </p>
              }
            </div>
          </div>

          <!--*ngIf="!lodgingQuoteAvailabilityError && !errorMessage"-->

          <div *ngIf="(!quote || editMode) && editAllowed">
            <twbooking-quote-widget
              [unit]="unit"
              [currentQuote]="this.quote ? this.quote : null"
              [fullDesktop]="true"
              (quoteEmitter)="keepQuote($event)"
              [mode]="mode"
            >
            </twbooking-quote-widget>
          </div>

          <mat-action-row>
            <button
              mat-button
              type="button"
              *ngIf="!editMode && editAllowed"
              class="brand-button"
              (click)="editMode = true"
              [disabled]="booked"
            >
              Edit
            </button>
            <button
              mat-button
              type="button"
              *ngIf="editMode && this.formTotalCost > 0"
              class="brand-button"
              (click)="editMode = false"
            >
              Save
            </button>

            <button
              mat-button
              type="button"
              *ngIf="!editMode && this.formTotalCost > 0"
              class="brand-button"
              (click)="agreeStep()"
              [disabled]="booked"
            >
              Agree
            </button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel
          [disabled]="
            lodgingQuoteAvailabilityError ||
            errorMessage ||
            (quote &&
              quote.quoteMM &&
              (this.form.controls.damageProtection.value === null ||
                this.form.controls.insurance.value === null))
          "
          [expanded]="step === 1"
          (opened)="setStep(1)"
          hideToggle
        >
          <mat-expansion-panel-header
            class="brand-light-background"
            [class.highlighted-panel]="
              submittedNotValid &&
              (!form.controls.firstnameField.valid ||
                !form.controls.lastnameField.valid ||
                !form.controls.emailField.valid ||
                !form.controls.phoneField.valid)
            "
          >
            <mat-panel-title>
              <mat-icon class="new-brand-color">account_circle</mat-icon>
              Account
            </mat-panel-title>
            <mat-panel-description>
              Your contact information
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="flex-container-column" style="padding-top: 20px">
            <mat-form-field
              class="md-block"
              floatLabel="never"
              class="enquiry-dialog-form-field flex-field"
            >
              <input
                matInput
                required
                placeholder="First name"
                formControlName="firstnameField"
                name="firstnameField"
                [readonly]="booked == true ? true : null"
                (change)="userDataChanged('name', $event)"
              />
            </mat-form-field>
            <mat-form-field
              class="md-block"
              floatLabel="never"
              class="enquiry-dialog-form-field flex-field"
            >
              <input
                matInput
                required
                placeholder="Last name"
                formControlName="lastnameField"
                name="lastnameField"
                (change)="userDataChanged('lastName', $event)"
                [readonly]="booked == true ? true : null"
              />
            </mat-form-field>
            <mat-form-field
              class="md-block"
              class="enquiry-dialog-form-field flex-field"
            >
              <input
                matInput
                required
                type="email"
                placeholder="Email address"
                formControlName="emailField"
                name="emailField"
                (change)="userDataChanged('email', $event)"
                [readonly]="booked == true ? true : null"
              />
              <div
                *ngIf="
                  !form.controls.emailField.valid &&
                  form.controls.emailField.touched
                "
                class="alert alert-danger"
              >
                Invalid email address
              </div>
            </mat-form-field>
            <mat-form-field
              class="md-block"
              class="enquiry-dialog-form-field flex-field"
            >
              <input
                matInput
                required
                placeholder="Phone number"
                formControlName="phoneField"
                name="phoneField"
                (change)="userDataChanged('phone', $event)"
                [readonly]="booked == true ? true : null"
              />
            </mat-form-field>
          </div>
          <mat-action-row>
            <button
              mat-button
              type="button"
              class="brand-button"
              (click)="prevStep()"
            >
              Previous
            </button>
            <button
              mat-button
              type="button"
              class="brand-button"
              (click)="nextStep()"
            >
              Next
            </button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel
          [disabled]="
            lodgingQuoteAvailabilityError ||
            errorMessage ||
            (quote &&
              quote.quoteMM &&
              (this.form.controls.damageProtection.value === null ||
                this.form.controls.insurance.value === null))
          "
          [expanded]="step === 2"
          (opened)="setStep(2)"
          hideToggle
          [class.highlighted-panel]="
            submittedNotValid &&
            (!form.controls.addressLine1.valid ||
              !form.controls.city.valid ||
              !form.controls.state.valid ||
              !form.controls.zip.valid)
          "
        >
          <mat-expansion-panel-header class="brand-light-background">
            <mat-panel-title>
              <mat-icon class="new-brand-color">account_circle</mat-icon>
              Address
            </mat-panel-title>
            <mat-panel-description>
              Your billing address
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="flex-container-column" style="padding-top: 20px">
            <mat-form-field *ngIf="booked">
              <input
                type="text"
                matInput
                [placeholder]="form.get('country').value"
                readonly
              />
            </mat-form-field>
            <mat-form-field *ngIf="!booked">
              <div>
                <mat-select
                  required
                  placeholder="Country"
                  formControlName="country"
                  (selectionChange)="userDataChanged('country', $event)"
                >
                  @for (country of countryOptions; track country.value){
                  <mat-option
                    [value]="country.value"
                    class="no-checkbox-select"
                  >
                    {{ country.viewValue }}
                  </mat-option>
                  }
                </mat-select>
              </div>
            </mat-form-field>
            <mat-form-field *ngIf="expandedCountry">
              <input
                type="text"
                placeholder="Choose Country"
                aria-label="Number"
                matInput
                [formControl]="countryexp"
                [matAutocomplete]="auto"
                [readonly]="booked == true ? true : null"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="userDataChanged('country1', $event)"
              >
                @for (option of filteredCountries| async; track $index){
                <mat-option [value]="option">
                  {{ option }}
                </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                required
                type="text"
                placeholder="Address Line 1"
                formControlName="addressLine1"
                (change)="userDataChanged('addressLine1', $event)"
                [readonly]="booked == true ? true : null"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                type="text"
                placeholder="Address Line 2"
                formControlName="addressLine2"
                (change)="userDataChanged('addressLine2', $event)"
                [readonly]="booked == true ? true : null"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                required
                type="text"
                placeholder="City"
                formControlName="city"
                (change)="userDataChanged('city', $event)"
                [readonly]="booked == true ? true : null"
              />
            </mat-form-field>
            <mat-form-field
              *ngIf="
                (form.get('country').value === 'US' ||
                  form.get('country').value === 'CA') &&
                booked
              "
            >
              <input
                type="text"
                matInput
                [placeholder]="form.get('state').value"
                readonly
              />
            </mat-form-field>
            <mat-form-field
              *ngIf="
                (form.get('country').value === 'US' ||
                  form.get('country').value === 'CA') &&
                !booked
              "
            >
              <mat-select
                placeholder="State"
                required
                formControlName="state"
                (selectionChange)="userDataChanged('stateUS', $event)"
                [readonly]="booked == true ? true : null"
              >
                @for (state of states; track state.value){
                <mat-option [value]="state.value" class="no-checkbox-select">
                  {{ state.viewValue }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field
              *ngIf="
                form.get('country').value !== 'US' &&
                form.get('country').value !== 'CA'
              "
            >
              <input
                matInput
                type="text"
                placeholder="State"
                formControlName="state"
                (change)="userDataChanged('state', $event)"
                [readonly]="booked == true ? true : null"
              />
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                required
                type="text"
                placeholder="Zip"
                formControlName="zip"
                (change)="userDataChanged('zip', $event)"
                [readonly]="booked == true ? true : null"
              />
              <div *ngIf="form.controls.zip.touched"></div>
              <div
                *ngIf="!form.controls.zip.valid && form.controls.zip.touched"
                class="alert alert-danger"
              >
                Invalid Zip code
              </div>
            </mat-form-field>
          </div>
          <mat-action-row>
            <button
              mat-button
              type="button"
              class="brand-button"
              (click)="prevStep()"
            >
              Previous
            </button>
            <button
              mat-button
              type="button"
              class="brand-button"
              (click)="nextStep()"
            >
              Next
            </button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel
          [disabled]="
            lodgingQuoteAvailabilityError ||
            errorMessage ||
            (quote &&
              quote.quoteMM &&
              (this.form.controls.damageProtection.value === null ||
                this.form.controls.insurance.value === null))
          "
          [expanded]="step === 3"
          (opened)="setStep(3)"
          hideToggle
        >
          <mat-expansion-panel-header class="brand-light-background">
            <mat-panel-title>
              <mat-icon class="new-brand-color">grading</mat-icon>
              Terms
            </mat-panel-title>
            <mat-panel-description>
              Our Terms and Conditions
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span style="flex: 0 0 100%">
            <span class="terms-and-conditions-container">
              <mat-checkbox
                *ngIf="!booked"
                formControlName="agreed"
                name="agree4"
              >
              </mat-checkbox>
              <mat-checkbox checked="true" *ngIf="booked" disabled>
              </mat-checkbox>
              <span class="terms-and-conditions brand-dark-color">
                <span>I accept and understand the </span>&nbsp;
                <a
                  class="show-more-nav-no-padding"
                  href="https://www.thetravelwhisperer.com/terms-conditions/"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
                <span
                  *ngIf="
                    bookingInput &&
                    bookingInput.depositPolicies &&
                    bookingInput.depositPolicies.DepositPolicyDescription__c
                  "
                  class="terms-and-conditions"
                  >,
                </span>
                <span
                  *ngIf="
                    bookingInput &&
                    bookingInput.depositPolicies &&
                    bookingInput.depositPolicies.DepositPolicyDescription__c
                  "
                  class="terms-and-conditions"
                >
                  <a
                    class="show-more-nav-no-padding"
                    (click)="showDepositPolicy = !showDepositPolicy"
                  >
                    Deposit Policy
                  </a>
                </span>
                <span
                  *ngIf="bookingInput && bookingInput.cancellationPolicy"
                  class="terms-and-conditions"
                >
                  <span
                    *ngIf="
                      bookingInput &&
                      bookingInput.depositPolicies &&
                      bookingInput.depositPolicies.DepositPolicyDescription__c
                    "
                    >,
                  </span>
                  &nbsp;and&nbsp;
                  <a
                    class="show-more-nav-no-padding"
                    (click)="showCancellationPolicy = !showCancellationPolicy"
                  >
                    Cancellation Policy
                  </a>
                </span>
                <span>.</span>
              </span>
            </span>

            <div *ngIf="showDepositPolicy">
              <h4>Deposit Policy.</h4>
              <p>
                {{ bookingInput.depositPolicies.DepositPolicyDescription__c }}
              </p>
            </div>

            <div *ngIf="showCancellationPolicy">
              <h4>Cancellation Policy.</h4>
              <p>{{ bookingInput.cancellationPolicy }}</p>
            </div>
          </span>
          <section *ngIf="submittedNotValid || errorMessage || taxesWarning">
            <div
              *ngIf="
                (!errorMessage &&
                  !taxesWarning &&
                  !form.controls.firstnameField.valid) ||
                !form.controls.lastnameField.valid ||
                !form.controls.emailField.valid ||
                !form.controls.phoneField.valid ||
                !form.controls.addressLine1.valid ||
                !form.controls.city.valid ||
                !form.controls.state.valid ||
                !form.controls.zip.valid
              "
              class="alert-danger"
            >
              <p>
                <span
                  >* Please ensure that you have entered all the required
                  information on the
                </span>
                <span
                  class="clickable-step"
                  (click)="setStep(1)"
                  *ngIf="
                    !form.controls.firstnameField.valid ||
                    !form.controls.lastnameField.valid ||
                    !form.controls.emailField.valid ||
                    !form.controls.phoneField.valid
                  "
                >
                  Account step</span
                >
                <span
                  class="clickable-step"
                  (click)="setStep(2)"
                  *ngIf="
                    form.controls.firstnameField.valid &&
                    form.controls.lastnameField.valid &&
                    form.controls.emailField.valid &&
                    form.controls.phoneField.valid &&
                    (!form.controls.addressLine1.valid ||
                      !form.controls.city.valid ||
                      !form.controls.state.valid ||
                      !form.controls.zip.valid)
                  "
                >
                  Address step</span
                >.
              </p>
            </div>
            <div *ngIf="errorMessage" class="alert-danger">
              {{ this.errorMessage }}
            </div>
            <div *ngIf="taxesWarning" style="font-style: italic">
              <p>
                Please note that additional taxes and fees could be applied. One
                of our Personal Travel designers will contact you within 24
                hours to provide taxes information and collect payment for
                deposit. This reservation is provisional until payment is
                received.
              </p>
            </div>
          </section>
          <mat-action-row>
            <button
              *ngIf="!booked"
              mat-button
              class="brand-button"
              (click)="prevStep()"
            >
              Previous
            </button>
            <button
              mat-button
              type="submit"
              class="tw-primary-button enquiry-button brand-button"
              (click)="
                completeBooking(form.value, form.valid);
                scrollToElement(bottomAnchor)
              "
              [disabled]="
                !(form.get('agreed').value === true) ||
                booked ||
                formTotalCost <= 0 ||
                !quote ||
                errorMessage
              "
            >
              Complete Booking
            </button>
          </mat-action-row>
          <mat-action-row
            *ngIf="!taxesWarning && formTotalCost > 0 && quote && !errorMessage"
          >
            <div class="provisional-booking-text">
              <p>
                At this point your booking is provisional, <br />
                please proceed to payment on next screen to finalize.
              </p>
            </div>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel
          [disabled]="
            lodgingQuoteAvailabilityError ||
            errorMessage ||
            (quote &&
              quote.quoteMM &&
              (this.form.controls.damageProtection.value === null ||
                this.form.controls.insurance.value === null))
          "
          *ngIf="booked && !taxesWarning"
          [expanded]="step === 4"
          (opened)="setStep(4)"
          hideToggle
        >
          <mat-expansion-panel-header class="brand-light-background">
            <mat-panel-title>
              <mat-icon class="new-brand-color">credit_card</mat-icon>
              Payment
            </mat-panel-title>
            <mat-panel-description>
              Select payment method
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span style="flex: 0 0 100%">
            <p style="font-weight: bold">
              Your booking has been placed, please click on the button below to
              be redirected to a secure payment gateway where you will be
              presented with two payment options. Your booking will be secure
              once the initial payment is received.
            </p>
            <p>
              <span style="font-weight: bold" class="textColor"
                >Visa Secure Checkout</span
              ><br />
              Visa Checkout is a digital payment service designed to simplify
              the checkout experience using a secure, single sign-on across
              channels and devices using your preferred payment method.<br />
              We accept
              <span style="font-weight: bold" class="textColor">
                Visa, Mastercard and American Express </span
              ><br />
              <br /><span style="font-weight: bold" class="textColor">
                eCheck</span
              ><br />
              eCheck is a payment option allowing US and Canadian customers to
              pay directly from their bank accounts.
            </p>
          </span>
          <mat-action-row>
            <app-checkout-step1
              *ngIf="booked && !testBooking"
              [booking]="formTotalDeposit"
              [purchase]="purchase"
              [ref]="payRef"
              [formValues]="form.value"
              (loadingEmitter)="goLoading($event)"
            ></app-checkout-step1>
            <!-- CHANGE PAYMENT REF TO BOOKING NUMBER -->
            <!-- <button
                  mat-button
                  class="tw-primary-button enquiry-button"
                  (click)="setStep(6)"
                >
                  Book Now
                </button> -->
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>

      <section class="loading">
        <mat-progress-spinner
          id="bookingspinner"
          class="mat-spinner-color"
          [color]="grey"
          [mode]="'indeterminate'"
          [value]="10"
          [diameter]="25"
          *ngIf="loading"
        >
        </mat-progress-spinner>
        <div style="position: relative; top: 7px; left: -24px" *ngIf="loading">
          Submitting...
        </div>
      </section>
      <br />
      <div #bottomAnchor></div>

      <!--<mat-dialog-actions align="start" #bottomAnchor><button class="brand-button" mat-button mat-dialog-close>
                    Cancel
                </button></mat-dialog-actions>-->
    </div>
  </form>
</div>
