<div class="unit-details-tab-container">
  <div class="unit-details-tab" #topLine>
    <div
      [class.left-description]="!isMobile && (hasRates || !nb)"
      [class.mobile-description]="isMobile || (!hasRates && nb)"
    >
      <div
        [class.left-content]="!isMobile && (hasRates || !nb)"
        [class.mobile-content]="isMobile || (!hasRates && nb)"
      >
        <h1 class="title-heading">{{ unit.MarketingHeadline__c }}</h1>

        <div class="mat-subtitle-1 header-detail standard-text new-brand-color">
          <span>{{ unit?.Sleeps__c }} guests</span>
          <span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>
          <span>{{ unit?.Bedrooms__c }} bedrooms</span>
          <span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>
          <span *ngIf="totalBeds > 0">{{ totalBeds }} beds</span>
          <span *ngIf="totalBeds > 0"
            >&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span
          >
          <span>{{ unit?.Bathrooms__c }} bathrooms</span>
        </div>

        <div class="header-separator-left">
          <h2 class="title-heading">Overview</h2>
          <div class="separator"></div>
        </div>

        <div *ngIf="shortText">
          <div
            class="standard-text"
            [innerHTML]="
              (unit?.Long_Description__c | words : 200).text | safeHtml
            "
          ></div>
          @if(moreThen200words){
          <a
            class="show-more-nav"
            *ngIf="(unit?.Long_Description__c | words : 200).isTruncated"
            (click)="shortText = !shortText"
          >
            <span>Read more about the space</span>
            <span class="hover-arrows">
              <mat-icon class="right-arrow-classic"
                >keyboard_arrow_right</mat-icon
              >
              <mat-icon class="right-arrow-on-hover">arrow_forward</mat-icon>
            </span>
          </a>
          }
        </div>

        <div *ngIf="!shortText">
          <div
            class="unit-description"
            [innerHTML]="unit?.Long_Description__c | safeHtml"
          ></div>
          <a class="show-more-nav" (click)="shortText = !shortText"
            >Hide description <mat-icon>keyboard_arrow_left </mat-icon></a
          >
        </div>
        <br />

        <div
          *ngIf="
            unit.Coordinates__Latitude__s && unit.Coordinates__Longitude__s
          "
          class="maps-container"
          #mapsContainer
        >
          <twbooking-unit-map
            [lat]="unit.Coordinates__Latitude__s"
            [lng]="unit.Coordinates__Longitude__s"
            [title]="unit.MarketingHeadline__c"
            [image]="unit.Images__r[0] ? unit.Images__r[0].URL__c : null"
            [caption]="unit.Images__r[0] ? unit.Images__r[0].Caption__c : null"
          ></twbooking-unit-map>
        </div>

        <twbooking-unit-amenities [unit]="unit"></twbooking-unit-amenities>

        <span #discountAnchor style="height: 0"></span>
        <div *ngIf="processedDeals && processedDeals.length > 0">
          <div class="header-separator-left">
            <h2 class="title-heading">Discounts</h2>
            <div class="separator"></div>
          </div>
          <div *ngIf="!showMoreDeals">
            <div>
              <div *ngIf="!this.unit.isInntopiaUnit__c">
                <h4>{{ processedDeals[0].Name }}</h4>
                <p [innerHTML]="processedDeals[0].Description__c"></p>
              </div>
              <div *ngIf="this.unit.isInntopiaUnit__c">
                <h4>{{ processedDeals[0].Name }}*</h4>
                @for (details of processedDeals[0].Details; track $index; let
                index = $index) {
                <ul>
                  <li>
                    {{ details.Discount__c }}% off {{ details.MinNights__c
                    }}<span
                      *ngIf="
                        details.MinNights__c !== details.MaxNights__c &&
                        details.MaxNights__c <= 10
                      "
                      >-{{ details.MaxNights__c }}</span
                    >
                    <span *ngIf="details.MaxNights__c > 10"> or more </span>
                    nights
                  </li>
                </ul>
                }
                <p>
                  *for stays
                  {{ processedDeals[0].ValidStart__c | date : "mediumDate" }} to
                  {{ processedDeals[0].ValidEnd__c | date : "mediumDate" }}
                  booked until
                  {{ processedDeals[0].BookByDate__c | date : "mediumDate" }}
                </p>
              </div>
            </div>
            <a
              *ngIf="processedDeals.length > 1"
              class="show-more-nav"
              (click)="showMoreDeals = !showMoreDeals"
              ><span>Show more deals</span>
              <span class="hover-arrows">
                <mat-icon class="right-arrow-classic"
                  >keyboard_arrow_right</mat-icon
                >
                <mat-icon class="right-arrow-on-hover">arrow_forward</mat-icon>
              </span>
            </a>
          </div>

          <div *ngIf="showMoreDeals">
            @for (promo of processedDeals; track promo.PromotionCode__c) {
            <div style="text-align: left">
              <div *ngIf="!this.unit.isInntopiaUnit__c">
                <h4>{{ promo.Name }}*</h4>
                <p [innerHTML]="processedDeals[0].Description__c"></p>
              </div>
              <div *ngIf="this.unit.isInntopiaUnit__c">
                <h4>{{ promo.Name }}*</h4>
                @for (details of promo.Details; track $index){
                <ul>
                  <li>
                    {{ details.Discount__c }}% off {{ details.MinNights__c
                    }}<span
                      *ngIf="
                        details.MinNights__c !== details.MaxNights__c &&
                        details.MaxNights__c <= 10
                      "
                      >-{{ details.MaxNights__c }}</span
                    >
                    <span *ngIf="details.MaxNights__c > 10"> or more </span>
                    nights
                  </li>
                </ul>
                }
                <p>
                  *for stays {{ promo.ValidStart__c | date : "mediumDate" }} to
                  {{ promo.ValidEnd__c | date : "mediumDate" }} booked until
                  {{ promo.BookByDate__c | date : "mediumDate" }}
                </p>
              </div>
            </div>
            }
            <a class="show-more-nav" (click)="showMoreDeals = !showMoreDeals"
              ><span>Hide deals</span>
              <mat-icon>keyboard_arrow_left</mat-icon>
            </a>
          </div>
          <br />
        </div>

        <div #reviewsAnchor>
          <twbooking-unit-reviews
            [unit]="unit"
            (rating)="getRating($event)"
          ></twbooking-unit-reviews>
        </div>

        <br #bottomLine />
      </div>
    </div>

    <!------------- Widget Desktop ----------------------------------------->

    <div
      class="right-widget-container brand-offwhite-background"
      *ngIf="!isMobile && !(!hasRates && nb)"
    >
      <tr
        #stickyMenu
        [class.sticky]="sticky === 'STICKY'"
        [class.sticky-bottom]="sticky === 'BOTTOM'"
        [class.sticky-top]="sticky === 'TOP'"
      >
        <mat-card appearance="outlined" *ngIf="!hasRates && !nb">
          <div class="top-icons-space-between">
            <a
              (click)="scrollToElement(reviewsAnchor)"
              fxLayout="row"
              fxLayoutAlign="start center"
              *ngIf="reviewsRating >= 0 && reviewsTotal > 0"
            >
              @for (ratingId of ratingArr; track $index; let i = $index) {
              <span [id]="'star_' + i">
                <mat-icon class="review-stars review-stars-large">
                  {{ showStarsIcon(i, reviewsRating) }}</mat-icon
                >
              </span>
              }
              <span style="margin-top: -6px">({{ reviewsTotal }})</span>
            </a>
            <div *ngIf="!(reviewsRating >= 0 && reviewsTotal > 0)"></div>

            <div
              *ngIf="!nb && !favoriteUnit"
              class="favorite-icon clickable-icon"
              (click)="saveFavorite()"
              matTooltip="Please like the units that you are interested in to help our personal travel designers find the best options for you"
            >
              <mat-icon class="brand-accent-color">favorite</mat-icon>
            </div>
            <div
              *ngIf="!nb && favoriteUnit"
              class="favorite-icon clickable-icon"
              (click)="removeFavorite()"
            >
              <mat-icon class="red-color white-border">favorite</mat-icon>
            </div>
          </div>

          <br *ngIf="reviewsRating >= 0 && reviewsTotal > 0" />
          <mat-divider
            *ngIf="reviewsRating >= 0 && reviewsTotal > 0"
          ></mat-divider>
          <p>Please contact our team to get a quote on this property</p>
          <div>
            <button
              mat-button
              class="brand-button full-width"
              (click)="enquiryNow()"
            >
              Inquire now
            </button>
          </div>
        </mat-card>
        <mat-card appearance="outlined" *ngIf="hasRates">
          <div class="top-icons-space-between">
            <a
              (click)="scrollToElement(reviewsAnchor)"
              fxLayout="row"
              fxLayoutAlign="start center"
              *ngIf="reviewsRating >= 0 && reviewsTotal > 0"
            >
              @for (ratingId of ratingArr; track $index; let i = $index) {
              <span [id]="'star_' + i">
                <mat-icon class="review-stars">
                  {{ showStarsIcon(i, reviewsRating) }}</mat-icon
                >
              </span>
              }
              <span style="margin-top: -6px">({{ reviewsTotal }})</span>
            </a>
            <div *ngIf="!(reviewsRating >= 0 && reviewsTotal > 0)"></div>
            <div
              *ngIf="!nb && !favoriteUnit"
              class="favorite-icon clickable-icon"
              (click)="saveFavorite()"
              matTooltip="Please like the units that you are interested in to help our personal travel designers find the best options for you"
            >
              <mat-icon class="brand-dark-color">favorite_border</mat-icon>
            </div>
            <div
              *ngIf="!nb && favoriteUnit"
              class="favorite-icon clickable-icon"
              (click)="removeFavorite()"
            >
              <mat-icon class="red-color">favorite</mat-icon>
            </div>
          </div>

          <br />
          <mat-divider
            *ngIf="reviewsRating >= 0 && reviewsTotal > 0"
          ></mat-divider>
          <twbooking-quote-widget
            [unit]="unit"
            [fullDesktop]="true"
            (quoteEmitter)="keepQuote($event)"
            mode="StandardBooking"
          >
          </twbooking-quote-widget>
          <br />
          <div>
            <div *ngIf="hasRates && !nb">
              <button
                mat-button
                class="brand-button half-size right-button"
                (click)="bookNow()"
              >
                Book now
              </button>
            </div>
            <div *ngIf="!nb">
              <button
                mat-button
                class="brand-button half-size left-button"
                (click)="enquiryNow()"
              >
                Inquire now
              </button>
            </div>
          </div>

          <div *ngIf="processedDeals && processedDeals.length > 0">
            <a (click)="scrollToElement(discountAnchor)" class="show-more-nav">
              <span>Available deals</span>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </a>
          </div>
        </mat-card>
      </tr>
    </div>
  </div>
</div>

<!------------- Widget Mobile ----------------------------------------->

<tr class="rate-widget-norates" *ngIf="isMobile && !hasRates && !nb">
  <mat-card
    appearance="outlined"
    class="dialogue-headline brand-light-background rate-widget-card"
  >
    <twbooking-quote-widget
      [unit]="unit"
      [fullDesktop]="false"
      (quoteEmitter)="keepQuote($event)"
      mode="StandardBooking"
    >
    </twbooking-quote-widget>
    <div class="contact-us">
      Please contact our team to get a quote on this property
    </div>
  </mat-card>
</tr>

<tr
  class="brand-light-background rate-widget-mobile"
  *ngIf="isMobile && hasRates"
>
  <twbooking-quote-widget
    [unit]="unit"
    [fullDesktop]="false"
    (quoteEmitter)="keepQuote($event)"
    mode="StandardBooking"
  >
  </twbooking-quote-widget>
</tr>
