<mat-card
  appearance="outlined"
  class="unit-summary-card white-background new-brand-color flex-container-column"
>
  <div (swipe)="onSwipe($event)" class="img-default">
    <twbooking-summary-carousel
      complex="true"
      [images]="images"
      [show]="choosenAmenity"
      [slug]="complex.UnitSlug__c"
      [searchQuery]="searchQuery"
      [lazyLoad]="isMobile"
    >
    </twbooking-summary-carousel>

    <span
      *ngIf="complex.promounits > 0"
      class="discount-sticker ttw-projected ttw-projected-paddings"
    >
      Discounts available
    </span>
  </div>

  <div
    class="summary-info-container new-brand-color container-default flex-even-column"
  >
    <h1 class="summary-info-title new-brand-color">
      <a
        class="summary-info-title new-brand-color"
        [routerLink]="['/complex-details', complex.UnitSlug__c]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
        >{{ complex.MarketingHeadline__c }}</a
      >
    </h1>

    <div class="summary-line-container">
      <span
        ><a
          [routerLink]="['/complex-details', complex.UnitSlug__c]"
          queryParamsHandling="preserve"
          [fragment]="'accommodations'"
          class="show-more-link-complex"
          >{{ complex.unitsSummary }}</a
        ></span
      >
      <div class="reviews-container" *ngIf="complex.ReviewRating > 0">
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="complex.ReviewRating > 3.8"
          >star</mat-icon
        >
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="complex.ReviewRating > 1 && complex.ReviewRating <= 3.8"
          >star_half</mat-icon
        >
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="complex.ReviewRating <= 1"
          >star_border</mat-icon
        >
        <span class="review-text">
          {{ complex.ReviewRating | number : "1.2-2" }} ({{
            complex.ReviewCount
          }})
        </span>
      </div>
    </div>

    <div class="summary-actions new-brand-color">
      <!--summary-info-actions-->

      <a
        class="unit-summary-button primary-button"
        mat-button
        [routerLink]="['/complex-details', complex.UnitSlug__c]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
        >View details</a
      >
      <a
        *ngIf="!nb"
        class="unit-summary-button tertiary-button"
        mat-button
        (click)="inquireNow()"
        >Inquire now</a
      >
      <span class="hidden-button"></span>
      <span *ngIf="nb" class="hidden-button"></span>
    </div>
  </div>
</mat-card>
