import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnitsService } from '../../services/units.service';
import { Unit } from '../../store/models/unit.model';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { FavoritesService } from 'src/app/services/favorites.service';
import { Subscription } from 'rxjs';
import { FavoriteSnackComponent } from '../favorite-snack/favorite-snack.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  localhost,
  mainsite,
  mm,
  staging,
} from 'src/app/store/models/hostings';
import * as moment from 'moment';

@Component({
  selector: 'twbooking-unit-details',
  templateUrl: './unit-details.component.html',
  styleUrls: ['./unit-details.component.scss'],
})
export class UnitDetailsComponent implements OnInit, OnDestroy {
  private sub: any;
  unitCode: string;
  unitSlug: string;
  unit: Unit = null;
  unitExist = true;
  isMobile = false;
  loading = true;
  nb = true;

  favoritesSub: Subscription;
  private subMedia: Subscription;
  favoriteUnit = false;

  constructor(
    private route: ActivatedRoute,
    private unitService: UnitsService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private metaTagService: Meta,
    private location: Location,
    private favoritesService: FavoritesService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );
    this.loading = true;
    let theDay = this.route.snapshot.queryParams.StartDate
      ? moment.utc(this.route.snapshot.queryParams.StartDate)
      : null;
    let season: 'Winter' | 'Summer' | 'None' = theDay
      ? this.pickSeason(theDay)
      : 'None';

    this.sub = this.route.params.subscribe((params) => {
      this.unitCode = params['unitSlug__c'];
      this.unit = null;

      this.unitService.getUnit(this.unitCode).subscribe((data: any) => {
        if (data && data.length > 0) {
          this.unit = data[0];

          let imagesRes = this.unitService.prepareImages(
            season,
            this.unit.Images__r
          );
          this.unit.ImagesProcessed = imagesRes.ImagesProcessed;
          if (this.unit && this.unit.MarketingHeadline__c) {
            this.titleService.setTitle(
              `${this.unit.Name} ${this.unit.PropertyManager__c}`
            );
            this.metaTagService.updateTag({
              name: 'description',
              content: `${this.unit.MarketingHeadline__c}`,
            });
            this.favoriteUnit = this.favoritesService.isFavorite({
              unit: this.unit.externalId,
              Name: this.unit.Name,
            });
            this.favoritesSub = this.favoritesService.favorites$.subscribe(
              (newList) => {
                this.favoriteUnit = this.favoritesService.isFavorite({
                  unit: this.unit.externalId,
                  Name: this.unit.Name,
                });
              }
            );
          } else {
            console.error('marketing headline does not exist for ', this.unit);
          }
          this.loading = false;
          this.unitExist = true;
        } else {
          this.loading = false;
          this.unitExist = false;
          throw new Error(
            'Get Unit problem. User requested unit ' +
              this.unitCode +
              '; URL params: ' +
              params
          );
        }
      });
    });

    this.subMedia = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  pickSeason(arrival: moment.Moment): 'Winter' | 'Summer' {
    let month = arrival.month(); // jan=0, dec=11
    let season: 'Winter' | 'Summer' =
      month > 2 && month < 10 ? 'Summer' : 'Winter';
    return season;
  }

  back(): void {
    this.location.back();
  }

  saveFavorite() {
    console.log('saveFavorite');
    this.favoriteUnit = true;
    this.favoritesService.add({
      unit: this.unit.externalId,
      Name: this.unit.Name,
    });
    this.openSnackBar(true);
  }

  removeFavorite() {
    console.log('removeFavorite');
    this.favoriteUnit = false;
    this.favoritesService.remove({
      unit: this.unit.externalId,
      Name: this.unit.Name,
    });
    this.openSnackBar(false);
  }
  openSnackBar(saved: boolean) {
    this._snackBar.openFromComponent(FavoriteSnackComponent, {
      duration: 3 * 1000,
      data: { saved: saved },
      panelClass: ['brand-snackbar'],
    });
  }

  ngOnDestroy() {
    if (this.favoritesSub) this.favoritesSub.unsubscribe();
    if (this.subMedia) this.subMedia.unsubscribe();
  }
}
