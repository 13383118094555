import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { SearchParamsService } from 'src/app/services/search-params.service';
import * as moment from 'moment';
import { UnitsService } from 'src/app/services/units.service';
import { BookedDatesService } from 'src/app/services/booked-dates.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MMRate,
  InntopiaRates,
  QuoteData,
} from 'src/app/store/models/rate.models';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { fadeIn, fadeOut } from '../../utils/animations/fade-animations';
import { trigger } from '@angular/animations';
import { UnitEnquiryDialogComponent } from '../../units/unit-booking/unit-enquiry-dialog/unit-enquiry-dialog.component';
import { BookingDialogComponent } from '../../units/unit-booking/unit-booking-dialog/unit-rates.component';
import { MatDialog } from '@angular/material/dialog';
import { mainsite, mm, staging, localhost } from '../../store/models/hostings';
import { ChatService } from 'src/app/services/chat.service';
import { AuthService } from 'src/app/services/auth.service';
import { LodgingQuoteService } from 'src/app/services/lodging-quote.service';

@Component({
  selector: 'twbooking-quote-widget',
  templateUrl: './quote-widget.component.html',
  styleUrls: ['./quote-widget.component.scss'],
  animations: [trigger('fadeOut', fadeOut()), trigger('fadeIn', fadeIn())],
})
export class QuoteWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() unit: any; // Unit or UnitSummary
  @Input() currentQuote?: QuoteData;
  @Input() fullDesktop: boolean; // Unit or UnitSummary
  @Output() quoteEmitter = new EventEmitter<QuoteData>();
  @Input() mode: 'StandardBooking' | 'LodgingQuote';

  quote: QuoteData;

  /* Dates */
  fastRatesWidget: UntypedFormGroup;
  arrivalDate: moment.Moment;
  departureDate: moment.Moment;
  departureStart: moment.Moment;
  bookedDates = []; // array of booked (unavailable) days in format YYYY-MM-DD
  myStay: number; // length of stay
  minStay: number; // minimal allowed stay
  maxDate = moment.utc(new Date()).add(550, 'days');
  /* Guests */
  numAdults = 2;
  numChildren = 0;
  numPets = 0;
  petOptions: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  guests: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  loading = false;
  firstAvailableDay: moment.Moment = null;
  guests_children: number[] = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
  ];
  /* Rent */
  rateDetails: MMRate;
  inntopiaRate: InntopiaRates;
  inntopiaPromo: InntopiaRates;
  inntopiaBaseRate: InntopiaRates;
  TotalCost = 0;
  Rent = 0;
  rateLoaded = false;
  showdetails = false;
  taxesWarning = null;
  /* Errors */
  errorMessage: string = null;
  /* Display */
  isMobile = false;
  subMedia: Subscription;
  isNarrow = false;

  datesfromSearch = false;
  explicit = false;

  reducedMobileWidget = true;
  nb = true;
  /* chat subscription */
  userIdSub: Subscription;
  chatIdSub: Subscription = null;
  statusSub: Subscription;
  newMessagesSub: Subscription = null;
  id: string = null;
  chatId: string = null;
  newMessage = false;
  dialogStatus: 'opened' | 'closed' = 'closed';
  status: 'On' | 'Off' | null = null;

  collectionObject: any = {
    COLLECTION_KEY: 'user',
    UUID_KEY: null,
    MESSAGE_COLLECTION_NAME: 'chat',
  };
  /* chat subscription end*/
  constructor(
    private _fastRatesWidget: UntypedFormBuilder,
    private searchService: SearchParamsService,
    private unitService: UnitsService,
    private bookedDatesService: BookedDatesService,
    private lodgingQuoteService: LodgingQuoteService,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    public chatService: ChatService
  ) {
    this.isNarrow = window.innerHeight <= 560;
    this.subMedia = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
    this.id = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isNarrow = window.innerHeight <= 560;
  }

  ngOnInit() {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );

    this.fastRatesWidget = this._fastRatesWidget.group({
      arrivalForRates: [null, [Validators.required]],
      departureForRates: [null, [Validators.required]],
    });
    this.reducedMobileWidget = true;
    if (this.unit.DisplayRates__c) {
      this.getBookedDates();
      if (this.currentQuote) {
        this.prepopulateQuoteData();
      } else {
        this.prepopulateData();
      }
      this.lodgingQuoteService.holdExists$.subscribe(
        (newHoldAdded: boolean) => {
          if (newHoldAdded) {
            let hasException = this.lodgingQuoteService.holdDates.get(
              this.unit.externalId
            );
            if (hasException) {
              this.getBookedDates();
              if (this.currentQuote) {
                this.prepopulateQuoteData();
              } else {
                this.prepopulateData();
              }
            }
          }
        }
      );
    }
    this.newMessage = false;
    this.userIdSub = this.authService.userUID$.subscribe((id: string) => {
      this.id = id;
      this.collectionObject.UUID_KEY = this.id;
    });
    this.statusSub = this.chatService.botStatus$.subscribe(
      (newStatus) => (this.status = newStatus)
    );
  }

  ngOnChanges(changes) {
    if (changes.unit) {
      this.unit = changes.unit.currentValue;
      this.reducedMobileWidget = true;
    }
    if (changes.fullDesktop) {
      this.fullDesktop = changes.fullDesktop.currentValue;
    }
  }

  prepopulateQuoteData() {
    this.explicit = true;

    if (this.currentQuote.adults) {
      this.numAdults = this.currentQuote.adults;
    }
    if (this.currentQuote.children) {
      this.numChildren = this.currentQuote.children;
    }
    if (this.currentQuote.pets) {
      this.numPets = this.currentQuote.pets;
    }
    if (this.currentQuote.arrival && this.currentQuote.departure) {
      this.fastRatesWidget.controls.arrivalForRates.setValue(
        moment.utc(this.currentQuote.arrival, 'YYYY-MM-DD')
      );
      this.fastRatesWidget.controls.departureForRates.setValue(
        moment.utc(this.currentQuote.departure, 'YYYY-MM-DD')
      );
      this.checkAndGetQuote(
        this.unit.externalId,
        moment.utc(this.currentQuote.arrival, 'YYYY-MM-DD'),
        moment.utc(this.currentQuote.departure, 'YYYY-MM-DD')
      );
    }
  }
  prepopulateData() {
    /* Parsing URL if users comes from search -> then get and check all filled data */
    this.route.queryParams.subscribe((params) => {
      if (params.Adults) {
        this.numAdults = +params.Adults;
      }
      if (params.Children) {
        this.numChildren = +params.Children;
      }
      if (params.StartDate && params.EndDate) {
        this.datesfromSearch = true;
        this.explicit = false;
        this.checkAndGetQuote(
          this.unit.externalId,
          moment.utc(params.StartDate, 'YYYY-MM-DD'),
          moment.utc(params.EndDate, 'YYYY-MM-DD')
        );
      }
    });
    if (!this.datesfromSearch) {
      const savedSearch = this.searchService.get();

      if (savedSearch && savedSearch.Adults) {
        this.numAdults = +savedSearch.Adults;
      }
      if (savedSearch && savedSearch.Children) {
        this.numChildren = +savedSearch.Children;
      }
      if (
        savedSearch &&
        savedSearch.Pets &&
        (this.unit.Pets_Allowed__c || this.unit.Dogs_Allowed__c)
      ) {
        this.numPets = +savedSearch.Pets;
      } else {
        this.numPets = 0;
      }
      if (savedSearch && savedSearch.StartDate && savedSearch.EndDate) {
        /* If we have saved dates */
        this.checkAndGetQuote(
          this.unit.externalId,
          moment.utc(savedSearch.StartDate, 'YYYY-MM-DD'),
          moment.utc(savedSearch.EndDate, 'YYYY-MM-DD')
        );
      } else {
        if (this.firstAvailableDay !== null) {
          this.explicit = false;
          this.checkAndGetQuote(
            this.unit.externalId,
            this.firstAvailableDay,
            this.firstAvailableDay.clone().add(4, 'days')
          );
        }
      }
    }
  }

  checkAndGetQuote(unitId, StartDate, EndDate) {
    if (this.checkRange(StartDate, EndDate)) {
      /* And requested dates range is allowed - then set all requered params and get quote */
      this.arrivalDate = StartDate;
      this.departureDate = EndDate;
      this.myStay = moment
        .duration(this.departureDate.diff(this.arrivalDate))
        .asDays();
      /* then start instant quote */
      this.fastRatesWidget.controls['arrivalForRates'].setValue(
        this.arrivalDate
      );
      this.fastRatesWidget.controls['departureForRates'].setValue(
        this.departureDate
      );
      this.errorMessage = null;
      this.getQuote();
    } else {
      if (this.mode === 'LodgingQuote') {
        let lodgingQuoteExist =
          this.lodgingQuoteService.checkLodgingQuoteForDates(
            unitId,
            StartDate,
            EndDate
          );
        if (lodgingQuoteExist) {
          this.errorMessage =
            'Oh no! This property may have been booked already. Please contact your Personal Travel Designer for help.';
        }
      }
    }
  }

  checkRange(startDay: moment.Moment, endDay: moment.Moment): boolean {
    // checks if all the dates inside range are available
    let checked = true;
    const checkDay = startDay.clone();
    while (checked && checkDay.isSameOrBefore(endDay)) {
      const day = checkDay.format('YYYY-MM-DD');
      checked = !this.bookedDates.includes(day);
      checkDay.add(1, 'days');
    }
    return checked;
  }

  getBookedDates() {
    if (this.bookedDatesService.get(this.unit.externalId) !== undefined) {
      this.bookedDates = this.bookedDatesService.get(this.unit.externalId);
    } else {
      this.bookedDatesService
        .getBookedDates(
          this.unit.externalId,
          this.unit.isInntopiaUnit__c,
          this.unit.isInntopiaUnit__c ? this.unit.inntopiaSupplierId__c : null,
          this.unit.isInntopiaUnit__c ? this.unit.inntopiaProductId__c : null
        )
        .subscribe((response) => {
          this.bookedDates = response;
          let day_moment = moment.utc().startOf('day').add(1, 'days');
          let day = day_moment.format('YYYY-MM-DD');
          while (
            this.bookedDates.includes(day) &&
            day_moment.isBefore(this.maxDate)
          ) {
            day_moment = day_moment.clone().add(1, 'days');
            day = day_moment.format('YYYY-MM-DD');
          }
          if (
            !this.bookedDates.includes(day) &&
            day_moment.isBefore(this.maxDate)
          ) {
            this.firstAvailableDay = day_moment.clone();
            if (!this.arrivalDate) {
              this.checkAndGetQuote(
                this.unit.externalId,
                this.firstAvailableDay,
                this.firstAvailableDay.clone().startOf('day').add(4, 'days')
              );
            }
          }
        });
    }
  }

  arrivalChanged(event) {
    this.arrivalDate = moment.utc(event.value);
    this.explicit = true;
    this.reset();
    if (
      !this.departureDate ||
      this.arrivalDate.isSameOrAfter(this.departureDate) ||
      !this.checkRange(this.arrivalDate, this.departureDate)
    ) {
      this.departureDate = this.arrivalDate.clone();
      this.departureDate.add(4, 'days');
      if (this.checkRange(this.arrivalDate, this.departureDate)) {
        this.fastRatesWidget.controls['departureForRates'].setValue(
          this.departureDate
        );
      } else {
        this.departureDate = null;
        this.fastRatesWidget.controls['departureForRates'].setValue(null);
      }
    }
    if (!this.departureDate) {
      this.departureStart = this.arrivalDate.clone();
    }
    if (this.arrivalDate && this.departureDate) {
      let searchParams = this.searchService.get();
      searchParams = {
        ...searchParams,
        StartDate: this.arrivalDate.format('YYYY-MM-DD'),
        EndDate: this.departureDate.format('YYYY-MM-DD'),
      };
      this.searchService.update(searchParams);
      this.getQuote();
    }
  } // end arrivalChanged

  departureChanged(event) {
    this.reset();
    this.explicit = true;
    this.departureDate = moment.utc(event.value);
    if (this.arrivalDate) {
      let searchParams = this.searchService.get();
      searchParams = {
        ...searchParams,
        StartDate: this.arrivalDate.format('YYYY-MM-DD'),
        EndDate: this.departureDate.format('YYYY-MM-DD'),
      };
      this.searchService.update(searchParams);
      this.getQuote();
    }
  }

  arrivalFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc(Date.now());
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    const day_moment = moment.utc(d_any);
    return (
      !this.bookedDates.includes(day) &&
      day_moment.isAfter(today) &&
      day_moment.isBefore(this.maxDate)
    );
  };

  departureFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc(Date.now());
    const day_moment = moment.utc(d_any);
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    if (this.arrivalDate !== null) {
      return (
        !this.bookedDates.includes(day) &&
        d_any.isAfter(today) &&
        d_any.isSameOrAfter(moment.utc(this.arrivalDate)) &&
        day_moment.isBefore(this.maxDate)
      );
    } else {
      return (
        !this.bookedDates.includes(day) &&
        day_moment.isAfter(today) &&
        day_moment.isBefore(this.maxDate)
      );
    }
  };

  getQuote() {
    if (this.nb) {
      this.errorMessage = 'Please contact us to get a quote';
    } else {
      this.loading = true;
      this.errorMessage = null;
      if (this.numAdults + this.numChildren > this.unit.Sleeps__c) {
        this.errorMessage =
          'Please note that the maximum occupancy for this property is ' +
          this.unit.Sleeps__c.toString() +
          'guests.';
        this.quoteEmitter.emit({ errorMessage: this.errorMessage });
        return;
      }
      this.myStay = moment
        .duration(this.departureDate.diff(this.arrivalDate))
        .asDays();
      const quoteData = null;
      this.TotalCost = 0;
      this.Rent = 0;
      if (this.checkRange(this.arrivalDate, this.departureDate)) {
        this.unitService
          .getQuote(
            this.unit.externalId,
            this.unit,
            this.numAdults,
            this.numChildren,
            this.numPets,
            this.arrivalDate,
            this.departureDate,
            this.explicit
          )
          .subscribe((result) => {
            if (result.minStay) {
              this.minStay = result.minStay;
            }
            if (result.errorMessage) {
              this.errorMessage = result.errorMessage;
              this.quote = { errorMessage: result.errorMessage };
              this.quoteEmitter.emit(this.quote);
            }

            if (result.Total) {
              this.TotalCost = result.Total;

              if (result.rateDetails) {
                this.rateDetails = result.rateDetails;
                this.Rent = this.rateDetails.TotalRent__c;
                if (
                  !this.rateDetails ||
                  !this.rateDetails.TotalCost__c ||
                  !this.rateDetails.TotalRent__c ||
                  this.rateDetails.TotalRent__c <= 0 ||
                  this.rateDetails.TotalCost__c <= 0 ||
                  (this.rateDetails.Discount__c &&
                    this.rateDetails.Discount__c > 0 &&
                    (!this.rateDetails.Discounted_Rent__c ||
                      this.rateDetails.Discounted_Rent__c <= 0))
                ) {
                  this.errorMessage =
                    'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates.';
                  this.rateDetails = null;
                  this.quote = {
                    errorMessage:
                      'Online booking is unavailable for these dates. Please change dates or contact our team to book this property.',
                  };

                  this.quoteEmitter.emit(this.quote);
                } else {
                  this.errorMessage = null;
                  if (this.rateDetails.arrival && this.rateDetails.departure) {
                    this.arrivalDate = moment.utc(this.rateDetails.arrival);
                    this.departureDate = moment.utc(this.rateDetails.departure);

                    this.fastRatesWidget.controls['arrivalForRates'].setValue(
                      this.arrivalDate
                    );
                    this.fastRatesWidget.controls['departureForRates'].setValue(
                      this.departureDate
                    );
                  }
                  if (this.rateDetails.numAdults > 0) {
                    this.numAdults = this.rateDetails.numAdults;
                  }
                  if (this.rateDetails.numChildren !== undefined) {
                    this.numChildren = this.rateDetails.numChildren;
                  }
                  if (this.rateDetails.numPets !== undefined) {
                    this.numPets = this.rateDetails.numPets;
                  }
                  this.quote = {
                    arrival: this.arrivalDate.format('YYYY-MM-DD'),
                    departure: this.departureDate.format('YYYY-MM-DD'),
                    adults: this.numAdults,
                    children: this.numChildren,
                    pets: this.numPets,
                    quoteMM: this.rateDetails,
                  };
                  this.quoteEmitter.emit(this.quote);
                }
                // process MM
              } else if (result.quoteInntopia) {
                this.errorMessage = null;
                this.inntopiaRate = result.quoteInntopia;
                this.Rent = this.inntopiaRate.rent;
                if (this.inntopiaRate.arrival && this.inntopiaRate.departure) {
                  this.arrivalDate = moment.utc(
                    this.inntopiaRate.arrival,
                    'YYYY-MM-DD'
                  );
                  this.departureDate = moment.utc(
                    this.inntopiaRate.departure,
                    'YYYY-MM-DD'
                  );
                  this.fastRatesWidget.controls['arrivalForRates'].setValue(
                    this.arrivalDate
                  );
                  this.fastRatesWidget.controls['departureForRates'].setValue(
                    this.departureDate
                  );
                }
                if (this.inntopiaRate.numAdults > 0) {
                  this.numAdults = this.inntopiaRate.numAdults;
                }
                if (this.inntopiaRate.numChildren !== undefined) {
                  this.numChildren = this.inntopiaRate.numChildren;
                }
                if (this.inntopiaRate.numPets !== undefined) {
                  this.numPets = this.inntopiaRate.numPets;
                }

                if (this.TotalCost > 0 && this.inntopiaRate.rent > 0) {
                  this.rateLoaded = true;
                  this.loading = false;
                  this.quote = {
                    arrival: this.arrivalDate.format('YYYY-MM-DD'),
                    departure: this.departureDate.format('YYYY-MM-DD'),
                    adults: this.numAdults,
                    children: this.numChildren,
                    pets: this.numPets,
                    quoteInntopia: this.inntopiaRate,
                  };
                  this.quoteEmitter.emit(this.quote);

                  if (this.inntopiaRate.taxes <= 0) {
                    this.taxesWarning =
                      'Please note that additional taxes and fees could be applied';
                    throw new Error('No Taxes for ' + this.unit.Name);
                  }
                } else {
                  this.quote = {
                    errorMessage:
                      'Online booking is unavailable for these dates. Please change dates or contact our team to book this property.',
                  };
                  this.errorMessage =
                    'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates.';
                  this.quoteEmitter.emit(this.quote);
                }
                // process Inntopia
              }
            }
            this.rateLoaded = true;
            this.loading = false;
          });
      } else {
        if (this.mode === 'LodgingQuote') {
          let lodgingQuoteExist =
            this.lodgingQuoteService.checkLodgingQuoteForDates(
              this.unit.externalId,
              this.arrivalDate,
              this.departureDate
            );
          if (lodgingQuoteExist) {
            this.errorMessage =
              'Oh no! This property may have been booked already. Please contact your Personal Travel Designer for help.';
          } else {
            this.errorMessage =
              'Choosen dates range is not allowed for booking. Please change dates or contact our team to book this property. ';
          }
        } else {
          this.errorMessage =
            'Choosen dates range is not allowed for booking. Please change dates or contact our team to book this property. ';
        }
        this.quote = {
          errorMessage: this.errorMessage,
        };
        // 'Choosen dates range is not allowed for booking. Please change dates or contact our team to book this property.',
        this.loading = false;
        this.quoteEmitter.emit(this.quote);
      }
    }
  }

  numberofGuestChanged(event: any) {
    this.reset();
    this.explicit = true;
    let searchParams = this.searchService.get();
    searchParams = {
      ...searchParams,
      Adults: this.numAdults,
      Children: this.numChildren,
    };
    this.searchService.update(searchParams);
    // FIXME NUMBER OF PETS
    const pets = 0;
    if (this.numAdults + this.numChildren > this.unit.Sleeps__c) {
      this.errorMessage =
        'Please note that the maximum occupancy for this property is ' +
        this.unit.Sleeps__c.toString() +
        ' guests.';
      this.quoteEmitter.emit({ errorMessage: this.errorMessage });
    } else if (!this.arrivalDate || !this.departureDate) {
      this.errorMessage = ' ';
    } else {
      this.getQuote();
    }
  }
  numberofPetsChanged(event: any) {
    this.reset();
    let searchParams = this.searchService.get();
    searchParams = {
      ...searchParams,
      Pets: this.numPets,
    };
    this.searchService.update(searchParams);
    // FIXME NUMBER OF PETS
    const pets = 0;
    if (!this.arrivalDate || !this.departureDate) {
      this.errorMessage = ' ';
    } else {
      this.getQuote();
    }
  }

  dateClass = (d_any: any) => {
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    if (this.arrivalDate) {
      const arrival = this.arrivalDate.format('YYYY-MM-DD');
      return day === arrival ? 'choosen-date' : undefined;
    } else {
      return undefined;
    }
  };

  reset() {
    this.rateLoaded = false;
    this.inntopiaRate = null;
    this.rateDetails = null;
    this.myStay = 0;
    this.minStay = 0;
    this.TotalCost = 0;
    this.Rent = 0;
    this.errorMessage = null;
    this.taxesWarning = null;
  }

  ngOnDestroy() {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
    this.userIdSub.unsubscribe();
    if (this.chatIdSub) {
      this.chatIdSub.unsubscribe();
    }
    if (this.newMessagesSub) {
      this.newMessagesSub.unsubscribe();
    }
    this.statusSub.unsubscribe();
  }

  bookNow() {
    // Check input. Submit input data to booking widget only if it's correct
    // TODO make same quote for mobile version

    const dialogRef = this.dialog.open(BookingDialogComponent, {
      data: {
        unit: this.unit,
        filter: {
          quoteData: this.quote,
        },
      },
      panelClass: 'unit-rates-container',
      maxWidth: this.isMobile ? '96vw' : 'auto',
      width: this.isMobile ? '96%' : 'auto',
    });
  }

  enquiryNow() {
    /* Don't have additional check here. User can send enquiry even if data range is incorrect */
    let rent = 0;
    if (
      this.quote &&
      this.quote.quoteInntopia &&
      this.quote.quoteInntopia.rent
    ) {
      rent = this.quote.quoteInntopia.rent;
    } else if (this.quote && this.quote.quoteMM && this.quote.quoteMM.Rent__c) {
      rent = this.quote.quoteMM.TotalRent__c;
    }
    const dialogRef = this.dialog.open(UnitEnquiryDialogComponent, {
      data: {
        unit: this.unit,
        filter: {
          arrival: this.quote && this.quote.arrival ? this.quote.arrival : null,
          departure:
            this.quote && this.quote.departure ? this.quote.departure : null,
          adults: this.quote && this.quote.adults ? this.quote.adults : null,
          children:
            this.quote && this.quote.children ? this.quote.children : null,
          value: rent,
        },
      },
      autoFocus: false,
      panelClass: 'inquiry-dialog-container',
      maxWidth: '96vw',
      maxHeight: '80vh',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['/booking-enquiry-thank-you']);
        this.unitService.startBooking(result);
      }
    });
  }
}
