import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { SearchParamsService } from 'src/app/services/search-params.service';
import { UnitsService } from 'src/app/services/units.service';
import { ComplexSummary } from 'src/app/store/models/complex.model';
import { amenities } from 'src/app/store/models/search-options';
import * as moment from 'moment';
import _ from 'lodash';
import { fadeIn, fadeOut } from 'src/app/utils/animations/fade-animations';
import { trigger } from '@angular/animations';
import { columnNumByBreakpoint } from 'src/app/store/models/responsiveness.model';

@Component({
  selector: 'twbooking-complex-listing',
  templateUrl: './complex-listing.component.html',
  styleUrls: ['../unit-search-listing/unit-search-listing.component.scss'],
  animations: [trigger('fadeOut', fadeOut()), trigger('fadeIn', fadeIn())],
})
export class ComplexListingComponent implements OnInit {
  complexes: ComplexSummary[] = [];
  columnNum: number;

  rowHeightFixed = null;

  done = false;
  loadingComplexes = true;

  subMedia: Subscription;
  params: any;

  complexListFiltered: any[] = [];
  complexListFilteredNotMm: any[] = [];

  complexDisplay: Observable<any> | null;

  sleepsCount = 0;
  bedroomCount = 0;

  complexQuery: any = {};
  searchQuery: any = {};

  idList: any[] = [];
  totalCount: number;
  pageSize = 12;
  complexLimit = 0;
  alreadyRequested = 0;
  filteredListLength: number;
  mmOnly = false;
  noResult = false;

  myStay = 0;

  // Submit data from search to property view details
  complexQueryEmitter: EventEmitter<any> = new EventEmitter(null);
  readonly amenities = amenities;
  selectedAmenities = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private unitService: UnitsService,
    private cdref: ChangeDetectorRef,
    private searchService: SearchParamsService,
    public dialog: MatDialog,
    private eventService: EventService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    if (window.location.hostname.includes('mountainmanagement.com')) {
      this.mmOnly = true;
    }
    this.columnNum = 2;
    this.subMedia = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        for (const breakpoint of Object.keys(columnNumByBreakpoint)) {
          if (result.breakpoints[breakpoint]) {
            this.columnNum = columnNumByBreakpoint[breakpoint];
            break;
          }
        }
      });
  }

  getMoreComplexes() {
    if (!this.done) {
      this.getMore();
    }
  }

  findAmenity(selectedAmenity) {
    return amenities.find((amenity) => amenity.value === selectedAmenity);
  }

  ngOnInit() {
    this.done = false;
    if (window.location.hostname.includes('thetravelwhisperer')) {
      this.titleService.setTitle('The Travel Whisperer');
      this.metaTagService.updateTag({
        name: 'description',
        content: 'The Travel Whisperer',
      });
      this.mmOnly = false;
    } else if (window.location.hostname.includes('mountainmanagement.com')) {
      this.titleService.setTitle('Mountain Management');
      this.metaTagService.updateTag({
        name: 'description',
        content: 'Mountain Management',
      });
      this.mmOnly = true;
    } else if (window.location.hostname.includes('vacationsbylaurie')) {
      this.titleService.setTitle('Vacations by Laurie');
      this.metaTagService.updateTag({
        name: 'description',
        content: 'Vacations by Laurie',
      });
      this.mmOnly = false;
    }
    this.columnNum = this.columnNum || 2;

    this.route.queryParams.subscribe((params: Params) => {
      this.done = false;

      this.params = params;
      // Defaults to 0 if no query param provided.
      this.complexQuery = {};
      this.sleepsCount = 0;
      this.totalCount = 0;
      this.complexLimit = 0;
      this.alreadyRequested = 0;
      this.complexDisplay = null;

      if (Object.keys(params).length === 0) {
      } else {
        this.selectedAmenities = [];
        for (const key in params) {
          if (key.startsWith('amenity')) {
            this.complexQuery[key] = params[key];
            let amenity = this.findAmenity(params[key]);
            this.selectedAmenities.push({ key: key, ...amenity });
          }
        }

        if (params.UnitName) {
          this.complexQuery = {
            UnitName: params.UnitName,
            ...this.complexQuery,
          };
        }
        if (params.Adults) {
          this.complexQuery = { Adults: params.Adults, ...this.complexQuery };
          this.sleepsCount += +params.Adults;
        }
        if (params.Children) {
          this.complexQuery = {
            Children: params.Children,
            ...this.complexQuery,
          };
          this.sleepsCount += +params.Children;
        }
        if (this.sleepsCount > 0) {
          this.complexQuery = {
            SleepsCount: this.sleepsCount,
            ...this.complexQuery,
          };
        }
        if (params.City) {
          this.complexQuery = { City: params.City, ...this.complexQuery };
        }

        if (params.Destination) {
          const tmp = params.Destination.split(', ');
          if (tmp && tmp.length && tmp.length > 1) {
            const City = params.Destination.split(', ')[0];
            const State = params.Destination.split(', ')[1];
            this.complexQuery = {
              City: City,
              State: State,
              ...this.complexQuery,
            };
          }
        }

        if (params.rateTop && params.rateBottom) {
          this.complexQuery = { rateTop: params.rateTop, ...this.complexQuery };
          this.complexQuery = {
            rateBottom: params.rateBottom,
            ...this.complexQuery,
          };
        }

        if (params.Country) {
          this.complexQuery = { Country: params.Country, ...this.complexQuery };
        }

        if (params.State) {
          this.complexQuery = { State: params.State, ...this.complexQuery };
        }
        if (params.Village) {
          this.complexQuery = { Village: params.Village, ...this.complexQuery };
        }

        if (params.StartDate && params.EndDate) {
          const today = moment.utc(new Date());
          const arrival = moment.utc(params.StartDate, 'YYYY-MM-DD');
          const departure = moment.utc(params.EndDate, 'YYYY-MM-DD');
          if (arrival.isAfter(today) && departure.isAfter(arrival)) {
            this.myStay = moment.duration(departure.diff(arrival)).asDays();
            this.complexQuery = {
              StartDate: params.StartDate,
              EndDate: params.EndDate,
              myStay: this.myStay,
              ...this.complexQuery,
            };
          }
        }
        if (params.Bedrooms) {
          this.bedroomCount = params.Bedrooms;
          this.complexQuery = {
            Bedrooms: params.Bedrooms,
            ...this.complexQuery,
          };
        }
        if (params.PetFriendly) {
          this.complexQuery = {
            PetFriendly: params.PetFriendly,
            ...this.complexQuery,
          };
        }

        if (params.DisplayRates) {
          this.complexQuery = {
            DisplayRates: params.DisplayRates,
            ...this.complexQuery,
          };
        }

        if (params.PropertyType) {
          if (params.PropertyType !== 'All') {
            this.complexQuery = {
              PropertyType: params.PropertyType,
              ...this.complexQuery,
            };
          }
        }
        if (params.PropertyCollection) {
          if (params.PropertyCollection !== 'All') {
            this.complexQuery = {
              PropertyCollection: params.PropertyCollection,
              ...this.complexQuery,
            };
          }
        }
        if (params.HotTub) {
          this.complexQuery = {
            HotTub: true,
            ...this.complexQuery,
          };
        }
        if (params.SkiIn) {
          this.complexQuery = {
            SkiIn: true,
            ...this.complexQuery,
          };
        }
        if (params.MarketingHeadline) {
          this.complexQuery = {
            MarketingHeadline: params.MarketingHeadline,
            ...this.complexQuery,
          };
        }
        if (params.WholesalePartner) {
          this.complexQuery = {
            WholesalePartner: params.WholesalePartner,
            ...this.complexQuery,
          };
        }
      }
      this.searchQuery = { ...this.complexQuery };

      this.complexQuery = {
        isComplex: true,
        ...this.complexQuery,
      };
      this.searchService.update(this.complexQuery);

      this.complexSearch();
    });
  }

  complexSearch() {
    if (this.complexQuery === undefined) {
      this.complexQuery = {};
      throw new Error('Undefined this.complexQuery in unit search listing ');
    }

    let adjustedQuery: any = {};
    if (Object.keys(this.complexQuery).length === 0) {
      adjustedQuery = {};
    } else {
      adjustedQuery = this.parseAmenities(this.complexQuery);
      let queryForAnalytics = { ...adjustedQuery, mapSearch: false };
      this.eventService.triggerSearchEvent(queryForAnalytics, this.mmOnly);
    }

    this.unitService
      .complexSearch(
        adjustedQuery,
        this.mmOnly,
        this.pageSize,
        this.complexLimit,
        false
      )
      .subscribe((resp: any) => {
        this.loadingComplexes = false;
        this.parseResp(resp);
      });
  }

  parseResp(resp) {
    if (resp) {
      if (resp.result && resp.result.startsWith('NO')) {
        this.noResult = true;

        this.done = true;

        return;
      }

      if (resp.error && resp.error === 'TooManyRequests') {
        console.log('TooManyRequests!');
        throw new Error('TooManyRequests error in search listing');
      }
      this.complexLimit += this.pageSize;
      if (resp.complexes) {
        this.totalCount = resp.complexes.length;
        this.noResult = false;
      }

      if (this.totalCount <= 0) {
        this.noResult = true;

        this.done = true;
      }

      if (this.totalCount < this.pageSize) {
        this.done = true;
      }

      if (this.complexDisplay) {
        let complexList: any[];
        this.complexDisplay.subscribe((units) => {
          complexList = _.union(units, resp.complexes);
        });
        this.complexDisplay = of(complexList);
      } else {
        this.complexDisplay = of(resp.complexes);
      }
      if (this.totalCount === 1) {
        const unitId = resp.complexes[0].UnitSlug__c;

        this.router.navigate(['/complex-details', unitId], {
          queryParams: this.complexQuery,
        });
      }
      this.cdref.detectChanges();
      return;
    }
  }

  parseAmenities(params: any): any {
    let resultQuery = {};
    const amenities: string[] = [];

    for (const key in params) {
      if (params.hasOwnProperty(key) && key.startsWith('amenity')) {
        amenities.push(params[key]);
      } else {
        resultQuery[key] = params[key];
      }
    }
    if (amenities && amenities.length > 0) {
      resultQuery['amenities'] = amenities;
    }

    return resultQuery;
  }

  getMore() {
    if (this.alreadyRequested === this.complexLimit) {
      console.log(
        `this page ${this.complexLimit} is requested already. skipping`
      );
      return;
    }
    this.alreadyRequested = this.complexLimit;
    let adjustedQuery = this.parseAmenities(this.complexQuery);
    this.unitService
      .complexSearch(
        adjustedQuery,
        this.mmOnly,
        this.pageSize,
        this.complexLimit
      )
      .subscribe((resp: any) => {
        if (resp) {
          if (resp.error && resp.error === 'TooManyRequests') {
            console.log('TooManyRequests!');
            throw new Error('TooManyRequests error in search listing');
          }
          if (resp.complexes) {
            this.complexLimit += this.pageSize;
            if (this.complexDisplay) {
              let complexList: any[];
              this.complexDisplay.subscribe((units) => {
                complexList = _.union(units, resp.complexes);
              });
              this.complexDisplay = of(complexList);
            } else {
              this.complexDisplay = of(resp.complexes);
            }
          } else {
            this.done = true;
          }
          this.cdref.detectChanges();
        }
        return;
      });
  }

  AfterViewInit() {
    this.cdref.detectChanges();
    this.complexQueryEmitter.emit(this.complexQuery);
  }

  ngOnDestroy() {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
  }

  onScroll(e) {
    if (!this.done) {
      this.getMore();
    }
  }

  identifyComplex(index: number, complex: any) {
    return complex ? complex.externalId : null;
  }
}
