import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'twbooking-inquiry-thankyou',
  templateUrl: './unit-enquiry-thank-you.component.html',
  styleUrls: ['./unit-enquiry-thank-you.component.scss'],
})
export class UnitEnquiryThankYouComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    (<any>window).fbq.push('track', 'Lead');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'conversion',
      send_to: 'AW-827181223/yYiGCIaU0eAZEKeRt4oD',
    });
  }
}
