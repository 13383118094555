import {
  Component,
  OnChanges,
  Input,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UnitSummary, UnitImage } from '../../store/models/unit-summary.model';
import { BookingDialogComponent } from '../unit-booking/unit-booking-dialog/unit-rates.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { localhost, mainsite, mm, staging } from '../../store/models/hostings';

import { UnitEnquiryDialogComponent } from '../unit-booking/unit-enquiry-dialog/unit-enquiry-dialog.component';

import { UnitsService } from 'src/app/services/units.service';
import { SearchParamsService } from 'src/app/services/search-params.service';

import {
  InntopiaPromotion,
  InntopiaPromotions,
  PromoCondition,
} from '../../store/models/inntopia-promotion';

import { trigger, transition, style, animate } from '@angular/animations';
import { FavoritesService } from 'src/app/services/favorites.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FavoriteSnackComponent } from '../favorite-snack/favorite-snack.component';
import { FeaturedAmenity } from 'src/app/store/models/unit.model';
import { iconByAmenity } from 'src/app/store/models/featured-amenity.model';
import { QuoteData } from 'src/app/store/models/rate.models';
import { ImageSize } from '../rates-chip/rates-chip.component';

export interface IResponsiveColumnsMap {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}

@Component({
  selector: 'twbooking-unit-summary',
  templateUrl: './unit-summary.component.html',
  styleUrls: ['./unit-summary.component.scss', './summary-card-general.scss'],
  animations: [
    trigger('inOutDates', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('200ms ease-out', style({ height: 35, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 35, opacity: 1 }),
        animate('200ms ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
    trigger('inOutRates', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('200ms ease-out', style({ height: 66, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 66, opacity: 1 }),
        animate('200ms ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class UnitSummaryComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() unit: UnitSummary;
  @Input() columnNum: number;
  @Input() searchQuery: any = {};

  /* featured amenities */
  readonly iconByAmenity = iconByAmenity;
  favoritesSub: Subscription;

  /* slider */
  images: UnitImage[] = [];
  choosenAmenity = 0;

  featuredAmenities: FeaturedAmenity[] = null;
  restAmenities: FeaturedAmenity[] = null;
  myOptions = {
    'tooltip-class': 'tw-tooltip',
  };

  imageSize: ImageSize = 'default';

  public countBySize: IResponsiveColumnsMap = {
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  };
  public smallDevice = false;

  nb = true;

  processedDeals: InntopiaPromotions[] = [];
  showDeals: string[] = [];

  isMobile = false;

  rent: number;
  bigTooltip = null;

  quote: QuoteData = null;

  defaultImage =
    'https://s3.amazonaws.com/vrp2/vrpimages/141/1/1/' +
    '59c46191ea1b1_2195%20Cresta%20Rd%20Edwards%20CO-MLS_Size-001-1-Cresta-1800x1200-72dpi.jpg';
  /* subscriptions */
  imageSrc = this.defaultImage; // TODO: remove

  subMedia: any;
  favoriteUnit = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private unitService: UnitsService,
    private searchService: SearchParamsService,
    private favoritesService: FavoritesService,
    private _snackBar: MatSnackBar
  ) {
    this.subMedia = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  openSnackBar(saved: boolean) {
    this._snackBar.openFromComponent(FavoriteSnackComponent, {
      duration: 3 * 1000,
      data: { saved: saved },
      panelClass: ['brand-snackbar'],
    });
  }

  inquireNow() {
    const dialogRef = this.dialog.open(UnitEnquiryDialogComponent, {
      data: {
        unit: this.unit,
        filter: {
          value: this.rent ? this.rent : 0,
        },
      },
      autoFocus: false,
      panelClass: 'inquiry-dialog-container',
      maxWidth: '96vw',
      maxHeight: '80vh',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['/booking-enquiry-thank-you']);
        this.unitService.startBooking(result);
      }
    });
  }

  ngOnChanges() {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );

    if (this.searchQuery && this.searchQuery.StartDate) {
      this.imageSize = 'middle';
    } else {
      this.imageSize = 'default';
    }

    this.quote = null;
    this.images = [];
    const screenWidth = window.innerWidth;
    const width =
      (screenWidth - (screenWidth * (this.columnNum * 4 + 4)) / 100) /
      this.columnNum;

    // const height = ((55 * width) / 38) * 0.58; // 38:55 - grid rowHeight; 60% - default image height
    const imgixPosfix = '?auto=enhance&w=' + width.toString();

    if (this.unit.SeasonThumbnail) {
      this.images.push({
        Name: this.unit.SeasonCaption ? this.unit.SeasonCaption : ' Main View',
        URL__c: this.unit.SeasonThumbnail.includes('imgix')
          ? this.unit.SeasonThumbnail + imgixPosfix
          : this.unit.SeasonThumbnail,
        Season__c: 'Any',
        Caption__c: this.unit.SeasonCaption,
      });
    } else {
      console.error('No thumbnail for unit ', this.unit.UnitSlug__c);
      this.images.push({
        Name: 'Main View',
        URL__c: this.defaultImage,
        Season__c: 'Any',
      });
    }

    this.imageSrc = this.images[0].URL__c;

    if (
      this.unit.requestrate &&
      this.unit.requestrate.length &&
      this.unit.requestrate.length > 0
    ) {
      this.rent = 0;
      let negativerent = false;
      for (
        let index = 0;
        index < this.unit.requestrate.length - 1 && !negativerent;
        index++
      ) {
        if (this.unit.requestrate[index] > 0) {
          this.rent += this.unit.requestrate[index];
        } else {
          negativerent = true;
        }
      }
      if (negativerent) {
        this.rent = -1;
      }
    }

    if (this.unit.featuredamenities && this.unit.featuredamenities.length > 0) {
      this.unit.featuredamenities.forEach((amenity) => {
        if (amenity.ImageLink__c) {
          amenity.sliderIndex = this.images.length;
          this.images.push({
            Name: amenity.AmenityType__c,
            URL__c: amenity.ImageLink__c + imgixPosfix,
            Caption__c: amenity.Description__c,
            Season__c: 'Any',
          });
        }
      });
      const filteredAmenities = this.unit.featuredamenities;

      const showAmenities = this.isMobile ? 4 : 6;
      const rest = filteredAmenities.length - showAmenities;
      if (rest > 0) {
        this.restAmenities = filteredAmenities.slice(-rest);
      } else {
        this.restAmenities = null;
      }
      this.featuredAmenities = filteredAmenities.slice(0, showAmenities);
    }
  }

  ngAfterViewInit() {
    // console.log('pre-loading images for ', this.unit.MarketingHeadline__c);
    /*for (const image of this.images) {
      new Image().src = image.URL__c;
    }*/
    this.favoriteUnit = this.favoritesService.isFavorite({
      unit: this.unit.externalId,
      Name: this.unit.Name,
    });
    this.favoritesSub = this.favoritesService.favorites$.subscribe(
      (newList) => {
        this.favoriteUnit = this.favoritesService.isFavorite({
          unit: this.unit.externalId,
          Name: this.unit.Name,
        });
      }
    );
  }

  bookNow() {
    const windowHeight = window.innerHeight;
    console.log('windowHeight = ', windowHeight);
    // Check input. Submit input data to booking widget only if it's correct

    const dialogRef = this.dialog.open(BookingDialogComponent, {
      data: {
        unit: this.unit,
        filter: {
          quoteData: this.quote && this.quote.errorMessage ? null : this.quote,
        },
      },
      panelClass: 'unit-rates-container',
      height: windowHeight < 1024 ? '98vh' : 'auto',
      maxWidth: this.isMobile ? '96vw' : 'auto',
      width: this.isMobile ? '96%' : 'auto',
    });
  }

  ngOnDestroy() {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
    if (this.favoritesSub) {
      this.favoritesSub.unsubscribe();
    }
  }

  showPromotion() {
    const start = null;
    const end = null;
    if (this.processedDeals.length === 0) {
      this.unitService
        .getPromotions(
          this.unit.isInntopiaUnit__c
            ? this.unit.inntopiaSupplierId__c
            : '999999',
          this.unit.isInntopiaUnit__c ? this.unit.inntopiaProductId__c : '1',
          this.unit.externalId,
          start,
          end
        )
        .subscribe((response: any) => {
          const availableDeals = response;
          if (!this.unit.isInntopiaUnit__c) {
            this.processedDeals = availableDeals;
          } else {
            this.processedDeals = [];
            let i = 0;
            while (availableDeals && i < availableDeals.length) {
              const promo: InntopiaPromotions = {
                Name: availableDeals[i].Name,
                PromotionCode__c: availableDeals[i].PromotionCode__c,
                Description__c: availableDeals[i].Description__c,
                ValidStart__c: availableDeals[i].ValidStart__c,
                ValidEnd__c: availableDeals[i].ValidEnd__c,
                BookByDate__c: availableDeals[i].BookByDate__c,
                Details: [],
              };
              const promoDetails: PromoCondition = {
                Discount__c: availableDeals[i].Discount__c,
                MinNights__c: availableDeals[i].MinNights__c,
                MaxNights__c: availableDeals[i].MaxNights__c,
              };
              promo.Details.push(promoDetails);
              while (
                i + 1 < availableDeals.length &&
                availableDeals[i + 1].Name === promo.Name &&
                availableDeals[i + 1].ValidStart__c === promo.ValidStart__c &&
                availableDeals[i + 1].ValidEnd__c === promo.ValidEnd__c
              ) {
                const promoDetails: PromoCondition = {
                  Discount__c: availableDeals[i + 1].Discount__c,
                  MinNights__c: availableDeals[i + 1].MinNights__c,
                  MaxNights__c: availableDeals[i + 1].MaxNights__c,
                };
                promo.Details.push(promoDetails);
                i++;
              }
              this.processedDeals.push(promo);
              i++;
            }
          }
        });
    }
  }

  showPrev() {
    this.choosenAmenity--;
    if (this.choosenAmenity < 0) {
      this.choosenAmenity = this.images.length - 1;
    }
    this.imageSrc = this.images[this.choosenAmenity].URL__c;
  }
  showNext() {
    this.choosenAmenity++;
    if (this.choosenAmenity >= this.images.length) {
      this.choosenAmenity = 0;
    }
    this.imageSrc = this.images[this.choosenAmenity].URL__c;
  }

  showAmenity(imageIndex) {
    if (imageIndex) {
      this.choosenAmenity = imageIndex;
      this.imageSrc = this.images[this.choosenAmenity].URL__c;
    }
  }

  onSwipe(evt) {
    const x =
      Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (x === 'right') {
      this.showNext();
    } else {
      this.showPrev();
    }
  }

  identifyUnit(index, unit) {
    return unit ? unit.externalId : null;
  }

  saveFavorite() {
    console.log('saveFavorite');
    this.favoriteUnit = true;
    this.favoritesService.add({
      unit: this.unit.externalId,
      Name: this.unit.Name,
    });
    this.openSnackBar(true);
  }

  async keepQuote(quote: QuoteData) {
    this.quote = quote;
    if (
      this.quote &&
      this.quote.quoteInntopia &&
      this.quote.quoteInntopia.baseRent
    ) {
      this.rent = this.quote.quoteInntopia.baseRent;
    } else if (this.quote && this.quote.quoteMM && this.quote.quoteMM.Rent__c) {
      this.rent = this.quote.quoteMM.Rent__c;
    }
  }

  async keepSize(size: ImageSize) {
    console.log('keepsize', size);
    if (
      size === 'default' &&
      this.unit.DisplayRates__c &&
      this.searchQuery &&
      this.searchQuery.StartDate
    ) {
      this.imageSize = 'middle';
    } else {
      this.imageSize = size;
    }
  }

  removeFavorite() {
    console.log('removeFavorite');
    this.favoriteUnit = false;
    this.favoritesService.remove({
      unit: this.unit.externalId,
      Name: this.unit.Name,
    });
    this.openSnackBar(false);
  }
}
