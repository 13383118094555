import { Injectable } from '@angular/core';
import { EventService } from './event.service';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SearchCacheService } from './search-cache.service';
import * as moment from 'moment';
import { MMRate, InntopiaRates } from '../store/models/rate.models';
import { UserDataService } from './user-data.service';
import { AuthService } from './auth.service';
import { UnitImage } from '../store/models/unit.model';
import { LodgingQuoteService } from './lodging-quote.service';
import { GeoAvailabilityQuery } from '../store/models/geoavailabilityquery.model';
import { QuoteParamsGA } from '../store/models/events-ga.model';
import { AuthenticatedApiService } from './authenticated-api.service';

@Injectable()
export class UnitsService {
  constructor(
    private apiService: AuthenticatedApiService,
    private cache: SearchCacheService,
    private eventService: EventService,
    private userDataService: UserDataService,
    private authService: AuthService,
    private lodgingQuoteService: LodgingQuoteService
  ) {}

  getUnit(unitSlug: string, complex?: boolean): Observable<any> {
    const width = window.innerWidth;

    return this.apiService
      .httpCall('/api/getUnit', {
        unitSlug: unitSlug,
        complex: complex ? complex : null,
        width: width,
      })
      .pipe(
        map((res: any) => {
          if (res && res.length > 0 && res[0].Name) {
            if (complex) {
              this.eventService.triggerComplexViewedEvent({
                complex: res[0].Name,
              });
            } else {
              this.eventService.triggerPropertyViewedEvent({
                unit: res[0].Name,
              });
            }
          }
          return res;
        })
      );
  }

  getAvailablity(bookedQuery: {
    unitId: string;
    supplierId: string;
    productId: string;
    personalHold: any[];
    maxDate: string;
  }): Observable<any> {
    return this.apiService
      .httpCall('/api/unitBookedDatesQueryNew', bookedQuery)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getRates(
    unitId,
    arrivalDate: Date,
    departureDate: Date,
    adults: number,
    children: number,
    pets: number,
    unitName: string,
    nights: number,
    explicit: boolean,
    personalHold?: { start: Date; end: Date }[]
  ): Observable<any> {
    let hl = (
      personalHold && personalHold.length ? personalHold.length : 0
    ).toString();
    const cachedResponse = this.cache.getRate(
      unitId +
        'arrivalDate:' +
        arrivalDate +
        'departureDate:' +
        departureDate +
        'adults' +
        adults +
        'children' +
        children +
        'pets' +
        pets +
        'personalHold' +
        hl
    );
    return cachedResponse
      ? of(cachedResponse)
      : this.apiService
          .httpCall('/api/getCalculatedRatesFunction', {
            id: unitId,
            startDate: arrivalDate,
            endDate: departureDate,
            guests: adults,
            adults: adults,
            children: children,
            pets: pets,
            personalHold: personalHold,
          })
          .pipe(
            map((res: any) => {
              let hl = (
                personalHold && personalHold.length ? personalHold.length : 0
              ).toString();
              this.cache.putRate(
                unitId +
                  'arrivalDate:' +
                  arrivalDate +
                  'departureDate:' +
                  departureDate +
                  'adults' +
                  adults +
                  'children' +
                  children +
                  'pets' +
                  pets +
                  'personalHold' +
                  hl,
                res
              );
              let fees = 0;
              if (res.DamageProtectionFee__c) {
                fees += res.DamageProtectionFee__c;
              }
              if (res.CleaningService__c) {
                fees += res.CleaningService__c;
              }
              if (res.GuestServicePackage__c) {
                fees += res.GuestServicePackage__c;
              }
              if (res.PetFee__c) {
                fees += res.PetFee__c;
              }

              if (res.TotalCost__c) {
                let data = this.userDataService.get();
                data = { ...data, segment: res.TotalCost__c > 10000 ? 10 : 0 };
                this.userDataService.update(data);
              }

              this.eventService.triggerQuoteEvent({
                unit: unitName,
                arrivalDate: arrivalDate,
                departureDate: departureDate,
                adults: adults,
                children: children,
                TotalCost: res.TotalCost__c ? res.TotalCost__c : null,
                Rent: res.TotalRent_c ? res.TotalRent_c : null,
                DiscountedRent: res.Discounted_Rent__c
                  ? res.Discounted_Rent__c
                  : null,
                Fees: fees,
                Taxes: res.TAX__c ? res.TAX__c : null,
                RefundableDeposit: res.RefundableDeposit
                  ? res.RefundableDeposit
                  : null,
                explicit: explicit,
                nights: nights,
              });
              return res;
            }),
            catchError((err, caught) => {
              console.error(
                'error ',
                err,
                ' in getCalculatedRatesFunction for ',
                unitId,
                '. startDate: ',
                arrivalDate,
                ' -- endDate:',
                departureDate
              );

              return of({ TotalCost__c: null, Rent__c: null });
            })
          );
  }

  getInntopiaRates(
    supplierId,
    productId,
    start: string, // YYYY-MM-DD
    end: string, // YYYY-MM-DD
    adults: number,
    children: number,
    pets: number,
    unitDestination: string,
    unitName: string,
    nights: number,
    explicit: boolean
  ): Observable<any> {
    const cachedResponse = this.cache.getRate(
      supplierId +
        '-' +
        productId +
        'arrivalDate:' +
        start +
        'departureDate:' +
        end +
        'adults' +
        adults +
        'children' +
        children +
        'Destination' +
        unitDestination
    );
    return cachedResponse
      ? of(cachedResponse)
      : this.apiService
          .httpCall('/api/getRatesInntopia', {
            supplierId: supplierId,
            productId: productId,
            startDate: start,
            endDate: end,
            adults: adults,
            children: children,
            pets: pets,
            unitDestination: unitDestination,
          })
          .pipe(
            map((res: any) => {
              this.cache.putRate(
                supplierId +
                  '-' +
                  productId +
                  'arrivalDate:' +
                  start +
                  'departureDate:' +
                  end +
                  'adults' +
                  adults +
                  'children' +
                  children +
                  'Destination' +
                  unitDestination,
                res
              );

              // FIXME do it more effectively!
              let rent: number = null;
              let discounted_rent: number = null;
              let total: number = null;
              let fees: number = null;
              let taxes: number = null;
              if (res.baseRate && res.baseRate.rent) {
                rent = res.baseRate.rent;

                if (
                  res.discountRate &&
                  res.discountRate.rent &&
                  res.discountRate.rent < res.baseRate.rent &&
                  res.discountRate.rent > 0
                ) {
                  discounted_rent = res.discountRate.rent;
                  fees = res.discountRate.fees;
                  taxes = res.discountRate.taxes;
                  total = discounted_rent + fees + taxes;
                } else {
                  fees = res.baseRate.fees;
                  taxes = res.baseRate.taxes;
                  total = rent + fees + taxes;
                }
              }
              this.eventService.triggerQuoteEvent({
                unit: unitName,
                arrivalDate: moment.utc(start, 'YYYY-MM-DD').toDate(),
                departureDate: moment.utc(end, 'YYYY-MM-DD').toDate(),
                adults: adults,
                children: children,
                nights: nights,
                TotalCost: total,
                Rent: rent,
                DiscountedRent: discounted_rent,
                Fees: fees,
                Taxes: taxes,
                RefundableDeposit: null,
                explicit: explicit,
              });
              return res;
            })
          );
  }

  getQuote(
    unitId,
    unit,
    numAdults: number,
    numChildren: number,
    numPets: number,
    arrivalDate: moment.Moment,
    departureDate: moment.Moment,
    explicit: boolean
  ): Observable<any> {
    return new Observable((observer) => {
      let errorMessage = null;
      let TotalCost = 0;
      let rateDetails: MMRate;
      let inntopiaRate: InntopiaRates;
      let inntopiaPromo: InntopiaRates;
      let inntopiaBaseRate: InntopiaRates;
      let minStay = 1;
      if (numAdults + numChildren > unit.Sleeps__c) {
        errorMessage =
          'Please note that the maximum occupancy for this property is ' +
          unit.Sleeps__c.toString() +
          ' guests.';
        observer.next({ errorMessage: errorMessage });
        observer.complete();
      }
      let myStay = moment.duration(departureDate.diff(arrivalDate)).asDays();
      if (!unit.isInntopiaUnit__c && myStay >= 30) {
        errorMessage =
          'Please inquire on stays longer than 30 night. Our Personal Travel Designers will be in touch with a personalized quote for your dates.';
        observer.next({ errorMessage: errorMessage });
        observer.complete();
      }
      const destination = unit.Destination
        ? unit.Destination
        : unit.Destination__Name;
      const quoteData = null;

      if (!unit.isInntopiaUnit__c) {
        let personalHold = this.lodgingQuoteService.getHold(unitId);
        this.getRates(
          unitId,
          arrivalDate.toDate(),
          departureDate.toDate(),
          numAdults,
          numChildren,
          numPets,
          unit.Name,
          myStay,
          explicit,
          personalHold
        ).subscribe((response: any) => {
          if (!response.available) {
            let lodgingQuoteExist =
              this.lodgingQuoteService.checkLodgingQuoteForDates(
                unitId,
                arrivalDate,
                departureDate
              );

            let errorMessage = lodgingQuoteExist
              ? 'Oh no! This property may have been booked already. Please contact your Personal Travel Designer for help.'
              : 'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates.';

            observer.next({ errorMessage: errorMessage });
            observer.complete();
          } else {
            if (!response.minStay) {
              throw Error(
                'No MinStay for ' +
                  unit.externalId +
                  ' ' +
                  arrivalDate.format('YYYY-MM-DD') +
                  ' ' +
                  departureDate.format('YYYY-MM-DD')
              );
            } else {
              minStay = response.minStay;
            }
            if (minStay > myStay) {
              errorMessage =
                'Please note that minimum stay allowed for booking is ' +
                minStay.toString() +
                ' nights.';
              observer.next({
                errorMessage: errorMessage,
                minStay: minStay,
              });
              observer.complete();
            } else {
              rateDetails = response;
              TotalCost = rateDetails.TotalCost__c;
              if (
                !rateDetails ||
                !rateDetails.TotalCost__c ||
                !rateDetails.Rent__c ||
                rateDetails.Rent__c <= 0 ||
                rateDetails.TotalCost__c <= 0 ||
                (rateDetails.Discount__c &&
                  rateDetails.Discount__c > 0 &&
                  (!rateDetails.Discounted_Rent__c ||
                    rateDetails.Discounted_Rent__c <= 0))
              ) {
                errorMessage =
                  'Please <a href="/contact">contact</a> the Travel Whisperer team, our Personal Travel Designers  can assist with a quote for your dates.';
                observer.next({ errorMessage: errorMessage });
                observer.complete();
              } else {
                observer.next({
                  rateDetails: rateDetails,
                  Total: TotalCost,
                  minStay: minStay,
                });
                observer.complete();

                //send GA4 quote event
                let rent =
                  rateDetails.Discount__c &&
                  rateDetails.Discount__c > 0 &&
                  rateDetails.Discounted_Rent__c &&
                  rateDetails.Discounted_Rent__c > 0
                    ? rateDetails.Discounted_Rent__c
                    : rateDetails.TotalRent__c;

                let quoteGA: QuoteParamsGA = {
                  UnitName: unit.Name,
                  currency: 'USD',
                  value: rent,
                  tax: rateDetails.TAX__c,
                  fees:
                    (rateDetails.DamageProtectionFee__c
                      ? rateDetails.DamageProtectionFee__c
                      : 0) +
                    rateDetails.CleaningService__c +
                    rateDetails.GuestServicePackage__c +
                    rateDetails.PetFee__c,
                  quote_type: 'MM',
                  Adults: numAdults,
                  Children: numChildren,
                  StartDate: arrivalDate.format('YYYY-MM-DD'),
                  EndDate: departureDate.format('YYYY-MM-DD'),
                  Destination: unit.Destination
                    ? unit.Destination
                    : unit.Destination__Name,
                };
                if (rateDetails.Discount__c && rateDetails.Discount__c > 0) {
                  quoteGA = {
                    ...quoteGA,
                    coupon: 'MM discount',
                  };
                }
                this.eventService.sendQuoteEventGA(quoteGA, explicit);
              }
            }
          }
        }),
          (error) => {
            console.error('error in subscription = ', error);
            errorMessage =
              'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates.';
            observer.next({ errorMessage: errorMessage });
            observer.complete();
          };
      } else {
        this.getInntopiaRates(
          unit.inntopiaSupplierId__c,
          unit.inntopiaProductId__c,
          arrivalDate.format('YYYY-MM-DD'),
          departureDate.format('YYYY-MM-DD'),
          numAdults,
          numChildren,
          numPets,
          destination,
          unit.Name,
          myStay,
          explicit
        ).subscribe((response: any) => {
          if (response && !response.error) {
            inntopiaBaseRate = response.baseRate;
            inntopiaPromo = response.discountRate;
            if (
              inntopiaPromo &&
              inntopiaBaseRate &&
              inntopiaPromo !== null &&
              inntopiaPromo.rent > 0 &&
              inntopiaPromo.rent < inntopiaBaseRate.rent &&
              inntopiaPromo.error === null
            ) {
              inntopiaRate = inntopiaPromo;
              inntopiaRate.savingsAmount =
                inntopiaBaseRate.rent - inntopiaPromo.rent;
              inntopiaRate.baseRent = inntopiaBaseRate.rent;
            } else {
              inntopiaPromo = null;
              inntopiaRate = inntopiaBaseRate;
              inntopiaRate.savingsAmount = 0;
            }
            if (response.arrival && response.departure) {
              inntopiaRate = {
                ...inntopiaRate,
                arrival: response.arrival,
                departure: response.departure,
              };
            }
            if (response.numAdults) {
              inntopiaRate = {
                ...inntopiaRate,
                numAdults: response.numAdults,
              };
            }
            if (response.numChildren !== undefined) {
              inntopiaRate = {
                ...inntopiaRate,
                numChildren: response.numChildren,
              };
            }

            if (response.numPets !== undefined) {
              inntopiaRate = {
                ...inntopiaRate,
                numPets: response.numPets,
              };
            }

            TotalCost =
              inntopiaRate.rent +
              inntopiaRate.fees +
              inntopiaRate.taxes +
              inntopiaRate.optionalFees;

            if (
              !inntopiaRate ||
              TotalCost <= 0 ||
              inntopiaRate.error !== null
            ) {
              errorMessage =
                'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates. ';
              observer.next({ errorMessage: errorMessage });
              observer.complete();
            } else {
              errorMessage = null;
              observer.next({
                quoteInntopia: inntopiaRate,
                Total: TotalCost,
              });
              observer.complete();

              let quoteGA: QuoteParamsGA = {
                UnitName: unit.Name,
                currency: 'USD',
                value: inntopiaRate.rent,
                tax: inntopiaRate.taxes,
                fees: inntopiaRate.fees,
                quote_type: 'inntopia',
                Adults: numAdults,
                Children: numChildren,
                StartDate: arrivalDate.format('YYYY-MM-DD'),
                EndDate: departureDate.format('YYYY-MM-DD'),
                Destination: unit.Destination
                  ? unit.Destination
                  : unit.Destination__Name,
              };
              if (
                inntopiaRate.savingsAmount &&
                inntopiaRate.savingsAmount > 0
              ) {
                quoteGA = {
                  ...quoteGA,
                  coupon: inntopiaRate.rateCode,
                };
              }
              this.eventService.sendQuoteEventGA(quoteGA, explicit);
            }
          } else {
            if (response && response.error === 'lessThenMinStay') {
              minStay = response.minStay;
              errorMessage =
                'Please note that minimum stay allowed for booking is ' +
                response.minStay.toString() +
                ' nights.';
              observer.next({ errorMessage: errorMessage, minStay: minStay });
              observer.complete();
            } else {
              errorMessage =
                'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates.';
              observer.next({ errorMessage: errorMessage });
              observer.complete();
            }
          }
        });
      }
    });
  }

  async checkAvailability(
    externalId,
    start: string, // YYYY-MM-DD
    end: string // YYYY-MM-DD
  ): Promise<any> {
    try {
      const result: any = await firstValueFrom(
        this.apiService.httpCall('/api/checkAvailability', {
          externalId: externalId,
          start: start,
          end: end,
        })
      );
      return result.available ? result.available : false;
    } catch (error) {
      throw error;
    }
  }

  checkIfAvailable(
    externalId,
    start: string, // YYYY-MM-DD
    end: string, // YYYY-MM-DD
    adults: number,
    children: number
  ): Observable<any> {
    /* It is legacy checkavailability */
    const cachedResponse = this.cache.getRate(
      'externalId: ' +
        externalId +
        'arrivalDate:' +
        start +
        'departureDate:' +
        end +
        'adults' +
        adults +
        'children' +
        children
    );
    return cachedResponse
      ? of(cachedResponse)
      : this.apiService
          .httpCall('/api/getRatesInntopia', {
            externalId: externalId,
            supplierId: null,
            productId: null,
            startDate: start,
            endDate: end,
            adults: adults,
            children: children,
            unitDestination: null,
          })
          .pipe(
            map((res: any) => {
              this.cache.putRate(
                'externalId: ' +
                  externalId +
                  'arrivalDate:' +
                  start +
                  'departureDate:' +
                  end +
                  'adults' +
                  adults +
                  'children' +
                  children,
                res
              );
              return res;
            })
          );
  }

  getBookedInntopia(
    inntopiaSupplierId: string,
    inntopiaProductId: string,
    maxDate: string // YYYY-MM-DD
  ): Observable<any> {
    return this.apiService
      .httpCall('/api/inntopiaBookedDatesQuery', {
        supplierId: inntopiaSupplierId,
        productId: inntopiaProductId,
        maxDate: maxDate,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getPromotions(
    inntopiaSupplierId: string,
    inntopiaProductId: string,
    externalId: string,
    arrival?: Date,
    departure?: Date
  ): Observable<any> {
    return this.apiService
      .httpCall('/api/promotionsQuery', {
        supplierId: inntopiaSupplierId !== '999999' ? inntopiaSupplierId : null,
        productId: inntopiaSupplierId !== '999999' ? inntopiaProductId : null,
        externalId: inntopiaSupplierId === '999999' ? externalId : null,
        start: arrival ? arrival : null,
        end: departure ? departure : null,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  complexSearch(
    searchQuery,
    mmOnly,
    pageSize: number,
    offset: number,
    countTotal: boolean = true
  ): Observable<any> {
    return this.apiService
      .httpCall('/api/complexSearchFunction', {
        searchParams: searchQuery,
        mmOnly: mmOnly,
        pageSize: pageSize,
        offset: offset,
        countTotal: countTotal,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  unitSearch(
    searchQuery,
    mmOnly,
    url: string,
    pageSize: number,
    offset: number
  ): Observable<any> {
    const cachedResponse = this.cache.get(
      url + 'pageSize:' + pageSize.toString() + 'offset:' + offset.toString()
    );
    if (searchQuery === undefined || searchQuery === null) {
      searchQuery = {};
      throw new Error(
        'Undefined this.searchQuery in unitSearch unit service, url =  ' +
          url +
          ' pageSize= ' +
          pageSize +
          ' offset = ' +
          offset
      );
    }
    return cachedResponse
      ? of(cachedResponse)
      : this.apiService
          .httpCall('/api/unitSearchFunction', {
            searchParams: searchQuery,
            mmOnly: mmOnly,
            pageSize: pageSize,
            offset: offset,
          })
          .pipe(
            map((res) => {
              this.cache.put(
                url +
                  'pageSize:' +
                  pageSize.toString() +
                  'offset:' +
                  offset.toString(),
                res
              );
              return res;
            })
          );
  }

  startBooking(result) {
    let userId = this.authService.getUserId();

    let payload = {
      ...result,
      userId: userId,
    };

    let googleClientId = this.authService.googleClientId;
    if (googleClientId) {
      payload = { ...payload, clientId: googleClientId };
    }

    let googleClickId = this.authService.googleClickId;
    if (googleClickId) {
      payload = { ...payload, googleClickId: googleClickId };
    }

    let ga_sessionId = this.authService.ga_sessionId;
    if (ga_sessionId) {
      payload = { ...payload, ga_sessionId: ga_sessionId };
    }

    if (payload.arrivalDate) {
      payload.arrivalDate = moment
        .utc(payload.arrivalDate)
        .format('YYYY-MM-DD');
    }
    if (payload.departureDate) {
      payload.departureDate = moment
        .utc(payload.departureDate)
        .format('YYYY-MM-DD');
    }
    payload = {
      ...payload,
      hostname: window.location.hostname.includes('thetravelwhisperer')
        ? 'ttw-booking'
        : window.location.hostname.includes('mountainmanagement')
        ? 'mm-booking'
        : window.location.hostname,
    };

    this.apiService
      .httpCall('/api/leadCreate', payload)
      .subscribe((response: any) => {
        if (!response || !response.success) {
          console.log('error with submitting the payload: ', payload);
          console.log('error response: ', response);
          console.error(
            `error with submitting the lead. Code: ${
              response.code ? response.code : ''
            }, message:${response.message ? response.message : ''}`
          );
        }
        return;
      });
  }

  fetchFeaturedCategories(destination: string): Observable<any> {
    return this.apiService
      .httpCall('/api/getFeaturedCategories', {
        destination: destination,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  isPurchaseEventAllowed(input: {
    unit: string;
    arrival: string;
    departure: string;
    user: string;
    mode: string;
  }): Observable<any> {
    return this.apiService
      .httpCall('/api/isPurchaseEventAllowed', {
        unit: input.unit,
        arrival: input.arrival,
        departure: input.departure,
        user: input.user,
        mode: input.mode,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  openSearch(
    searchId,
    ptd: boolean,
    pageSize: number,
    offset: number
  ): Observable<any> {
    if (searchId === undefined || searchId === null) {
      searchId = 1;
      throw new Error('Undefined searchId in openSearch unit service ');
    }
    return this.apiService
      .httpCall('/api/openSearch', {
        searchId: searchId,
        ptd: ptd,
        pageSize: pageSize,
        offset: offset,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  fetchThumbnails(unitList: string[]): Observable<any> {
    return this.apiService
      .httpCall('/api/fetchThumbnails', { units: unitList })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  geoSearch(
    geoQuery: GeoAvailabilityQuery,
    mm: boolean,
    pageSize: number,
    offset: number
  ): Observable<any> {
    return this.apiService
      .httpCall('/api/geoSearh', {
        geoQuery: geoQuery,
        mmOnly: mm,
        pageSize: pageSize,
        offset: offset,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  fetchUnitData(unit: string, season: string): Observable<any> {
    return this.apiService
      .httpCall('/api/fetchUnitData', { unit: unit, season: season })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  prepareImages(
    season: 'Winter' | 'Summer' | 'None',
    unitImages: UnitImage[]
  ): { ImagesProcessed: UnitImage[] } {
    let allUnitImages = unitImages;
    let ImagesProcessed: UnitImage[] = [];

    if (season === 'None') {
      ImagesProcessed = allUnitImages.sort((a, b) => this.sortPriority(a, b));
    } else {
      let filteredImages = allUnitImages.filter(
        (image: UnitImage) =>
          (image.Season__c === season || image.Season__c === 'Any') &&
          image.Display__c !== 'Thumbnail'
      );
      if (filteredImages.length >= 10) {
        ImagesProcessed = filteredImages.sort((a, b) =>
          this.sortSeason(a, b, season)
        );
      } else {
        ImagesProcessed = allUnitImages.sort((a, b) =>
          this.sortSeason(a, b, season)
        );
      }
    }
    return { ImagesProcessed: ImagesProcessed };
  }

  pickSeason(arrival: moment.Moment): 'Winter' | 'Summer' {
    let month = arrival.month(); // jan=0, dec=11
    let season: 'Winter' | 'Summer' =
      month > 2 && month < 10 ? 'Summer' : 'Winter';
    return season;
  }

  sortPriority(x, y) {
    let result;
    if (x.Display__c !== y.Display__c) {
      return x.Display__c === 'Hero' ? 1 : -1;
    } else {
      const a = x.DisplayOrder__c;
      const b = y.DisplayOrder__c;

      const asc = true;

      if (a === null) {
        return 1;
      }
      if (b === null) {
        return -1;
      }
      if (a === null && b === null) {
        return 0;
      }

      result = a - b;

      if (isNaN(result)) {
        return asc
          ? a.toString().localeCompare(b)
          : b.toString().localeCompare(a);
      } else {
        return asc ? result : -result;
      }
    }
  }

  sortSeason(x, y, season: 'Winter' | 'Summer') {
    let result;
    if (x.Display__c !== y.Display__c) {
      return x.Display__c === 'Hero' ? -1 : 1;
    } else {
      if (x.Season__c !== y.Season__c) {
        if (x.Season__c === season) {
          return -1;
        } else if (y.Season__c === season) {
          return 1;
        } else if (x.Season__c === 'Any') {
          return -1;
        } else if (y.Season__c === 'Any') {
          return 1;
        }
      } else {
        const a = x.DisplayOrder__c;
        const b = y.DisplayOrder__c;

        const asc = true;

        if (a === null) {
          return 1;
        }
        if (b === null) {
          return -1;
        }
        if (a === null && b === null) {
          return 0;
        }

        result = a - b;

        if (isNaN(result)) {
          return asc
            ? a.toString().localeCompare(b)
            : b.toString().localeCompare(a);
        } else {
          return asc ? result : -result;
        }
      }
    }
  }
}
