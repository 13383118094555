<div class="parent-maps-container">
  @if (showMap) { @if (!isMobile) {

  <div class="maps-container">
    <div class="unit-summary-cards">
      <div
        class="back-button new-brand-color"
        aria-label="Back button"
        (click)="backToListing()"
      >
        <mat-icon class="new-brand-color">keyboard_arrow_left</mat-icon>
        <span class="back-text">back to list view</span>
      </div>
      <div *ngIf="loadingMarkers">
        <p>
          Searching for units <span *ngIf="mapinteraction">in this area</span>
        </p>
        <mat-progress-bar class="ttw-progress" mode="indeterminate">
        </mat-progress-bar>
      </div>
      <span *ngIf="total">
        <p class="h3-like new-brand-color">
          {{ total }} homes within map area
        </p></span
      >
      <span *ngIf="(!total || total == 0) && !loadingMarkers">
        <p class="h3-like new-brand-color">
          No exact matches. Try change zoom or drag map.
        </p></span
      >

      <div class="geo-card-columns">
        @for (unit of unitsPage; track unit.externalId; let ind = $index) {
        <div class="unit-geo-card unit-geo-card-shadow">
          <div class="unit-geo-image-container">
            <img
              class="unit-geo-image"
              [src]="unit.SeasonThumbnail"
              [alt]="unit.SeasonCaption"
              (click)="openMarker(ind)"
            />
            @if (!unit.isComplex){
            <div class="projected-type ttw-projected">
              <img
                width="36px"
                src="/assets/home.svg"
                alt="complex"
                (click)="openMarker(ind)"
              />
              <span>Residence</span>
            </div>
            } @else {
            <div class="projected-type ttw-projected">
              <img
                width="36px"
                srcset="/assets/complex.svg"
                alt="home"
              />Complex
            </div>
            }
          </div>
          <div class="unit-geo-content">
            <a
              class="unit-link desktop-min-height"
              (click)="openLinkInNewTab(unit.link)"
            >
              <h3 class="unit-geo-title new-brand-color">
                {{ unit.MarketingHeadline__c }}
              </h3></a
            >

            @if (!unit.isComplex) {
            <div
              class="unit-geo-details new-brand-color"
              *ngIf="!unit.isComplex"
            >
              <div>
                <span>{{ unit?.Bedrooms__c }} bedrooms</span>
                <span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>
                <span *ngIf="totalBeds > 0">{{ totalBeds }} beds</span>
                <span *ngIf="totalBeds > 0"
                  >&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span
                >
                <span>{{ unit?.Bathrooms__c }} bathrooms</span>
              </div>

              <mat-icon
                width="48px"
                class="clickable-icon new-brand-color"
                (click)="openMarker(ind)"
                >place</mat-icon
              >
            </div>
            } @if (unit.isComplex) {
            <div class="unit-geo-details new-brand-color">
              <div>
                <span>{{ unit?.unitsSummary }}</span>
              </div>

              <mat-icon
                width="48px"
                class="clickable-icon new-brand-color"
                (click)="openMarker(ind)"
                >place</mat-icon
              >
            </div>
            }
          </div>
        </div>
        } @if (total>pageSize){
        <mat-paginator
          class="invisible-item-number new-brand-color full-width-paginator"
          [length]="total"
          [pageSize]="pageSize"
          [pageSizeOptions]="[]"
          [showFirstLastButtons]="false"
          [pageIndex]="pageIndex"
          (page)="pageEvent.next($event)"
          aria-label="Select complex page"
        >
        </mat-paginator>
        }
      </div>
    </div>
    <div class="google-map-container" #googleMap>
      <google-map
        [options]="mapOptions"
        *ngIf="latNumber && lngNumber"
        [center]="{ lat: latNumber, lng: lngNumber }"
        [width]="width"
        [height]="height"
        (mapInitialized)="onMapReady($event)"
        [ngClass]="{ 'ttw-map-info-window-with-margin': useMargin }"
      >
        @for (marker of markersPage; track marker.link; let idx = $index) {
        <map-advanced-marker
          #htmlmarker="mapAdvancedMarker"
          [position]="marker.position"
          [content]="marker.customPin"
          (mapClick)="openInfoWindowHTML(htmlmarker, idx)"
        >
        </map-advanced-marker>

        <map-info-window #infoWindow>
          <div [innerHTML]="infoContent" class="info-content"></div
        ></map-info-window>

        }
      </google-map>
    </div>
  </div>
  } @if(isMobile){
  <div
    class="back-button new-brand-color"
    aria-label="Back button"
    (click)="backToListing()"
  >
    <mat-icon class="new-brand-color">keyboard_arrow_left</mat-icon>
    <span class="back-text">back to list view</span>
  </div>
  <div class="mobile-maps-search-container"></div>
  <div class="google-map-container-mobile" #googleMap>
    <google-map
      [options]="mapOptions"
      *ngIf="latNumber && lngNumber"
      [center]="{ lat: latNumber, lng: lngNumber }"
      [width]="width"
      [height]="height"
      (mapInitialized)="onMapReady($event)"
      [ngClass]="{ 'ttw-map-info-window-with-margin': useMargin }"
    >
      @for (marker of markersPage; track marker.link; let idx = $index) {
      <map-advanced-marker
        #htmlmarker="mapAdvancedMarker"
        [position]="marker.position"
        [content]="marker.customPin"
        (mapClick)="openInfoWindowHTML(htmlmarker, idx)"
      >
      </map-advanced-marker>

      <map-info-window #infoWindow
        ><div [innerHTML]="infoContent"></div>
      </map-info-window>

      }
    </google-map>
  </div>

  <twbooking-bottom-sheet (sheetSizeChanged)="panMap($event)">
    @if(loadingMarkers){
    <div>
      <p class="h3-like">
        Searching for units <span *ngIf="mapinteraction">in this area</span>
      </p>
      <mat-progress-bar class="ttw-progress" mode="indeterminate">
      </mat-progress-bar>
    </div>

    } @if(total){
    <span>
      <p class="h3-like new-brand-color">
        {{ total }} homes within map area
      </p></span
    >
    } @if(!total && !loadingMarkers){
    <span>
      <p class="h3-like new-brand-color">
        No exact matches. Try change zoom or drag map.
      </p></span
    >
    } @for (unit of unitsPage; track unit.externalId; let ind = $index){
    <div class="unit-geo-card unit-geo-card-shadow">
      <div class="unit-geo-image-container">
        <img
          class="unit-geo-image"
          [src]="unit.SeasonThumbnail"
          [alt]="unit.SeasonCaption"
          (click)="openMarker(ind)"
        />
      </div>
      <div class="unit-geo-content">
        <a class="unit-link" [href]="unit.link">
          <h3 class="unit-geo-title new-brand-color">
            {{ unit.MarketingHeadline__c }}
          </h3></a
        >

        @if (!unit.isComplex) {
        <div class="unit-geo-details new-brand-color" *ngIf="!unit.isComplex">
          <div>
            <span>{{ unit?.Bedrooms__c }} bedrooms</span>
            <span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>
            <span *ngIf="totalBeds > 0">{{ totalBeds }} beds</span>
            <span *ngIf="totalBeds > 0"
              >&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span
            >
            <span>{{ unit?.Bathrooms__c }} bathrooms</span>
          </div>
          <img
            width="48px"
            class="clickable-icon"
            src="/assets/home.svg"
            alt="complex"
            (click)="openMarker(ind)"
          />
        </div>
        } @if (unit.isComplex) {
        <div class="unit-geo-details new-brand-color">
          <div>
            <span>{{ unit?.unitsSummary }}</span>
          </div>
          <img
            width="48px"
            class="clickable-icon"
            srcset="/assets/complex.svg"
            alt="home"
            (click)="openMarker(ind)"
          />
        </div>
        }
      </div>
    </div>

    } @if (total>pageSize){
    <mat-paginator
      class="invisible-item-number new-brand-color full-width-paginator"
      [length]="total"
      [pageSize]="pageSize"
      [pageSizeOptions]="[]"
      [showFirstLastButtons]="false"
      [pageIndex]="pageIndex"
      (page)="pageEvent.next($event)"
      aria-label="Select complex page"
    >
    </mat-paginator>
    }
  </twbooking-bottom-sheet>

  } }
</div>
