import { Component, OnInit, Input, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import {
  CampaignInfoService,
  Campaign,
} from 'src/app/services/campaign-info.service';
import { EventService, Inquiry } from 'src/app/services/event.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { SearchParamsService } from 'src/app/services/search-params.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { locationGroups } from '../../../store/models/search-options';
import {
  mm,
  mainsite,
  staging,
  localhost,
} from '../../../store/models/hostings';
import { LeadSubmitParamsGA } from '../../../store/models/events-ga.model';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'twbooking-inquiry-form',
  templateUrl: './inquiry-form.component.html',
  styleUrls: ['./inquiry-form.component.scss'],
})
export class InquiryFormComponent implements OnInit {
  @Input() inquiryInput: {
    unit?: any;
    filter?: {
      arrival?;
      departure?;
      adults?: number;
      children?: number;
      value?: number;
    };
  };
  @Input() mode: 'Inquiry' | 'ContactUs';
  @Output() resultEmitter = new EventEmitter();

  hostMode: 'basic' | 'MM' | 'other' = 'other';

  logoSrc = 'assets/TTW-logo.jpg';
  logoAlt = 'The Travel Whisperer';
  welcomeText =
    'Contacting us will put you in touch with one of our Personal Travel Designers. We have access to the majority of the finest vacation homes and hotels throughout North America and our amazing team will curate an unforgettable experience for you. Our services include finding you amazing accommodation, organising transfers/car hire, booking additional components for you (e.g. Lift tickets, Ski rentals etc) and much more!';
  contactEmail = 'reservations@thetravelwhisperer.com';
  reviewText =
    'Kelly and her team are consistently responsive to any inquiries. We were very pleased with the property and have reserved for next summer.';
  reviewAuthor = 'Barbara D.';
  priceModel = 1000;

  submitted = false;
  warningMessage: string = null;
  arrivalCheck: string;
  departureCheck: string;

  children: any;
  adults: any;

  form: UntypedFormGroup;

  minStayDays: number;
  invalidDates: boolean;
  availabilityChecked: boolean;
  checkingAvailability: boolean;
  mmProperty: boolean;

  lastChangedArrival: boolean;

  minPrice = 0;
  maxPrice = 100000;
  scaleMarks: string[] = [];
  priceSubscription: Subscription;

  /* inquiry value variables */
  inquiryValue = 0;
  QUALIFICATION_RATE = 0.5;
  BOOKING_RATE = 0.17;
  COMMISSION_RATE = 0.15;
  AVERAGE_VALUE = 38;

  today = new Date();
  minDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate()
  );
  maxDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate()
  );

  /* location variables */
  readonly locationGroups: any[] = locationGroups;
  locationValue: any;

  commentPlaceholder: string =
    'Important bed configurations, must be ski-in/ski-out etc';

  initializeScaleMarks(): void {
    const interval = 10000; // Define the interval between marks
    const numberOfMarks = (this.maxPrice - this.minPrice) / interval;

    for (let i = 0; i <= numberOfMarks; i++) {
      const value = this.minPrice + i * interval;
      const formattedValue = `$${value / 1000}k`;
      this.scaleMarks.push(formattedValue);
    }
  }

  checkPlaceHolder() {
    if (this.commentPlaceholder) {
      this.commentPlaceholder = null;
      return;
    } else {
      this.commentPlaceholder =
        'Important bed configurations, must be ski-in/ski-out etc';
      return;
    }
  }

  ngOnInit() {
    if (
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(localhost) ||
      window.location.hostname.includes(staging)
    ) {
      this.hostMode = 'basic';
      this.logoSrc = 'assets/TTW-logo.jpg';
      this.logoAlt = 'The Travel Whisperer';
      this.welcomeText =
        'Contacting us will put you in touch with one of our Personal Travel Designers. We have access to the majority of the finest vacation homes and hotels throughout North America and our amazing team will curate an unforgettable experience for you. Our services include finding you amazing accommodation, organising transfers/car hire, booking additional components for you (e.g. Lift tickets, Ski rentals etc) and much more!';
      this.contactEmail = 'reservations@thetravelwhisperer.com';
      this.reviewText =
        'Kelly and her team are consistently responsive to any inquiries. We were very pleased with the property and have reserved for next summer.';
      this.reviewAuthor = 'Barbara D.';
    } else if (window.location.hostname.includes(mm)) {
      this.logoSrc =
        'https://images.thetravelwhisperer.com/Md4nvO-2iqCUYPFCzdoMfpIpH-U=/0x0/storage.googleapis.com/ttw-marketing-images/Logos/MM_Logo.png';
      this.hostMode = 'MM';
      this.logoAlt = 'Mountain Management';
      this.welcomeText =
        'Mountain Management is a boutique, family-owned luxury property manager, trusted with some of the finest homes and condominiums in Beaver Creek Resort and the surrounding area.';
      this.contactEmail = 'reservations@mtnmgt.com';
      this.reviewText =
        'Mountain Management is the best. The amenities and cleaning service make the experience great.';
      this.reviewAuthor = 'Cassie';
    } else {
      this.hostMode = 'other';
      console.error('Inquiry form opened for host ', window.location.hostname);
    }
    this.favorites = this.favoriteService.get();
    const emailRegex = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
    );

    const phoneRegex = new RegExp(
      '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
    );
    this.form = this._fb.group({
      propertyField: ['', []],
      arrivalField: ['', [Validators.required]],
      departureField: ['', [Validators.required]],
      children: [0, []],
      adults: [2, []],
      infants: [0, []],
      pets: [0, []],
      bedroomsField: [1, []],
      firstnameField: ['', [Validators.required]],
      lastnameField: ['', [Validators.required]],
      flexibilityField: [false],
      emailField: ['', [Validators.required, Validators.pattern(emailRegex)]],
      phoneField: ['', [Validators.required, Validators.pattern(phoneRegex)]],
      additionalField: ['', []],
      priceField: [this.priceModel, [Validators.pattern('^[0-9.,]*$')]],
      priceAdditional: [this.priceModel, []],
      location: [null],
      alternativeUnitsField: [this.favorites, []],
    });

    this.prepopulateData();

    if (this.inquiryInput && this.inquiryInput.unit) {
      this.form
        .get('propertyField')
        .setValue(this.inquiryInput.unit.MarketingHeadline__c);
      this.form.get('propertyField').disable();
    }

    const savedSearch = this.searchService.get();
    if (
      this.inquiryInput &&
      this.inquiryInput.filter &&
      this.inquiryInput.filter.value &&
      this.inquiryInput.filter.value > 0
    ) {
      this.inquiryValue =
        Math.round(
          (this.inquiryInput.filter.value *
            (this.QUALIFICATION_RATE *
              this.BOOKING_RATE *
              this.COMMISSION_RATE *
              100)) /
            3
        ) / 100; // *100/100 to have 2 decimal places
    } else {
      this.inquiryValue = this.AVERAGE_VALUE;
    }

    if (
      this.inquiryInput &&
      this.inquiryInput.filter.arrival &&
      this.inquiryInput.filter.departure
    ) {
      this.form.controls['arrivalField'].setValue(
        moment
          .utc(this.inquiryInput.filter.arrival, 'YYYY-MM-DD')
          .startOf('day')
      );
      this.form.controls['departureField'].setValue(
        moment
          .utc(this.inquiryInput.filter.departure, 'YYYY-MM-DD')
          .startOf('day')
      );
    } else {
      if (savedSearch && savedSearch.StartDate && savedSearch.EndDate) {
        this.form.controls['arrivalField'].setValue(
          moment.utc(savedSearch.StartDate, 'YYYY-MM-DD')
        );
        this.form.controls['departureField'].setValue(
          moment.utc(savedSearch.EndDate, 'YYYY-MM-DD')
        );
      }
    }

    if (savedSearch && savedSearch.Adults) {
      this.form.controls['adults'].setValue(savedSearch.Adults.toString());
    }
    if (savedSearch && savedSearch.Children) {
      this.form.controls['children'].setValue(savedSearch.Children.toString());
    }

    this.form.get('arrivalField').valueChanges.subscribe((d: any) => {
      const date = moment.utc(d);
      this.lastChangedArrival = true;
      this.arrivalCheck = date.format('MM/DD/YYYY');
    });

    this.form.get('departureField').valueChanges.subscribe((d: any) => {
      const date = moment.utc(d);
      this.lastChangedArrival = false;
      this.departureCheck = date.format('MM/DD/YYYY');
    });
  }

  filterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc(Date.now());
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    const day_moment = moment.utc(d_any);
    return day_moment.isAfter(today);
  };

  constructor(
    private _fb: UntypedFormBuilder,
    private campaignService: CampaignInfoService,
    private breakpointObserver: BreakpointObserver,
    private searchService: SearchParamsService,
    private userDataService: UserDataService,
    private authService: AuthService,
    private eventService: EventService,
    private favoriteService: FavoritesService
  ) {
    this.initializeScaleMarks();
  }

  favorites: { unit: string; Name: string }[] = [];

  prepopulateData() {
    const savedUser = this.userDataService.get();
    if (savedUser && savedUser.firstName) {
      this.form.controls['firstnameField'].setValue(savedUser.firstName);
    }
    if (savedUser && savedUser.lastName) {
      this.form.controls['lastnameField'].setValue(savedUser.lastName);
    }
    if (savedUser && savedUser.email) {
      this.form.controls['emailField'].setValue(savedUser.email);
    }
    if (savedUser && savedUser.phone) {
      this.form.controls['phoneField'].setValue(savedUser.phone);
    }
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
  /*
  priceSlided(newvalue: number) {
    let formatter: Intl.NumberFormat = new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 0,
    });

    let formattedValue = formatter.format(newvalue);

    this.form.controls.priceField.setValue(formattedValue);
  }*/

  priceSlided(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = parseInt(inputElement.value, 10);
    console.log('Slider value:', value);
    let formatter: Intl.NumberFormat = new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 0,
    });

    if (!isNaN(value)) {
      let formattedValue = formatter.format(value);
      this.form.controls.priceField.setValue(formattedValue);
    }
  }

  priceInputChanged(event) {
    this.form.controls.priceAdditional.setValue(
      this.form.controls.priceField.value
    );
  }

  public handleSubmit(
    bookingForm: any,
    isValid: boolean
  ): { result: any; warningMessage: string } {
    this.submitted = true;

    if (isValid) {
      let timestamp: Date = moment.utc().toDate();
      let alternativeUnits = '';
      if (
        bookingForm.alternativeUnitsField &&
        bookingForm.alternativeUnitsField.length
      ) {
        bookingForm.alternativeUnitsField.forEach((alternative) => {
          if (alternative && alternative.Name) {
            if (alternativeUnits !== '') {
              alternativeUnits += ', ';
            }
            alternativeUnits += alternative.Name;
          }
        });
      }

      const enquiry = {
        arrivalDate: bookingForm.arrivalField,
        departureDate: bookingForm.departureField,
        firstName: bookingForm.firstnameField,
        lastName: bookingForm.lastnameField,
        phone: bookingForm.phoneField,
        email: bookingForm.emailField,
        adults: bookingForm.adults,
        children: bookingForm.children,
        infants: bookingForm.infants,
        location:
          bookingForm.location &&
          bookingForm.location.City &&
          bookingForm.location.City !== ''
            ? bookingForm.location.City + ', ' + bookingForm.location.State
            : "Haven't decided yet",
        pets: bookingForm.pets,
        bedrooms: bookingForm.bedroomsField,
        description: bookingForm.additionalField,
        flexibility: bookingForm.flexibilityField,
        unit:
          this.inquiryInput &&
          this.inquiryInput.unit &&
          this.inquiryInput.unit.MarketingHeadline__c
            ? this.inquiryInput.unit.MarketingHeadline__c
            : null,
        InquiryDate: timestamp,
        AlternativeUnits: alternativeUnits,
        InquirySource__c: this.hostMode === 'MM' ? 'mm' : 'ttw',
        Budget__c: bookingForm.priceField.toString(),
        sessionId: this.authService.getSessionId(),
      };

      const inquiry: Inquiry = {
        unit:
          this.inquiryInput &&
          this.inquiryInput.unit &&
          this.inquiryInput.unit.Name
            ? this.inquiryInput.unit.Name
            : null,
        arrivalDate: enquiry.arrivalDate.startOf('day').toDate(),
        departureDate: enquiry.departureDate.startOf('day').toDate(),
        adults: enquiry.adults,
        children: enquiry.children,
        guests: +enquiry.adults + +enquiry.children,
        infants: enquiry.infants,
        pets: enquiry.pets,
        preferredDestination: enquiry.location,
        nights: moment
          .duration(
            enquiry.departureDate
              .startOf('day')
              .diff(enquiry.arrivalDate.startOf('day'))
          )
          .asDays(),
        email: enquiry.email,
        firstName: enquiry.firstName,
        lastName: enquiry.lastName,
        phone: enquiry.phone,
        flexibility: bookingForm.flexibilityField ? 1 : 0,
        bedrooms: enquiry.bedrooms,
        description: enquiry.description,
        InquiryDate: timestamp,
        AlternativeUnits: alternativeUnits,
        InquirySource__c: enquiry.InquirySource__c,
        Budget__c: enquiry.Budget__c,
      };

      let leadGA: LeadSubmitParamsGA = {
        StartDate: enquiry.arrivalDate.format('YYYY-MM-DD'),
        EndDate: enquiry.arrivalDate.format('YYYY-MM-DD'),
        Adults: enquiry.adults,
        Children: enquiry.children,
        Bedrooms: enquiry.bedrooms,
        UnitName: inquiry.unit,
        Destination: enquiry.location,
        value: this.inquiryValue,
        currency: 'USD',
      };
      this.eventService.sendLeadSubmitGA(leadGA, {
        email: enquiry.email,
        phone: enquiry.phone,
      });
      this.eventService.triggerSaveInquiryEvent(inquiry);

      const campaignData: Campaign = this.campaignService.get();
      let enquiryUPD: any = enquiry;
      if (campaignData && campaignData.utm_campaign) {
        enquiryUPD = { ...enquiryUPD, utm_campaign: campaignData.utm_campaign };
      }
      if (campaignData && campaignData.utm_content) {
        enquiryUPD = { ...enquiryUPD, utm_content: campaignData.utm_content };
      }
      if (campaignData && campaignData.utm_medium) {
        enquiryUPD = { ...enquiryUPD, utm_medium: campaignData.utm_medium };
      }
      if (campaignData && campaignData.utm_source) {
        enquiryUPD = { ...enquiryUPD, utm_source: campaignData.utm_source };
      }
      this.resultEmitter.emit(enquiryUPD);
      return { result: enquiryUPD, warningMessage: null };
    } else {
      this.warningMessage = this.prepareWarningsSummary(this.form);

      return { result: null, warningMessage: this.warningMessage };
    }
  }

  public prepareWarningsSummary(bookingForm): string {
    let warningMessage = 'Cannot submit your inquiry. ';

    if (
      bookingForm.controls.firstnameField.errors ||
      bookingForm.controls.lastnameField.errors ||
      bookingForm.controls.emailField.errors ||
      bookingForm.controls.phoneField.errors ||
      bookingForm.controls.arrivalField.errors ||
      bookingForm.controls.departureField.errors
    ) {
      warningMessage += 'Please ensure you have entered ';
      let addComma = false;
      if (bookingForm.controls.firstnameField.errors) {
        warningMessage += 'first name';
        addComma = true;
      }
      if (bookingForm.controls.lastnameField.errors) {
        if (addComma) {
          warningMessage += ', ';
        }
        warningMessage += 'last name';
        addComma = true;
      }
      if (bookingForm.controls.emailField.errors) {
        if (addComma) {
          warningMessage += ', ';
        }
        if (bookingForm.controls.emailField.errors.required) {
          warningMessage += 'contact email';
        } else {
          warningMessage += 'valid email';
        }
        addComma = true;
      }
      if (bookingForm.controls.phoneField.errors) {
        if (addComma) {
          warningMessage += ', ';
        }
        if (bookingForm.controls.phoneField.errors.required) {
          warningMessage += 'contact phone';
        } else {
          warningMessage += 'valid phone';
        }
        addComma = true;
      }
      if (bookingForm.controls.arrivalField.errors) {
        if (addComma) {
          warningMessage += ', ';
        }
        warningMessage += 'arrival date';
        addComma = true;
      }
      if (bookingForm.controls.departureField.errors) {
        if (addComma) {
          warningMessage += ', ';
        }
        warningMessage += 'departure date';
        addComma = true;
      }
      warningMessage += '. ';
    }

    return warningMessage;
  }

  userDataChanged(field: string, event) {
    let savedUser = this.userDataService.get();
    switch (field) {
      case 'firstName': {
        savedUser = { ...savedUser, firstName: event.target.value };
        break;
      }
      case 'lastName': {
        savedUser = { ...savedUser, lastName: event.target.value };
        break;
      }
      case 'email': {
        savedUser = { ...savedUser, email: event.target.value };
        break;
      }
      case 'phone': {
        savedUser = { ...savedUser, phone: event.target.value };
        break;
      }
    }
    this.authService.updateUserData(savedUser);
  }

  arrivalChanged(event) {
    if (
      !this.form.controls.departureField.value ||
      this.form.controls.arrivalField.value.isSameOrAfter(
        this.form.controls.departureField.value
      )
    ) {
      let departureDate = this.form.controls.arrivalField.value.clone();
      departureDate.add(4, 'days');
      this.form.controls.departureField.setValue(departureDate);
    }
  } // end arrivalChanged

  departureChanged(event) {
    if (
      !this.form.controls.arrivalField.value ||
      this.form.controls.arrivalField.value.isSameOrAfter(
        this.form.controls.departureField.value
      )
    ) {
      let arrivalMoment = this.form.controls.departureField.value.clone();
      arrivalMoment.subtract(4, 'days');
      this.form.controls.arrivalField.setValue(arrivalMoment);
    }
  }

  compareLocation(v1: any, v2: any) {
    return v2 ? v1.City === v2.City : false;
  }
}
