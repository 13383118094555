import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import {
  Unit,
  FeaturedAmenity,
  UnitImage,
} from '../../store/models/unit.model';
import { Complex } from 'src/app/store/models/complex.model';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  trigger,
  style,
  transition,
  animate,
  state,
  keyframes,
} from '@angular/animations';
import { imageByAmenity } from './default-images';
import { iconByAmenity } from 'src/app/store/models/featured-amenity.model';

@Component({
  selector: 'twbooking-unit-amenities',
  templateUrl: './unit-amenities.component.html',
  styleUrls: ['./unit-amenities.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ transform: 'scale(1.5)' }),
        animate('3000ms', style({ transform: 'scale(1)' })),
      ]),
    ]),
  ],
})
export class UnitAmenitiesComponent implements OnChanges, OnDestroy {
  @Input() unit: Unit | Complex;
  readonly iconByAmenity = iconByAmenity;
  amenityList = [];
  showAll = false;
  showFirst = false;
  isMobile = false;
  subMedia: Subscription;
  currentImage = 0;
  doEffect = false;
  showDescription = false;

  amenityIcons = {
    PoolSpa__c: {
      text: 'Pool / Spa',
      img: 'M7,4A2,2 0 0,1 9,6A2,2 0 0,1 7,8A2,2 0 0,1 5,6A2,2 0 0,1 7,4M11.15,12H22V20A2,2 0 0,1 20,22H4A2,2 0 0,1 2,20V12H5V11.25C5,10 6,9 7.25,9H7.28C7.62,9 7.95,9.09 8.24,9.23C8.5,9.35 8.74,9.5 8.93,9.73L10.33,11.28C10.56,11.54 10.84,11.78 11.15,12M7,20V14H5V20H7M11,20V14H9V20H11M15,20V14H13V20H15M19,20V14H17V20H19M18.65,5.86C19.68,6.86 20.16,8.21 19.95,9.57L19.89,10H18L18.09,9.41C18.24,8.62 18,7.83 17.42,7.21L17.35,7.15C16.32,6.14 15.85,4.79 16.05,3.43L16.11,3H18L17.91,3.59C17.76,4.38 18,5.17 18.58,5.79L18.65,5.86M14.65,5.86C15.68,6.86 16.16,8.21 15.95,9.57L15.89,10H14L14.09,9.41C14.24,8.62 14,7.83 13.42,7.21L13.35,7.15C12.32,6.14 11.85,4.79 12.05,3.43L12.11,3H14L13.91,3.59C13.76,4.38 14,5.17 14.58,5.79L14.65,5.86Z',
      viewBox: '0 0 24 24',
    },
    Entertainment__c: {
      text: 'Entertainment',
      img: 'M160-80q-33 0-56.5-23.5T80-160v-480q0-25 13.5-45t36.5-29l506-206 26 66-330 134h468q33 0 56.5 23.5T880-640v480q0 33-23.5 56.5T800-80H160Zm0-80h640v-280H160v280Zm160-40q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM160-520h480v-80h80v80h80v-120H160v120Zm0 360v-280 280Z',
      viewBox: '0 -960 960 960',
    },
    Outdoor__c: {
      text: 'Outdoor',
      img: 'M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,2L14.39,5.42C13.65,5.15 12.84,5 12,5C11.16,5 10.35,5.15 9.61,5.42L12,2M3.34,7L7.5,6.65C6.9,7.16 6.36,7.78 5.94,8.5C5.5,9.24 5.25,10 5.11,10.79L3.34,7M3.36,17L5.12,13.23C5.26,14 5.53,14.78 5.95,15.5C6.37,16.24 6.91,16.86 7.5,17.37L3.36,17M20.65,7L18.88,10.79C18.74,10 18.47,9.23 18.05,8.5C17.63,7.78 17.1,7.15 16.5,6.64L20.65,7M20.64,17L16.5,17.36C17.09,16.85 17.62,16.22 18.04,15.5C18.46,14.77 18.73,14 18.87,13.21L20.64,17M12,22L9.59,18.56C10.33,18.83 11.14,19 12,19C12.82,19 13.63,18.83 14.37,18.56L12,22Z',
      viewBox: '0 0 24 24',
    },
    Amenity__c: {
      text: 'Amenities',
      img: 'M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z',
      viewBox: '0 0 24 24',
    },
    General__c: {
      text: 'General',
      img: 'M2,12L12,3L22,12H19V20H5V12H2M12,18L12.72,17.34C15.3,15 17,13.46 17,11.57C17,10.03 15.79,8.82 14.25,8.82C13.38,8.82 12.55,9.23 12,9.87C11.45,9.23 10.62,8.82 9.75,8.82C8.21,8.82 7,10.03 7,11.57C7,13.46 8.7,15 11.28,17.34L12,18Z',
      viewBox: '0 0 24 24',
    },
    Kitchen__c: {
      text: 'Kitchen',
      img: 'M12.5,3C7.81,3 4,5.69 4,9V9C4,10.19 4.5,11.34 5.44,12.33C4.53,13.5 4,14.96 4,16.5C4,17.64 4,18.83 4,20C4,21.11 4.89,22 6,22H19C20.11,22 21,21.11 21,20C21,18.85 21,17.61 21,16.5C21,15.28 20.66,14.07 20,13L22,11L19,8L16.9,10.1C15.58,9.38 14.05,9 12.5,9C10.65,9 8.95,9.53 7.55,10.41C7.19,9.97 7,9.5 7,9C7,7.21 9.46,5.75 12.5,5.75V5.75C13.93,5.75 15.3,6.08 16.33,6.67L18.35,4.65C16.77,3.59 14.68,3 12.5,3M12.5,11C12.84,11 13.17,11.04 13.5,11.09C10.39,11.57 8,14.25 8,17.5V20H6V17.5A6.5,6.5 0 0,1 12.5,11Z',
      viewBox: '0 0 24 24',
    },
    Attractions__c: {
      text: 'Attractions',
      img: 'M10,21V18H3L8,13H5L10,8H7L12,3L17,8H14L19,13H16L21,18H14V21H10Z',
      viewBox: '0 0 24 24',
    },
    Convenience__c: {
      text: 'Convenience',
      img: 'M5.5,21C4.72,21 4.04,20.55 3.71,19.9V19.9L1.1,10.44L1,10A1,1 0 0,1 2,9H6.58L11.18,2.43C11.36,2.17 11.66,2 12,2C12.34,2 12.65,2.17 12.83,2.44L17.42,9H22A1,1 0 0,1 23,10L22.96,10.29L20.29,19.9C19.96,20.55 19.28,21 18.5,21H5.5M12,4.74L9,9H15L12,4.74M12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17A2,2 0 0,0 14,15A2,2 0 0,0 12,13Z',
      viewBox: '0 0 24 24',
    },
    LeisureActivities__c: {
      text: 'Leisure Activities',
      img: 'm160-40 80-360h61L221-40h-61Zm120 0 112-564-72 28v136h-80v-188l202-86q29-12 59-2.5t47 36.5l40 64q26 42 70.5 69T760-520v80q-66 0-123.5-27.5T540-540l-24 120 84 80v300h-80v-240l-84-80-72 320h-84Zm260-700q-33 0-56.5-23.5T460-820q0-33 23.5-56.5T540-900q33 0 56.5 23.5T620-820q0 33-23.5 56.5T540-740ZM700-40v-360h60v360h-60Z',
      viewBox: '0 -960 960 960',
    },
    LocalServicesBusinesses__c: {
      text: 'Local Services And Businesses',
      img: 'M11.5,1L2,6V8H21V6M16,10V17H19V10M2,22H21V19H2M10,10V17H13V10M4,10V17H7V10H4Z',
      viewBox: '0 0 24 24',
    },

    Restrictions__c: {
      text: 'Restrictions',
      img: 'M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z',
      viewBox: '0 0 24 24',
    },
  };

  objectKeys = Object.keys;
  arrayFrom = Array.from;

  chipSelectable = false;

  /* Featured Amenities */
  showAmenities = false; // show first rows of classic amenities
  featuredAmenities: FeaturedAmenity[] = null;
  images: UnitImage[] = [];
  choosenAmenity = 0;
  amenityIndex = 0;

  constructor(private breakpointObserver: BreakpointObserver) {}

  async ngOnChanges(changes) {
    if (changes.unit) {
      this.featuredAmenities = null;
      this.unit = changes.unit.currentValue;

      if (
        this.unit.FeaturedAmenities__r &&
        this.unit.FeaturedAmenities__r &&
        this.unit.FeaturedAmenities__r.length > 0
      ) {
        this.featuredAmenities = this.unit.FeaturedAmenities__r.filter(
          (amenity) => {
            return (
              amenity.AmenityType__c !== 'Housekeeping Service - Alternate' &&
              amenity.AmenityType__c !== 'Housekeeping Service - Daily'
            );
          }
        );
      }

      const screenWidth = window.innerWidth <= 1366 ? window.innerWidth : 1366;
      const width = screenWidth < 576 ? screenWidth : (screenWidth - 350) / 2;

      const imgixPosfix =
        '?auto=enhance&w=' +
        width.toString() +
        '&h=' +
        ((width * 2) / 3).toString() +
        '&fit=crop&crop=edges';
      this.subMedia = this.breakpointObserver
        .observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe((result) => {
          this.isMobile =
            result.breakpoints[Breakpoints.XSmall] ||
            result.breakpoints[Breakpoints.Small];
        });

      if (
        this.unit.FeaturedAmenities__r &&
        this.unit.FeaturedAmenities__r &&
        this.unit.FeaturedAmenities__r.length > 0
      ) {
        const filteredAmenities = this.unit.FeaturedAmenities__r.filter(
          (amenity) => {
            if (amenity.ImageLink__c && amenity.ImageLink__c !== '') {
              amenity.sliderIndex = this.images ? this.images.length : 0;
              this.images.push({
                Name: amenity.AmenityType__c,
                URL__c: amenity.ImageLink__c.includes('imgix')
                  ? amenity.ImageLink__c + imgixPosfix
                  : amenity.ImageLink__c,
                Caption__c: amenity.Description__c,
                Season__c: 'Any',
                representative: false,
              });
            } else {
              if (
                amenity.AmenityType__c !== 'Housekeeping Service - Alternate' &&
                amenity.AmenityType__c !== 'Housekeeping Service - Daily'
              ) {
                amenity.sliderIndex = this.images ? this.images.length : 0;
                this.images.push({
                  Name: amenity.AmenityType__c,
                  URL__c: imageByAmenity[amenity.AmenityType__c] + imgixPosfix,
                  Caption__c: amenity.Description__c
                    ? amenity.Description__c
                    : amenity.AmenityType__c,
                  Season__c: 'Any',
                  representative: true,
                });
              }
            }
            return (
              amenity.AmenityType__c !== 'Housekeeping Service - Alternate' &&
              amenity.AmenityType__c !== 'Housekeeping Service - Daily'
            );
          }
        );
      }
      this.amenityIndex = this.featuredAmenities
        ? this.featuredAmenities.findIndex((x) => x.sliderIndex === 0)
        : 0;

      this.showAmenities = !(
        this.featuredAmenities && this.featuredAmenities.length > 0
      );
      if (
        this.unit &&
        this.unit.Amenities__r &&
        this.unit.Amenities__r.length > 0
      ) {
        this.setAmenityList(this.unit.Amenities__r[0]);
      }
      this.showDescription = false;
      await this.timeout(3000);
      this.showDescription = true;
    }
  }

  setAmenityList(amenities: any) {
    if (!Object.keys) {
      Object.keys = (function () {
        'use strict';
        const hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor',
          ],
          dontEnumsLength = dontEnums.length;

        return function (obj) {
          if (
            typeof obj !== 'function' &&
            (typeof obj !== 'object' || obj === null)
          ) {
            throw new TypeError('Object.keys called on non-object');
          }

          let result = [],
            prop,
            i;

          for (prop in obj) {
            if (hasOwnProperty.call(obj, prop)) {
              result.push(prop);
            }
          }

          if (hasDontEnumBug) {
            for (i = 0; i < dontEnumsLength; i++) {
              if (hasOwnProperty.call(obj, dontEnums[i])) {
                result.push(dontEnums[i]);
              }
            }
          }
          return result;
        };
      })();
    }
    if (!Object.entries) {
      Object.entries = function (obj) {
        let ownProps = Object.keys(obj),
          i = ownProps.length,
          resArray = new Array(i); // preallocate the Array
        while (i--) {
          resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }

        return resArray;
      };
    }

    for (const [key, value] of Object.entries(amenities)) {
      if (
        Object.keys(this.amenityIcons).indexOf(key) !== -1 &&
        value &&
        typeof value === 'string'
      ) {
        this.amenityIcons[key].values = (<string>value).split(';');
      }
    }
    const values = Object.keys(this.amenityIcons).map(
      (e) => this.amenityIcons[e]
    );
    this.amenityList = Array.from(values);
  }

  timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  keepAmenity(imageIndex) {
    this.choosenAmenity = imageIndex;

    this.amenityIndex = this.featuredAmenities.findIndex(
      (x) => x.sliderIndex === imageIndex
    );
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  ngOnDestroy() {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
  }

  /* carousel images */

  async showAmenity(imageIndex) {
    this.showDescription = false;

    if (imageIndex >= 0) {
      this.currentImage = imageIndex;

      this.amenityIndex = this.featuredAmenities.findIndex(
        (x) => x.sliderIndex === imageIndex
      );
    }

    this.showDescription = true;
  }

  async showPrev() {
    this.showDescription = false;

    this.currentImage--;
    if (this.currentImage < 0) {
      this.currentImage = this.images.length - 1;
    }

    this.amenityIndex = this.featuredAmenities.findIndex(
      (x) => x.sliderIndex === this.currentImage
    );

    this.preloadPrev(this.currentImage);

    this.showDescription = true;
  }
  async showNext() {
    this.showDescription = false;

    this.currentImage++;
    if (this.currentImage >= this.images.length) {
      this.currentImage = 0;
    }

    this.amenityIndex = this.featuredAmenities.findIndex(
      (x) => x.sliderIndex === this.currentImage
    );

    this.preloadNext(this.currentImage);

    this.showDescription = true;
  }

  preloadNext(currentImage) {
    let nextImage = currentImage + 1;
    if (nextImage >= this.images.length) {
      nextImage = 0;
    }
    new Image().src = this.images[nextImage].URL__c;
  }

  preloadPrev(currentImage) {
    let prevImage = currentImage--;
    if (prevImage < 0) {
      prevImage = this.images.length - 1;
    }
    new Image().src = this.images[prevImage].URL__c;
  }

  onSwipeLeft(evt) {
    this.showPrev();
  }
  onSwipeRight(evt) {
    this.showNext();
  }
}
