import { Component, OnChanges, OnDestroy, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { UnitsService } from 'src/app/services/units.service';
import { UnitImage } from '../../store/models/unit-summary.model';

import { ComplexSummary } from '../../store/models/complex.model';
import { UnitEnquiryDialogComponent } from '../unit-booking/unit-enquiry-dialog/unit-enquiry-dialog.component';
import { localhost, mainsite, mm, staging } from '../../store/models/hostings';
import { FeaturedAmenity } from 'src/app/store/models/unit.model';
import { iconByAmenity } from 'src/app/store/models/featured-amenity.model';

export interface IResponsiveColumnsMap {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
}

@Component({
  selector: 'twbooking-complex-summary',
  templateUrl: './complex-summary.component.html',
  styleUrls: [
    '../unit-summary/summary-card-general.scss',
    './complex-summary.component.scss',
  ],
})
export class ComplexSummaryComponent implements OnChanges, OnDestroy {
  @Input() complex: ComplexSummary;
  @Input() columnNum: number;
  @Input() searchQuery: any = {};

  readonly iconByAmenity = iconByAmenity;

  /* slider */

  images: UnitImage[] = [];
  choosenAmenity = 0;
  defaultImage =
    'https://s3.amazonaws.com/vrp2/vrpimages/141/1/1/' +
    '59c46191ea1b1_2195%20Cresta%20Rd%20Edwards%20CO-MLS_Size-001-1-Cresta-1800x1200-72dpi.jpg';
  /* subscriptions */
  imageSrc = this.defaultImage;

  myOptions = {
    'tooltip-class': 'tw-tooltip',
  };
  public ratingArr = [0, 1, 2, 3, 4]; // auxiliary array for showing star icons.
  public countBySize: IResponsiveColumnsMap = {
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  };
  public smallDevice = false;

  nb = true;
  isMobile = false;
  subMedia: Subscription;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver,
    private unitService: UnitsService
  ) {
    this.subMedia = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });
  }

  ngOnChanges() {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );

    this.images = [];
    const screenWidth = window.innerWidth;
    const width =
      (screenWidth - (screenWidth * (this.columnNum * 4 + 4)) / 100) /
      this.columnNum;

    // const height = ((55 * width) / 38) * 0.58; // 38:55 - grid rowHeight; 60% - default image height
    const imgixPosfix = '?auto=enhance&w=' + width.toString();

    if (this.complex.SeasonThumbnail) {
      this.images.push({
        Name: this.complex.SeasonCaption
          ? this.complex.SeasonCaption
          : ' Main View',
        URL__c: this.complex.SeasonThumbnail.includes('imgix')
          ? this.complex.SeasonThumbnail + imgixPosfix
          : this.complex.SeasonThumbnail,
        Season__c: 'Any',
        Caption__c: this.complex.SeasonCaption,
      });
    } else {
      console.error(
        'no thumbnail for  complex ',
        this.complex.MarketingHeadline__c
      );
      this.images.push({
        Name: 'Main View',
        URL__c: this.defaultImage,
        Season__c: 'Any',
      });
    }

    this.imageSrc = this.images[0].URL__c;

    if (this.complex.additionalimages && this.complex.additionalimages.length) {
      this.complex.additionalimages.forEach((additionalimage) => {
        const image: UnitImage = {
          URL__c: additionalimage.URL__c.includes('imgix')
            ? additionalimage.URL__c + imgixPosfix
            : additionalimage.URL__c,
          Name: additionalimage.Caption__c,
          Caption__c: additionalimage.Caption__c,
          Season__c: 'Any',
        };
        this.images.push(image);
      });
    }

    if (
      this.complex.featuredamenities &&
      this.complex.featuredamenities.length > 0
    ) {
      const filteredAmenities = this.complex.featuredamenities.filter(
        (amenity) => {
          if (amenity.ImageLink__c) {
            amenity.sliderIndex = this.images.length;
            this.images.push({
              Name: amenity.AmenityType__c,
              URL__c: amenity.ImageLink__c + imgixPosfix,
              Caption__c: amenity.Description__c,
              Season__c: 'Any',
            });
          }
          return (
            amenity.AmenityType__c !== 'Housekeeping Service - Alternate' &&
            amenity.AmenityType__c !== 'Housekeeping Service - Daily'
          );
        }
      );
    }
  }

  inquireNow() {
    const dialogRef = this.dialog.open(UnitEnquiryDialogComponent, {
      data: {
        unit: this.complex,
        filter: {},
      },
      autoFocus: false,
      panelClass: 'inquiry-dialog-container',
      maxWidth: '96vw',
      maxHeight: '80vh',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['/booking-enquiry-thank-you']);
        this.unitService.startBooking(result);
      }
    });
  }

  ngOnDestroy() {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
  }

  showPrev() {
    this.choosenAmenity--;
    if (this.choosenAmenity < 0) {
      this.choosenAmenity = this.images.length - 1;
    }
    this.imageSrc = this.images[this.choosenAmenity].URL__c;
  }
  showNext() {
    this.choosenAmenity++;
    if (this.choosenAmenity >= this.images.length) {
      this.choosenAmenity = 0;
    }
    this.imageSrc = this.images[this.choosenAmenity].URL__c;
  }

  showAmenity(imageIndex) {
    if (imageIndex) {
      this.choosenAmenity = imageIndex;
      this.imageSrc = this.images[this.choosenAmenity].URL__c;
    }
  }

  onSwipe(evt) {
    const x =
      Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (x === 'right') {
      this.showNext();
    } else {
      this.showPrev();
    }
  }

  identifyUnit(index, complex) {
    return complex ? complex.externalId : null;
  }
}
