import { Component, OnInit } from '@angular/core';
import {
  newbrand,
  stagingwtlbl,
  mainsite,
  mm,
  amanda,
  smartflyer,
  jettsettravel,
  packhappytravel,
  staging,
  localhost,
} from '../../store/models/hostings';

@Component({
  selector: 'twbooking-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor() {}
  mode:
    | 'basic'
    | 'MM'
    | 'Laurie'
    | 'Amanda'
    | 'smartflyer'
    | 'jettsettravel'
    | 'packhappytravel'
    | 'neutral' = 'basic';

  ngOnInit(): void {
    if (
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    ) {
      this.mode = 'basic';
    } else if (window.location.hostname.includes(mm)) {
      this.mode = 'MM';
    } else if (
      window.location.hostname.includes(newbrand) ||
      window.location.hostname.includes(stagingwtlbl)
    ) {
      this.mode = 'Laurie';
    } else if (window.location.hostname.includes(amanda)) {
      this.mode = 'Amanda';
    } else if (window.location.hostname.includes(smartflyer)) {
      this.mode = 'smartflyer';
    } else if (window.location.hostname.includes(jettsettravel)) {
      this.mode = 'jettsettravel';
    } else if (window.location.hostname.includes(packhappytravel)) {
      this.mode = 'packhappytravel';
    } else {
      this.mode = 'neutral';
    }
  }
}
