import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MustMatch } from './match.validator';
import { sendSignInLinkToEmail } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'twbooking-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit {
  @Input()
  sidenav: any;

  @Input()
  formMode: string; //fullscreen | sidebar
  email: string = null;
  password: string = null;
  registrationForm: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  submitted = false;
  logInMode = 'signUp'; // if false, registration mode on.
  loggedIn = false;
  wrongPassword = false;
  googleSignInWarning = false;
  // Error handling variables
  title = '';
  message = '';
  loading = false;
  linkRequestResult: string = '';
  linkTitle: string = '';
  linkComment: string = '';

  constructor(
    private authService: AuthService,
    private _fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  signUpwithEmail(regForm, valid: boolean) {
    this.submitted = true;

    if (valid) {
      this.loading = true;
      this.authService
        .signUpwithEmail(regForm.emailField, regForm.passwordField)
        .then((result) => {
          if (result && result === 'success') {
            this.linkTitle = 'Confirm email';
            this.linkRequestResult =
              'A verification link has been sent to your email account.';
            this.linkComment = `Please click on the link that has just been sent to your email account to verify your email.`;
          } else {
            this.linkTitle = 'Confirm email';
            this.linkRequestResult =
              'Oops, something went wrong. Please wait a few minutes then try again or contact us.';
          }
          this.loading = false;
          this.logInMode = 'emailCode';
        })
        .catch((error) => {
          this.loading = false;

          if (error.code === 'auth/email-already-in-use') {
            this.title = 'Sign up failed';
            this.message =
              '<p>We already have an account registered with your email. Please sign in instead.</p>';
            this.logInMode = 'logInFailed';
          } else {
            this.title = 'Sign up failed';
            this.message =
              '<p>We encountered an error while registering your account. Please try again and contact us via email at <a class="brand-accent-color" href="mailto:reservations@thetravelwhisperer.com">reservations@thetravelwhisperer.com</a> if the error persists.</p>';
            this.logInMode = 'logInFailed';
            console.error(
              `error in signUpwithEmail catched for user ${regForm.emailField}. Error message: 
            ${error.message}, error code ${error.code}`
            );
            throw new Error(
              'signUpwithEmail failed with error' + error.message
                ? error.message
                : error
            );
          }
        });
    }
  }

  resetPassword(resetForm, valid: boolean) {
    this.submitted = true;

    if (valid) {
      this.authService
        .resetPassword(resetForm.emailField)
        .then((result) => {
          this.logInMode = 'confirmReset';
        })
        .catch((error) => {
          if (error.code === 'auth/user-not-found') {
            this.title = 'Password reset failed';
            this.message =
              'There is no user corresponding to this email address. Please sign up again.';
            this.logInMode = 'logInFailed';
          } else {
            this.title = 'Password reset failed';
            this.logInMode = 'logInFailed';
            this.message =
              '<p>Something went wrong with password reset failed. Please try again and contact us via email at <a class="brand-accent-color" href="mailto:reservations@thetravelwhisperer.com">reservations@thetravelwhisperer.com</a> if the error persists.</p>';
            throw new Error(
              'resetPassword send email failed with error' + error
            );
          }
        });
    }
  }

  unsetWrong() {
    this.wrongPassword = false;
  }

  logInwithEmail(logForm, valid: boolean) {
    this.submitted = true;
    if (valid) {
      this.loading = true;
      this.authService
        .logInwithEmail(logForm.emailField, logForm.passwordField)
        .then((success) => {
          if (this.sidenav && this.formMode === 'sidebar') {
            this.sidenav.close();
          } else {
            this.router.navigate(['guest/reservations']);
          }
          this.wrongPassword = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.code === 'auth/wrong-password') {
            this.wrongPassword = true;
          } else if (error.code === 'auth/user-not-found') {
            this.title = 'Log in failed';
            this.message =
              '<p>There is no user corresponding to this email address. Please sign up again.</p>';
            this.logInMode = 'logInFailed';
          } else {
            console.error('log in error: ', error);
            this.title = 'Log in failed';
            this.message =
              '<p>Something went wrong with log in to your account. Please try again and contact us via email at <a class="brand-accent-color" href="mailto:reservations@thetravelwhisperer.com">reservations@thetravelwhisperer.com</a> if the error persists.</p>';
            this.logInMode = 'logInFailed';
            throw new Error('Unknown error during log in with email: ' + error);
          }
        });
    }
  }

  ngOnInit(): void {
    this.googleSignInWarning = false;
    const emailRegex = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
    );
    this.submitted = false;
    this.logInMode = this.route.snapshot.queryParams.mode
      ? this.route.snapshot.queryParams.mode
      : 'signUp';
    this.loggedIn = false; // FIXME TEST MODE
    try {
      const ua = navigator.userAgent || navigator.vendor;
      if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
        this.googleSignInWarning = true;
      }
    } catch (e) {
      console.error('Detect facebook browser err: ', e);
    }
    this.registrationForm = this._fb.group(
      {
        emailField: ['', [Validators.required, Validators.pattern(emailRegex)]],
        passwordField: ['', [Validators.required, Validators.minLength(6)]],
        passwordConfirmField: ['', Validators.required],
      },
      {
        validator: MustMatch('passwordField', 'passwordConfirmField'),
      }
    );
    this.loginForm = this._fb.group({
      emailField: ['', [Validators.required, Validators.pattern(emailRegex)]],
      passwordField: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.resetForm = this._fb.group({
      emailField: ['', [Validators.required, Validators.pattern(emailRegex)]],
    });
  }

  handleSubmit(registrationForm: any, isValid: boolean) {
    if (isValid) {
      this.submitted = true;
    }
  }

  changeForm(mode: string) {
    switch (mode) {
      case 'passwordReset':
        this.logInMode = 'passwordReset';
        break;
      case 'logIn':
        this.logInMode = 'logIn';
        this.submitted = false;
        break;
      case 'signUp':
        this.logInMode = 'signUp';
        this.submitted = false;

        break;
    }
  }
}
