<mat-card
  appearance="outlined"
  class="unit-summary-card white-background new-brand-color flex-container-column"
>
  <div
    (swipe)="onSwipe($event)"
    [class.img-default]="
      !(unit.DisplayRates__c && searchQuery && searchQuery.StartDate) &&
      !showQuote &&
      !showDatepickers
    "
    [class.img-middle]="
      ((unit.DisplayRates__c && searchQuery && searchQuery.StartDate) ||
        showDatepickers) &&
      !showQuote
    "
    [class.img-reduced]="showQuote"
  >
    <twbooking-summary-carousel
      complex="false"
      [images]="images"
      [show]="choosenAmenity"
      [slug]="unit.UnitSlug__c"
      [searchQuery]="searchQuery"
    >
    </twbooking-summary-carousel>

    <ng-template #AvailableDeals let-value>
      <div class="tooltip-container-small">
        <div class="tooltip-content white-color">
          <div *ngIf="processedDeals.length === 0">
            <p class="white-color">Loading available deals...</p>
          </div>
          <div *ngIf="!this.unit.isInntopiaUnit__c">
            @for (promo of processedDeals; track $index){
            <div style="text-align: left">
              <p class="white-color" style="font-weight: bold">
                {{ promo.Name }}
              </p>
              <p class="white-color" [innerHTML]="promo.Description__c"></p>
            </div>
            }
          </div>
          <div
            *ngIf="
              this.unit.isInntopiaUnit__c &&
              processedDeals &&
              processedDeals.length > 0
            "
          >
            @for (promo of processedDeals; track $index){
            <div style="text-align: left">
              <p class="white-color" style="font-weight: bold">
                {{ promo.Name }}*
              </p>
              @for (details of promo.Details; track $index){
              <ul>
                <li class="white-color">
                  {{ details.Discount__c }}% off {{ details.MinNights__c
                  }}<span
                    *ngIf="
                      details.MinNights__c !== details.MaxNights__c &&
                      details.MaxNights__c <= 10
                    "
                    >-{{ details.MaxNights__c }}</span
                  >
                  <span *ngIf="details.MaxNights__c > 10"> or more </span>
                  nights
                </li>
              </ul>
              }
              <p class="white-color">
                *for stays
                {{ promo.ValidStart__c | date : "mediumDate" }} to
                {{ promo.ValidEnd__c | date : "mediumDate" }} booked until
                {{ promo.BookByDate__c | date : "mediumDate" }}
              </p>
            </div>
            }
          </div>
        </div>
      </div>
    </ng-template>
    <div
      *ngIf="!nb && !favoriteUnit"
      class="favorite-icon"
      (click)="saveFavorite()"
      matTooltip="Please like the units that you are interested in to help our personal travel designers find the best options for you"
    >
      <mat-icon class="white-color">favorite</mat-icon>
    </div>
    <div
      *ngIf="!nb && favoriteUnit"
      class="favorite-icon"
      (click)="removeFavorite()"
    >
      <mat-icon class="red-color">favorite</mat-icon>
    </div>
    <a
      (click)="showPromotion()"
      (mouseenter)="showPromotion()"
      *ngIf="unit.promotioncount > 0"
      class="discount-sticker ttw-projected ttw-projected-paddings"
      ttw-tooltip
      smallTooltip="yes"
      [tooltipHtml]="AvailableDeals"
      tooltipHideDelay="1500"
    >
      Discounts available
    </a>
  </div>

  <div
    class="summary-info-container new-brand-color flex-even-column"
    [class.container-default]="
      !(unit.DisplayRates__c && searchQuery && searchQuery.StartDate) &&
      !showQuote &&
      !showDatepickers
    "
    [class.container-middle]="
      ((unit.DisplayRates__c && searchQuery && searchQuery.StartDate) ||
        showDatepickers) &&
      !showQuote
    "
    [class.container-expanded]="showQuote"
  >
    <h1 class="summary-info-title new-brand-color">
      <a
        class="summary-info-title new-brand-color"
        [routerLink]="['/unit-details', unit.UnitSlug__c]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
        >{{ unit.MarketingHeadline__c }}</a
      >
    </h1>

    <div class="summary-info-data new-brand-color">
      <span
        >{{ unit.Bedrooms__c }} Bedrooms · {{ unit.Bathrooms__c }} Bathrooms ·
        Sleeps {{ unit.Sleeps__c }}</span
      >

      <span *ngIf="unit.DisplayRates__c" class="float-right calendar-icon">
        <span
          class="float-right new-brand-color calendar-icon-new"
          style="font-size: small"
          (click)="showAvailability()"
        >
          <mat-icon>date_range</mat-icon>
        </span>
      </span>
    </div>

    @if(!showDatepickers && !unit.requestrate || unit.ReviewCount > 0){
    <div class="summary-info-data">
      @if(!showDatepickers && !unit.requestrate){
      <span *ngIf="!unit.requestrate">{{ unit.DisplayRate__c }} </span>
      } @if(unit.ReviewRating > 0){
      <div class="reviews-container float-right">
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="unit.ReviewRating > 3.8"
          >star</mat-icon
        >
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="unit.ReviewRating > 1 && unit.ReviewRating <= 3.8"
          >star_half</mat-icon
        >
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="unit.ReviewRating <= 1"
          >star_border</mat-icon
        >
        <span class="review-text">
          {{ unit.ReviewRating | number : "1.2-2" }} ({{ unit.ReviewCount }})
        </span>
      </div>
      }
    </div>
    }

    <div
      *ngIf="
        unit.DisplayRates__c &&
        unit.requestrate &&
        !nb &&
        searchQuery &&
        searchQuery.StartDate &&
        searchQuery.EndDate &&
        !showDatepickers &&
        rent > 0
      "
      class="rate-data show-quote-chip"
    >
      <mat-chip-option
        [selected]="showQuote"
        (click)="getQuote($event)"
        class="float-left brand-dark-background new-brand-color"
      >
        <span *ngIf="!showQuote" class="new-brand-color"
          >Rent: ${{ rent | number : "1.0-0" }}</span
        >
        <span *ngIf="showQuote"> Quote: </span>
        <mat-icon
          class="expand-icon new-brand-color"
          *ngIf="!showQuote"
          matChipRemove
          (click)="getQuote($event)"
        >
          expand_more</mat-icon
        >
        <mat-icon
          class="expand-icon"
          *ngIf="showQuote"
          matChipRemove
          (click)="showQuote = !showQuote"
        >
          expand_less</mat-icon
        >
      </mat-chip-option>
    </div>

    <div
      *ngIf="showDatepickers"
      style="width: 100%; margin-top: 5px"
      [@inOutDates]
    >
      <form [formGroup]="fastRatesWidget">
        <span
          class="float-left brand-light-background new-brand-color date-input"
        >
          <input
            matInput
            readonly
            [matDatepicker]="arrivalPicker"
            placeholder="Arrival"
            [matDatepickerFilter]="arrivalFilterFunction"
            formControlName="arrivalForRates"
            (dateChange)="arrivalChanged($event)"
            (click)="arrivalPicker.open()"
          />
          <mat-datepicker #arrivalPicker [touchUi]="isMobile"></mat-datepicker>
        </span>

        <span
          class="float-right brand-light-background new-brand-color date-input"
        >
          <input
            matInput
            readonly
            placeholder="Departure"
            [matDatepicker]="departurePicker"
            [matDatepickerFilter]="departureFilterFunction"
            formControlName="departureForRates"
            (dateChange)="departureChanged($event)"
            (click)="departurePicker.open()"
          />

          <mat-datepicker
            [touchUi]="isMobile"
            #departurePicker
            [startAt]="arrivalDate"
            [dateClass]="dateClass"
          >
          </mat-datepicker>
        </span>
      </form>
    </div>

    <div
      *ngIf="
        !unit.DisplayRates__c &&
        searchQuery &&
        searchQuery.StartDate &&
        searchQuery.EndDate
      "
      style="height: 32px; visibility: hidden"
    ></div>

    <div
      *ngIf="showQuote"
      [@inOutRates]
      class="brand-light-background new-brand-color rate-details-expanded"
    >
      <div *ngIf="!this.unit.isInntopiaUnit__c && !this.errorMessage">
        <div>
          <span class="float-left total-line">Total: </span>
          <span class="float-right" *ngIf="!rateLoaded">
            <span class="typing-dot1">.</span><span class="typing-dot2">.</span
            ><span class="typing-dot3">.</span>
          </span>
          <span
            *ngIf="rateLoaded && rateDetails"
            class="float-right total-line"
            >{{ this.rateDetails.TotalCost__c | currency }}</span
          >
          <br />
        </div>

        <div>
          <span class="float-left">
            <span
              [class.striked-out]="
                rateDetails &&
                rateDetails.Discount__c &&
                rateDetails.Discount__c > 0 &&
                rateDetails.Discounted_Rent__c &&
                rateDetails.Discounted_Rent__c > 0
              "
              >Rent:
            </span>
          </span>
          <span class="float-right" *ngIf="!rateLoaded"> </span>

          <span *ngIf="rateLoaded" class="float-right"
            ><span
              [class.striked-out]="
                rateDetails &&
                rateDetails.Discount__c &&
                rateDetails.Discount__c > 0 &&
                rateDetails.Discounted_Rent__c &&
                rateDetails.Discounted_Rent__c > 0
              "
              >{{ rateDetails.TotalRent__c | currency }}</span
            ></span
          >
        </div>
        <br />

        <div
          *ngIf="
            rateDetails &&
            rateDetails.Discount__c &&
            rateDetails.Discount__c > 0 &&
            rateDetails.Discounted_Rent__c &&
            rateDetails.Discounted_Rent__c > 0
          "
        >
          <span class="float-left">Discounted rent: </span>
          <span *ngIf="rateLoaded" class="float-right">{{
            rateDetails.Discounted_Rent__c | currency
          }}</span>
          <br />
        </div>

        <div
          *ngIf="
            rateDetails &&
            rateDetails.RefundableDeposit__c &&
            rateDetails.RefundableDeposit__c > 0
          "
        >
          <span class="float-left">Refundable Security Deposit: </span>
          <span *ngIf="rateLoaded" class="float-right">
            {{ rateDetails.RefundableDeposit__c | currency }}</span
          >
          <br />
        </div>

        <div>
          <span class="float-left">Taxes and Fees: </span>
          <span *ngIf="rateLoaded" class="float-right">
            {{
              rateDetails.DamageProtectionFee__c +
                rateDetails.CleaningService__c +
                rateDetails.GuestServicePackage__c +
                rateDetails.PetFee__c +
                rateDetails.TAX__c | currency
            }}</span
          >
          <span class="float-right" *ngIf="!rateLoaded"></span>
          <br />
        </div>
      </div>
      <!--end  *ngIf=' !this.unit.isInntopiaUnit__c' style='width: 80%' -->
      <div *ngIf="this.unit.isInntopiaUnit__c && !this.errorMessage">
        <div>
          <span class="float-left total-line">Total: </span>
          <span class="float-right" *ngIf="!rateLoaded">
            <span class="typing-dot1">.</span><span class="typing-dot2">.</span
            ><span class="typing-dot3">.</span>
          </span>
          <span *ngIf="rateLoaded" class="float-right total-line">{{
            this.inntopiaRate.rent +
              this.inntopiaRate.fees +
              this.inntopiaRate.taxes +
              this.inntopiaRate.optionalFees | currency
          }}</span>
          <br />
        </div>
        <div *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0">
          <span class="float-left striked-out">Rent: </span>
          <span *ngIf="rateLoaded" class="float-right striked-out">{{
            this.inntopiaRate.baseRent | currency
          }}</span>
          <br />
        </div>
        <div>
          <span
            *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0"
            class="float-left"
            >Discounted Rent:
          </span>
          <span
            *ngIf="inntopiaRate && inntopiaRate.savingsAmount === 0"
            class="float-left"
            >Rent:</span
          >
          <span *ngIf="rateLoaded" class="float-right">{{
            this.inntopiaRate.rent | currency
          }}</span>
          <br />
        </div>
        <div *ngIf="rateLoaded && inntopiaRate.taxes && inntopiaRate.taxes > 0">
          <span class="float-left">Taxes: </span>
          <span *ngIf="rateLoaded" class="float-right">{{
            this.inntopiaRate.taxes | currency
          }}</span>
          <br />
        </div>
        <div *ngIf="rateLoaded && inntopiaRate.fees && inntopiaRate.fees > 0">
          <span class="float-left">Fees: </span>
          <span *ngIf="rateLoaded" class="float-right">{{
            this.inntopiaRate.fees | currency
          }}</span>
          <br />
        </div>
        <div
          *ngIf="
            rateLoaded &&
            inntopiaRate.optionalFees &&
            inntopiaRate.optionalFees > 0
          "
        >
          <span class="float-left">Optional Fees: </span>
          <span *ngIf="rateLoaded" class="float-right">{{
            this.inntopiaRate.optionalFees | currency
          }}</span>
          <br />
        </div>
        <div
          *ngIf="rateLoaded && inntopiaRate && inntopiaRate.taxes <= 0"
          style="font-style: italic"
        >
          Please note that additional taxes and fees could be applied.
          <br />
        </div>
        <div
          *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0"
          style="font-weight: bold"
        >
          Book now to secure savings of
          {{ inntopiaRate.savingsAmount | currency }} on this reservation
        </div>
      </div>
      <!-- end *ngIf='rateChip && this.unit.isInntopiaUnit__c' -->
      <div
        *ngIf="quoteError && !errorMessage"
        class="rate-details-expanded brand-light-background brand-dark-color"
      >
        Online quote is unavailable for these dates. Please change dates or
        contact our team to book this property.
      </div>
      <div *ngIf="errorMessage" [innerHTML]="this.errorMessage"></div>
    </div>

    <div class="summary-actions new-brand-color">
      <!--summary-info-actions-->

      <a
        class="unit-summary-button primary-button"
        mat-button
        [routerLink]="['/unit-details', unit.UnitSlug__c]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
        >View details</a
      >
      <a
        *ngIf="!nb && unit.DisplayRates__c"
        class="unit-summary-button secondary-button"
        mat-button
        (click)="bookNow()"
        >Book now</a
      >
      <span *ngIf="nb || !unit.DisplayRates__c" class="hidden-button"></span>
      <a
        *ngIf="!nb"
        [ngClass]="{ 'hidden-button': nb }"
        class="unit-summary-button tertiary-button"
        mat-button
        (click)="inquireNow()"
        >Inquire now</a
      >
      <span *ngIf="nb" class="hidden-button"></span>
    </div>
  </div>
</mat-card>
