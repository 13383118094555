<form
  [formGroup]="form"
  (ngSubmit)="handleSubmit(form.value, form.valid)"
  novalidate
>
  <div class="dialog-close modal-headline-close">
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="search-dialog-container-inner">
    <mat-dialog-content class="search-dialog-container-classic">
      <div class="unit-search-form">
        <!-- Dates and Guest Search Bar -->

        <div
          class="search-params-chip brand-border-16"
          *ngIf="isMobile && !showAllFields"
          (click)="showAllFields = true"
        >
          <div class="search-group">
            <div class="search-icon">
              <mat-icon>search</mat-icon>
            </div>

            <div class="search-summary">
              <div class="search-summary-location">
                {{
                  this.form.get("locationValue").value
                    ? this.form.get("locationValue").value.City +
                      ", " +
                      this.form.get("locationValue").value.State
                    : "All Locations"
                }}
              </div>

              <div class="dates-and-guests">
                <div>
                  {{
                    this.form.get("arrival").value &&
                    this.form.get("departure").value
                      ? this.form.get("arrival").value.format("DD MMM") +
                        " - " +
                        this.form.get("departure").value.format("DD MMM YYYY")
                      : "Any week"
                  }}
                </div>
                <div>&nbsp;&middot;&nbsp;</div>
                <div>
                  {{
                    this.form.get("numAdults").value +
                      " Adults" +
                      (this.form.get("numChildren").value
                        ? ", " +
                          this.form.get("numChildren").value +
                          (this.form.get("numChildren").value > 1
                            ? " Children"
                            : " Child")
                        : "")
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="search-icon">
            <mat-icon>tune</mat-icon>
          </div>
        </div>

        <div
          class="search-bar"
          [class.figure-shadow]="!isMobile"
          [class.search-bar-desktop-paddings]="!isMobile"
          *ngIf="!isMobile || showAllFields"
        >
          <div
            [class.search-form-row]="!isMobile"
            [class.search-form-column]="isMobile"
          >
            <div
              class="action-full"
              [class.border-bottom-opacity-16]="isMobile"
            >
              <h3 class="new-brand-color">LOCATION</h3>

              <mat-select
                *ngIf="locationMode !== 'mm'"
                panelClass="location-select"
                placeholder="Where are you going? "
                formControlName="locationValue"
                name="locationValue"
                class="date-input brand-dark-color"
                [compareWith]="compareLocation"
                (selectionChange)="onLocationChange($event)"
              >
                @for (group of locationGroups; track group.value){
                <mat-optgroup [label]="group.name" [disabled]="group.disabled">
                  @for (location of group.locations; track location.value){
                  <mat-option
                    [value]="location.value"
                    class="no-checkbox-select"
                  >
                    {{ location.viewValue }}
                  </mat-option>
                  }
                </mat-optgroup>
                }
              </mat-select>
              <mat-select
                *ngIf="locationMode == 'mm'"
                panelClass="location-select"
                placeholder="Where are you going? "
                formControlName="village"
                name="village"
                class="date-input brand-dark-color"
              >
                @for (group of villagesBC; track group.name) {
                <mat-optgroup [label]="group.name" [disabled]="group.disabled">
                  @for (village of group.villages; track village.value) {
                  <mat-option
                    class="no-checkbox-select"
                    [value]="village.value"
                  >
                    {{ village.viewValue }}
                  </mat-option>
                  }
                </mat-optgroup>
                }
              </mat-select>
            </div>
            <div class="right-divider brand-dark-opacity-16-background"></div>

            <div
              class="action-full"
              [class.border-bottom-opacity-16]="isMobile"
            >
              <h3 class="new-brand-color">CHECK IN</h3>
              <input
                matInput
                class="brand-dark-color date-input"
                readonly
                [matDatepicker]="arrivalPicker"
                placeholder="Add dates"
                formControlName="arrival"
                [matDatepickerFilter]="arrivalFilterFunction"
                (dateChange)="arrivalChanged($event)"
                (click)="arrivalPicker.open()"
              />
              <mat-datepicker
                #arrivalPicker
                [touchUi]="isMobile"
                color="primary"
              ></mat-datepicker>
            </div>
            <div class="right-divider brand-dark-opacity-16-background"></div>

            <ng-template #shortStayPopover>
              <div class="tooltip-container">
                <div class="tooltip-content">
                  You are searching for a {{ myStay }} night trip, please note
                  that much of our inventory requires longer (3-4 night) minimum
                  stays and we may have discounted rates for longer stays.
                  <a href="/contact-us" class="underlined-link"
                    >Our Personal Travel Designers</a
                  >
                  are standing by to assist you with finding homes where short
                  stays are available.
                </div>
              </div>
            </ng-template>
            <div
              class="action-full"
              [class.border-bottom-opacity-16]="isMobile"
              clickable="yes"
              [tooltipHtml]="shortStayPopover"
              [tooltipHideDelay]="10000"
              ttw-tooltip
              [tooltipOnHover]="false"
              #tooltip1Directive
              [target]="myDeparture"
            >
              <h3 class="new-brand-color">CHECK OUT</h3>
              <input
                matInput
                class="brand-dark-color date-input"
                readonly
                placeholder="Add dates"
                formControlName="departure"
                [matDatepicker]="departurePicker"
                [matDatepickerFilter]="departureFilterFunction"
                (dateChange)="departureChanged($event)"
                (click)="departurePicker.open()"
                ttw-tooltip
                [tooltipHtml]="shortStayPopover"
                tooltipHideDelay="3500"
                tooltipOnHover="false"
              />
              <mat-datepicker
                #departurePicker
                color="primary"
                [dateClass]="dateClass"
                [touchUi]="isMobile"
              ></mat-datepicker>
              <div #myDeparture></div>
            </div>
            <div class="right-divider brand-dark-opacity-16-background"></div>
            <ng-template #lotOfGuestsPopover>
              <div class="tooltip-container">
                <div class="tooltip-content">
                  Need to accommodate a large group?
                  <a href="/contact-us" class="underlined-link"
                    >Contact our Personal Travel Designers</a
                  >, who are adept at finding the perfect homes for parties of
                  any size, to assist you.
                </div>
              </div>
            </ng-template>
            <div
              class="action-full"
              [class.border-bottom-opacity-16]="isMobile"
              [tooltipHtml]="lotOfGuestsPopover"
              ttw-tooltip
              clickable="yes"
              [tooltipHideDelay]="10000"
              [tooltipOnHover]="false"
              #tooltip2Directive
              [target]="myGuests"
            >
              <h3 class="new-brand-color">GUESTS</h3>
              <twbooking-guests-select
                [numAdults]="form.get('numAdults').value"
                [numChildren]="form.get('numChildren').value"
                [numInfants]="0"
                [numPets]="0"
                [petFriendly]="false"
                (guestEmitter)="numberofGuestChanged($event)"
              ></twbooking-guests-select>
              <div #myGuests></div>
            </div>
          </div>
        </div>
        <!-- End of Dates and Guests -->
        <div [class.search-preferences-container]="isMobile">
          <h3 class="amenities-header new-brand-color">PREFERENCES</h3>
          <div class="two-column-layout">
            <mat-form-field
              [class.unit-search-form-field-desktop]="!isMobile"
              [class.unit-search-form-field-mobile]="isMobile"
            >
              <mat-label>Property Type</mat-label>
              <mat-select
                formControlName="propertyType"
                name="propertyType"
                [compareWith]="compareProperty"
              >
                @for (option of propertyTypeOptions; track option.value){
                <mat-option class="no-checkbox-select" [value]="option">
                  {{ option.viewValue }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field
              *ngIf="locationMode === 'mm'"
              [class.unit-search-form-field-desktop]="!isMobile"
              [class.unit-search-form-field-mobile]="isMobile"
            >
              <mat-label>Property Collection</mat-label>

              <mat-select
                formControlName="propertyCollection"
                name="propertyCollection"
                [compareWith]="compareProperty"
              >
                @for (option of propertyCollectionOptions; track option.value){
                <mat-option class="no-checkbox-select" [value]="option">
                  {{ option.viewValue }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="locationMode !== 'mm'"
              [class.unit-search-form-field-desktop]="!isMobile"
              [class.unit-search-form-field-mobile]="isMobile"
            >
              <mat-label>Village</mat-label>
              <mat-select formControlName="village" name="Village">
                @for (option of villageFiltered; track option.value){
                <mat-option class="no-checkbox-select" [value]="option.value">
                  {{ option.viewValue }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field
              [class.unit-search-form-field-desktop]="!isMobile"
              [class.unit-search-form-field-mobile]="isMobile"
            >
              <mat-label>Property Name</mat-label>
              <input matInput formControlName="unitName" name="unitName" />
            </mat-form-field>

            <mat-form-field
              [class.unit-search-form-field-desktop]="!isMobile"
              [class.unit-search-form-field-mobile]="isMobile"
            >
              <mat-label>Bedrooms</mat-label>
              <mat-select formControlName="bedrooms" name="bedrooms">
                @for (option of bedroomOptions; track option.value){
                <mat-option
                  class="no-checkbox-select bedrooms-select"
                  [value]="option.value"
                >
                  {{ option.viewValue }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <br />
          <div class="amenities" [class.amenities-desktop]="!isMobile">
            <div
              [class.unit-amenity-desktop]="!isMobile"
              [class.unit-amenity-mobile]="isMobile"
            >
              <mat-checkbox formControlName="petFriendly" name="petFriendly"
                >Pet Friendly
              </mat-checkbox>
            </div>
            @for (amenity of amenitiesOptions; track amenity.value){
            <div
              [class.unit-amenity-desktop]="!isMobile"
              [class.unit-amenity-mobile]="isMobile"
            >
              <mat-checkbox
                formControlName="{{ amenity.value }}"
                name="{{ amenity.value }}"
                [value]="amenity.value"
                >{{ amenity.viewValue }}
              </mat-checkbox>
            </div>
            @if(!isMobile && searchMode !== 'map'){
            <div
              [class.unit-amenity-desktop]="!isMobile"
              [class.unit-amenity-mobile]="isMobile"
            >
              <mat-checkbox formControlName="showComplexes" name="showComplexes"
                >Complexes
              </mat-checkbox>
            </div>
            <div
              [class.unit-amenity-desktop]="!isMobile"
              [class.unit-amenity-mobile]="isMobile"
            >
              <mat-checkbox formControlName="showUnits" name="showUnits"
                >Individual Residences
              </mat-checkbox>
            </div>
            } }
          </div>
          <!--<h3 class="amenities-header">AMENITIES</h3>

                    <div class="amenities" [class.amenities-desktop]="!isMobile">
                        <div [class.unit-amenity-desktop]="!isMobile" [class.unit-amenity-mobile]="isMobile">
                            <mat-checkbox formControlName="petFriendly" name="petFriendly">Pet Friendly
                            </mat-checkbox>
                        </div>
                        @for amenity of amenitiesOptions TODO
                        <div  [class.unit-amenity-desktop]="!isMobile"
                            [class.unit-amenity-mobile]="isMobile">
                            <mat-checkbox formControlName="{{amenity.value}}" name="{{amenity.value}}"
                                [value]="amenity.value">{{amenity.viewValue}}
                            </mat-checkbox>
                        </div>
                    </div>
                -->
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="actions-classic">
      <button
        class="brand-button search-button"
        mat-button
        type="submit"
        [disabled]="form.invalid"
      >
        Search
      </button>
      <button
        type="button"
        class="brand-bordered-button search-button"
        mat-button
        (click)="resetForm(form)"
      >
        Reset
      </button>
    </mat-dialog-actions>
  </div>
</form>
