import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  getGoogleClickId(): string | null {
    return localStorage.getItem('googleClickId');
  }

  getGoogleClientId(): string | null {
    return localStorage.getItem('googleClientId');
  }
}
