<div class="unit-geo-card unit-summary-card-shadow">
  <a
    class="unit-geo-image-container"
    [routerLink]="['/unit-details', unit.UnitSlug__c]"
    queryParamsHandling="preserve"
  >
    <img class="unit-geo-image" [src]="unit.URL__c" [alt]="unit.Caption__c" />

    @if (unit.promotioncount > 0){
    <a
      (click)="showPromotion()"
      (mouseenter)="showPromotion()"
      class="projected-type ttw-projected"
      ttw-tooltip
      [tooltipHtml]="AvailableDeals"
      tooltipHideDelay="1000"
    >
      Discounts
    </a>
    }

    <ng-template #AvailableDeals class="tw-tooltip" let-value>
      <div *ngIf="processedDeals.length === 0">
        <p class="white-color">Loading available deals...</p>
      </div>
      <div *ngIf="!this.unit.isInntopiaUnit__c">
        @for (promo of processedDeals; track promo.Name ) {
        <div style="text-align: left">
          <p class="white-color" style="font-weight: bold">{{ promo.Name }}</p>
          <p class="white-color" [innerHTML]="promo.Description__c"></p>
        </div>
        }
      </div>
      <div
        *ngIf="
          this.unit.isInntopiaUnit__c &&
          processedDeals &&
          processedDeals.length > 0
        "
      >
        @for (promo of processedDeals; track promo.Name ) {
        <div style="text-align: left">
          <p class="white-color" style="font-weight: bold">{{ promo.Name }}*</p>
          @for (details of promo.Details; track $index){
          <ul>
            <li class="white-color">
              {{ details.Discount__c }}% off {{ details.MinNights__c
              }}<span
                *ngIf="
                  details.MinNights__c !== details.MaxNights__c &&
                  details.MaxNights__c <= 10
                "
                >-{{ details.MaxNights__c }}</span
              >
              <span *ngIf="details.MaxNights__c > 10"> or more </span>
              nights
            </li>
          </ul>
          }
          <p class="white-color">
            *for stays
            {{ promo.ValidStart__c | date : "mediumDate" }} to
            {{ promo.ValidEnd__c | date : "mediumDate" }} booked until
            {{ promo.BookByDate__c | date : "mediumDate" }}
          </p>
        </div>
        }
      </div>
    </ng-template>
  </a>
  <div class="unit-geo-content">
    <a class="unit-link desktop-min-height"
      ><a
        class="unit-link"
        [routerLink]="['/unit-details', unit.UnitSlug__c]"
        queryParamsHandling="preserve"
      >
        <h3 class="unit-accommodation-title new-brand-color">
          {{ unit.MarketingHeadline__c }}
        </h3></a
      >

      <div class="unit-geo-details new-brand-color">
        <div>
          <span>{{ unit?.Bedrooms__c }} bedrooms</span>
          <span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>

          <span>{{ unit?.Bathrooms__c }} bathrooms</span>
        </div>
      </div>
    </a>
  </div>
</div>
