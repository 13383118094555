import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Input,
  HostListener,
} from '@angular/core';

import {
  beaverCreekGroups,
  LocationGroup,
  locationGroups as locationSource,
  beaverCreekVillages,
  LocationGroupVillages,
  isLocationExcluded,
} from '../../store/models/search-options';
import { SearchParamsService } from './../../services/search-params.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TooltipDirective } from 'src/app/shared/ui/tooltip/tooltip.directive';
import { MatSelect } from '@angular/material/select';
import { mm } from 'src/app/store/models/hostings';
import { SearchDialogComponent } from 'src/app/shared/search-dialog/search-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GuestData } from 'src/app/store/models/guest-data.model';

type GuestType = 'adults' | 'children' | 'infants' | 'pets';
type ActionType = '+' | '-';
@Component({
  selector: 'twbooking-top-actions',
  templateUrl: './top-actions.component.html',
  styleUrls: ['./top-actions.component.scss'],
})
export class TopActionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() mode: 'classic' | 'map' = 'classic';
  @ViewChild('mySelect') matSelect: MatSelect;

  @ViewChild('tooltip1Directive', { static: false, read: TooltipDirective })
  tooltip1: TooltipDirective;

  @ViewChild('tooltip2Directive', { static: false, read: TooltipDirective })
  tooltip2: TooltipDirective;

  locationGroups: LocationGroup[] = [];
  villagesBC: LocationGroupVillages[] = beaverCreekVillages;
  locationsPlaceholder = 'Where are you going?';
  locationMode: 'classic' | 'mm' = 'classic';

  isStickyPanelVisible = false;

  filtersNumber = 0;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    this.isStickyPanelVisible = scrollPosition > 150;
  }

  isMobile = false;
  isTabletOrDesktop = false;

  constructor(
    private searchService: SearchParamsService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private _fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.subMedia = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        this.isMobile =
          result.breakpoints[Breakpoints.XSmall] ||
          result.breakpoints[Breakpoints.Small] ||
          result.breakpoints[Breakpoints.Medium];
        this.isTabletOrDesktop =
          result.breakpoints[Breakpoints.Small] ||
          result.breakpoints[Breakpoints.Medium] ||
          result.breakpoints[Breakpoints.Large] ||
          result.breakpoints[Breakpoints.XLarge];
        this.recalculateFilters();
      });
    if (window.location.hostname.includes(mm)) {
      this.locationGroups = beaverCreekGroups;
      this.locationMode = 'mm';
    } else {
      this.locationGroups = locationSource;
    }
  }

  /* location variables */

  locationValue: any;
  locationExcludedFromMaps = false;
  showAllFields = true;

  /* dates variables */
  form: UntypedFormGroup;
  arrivalField: moment.Moment;
  departureField: moment.Moment;
  myStay = 0; // length of requested stay
  lastChangedArrival = false;
  // arrivalForm = new FormControl();
  // departureForm = new FormControl();

  /* guests */
  adultOptions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  childrenOptions: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  guests = '2 adults';
  adults = null;
  children = null;
  showGuestsConfig = false;

  /* subscriptions */
  subMedia: any = null;
  subSearch: any = null;

  ngOnInit() {
    this.form = this._fb.group({
      location: ['', []],
      village: [null, []],
      numAdults: [2, []],
      numChildren: [0, []],
      arrival: [null, []],
      departure: [null, []],
    });
    this.showAllFields = !this.isMobile;
    if (this.mode === 'map') {
      this.locationsPlaceholder = 'Search by location';
    } else {
      this.locationsPlaceholder = 'Where are you going?';
    }
    if (this.locationMode == 'mm') {
      this.form.controls.location.setValue({
        State: 'Colorado',
        City: 'Beaver Creek',
      });
      this.locationValue = { State: 'Colorado', City: 'Beaver Creek' };
    }
  }

  recalculateFilters() {
    this.filtersNumber = 0;

    if (this.mode !== 'map' && !this.isMobile) {
      const hasComplexesInPath = this.router.url.includes('complexes');
      const includeComplexesParam =
        this.route.snapshot.queryParamMap.get('includeComplexes');
      const includeComplexesIsFalse =
        includeComplexesParam === 'false' || includeComplexesParam === 'False';

      if (!hasComplexesInPath && !includeComplexesIsFalse) {
        this.filtersNumber = 2;
      } else {
        this.filtersNumber = 1;
      }
    }

    const params = this.searchService.searchParams$.getValue();
    if (params) {
      if (params.Village) this.filtersNumber++;
      if (params.StartDate && params.EndDate) this.filtersNumber++;
      if (params.UnitName) this.filtersNumber++;
      if (params.rateBottom && params.rateTop) this.filtersNumber++;
      if (params.Bedrooms) this.filtersNumber++;
      if (params.PetFriendly) this.filtersNumber++;

      for (const key in params) {
        if (key.startsWith('amenity') && params.hasOwnProperty(key)) {
          this.filtersNumber++;
        }
      }

      if (params.PropertyType) this.filtersNumber++;
      if (params.PropertyCollection) this.filtersNumber++;
    }
  }

  guestsPanelClose() {
    this.matSelect.close();
  }

  ngAfterViewInit() {
    this.subSearch = this.searchService.searchParams$.subscribe((params) => {
      this.recalculateFilters();
      if (params) {
        if (!(params.City && params.State)) {
          this.locationValue = '';
          this.form.controls.location.setValue('');
        } else {
          this.form.controls.location.setValue({
            State: params.State,
            City: params.City,
          });
          this.locationValue = { State: params.State, City: params.City };
        }

        this.locationExcludedFromMaps = isLocationExcluded(
          this.locationValue.City,
          this.locationValue.State
        );
        if (params.Village) {
          this.form.controls.village.setValue(params.Village);
          this.filtersNumber++;
        }
        if (params.StartDate && params.EndDate) {
          this.arrivalField = moment.utc(params.StartDate, 'YYYY-MM-DD');
          this.departureField = moment.utc(params.EndDate, 'YYYY-MM-DD');
          this.myStay = moment
            .duration(this.departureField.diff(this.arrivalField))
            .asDays();
          this.form.controls.arrival.setValue(this.arrivalField);
          this.form.controls.departure.setValue(this.departureField);
        } else {
          this.arrivalField = null;
          this.departureField = null;
        }

        if (params.Adults) {
          this.form.controls.numAdults.setValue(+params.Adults);
        }
        if (params.Children) {
          this.form.controls.numChildren.setValue(+params.Children);
        }

        this.guests = this.form.get('numAdults').value.toString() + ' adult';
        if (this.form.get('numAdults').value > 1) {
          this.guests += 's';
        }

        if (this.form.get('numChildren').value > 0) {
          this.guests +=
            ' + ' + this.form.get('numChildren').value.toString() + ' child';
          if (this.form.get('numChildren').value > 1) {
            this.guests += 'ren';
          }
        }
      } else {
        this.arrivalField = null;
        this.departureField = null;
        this.form.controls.arrival.setValue(this.arrivalField);
        this.form.controls.departure.setValue(this.departureField);
        this.form.controls.numAdults.setValue(2);
        this.form.controls.numChildren.setValue(0);
        this.guests = '2 adults';
        this.locationValue = '';
        this.form.controls.location.setValue('');
        this.locationExcludedFromMaps = false;
      }
    });
  }

  openShortStayWarning() {
    this.tooltip2.closeTooltip();
    this.tooltip1.openTooltip();
  }

  openLotOfGuestsWarning() {
    this.tooltip1.closeTooltip();
    this.tooltip2.openTooltip();
  }

  closeShortStayWarning() {
    this.tooltip1.closeTooltip();
  }

  arrivalChanged(event) {
    this.arrivalField = moment.utc(event.value).clone();
    this.lastChangedArrival = true;
    this.handleDateUpdate();
  }
  departureChanged(event) {
    this.departureField = moment.utc(event.value).clone();
    this.lastChangedArrival = false;
    this.handleDateUpdate();
  }

  arrivalFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc().startOf('day');
    const day_moment = moment.utc(d_any);
    return day_moment.isAfter(today);
  };

  departureFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const tomorrow = moment.utc().startOf('day').add(1, 'days');
    const day_moment = moment.utc(d_any);
    if (this.arrivalField !== null) {
      return (
        d_any.isAfter(tomorrow) &&
        d_any.isSameOrAfter(moment.utc(this.arrivalField))
      );
    } else {
      return day_moment.isAfter(tomorrow);
    }
  };

  numberofGuestChanged(guests: GuestData) {
    this.form.controls.numAdults.setValue(guests.adults);
    this.form.controls.numChildren.setValue(guests.children);
    if (guests.adults + guests.children > 20) {
      this.openLotOfGuestsWarning();
    }
  }

  handleDateUpdate() {
    this.closeShortStayWarning();
    if (
      this.arrivalField &&
      this.departureField &&
      this.arrivalField.isSameOrAfter(this.departureField)
    ) {
      if (this.lastChangedArrival) {
        this.departureField = null;
        this.form.controls.departure.setValue(null);
      } else {
        this.arrivalField = null;
        this.form.controls.arrival.setValue(null);
      }
    }

    if (!this.departureField && this.arrivalField) {
      this.departureField = this.arrivalField.clone();
      this.departureField.add(4, 'days');
      this.form.controls.departure.setValue(this.departureField);
    }
    if (this.departureField && !this.arrivalField) {
      this.arrivalField = this.departureField.clone();
      this.arrivalField.subtract(4, 'days');
      const tomorrow = moment.utc().startOf('day').add(1, 'days');
      if (this.arrivalField.isBefore(tomorrow)) {
        this.arrivalField = tomorrow;
      }
      this.form.controls.arrival.setValue(this.arrivalField);
    }
    if (this.arrivalField && this.departureField) {
      this.myStay = moment
        .duration(this.departureField.diff(this.arrivalField))
        .asDays();
      if (this.myStay === 1 || this.myStay === 2) {
        this.openShortStayWarning();
      }
    }
  }

  handleSubmit(formValue, formValid) {
    let searchQuery = this.searchService.get();
    if (searchQuery && searchQuery.StartDate && searchQuery.EndDate) {
      delete searchQuery['StartDate'];
      delete searchQuery['EndDate'];
      delete searchQuery['myStay'];
    }
    if (searchQuery && searchQuery.Children) {
      delete searchQuery['Children'];
    }
    if (searchQuery && searchQuery.Adults) {
      delete searchQuery['Adults'];
    }
    if (searchQuery && searchQuery.SleepsCount) {
      delete searchQuery['SleepsCount'];
    }

    if (searchQuery && searchQuery.City) {
      delete searchQuery['City'];
    }

    if (searchQuery && searchQuery.State) {
      delete searchQuery['State'];
    }

    if (searchQuery && searchQuery.Village) {
      delete searchQuery['Village'];
    }

    if (formValue.arrival && formValue.departure) {
      this.closeShortStayWarning();
      this.myStay = moment
        .duration(formValue.departure.diff(formValue.arrival))
        .asDays();

      searchQuery = {
        StartDate: formValue.arrival.format('YYYY-MM-DD'),
        EndDate: formValue.departure.format('YYYY-MM-DD'),
        myStay: this.myStay,
        ...searchQuery,
      };
    }

    if (formValue.numAdults) {
      searchQuery = {
        Adults: +formValue.numAdults,
        ...searchQuery,
      };
    }

    if (formValue.numChildren) {
      searchQuery = {
        Children: +formValue.numChildren,
        ...searchQuery,
      };
    }

    if (formValue.numAdults || formValue.numChildren) {
      searchQuery = {
        SleepsCount: +formValue.numAdults + +formValue.numChildren,
        ...searchQuery,
      };
    }

    if (formValue.location !== '') {
      searchQuery = {
        City: formValue.location.City,
        State: formValue.location.State,
        ...searchQuery,
      };
    }

    if (formValue.village) {
      searchQuery = {
        Village: formValue.village,
        ...searchQuery,
      };
    }

    if (this.locationMode == 'mm') {
      searchQuery = {
        City: 'Beaver Creek',
        State: 'Colorado',
        ...searchQuery,
      };
    }

    this.searchService.update(searchQuery);
    const queryParams = searchQuery;
    this.showAllFields = false;

    let locationExcludedFromMaps = isLocationExcluded(
      searchQuery.City,
      searchQuery.State
    );
    console.log(
      `location ${searchQuery.City}, ${searchQuery.State}  excluded from maps: ${locationExcludedFromMaps}`
    );

    if (this.mode === 'map' && !locationExcludedFromMaps) {
      this.router.navigate(['map-search'], { queryParams });
    } else {
      this.router.navigate(['search-results'], { queryParams });
    }
  }

  changeSearchMode(newmode: 'map' | 'classic', formValue, formValid) {
    console.log('changeSearchMode', newmode);
    let queryParams: any = {};
    if (this.mode !== newmode) {
      let searchQuery = this.searchService.get();
      if (searchQuery && searchQuery.StartDate && searchQuery.EndDate) {
        delete searchQuery['StartDate'];
        delete searchQuery['EndDate'];
        delete searchQuery['myStay'];
      }
      if (searchQuery && searchQuery.Children) {
        delete searchQuery['Children'];
      }
      if (searchQuery && searchQuery.Adults) {
        delete searchQuery['Adults'];
      }
      if (searchQuery && searchQuery.SleepsCount) {
        delete searchQuery['SleepsCount'];
      }

      if (searchQuery && searchQuery.City) {
        delete searchQuery['City'];
      }

      if (searchQuery && searchQuery.State) {
        delete searchQuery['State'];
      }

      if (searchQuery && searchQuery.Village) {
        delete searchQuery['Village'];
      }

      if (formValue.arrival && formValue.departure) {
        this.closeShortStayWarning();
        this.myStay = moment
          .duration(formValue.departure.diff(formValue.arrival))
          .asDays();

        searchQuery = {
          StartDate: formValue.arrival.format('YYYY-MM-DD'),
          EndDate: formValue.departure.format('YYYY-MM-DD'),
          myStay: this.myStay,
          ...searchQuery,
        };
      }

      if (formValue.numAdults) {
        searchQuery = {
          Adults: +formValue.numAdults,
          ...searchQuery,
        };
      }

      if (formValue.numChildren) {
        searchQuery = {
          Children: +formValue.numChildren,
          ...searchQuery,
        };
      }

      if (formValue.numAdults || formValue.numChildren) {
        searchQuery = {
          SleepsCount: +formValue.numAdults + +formValue.numChildren,
          ...searchQuery,
        };
      }

      if (formValue.location !== '') {
        searchQuery = {
          City: formValue.location.City,
          State: formValue.location.State,
          ...searchQuery,
        };
      }

      if (formValue.village) {
        searchQuery = {
          Village: formValue.village,
          ...searchQuery,
        };
      }

      if (this.locationMode == 'mm') {
        searchQuery = {
          City: 'Beaver Creek',
          State: 'Colorado',
          ...searchQuery,
        };
      }

      this.searchService.update(searchQuery);
      queryParams = searchQuery;
      this.showAllFields = false;
    }

    let locationExcludedFromMaps = isLocationExcluded(
      queryParams.City,
      queryParams.State
    );
    if (this.mode !== 'map' && newmode === 'map' && !locationExcludedFromMaps) {
      this.router.navigate(['map-search'], {
        queryParams: queryParams,
      });
    }
    if (this.mode !== 'classic' && newmode === 'classic') {
      this.router.navigate(['search-results'], {
        queryParams: queryParams,
      });
    }
  }

  compareLocation = (v1: any, v2: any) => {
    return v2 ? v1.City === v2.City : false;
  };

  dateClass = (d_any: any) => {
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    if (this.arrivalField) {
      const arrival = moment.utc(this.arrivalField).format('YYYY-MM-DD');
      return day === arrival ? 'choosen-date' : undefined;
    } else {
      return undefined;
    }
  };

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {
        arrival: this.form.controls.arrival.value,
        departure: this.form.controls.departure.value,
        location: this.form.controls.location.value,
        numAdults: this.form.controls.numAdults.value,
        numChildren: this.form.controls.numChildren.value,
        village: this.form.controls.village.value,
      },
      panelClass: 'search-dialog-container',
      maxWidth: '96vw',
      maxHeight: '85vh',
      width: 'auto',
    });
  }

  onLocationChange(event) {
    this.locationExcludedFromMaps = isLocationExcluded(
      event.value.City,
      event.value.State
    );
    if (
      event.value &&
      (event.value.City === '' || !event.value.City) &&
      this.mode === 'map'
    ) {
      /* Change to list view if All Locations selected */

      this.changeSearchMode('classic', this.form.value, this.form.valid);
    }
    if (event.value !== '') {
      this.form.controls.village.setValue(null);
    }
  }

  /************ Mobile widget only ****************/
  changeGuests(action, guests) {
    if (guests === 'numAdults') {
      if (action === '+') {
        this.form.controls.numAdults.setValue(this.form.get(guests).value + 1);
      } else {
        if (this.form.get('numAdults').value > 1) {
          this.form.controls.numAdults.setValue(
            this.form.get(guests).value - 1
          );
        }
      }
    } else {
      if (action === '+') {
        this.form.controls.numChildren.setValue(
          this.form.get(guests).value + 1
        );
      } else {
        if (this.form.get('numChildren').value > 0) {
          this.form.controls.numChildren.setValue(
            this.form.get(guests).value - 1
          );
        }
      }
    }
    let adults = this.form.get('numAdults').value;
    let children = this.form.get('numChildren').value;
    this.guests = adults.toString() + ' adult';
    if (adults > 1) {
      this.guests += 's';
    }
    if (children > 0) {
      this.guests += ' + ' + children.toString() + ' child';
      if (children > 1) {
        this.guests += 'ren';
      }
    }
    if (adults + children > 20) {
      this.openLotOfGuestsWarning();
    }
  }

  ngOnDestroy() {
    this.subMedia.unsubscribe();
    this.subSearch.unsubscribe();
  }
}
