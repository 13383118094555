import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SearchParamsService } from './../../services/search-params.service';

import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  max,
  VillageOptions as villagesSource,
  locationGroups as locationSource,
  beaverCreekGroups,
  bedroomOptions,
  ratesOptions,
  propertyTypeOptions,
  propertyCollectionOptions,
  amenities,
  Villages,
  LocationGroup,
  LocationGroupVillages,
  beaverCreekVillages,
  isLocationExcluded,
} from '../../store/models/search-options';
import { localhost, mm } from 'src/app/store/models/hostings';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { GuestData } from 'src/app/store/models/guest-data.model';
import { TooltipDirective } from '../ui/tooltip/tooltip.directive';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'twbooking-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
})
export class SearchDialogComponent implements OnInit {
  @ViewChild('tooltip1Directive', { static: false, read: TooltipDirective })
  tooltip1: TooltipDirective;

  @ViewChild('tooltip2Directive', { static: false, read: TooltipDirective })
  tooltip2: TooltipDirective;

  @Input()
  sidenav: any;

  locationGroups: LocationGroup[] = [];
  villageFiltered: Villages[] = [];
  locationMode: 'classic' | 'mm' = 'classic';
  searchMode: 'classic' | 'map' = 'classic';
  villagesBC: LocationGroupVillages[] = beaverCreekVillages;

  lastChangedArrival = false;
  showAllFields = false;

  readonly bedroomOptions = bedroomOptions;
  readonly ratesOptions = ratesOptions;
  readonly propertyTypeOptions = propertyTypeOptions;
  readonly propertyCollectionOptions = propertyCollectionOptions;
  readonly amenitiesOptions = amenities;
  readonly VillageOptions: Villages[] = villagesSource;

  form: UntypedFormGroup;
  myStay = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private searchService: SearchParamsService,
    private breakpointObserver: BreakpointObserver,
    private _fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SearchDialogComponent>,
    private route: ActivatedRoute
  ) {
    if (window.location.pathname.includes('map-search')) {
      this.searchMode = 'map';
    }
    console.log('this.searchMode = ', this.searchMode);
    if (window.location.hostname.includes(mm)) {
      this.locationMode = 'mm';
      this.locationGroups = beaverCreekGroups;

      this.VillageOptions = villagesSource.filter((village: Villages) => {
        return village.location === 'Beaver Creek';
      });
    } else {
      this.locationGroups = locationSource;
      this.VillageOptions = villagesSource;
    }
    this.villageFiltered = this.VillageOptions;
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  findPropertyType(property) {
    return propertyTypeOptions.find((type) => type.value === property);
  }

  findPropertyCollection(property) {
    return propertyCollectionOptions.find((type) => type.value === property);
  }

  ngOnInit() {
    this.form = this._fb.group({
      locationValue: ['', []],
      numAdults: [2, []],
      numChildren: [0, []],
      arrival: [null, []],
      departure: [null, []],
      unitName: [null, []],
      rates: [null, []],
      village: [null, []],
      bedrooms: [null, []],
      petFriendly: [false, []],
      propertyType: [null, []],
      propertyCollection: [null, []],
      gamesroom: [false, []],
      pool: [false, []],
      bar: [false, []],
      ac: [false, []],
      gym: [false, []],
      skiin: [false, []],
      view: [false, []],
      cinema: [false, []],
      hottub: [false, []],
      fireplace: [false, []],
      bbq: [false, []],
      shuttle: [false, []],
      jacuzzi: [false, []],
      washer: [false, []],
      walk: [false, []],
      steam: [false, []],
      sauna: [false, []],
      tennis: [false, []],
      spa: [false, []],
      dining: [false, []],
      parking: [false, []],
      showUnits: [true, []],
      showComplexes: [true, []],
    });

    if (this.router.url.includes('complexes')) {
      this.form.controls.showUnits.setValue(false);
    }
    this.route.queryParams.subscribe((params) => {
      const includeComplexes = params['includeComplexes'];
      // Check if the value is explicitly 'false' or a boolean false
      if (includeComplexes === 'false' || includeComplexes === false) {
        this.form.controls.showComplexes.setValue(false);
      }
    });

    this.form.get('showComplexes')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.form.controls.showUnits.setValue(true);
      }
    });

    this.form.get('showUnits')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.form.controls.showComplexes.setValue(true);
      }
    });
    if (this.locationMode == 'mm') {
      this.form.controls.locationValue.setValue({
        State: 'Colorado',
        City: 'Beaver Creek',
      });
      if (this.data.village) {
        this.form.controls.village.setValue(this.data.village);
      }
    }

    if (this.data.numAdults) {
      this.form.controls.numAdults.setValue(this.data.numAdults);
    }
    if (this.data.numChildren) {
      this.form.controls.numChildren.setValue(this.data.numChildren);
    }

    if (this.data.arrival && this.data.departure) {
      let arrivalField = this.data.arrival;
      let departureField = this.data.departure;
      this.populateArrivalDeparture(arrivalField, departureField);
    }

    if (
      this.data.location &&
      this.data.location.City &&
      this.data.location.State
    ) {
      console.log('search-dialog: this.data.location', this.data.location);
      this.form.controls.locationValue.setValue({
        State: this.data.location.State,
        City: this.data.location.City,
      });
      const tempArray = this.VillageOptions;
      this.villageFiltered = tempArray.filter(
        (unit) => unit.location.indexOf(this.data.location.City) > -1
      );
    }

    this.searchService.searchParams$.subscribe((params) => {
      if (params) {
        if (params.UnitName) {
          this.form.controls.unitName.setValue(params.UnitName);
        }
        if (params.rateBottom && params.rateTop) {
          if (params.rateTop <= 500) {
            this.form.controls.rates.setValue({ rateBottom: 0, rateTop: 500 });
          } else if (params.rateTop <= 1000) {
            this.form.controls.rates.setValue({
              rateBottom: 500,
              rateTop: 1000,
            });
          } else if (params.rateTop <= 2500) {
            this.form.controls.rates.setValue({
              rateBottom: 1000,
              rateTop: 2500,
            });
          } else if (params.rateTop <= 500) {
            this.form.controls.rates.setValue({
              rateBottom: 2500,
              rateTop: 5000,
            });
          } else if (params.rateTop <= 10000) {
            this.form.controls.rates.setValue({
              rateBottom: 5000,
              rateTop: 10000,
            });
          } else {
            this.form.controls.rates.setValue({
              rateBottom: 10000,
              rateTop: max,
            });
          }
        } else {
          this.form.controls.rates.setValue(null);
        }

        if (params.Adults && !this.data.numAdults) {
          this.form.controls.numAdults.setValue(params.Adults);
        }
        if (params.Children && !this.data.numChildren) {
          this.form.controls.numChildren.setValue(params.Children);
        }
        if (
          params.City &&
          params.State &&
          !(
            this.data &&
            this.data.location &&
            this.data.location.City &&
            this.data.location.State
          )
        ) {
          this.form.controls.locationValue.setValue({
            State: params.State,
            City: params.City,
          });
          const tempArray = this.VillageOptions;
          this.villageFiltered = tempArray.filter(
            (unit) => unit.location.indexOf(params.City) > -1
          );
        } else if (
          this.data &&
          this.data.location &&
          this.data.location !== '' &&
          this.data.location.City &&
          this.data.location.State
        ) {
          this.form.controls.locationValue.setValue({
            State: this.data.location.State,
            City: this.data.location.City,
          });
          const tempArray = this.VillageOptions;
          this.villageFiltered = tempArray.filter(
            (unit) => unit.location.indexOf(params.City) > -1
          );
        } else {
          this.form.controls.locationValue.setValue(null);
          if (this.locationMode !== 'mm') {
            this.villageFiltered = this.VillageOptions;
          }
        }
        if (params.Village) {
          this.form.controls.village.setValue(params.Village);
        } else {
          this.form.controls.village.setValue(null);
        }

        if (
          params.StartDate &&
          params.EndDate &&
          !(this.data.arrival && this.data.departure)
        ) {
          this.populateArrivalDeparture(
            moment.utc(params.StartDate, 'YYYY-MM-DD'),
            moment.utc(params.EndDate, 'YYYY-MM-DD')
          );
        }

        if (params.Bedrooms) {
          this.form.controls.bedrooms.setValue(params.Bedrooms);
        } else {
          this.form.controls.bedrooms.setValue(null);
        }

        this.form.controls.petFriendly.setValue(
          params.PetFriendly ? params.PetFriendly : false
        );

        for (const key in params) {
          if (key.startsWith('amenity') && params.hasOwnProperty(key)) {
            const amenity = params[key];
            if (this.form.controls.hasOwnProperty(amenity)) {
              this.form.controls[amenity].setValue(true);
            }
          }
        }

        if (params.PropertyType) {
          let propertyType = this.findPropertyType(params.PropertyType);
          this.form.controls.propertyType.setValue(propertyType);
        } else {
          this.form.controls.propertyType.setValue(null);
        }
        if (params.PropertyCollection) {
          let propertyCollection = this.findPropertyCollection(
            params.PropertyCollection
          );
          this.form.controls.propertyCollection.setValue(propertyCollection);
        } else {
          this.form.controls.propertyCollection.setValue(null);
        }
      }
    });
  }

  populateArrivalDeparture(arrivalField, departureField) {
    if (arrivalField && departureField) {
      if (arrivalField.isBefore(departureField)) {
        this.form.controls.arrival.setValue(arrivalField);
        this.form.controls.departure.setValue(departureField);
        this.myStay = moment
          .duration(
            moment
              .utc(departureField, 'YYYY-MM-DD')
              .diff(moment.utc(arrivalField, 'YYYY-MM-DD'))
          )
          .asDays();
      } else {
        this.form.controls.arrival.setValue(null);
        this.form.controls.departure.setValue(null);
        this.myStay = 0;
      }
    } else {
      this.form.controls.arrival.setValue(null);
      this.form.controls.departure.setValue(null);
      this.myStay = 0;
    }
  }

  onLocationChange(val) {
    const tempArray = this.VillageOptions;
    this.villageFiltered = tempArray.filter(
      (unit) => unit.location.indexOf(val.value.City) > -1
    );
    this.form.controls.village.setValue(null);
  }

  /*
  arrivalChanged(event, form) {
    const date = new Date(event.value);
    this.arrivalField = moment.utc(event.value);
    this.lastChangedArrival = true;
    this.handleDateUpdate(form);
  }
  departureChanged(event, form) {
    this.departureField = moment.utc(event.value);

    this.lastChangedArrival = false;
    this.handleDateUpdate(form);
  }

  handleDateUpdate(form) {
    if (
      this.arrivalField &&
      this.departureField &&
      this.arrivalField.isSameOrAfter(this.departureField)
    ) {
      if (this.lastChangedArrival) {
        this.departureField = null;
      } else {
        this.arrivalField = null;
      }
    }

    if (!this.departureField && this.arrivalField) {
      this.departureField = this.arrivalField.clone();
      this.departureField.add(4, 'days');
    }
    if (this.departureField && !this.arrivalField) {
      this.arrivalField = this.departureField.clone();
      this.arrivalField.subtract(4, 'days');
      const tomorrow = moment.utc().startOf('day').add(1, 'days');
      if (this.arrivalField.isBefore(tomorrow)) {
        this.arrivalField = tomorrow;
      }
    }
    if (this.arrivalField && this.departureField) {
      this.myStay = moment
        .duration(this.departureField.diff(this.arrivalField))
        .asDays();
    }
  }
  */

  handleSubmit(searchForm: any, isValid: boolean) {
    let queryParams: any = {};

    if (searchForm.locationValue) {
      queryParams.City = searchForm.locationValue.City;
      queryParams.State = searchForm.locationValue.State;
    }

    if (searchForm.village) {
      queryParams.Village = searchForm.village;
    }

    if (searchForm.arrival && searchForm.departure) {
      queryParams.StartDate = moment
        .utc(searchForm.arrival)
        .format('YYYY-MM-DD');
      queryParams.EndDate = moment
        .utc(searchForm.departure)
        .format('YYYY-MM-DD');
    }

    if (searchForm.unitName) {
      queryParams.UnitName = searchForm.unitName;
    }

    if (searchForm.bedrooms) {
      queryParams.Bedrooms = parseInt(searchForm.bedrooms, 10);
    }

    if (searchForm.rates) {
      queryParams.rateBottom = searchForm.rates.rateBottom;
      queryParams.rateTop = searchForm.rates.rateTop;
    }

    if (searchForm.numAdults) {
      queryParams.Adults = parseInt(searchForm.numAdults, 10);
    }

    if (searchForm.numChildren) {
      queryParams.Children = parseInt(searchForm.numChildren, 10);
    }

    if (searchForm.petFriendly) {
      queryParams.PetFriendly = searchForm.petFriendly;
    }

    if (searchForm.propertyType && searchForm.propertyType.value) {
      queryParams.PropertyType = searchForm.propertyType.value;
    }

    if (searchForm.propertyCollection && searchForm.propertyCollection.value) {
      queryParams.PropertyCollection = searchForm.propertyCollection.value;
    }

    let amenityCount = 1;

    for (let i = 0; i < this.amenitiesOptions.length; i++) {
      if (
        searchForm.hasOwnProperty(this.amenitiesOptions[i].value) &&
        searchForm[this.amenitiesOptions[i].value]
      ) {
        queryParams[`amenity${amenityCount}`] = this.amenitiesOptions[i].value;
        amenityCount++;
      }
    }
    if (isValid) {
      let locationExcludedFromMaps = isLocationExcluded(
        queryParams.City,
        queryParams.State
      );

      if (this.searchMode === 'map' && !locationExcludedFromMaps) {
        this.router.navigate(['map-search'], { queryParams });
      } else {
        if (searchForm.showUnits && !searchForm.showComplexes) {
          queryParams = { ...queryParams, includeComplexes: false };
          this.router.navigate(['search-results'], { queryParams });
        } else if (!searchForm.showUnits && searchForm.showComplexes) {
          this.router.navigate(['complexes'], { queryParams });
        } else {
          this.router.navigate(['search-results'], { queryParams });
        }
      }
    }

    this.closeCurrentDialog();
  }

  resetForm(form: any) {
    form.reset({
      locationValue: '',
      numAdults: 2,
      numChildren: 0,
      arrival: null,
      departure: null,
      unitName: null,
      rates: null,
      village: null,
      bedrooms: null,
      petFriendly: false,
      propertyType: null,
      propertyCollection: null,
      gamesroom: false,
      pool: false,
      bar: false,
      ac: false,
      gym: false,
      skiin: false,
      view: false,
      cinema: false,
      hottub: false,
      fireplace: false,
      bbq: false,
      shuttle: false,
      jacuzzi: false,
      washer: false,
      walk: false,
      steam: false,
      sauna: false,
      tennis: false,
      spa: false,
      dining: false,
      parking: false,
      showUnits: true,
      showComplexes: true,
    });
    this.searchService.update(null);
    this.villageFiltered = this.VillageOptions;
  }

  compareFn(v1: any, v2: any): boolean {
    return v2
      ? v1.rateTop === v2.rateTop && v1.rateBottom === v2.rateBottom
      : false;
  }

  compareProperty = (v1: any, v2: any) => {
    return v2 ? v1.value === v2.value : false;
  };

  compareLocation = (v1: any, v2: any) => {
    return v2 ? v1.City === v2.City : false;
  };

  public closeCurrentDialog() {
    this.dialogRef.close();
  }

  numberofGuestChanged(guests: GuestData) {
    this.form.controls.numAdults.setValue(guests.adults);
    this.form.controls.numChildren.setValue(guests.children);
    if (guests.adults + guests.children > 20) {
      this.openLotOfGuestsWarning();
    }
  }

  dateClass = (d_any: any) => {
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    let arrivalField = this.form.get('arrival').value;
    if (arrivalField) {
      const arrival = moment.utc(arrivalField).format('YYYY-MM-DD');
      return day === arrival ? 'choosen-date' : undefined;
    } else {
      return undefined;
    }
  };

  arrivalChanged(event) {
    this.lastChangedArrival = true;
    this.handleDateUpdate();
  }
  departureChanged(event) {
    this.lastChangedArrival = false;
    this.handleDateUpdate();
  }

  handleDateUpdate() {
    this.closeShortStayWarning();
    let arrivalField = this.form.controls.arrival.value;
    let departureField = this.form.controls.departure.value;
    if (
      arrivalField &&
      departureField &&
      arrivalField.isSameOrAfter(departureField)
    ) {
      if (this.lastChangedArrival) {
        departureField = null;
        this.form.controls.departure.setValue(null);
      } else {
        arrivalField = null;
        this.form.controls.arrival.setValue(null);
      }
    }

    if (!departureField && arrivalField) {
      departureField = arrivalField.clone();
      departureField.add(4, 'days');
      this.form.controls.departure.setValue(departureField);
    }
    if (departureField && !arrivalField) {
      arrivalField = departureField.clone();
      arrivalField.subtract(4, 'days');
      const tomorrow = moment.utc().startOf('day').add(1, 'days');
      if (arrivalField.isBefore(tomorrow)) {
        arrivalField = tomorrow;
      }
      this.form.controls.arrival.setValue(arrivalField);
    }
    if (arrivalField && departureField) {
      this.myStay = moment.duration(departureField.diff(arrivalField)).asDays();
      if (this.myStay === 1 || this.myStay === 2) {
        this.openShortStayWarning();
      }
    }
  }

  arrivalFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc().startOf('day');
    const day_moment = moment.utc(d_any);
    return day_moment.isAfter(today);
  };

  departureFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const tomorrow = moment.utc().startOf('day').add(1, 'days');
    const day_moment = moment.utc(d_any);
    if (this.form.controls.arrival.value !== null) {
      return (
        d_any.isAfter(tomorrow) &&
        d_any.isSameOrAfter(moment.utc(this.form.controls.arrival.value))
      );
    } else {
      return day_moment.isAfter(tomorrow);
    }
  };

  openShortStayWarning() {
    this.tooltip2.closeTooltip();
    this.tooltip1.openTooltip();
  }

  openLotOfGuestsWarning() {
    this.tooltip1.closeTooltip();
    this.tooltip2.openTooltip();
  }

  closeShortStayWarning() {
    this.tooltip1.closeTooltip();
  }
}
