<div class="amenities-carousel-container">
  <div
    *ngIf="images.length > 1"
    class="left-arrow"
    (click)="showPrev()"
    (swipeleft)="onSwipeLeft($event)"
    (swiperight)="onSwipeRight($event)"
  >
    <mat-icon class="slider-arrow arrow-with-bg">keyboard_arrow_left</mat-icon>
  </div>
  <div *ngIf="complex" class="mat-card-image-i">
    @for (image of images; track i; let i = $index) {
    <ng-container>
      <a
        [routerLink]="['/complex-details', slug]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
      >
        <img
          *ngIf="i === currentImage"
          (swipeleft)="onSwipeLeft($event)"
          (swiperight)="onSwipeRight($event)"
          mat-card-image
          class="mat-card-image-summary"
          [src]="image.URL__c"
          alt="{{ image.Caption__c ? image.Caption__c : image.Name }}"
          (error)="onImgError($event, image.URL__c, 'complex', slug)"
          @carouselAnimation
          [attr.loading]="lazyLoad ? 'lazy' : null"
        />
      </a>
    </ng-container>
    }
  </div>
  <div *ngIf="!complex" class="mat-card-image-summary">
    @for (image of images; track i; let i = $index) {
    <ng-container>
      <a
        [routerLink]="['/unit-details', slug]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
      >
        <img
          *ngIf="i === currentImage"
          (swipeleft)="onSwipeLeft($event)"
          (swiperight)="onSwipeRight($event)"
          mat-card-image
          class="mat-card-image-summary"
          [src]="image.URL__c"
          alt="{{ image.Caption__c ? image.Caption__c : image.Name }}"
          (error)="onImgError($event, image.URL__c, 'unit', slug)"
          loading="lazy"
          @carouselAnimation
        />
      </a>
    </ng-container>
    }
  </div>
  <div
    *ngIf="images.length > 1"
    class="right-arrow"
    (click)="showNext()"
    (swipeleft)="onSwipeLeft($event)"
    (swiperight)="onSwipeRight($event)"
  >
    <mat-icon class="slider-arrow arrow-with-bg">keyboard_arrow_right</mat-icon>
  </div>
</div>
