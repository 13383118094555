import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  CampaignInfoService,
  Campaign,
} from 'src/app/services/campaign-info.service';
import { UserDataService } from '../services/user-data.service';
import { UserData } from '../store/models/user.model';

import {
  Observable,
  BehaviorSubject,
  Subscription,
  firstValueFrom,
} from 'rxjs';
import { first, takeWhile, take } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import {
  applyActionCode,
  Auth,
  AuthCredential,
  browserLocalPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getRedirectResult,
  isSignInWithEmailLink,
  linkWithCredential,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { collection, doc, Firestore, setDoc } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

declare let gtag: any;

export type Role =
  | 'guest'
  | 'guestnotverified'
  | 'ptd'
  | 'travelAgent'
  | 'anonymous'
  | 'loading';

export interface UpdateUserInterface {
  uuid?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  photo?: string;
  gclid?: string;
  fbclid?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  country1?: string; // in case of country from expanded list
  zip?: string;
  sessionId?: number;
  anonymous?: boolean;
  updateHistoryFor?: string;
  segment?: number;
  verified?: boolean;
  authenticatedBy?: string;
  ga_sessionId?: string;
}

export interface PTD {
  ttwEmail: string;
  sfsEmail: string;
  signature: string;
  facebook: string;
  instagram: string;
  twitter: string;
  skype: string;
}

export class EmailLog {
  email: string;
  status: string;
  template: string;
  sender?: string;
  rejectReason?: string;
  subject: string;
  sessionId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public userEmail$: BehaviorSubject<string>; // user email if user signed in, null else
  public userVerified$: BehaviorSubject<{
    verified: boolean;
    email: string;
  }>;
  public userRole$: BehaviorSubject<Role>;
  public userUID$: BehaviorSubject<string>;
  public ptd$: BehaviorSubject<PTD>;
  public sessionId: BehaviorSubject<number>;
  public status$: BehaviorSubject<any>;
  private authState: User = null;
  private authObs$: Observable<User>;
  public campaign: Campaign;
  public gclid: string = null;
  public googleClickId: string = null;
  public googleClientId: string = null;
  public fbclId: string = null;
  private subRoute: Subscription;
  public ga_sessionId = null;
  reloadrequired = false;
  campaignsaved = false;

  constructor(
    private auth: Auth,
    private readonly firestore: Firestore,
    private router: Router,
    private apiService: ApiService,
    // private _firebaseAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private campaignService: CampaignInfoService,
    private userDataService: UserDataService,
    private localStorageService: LocalStorageService,
    private ngZone: NgZone
  ) {
    this.campaign = {};
    this.sessionId = new BehaviorSubject<number>(null);
    this.userEmail$ = new BehaviorSubject<string>(null);
    this.userVerified$ = new BehaviorSubject<{
      verified: boolean;
      email: string;
    }>(null);
    this.userRole$ = new BehaviorSubject<Role>('loading');
    this.userUID$ = new BehaviorSubject<string>(null);
    this.ptd$ = new BehaviorSubject<PTD>(null);
    this.status$ = new BehaviorSubject<any>(null);
    this.authObs$ = Observable.create((obs) => {
      return this.auth.onAuthStateChanged(
        (user) => obs.next(user),
        (err) => obs.error(err),
        () => obs.complete()
      );
    });

    this.auth.onAuthStateChanged((user) => {
      this.ngZone.run(async () => {
        // Logged in
        if (user) {
          this.authState = user;
          if (this.reloadrequired) {
            await this.authState.reload();
            this.reloadrequired = false;
          }
          this.authState.getIdTokenResult().then((idTokenResult) => {
            if (idTokenResult.claims.ptd) {
              this.userRole$.next('ptd');
              this.userEmail$.next(user.email);
            } else if (idTokenResult.claims.travelAgent) {
              this.userRole$.next('travelAgent');
              this.userEmail$.next(user.email);
            } else {
              if (user.email) {
                if (user.emailVerified) {
                  this.userRole$.next('guest');
                } else {
                  this.userRole$.next('guestnotverified');
                }
              } else {
                this.userRole$.next('anonymous');
              }
              this.userEmail$.next(user.email);
            }
            //   console.log('this.authState changed', this.authState);
            // console.log('new user email', this.userEmail$.value);
          });

          this.userVerified$.next({
            verified: user.emailVerified,
            email: user.email,
          });
          this.saveUserToFirestore(user);
          this.userUID$.next(this.authState.uid);
        } else {
          // Logged out
          this.authState = null;
          this.userUID$.next(null);
          this.userRole$.next('anonymous');
          this.userEmail$.next(null);
          this.userVerified$.next(null);
        }
      });
    });
  }

  setPersistence() {
    // Set the persistence to LOCAL or SESSION as needed
    this.auth
      .setPersistence(browserLocalPersistence)
      .then(() => {
        console.log('persistence set successfully'); // Persistence is set successfully
      })
      .catch((error) => {
        // Handle Errors here.
        console.error('Error setting persistence:', error);
      });
  }

  public getSessionId() {
    return this.sessionId.value;
  }

  async getUserInfo() {
    let localGoogleClickId = this.localStorageService.getGoogleClickId();
    console.log('googleClickId from local storage', localGoogleClickId);

    let localGoogleClientId = this.localStorageService.getGoogleClientId();
    console.log('googleClientId from local storage', localGoogleClientId);

    gtag('get', 'AW-827181223', 'gclid', (clickId) => {
      this.googleClickId =
        !this.googleClickId || clickId ? clickId : this.googleClickId;
    });
    gtag('get', 'G-JR581MM61N', 'session_id', (sessionId) => {
      this.ga_sessionId = sessionId;
    });

    gtag('get', 'G-JR581MM61N', 'client_id', (clientId) => {
      this.gclid = clientId;
      this.googleClientId = clientId;
    });

    // this.fbclId = this.route.snapshot.queryParams.fbclid;
    this.campaign = null;
    let landing_page = window.location.href;
    if (landing_page) {
      this.campaign = {
        landingPagePath: landing_page,
      };
    }

    this.subRoute = this.route.queryParams.subscribe((params) => {
      if (params.fbclid) {
        this.fbclId = params.fbclid;
      }
      if (params.utm_source) {
        this.campaign = { ...this.campaign, utm_source: params.utm_source };
      }
      if (params.utm_campaign) {
        this.campaign = { ...this.campaign, utm_campaign: params.utm_campaign };
      }
      if (params.utm_medium) {
        this.campaign = { ...this.campaign, utm_medium: params.utm_medium };
      }
      if (params.utm_content) {
        this.campaign = { ...this.campaign, utm_content: params.utm_content };
      }
      if (params.utm_term) {
        this.campaign = { ...this.campaign, utm_term: params.utm_term };
      }
      if (params.mc_cid) {
        this.campaign = { ...this.campaign, mc_cid: params.mc_cid };
      }
      if (params.mc_eid) {
        this.campaign = { ...this.campaign, mc_eid: params.mc_eid };
      }
      if (this.campaign || this.fbclId) {
        if (this.campaign) {
          this.campaignService.update(this.campaign);
        }
        const session = this.sessionId.getValue();
        if (this.authState && this.authState.uid && this.fbclId) {
          this.updateUserData({
            uuid: this.authState.uid,
            fbclid: this.fbclId,
          });
        }
        if (session && this.campaign && !this.campaignsaved) {
          return this.apiService
            .httpCall('/api/saveCampaign', {
              sessionId: session,
              campaign: this.campaign,
            })
            .subscribe((saved: any) => {
              this.campaignsaved = true;
              //console.log('campaign saved');
            });
        }
      }
    });

    // If 'gclid' is not obtained from gtag, check for 'gclid' parameter in the url
    if (!this.googleClickId && landing_page) {
      const gclidMatch = landing_page.match(/[?&]gclid=([^&]*)/);
      this.googleClickId = this.googleClickId
        ? this.googleClickId
        : gclidMatch
        ? gclidMatch[1]
        : null;
    }

    // If 'gclid' is not found, check for '_gac' parameter
    if (!this.googleClickId && landing_page) {
      const gacMatch = landing_page.match(/[?&]_gac=([^&]*)/);
      if (gacMatch) {
        const gacValue = decodeURIComponent(gacMatch[1]);
        // Extract Google click ID from '_gac' value if it exists
        const gacParts = gacValue.split('.');
        if (gacParts && gacParts.length >= 4) {
          this.googleClickId = gacParts[3];
        }
      }
    }
  }

  anonymousLogin(): Observable<any> {
    this.getUserInfo();
    return new Observable((observer) => {
      this.auth.currentUser;
      this.authObs$.pipe(first()).subscribe((authNow) => {
        if (!this.authState) {
          try {
            return this.apiService
              .httpCall('/api/getToken', {
                gclid: this.gclid,
                googleClickId: this.googleClickId,
                googleClientId: this.googleClientId,
                fbclid: this.fbclId,
                ga_sessionId: this.ga_sessionId,
                campaign: this.campaign,
                appversion: environment.version,
                hosting: window.location.hostname.includes('thetravelwhisperer')
                  ? 'ttw'
                  : window.location.hostname.includes('mountainmanagement')
                  ? 'mm'
                  : window.location.hostname,
              })
              .subscribe((token: any) => {
                if (token.token) {
                  if (token.token === 'not') {
                    this.sessionId.next(token.session);
                    if (this.campaign !== null) {
                      this.campaignsaved = true;
                    }

                    if (token.segment >= 0) {
                      this.userDataService.add({ segment: token.segment });
                    }
                    this.sessionId.complete();
                    observer.next({ status: 'Proceed without sign in' });
                    observer.complete();
                  } else {
                    signInWithCustomToken(this.auth, token.token)
                      .then((userCredential) => {
                        console.log('success');
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.error(
                          'sign in with custom token failed',
                          errorMessage
                        );
                      });

                    this.sessionId.next(token.session);
                    if (token.segment >= 0) {
                      this.userDataService.add({ segment: token.segment });
                    }
                    if (token.firstName) {
                      this.userDataService.add({ firstName: token.firstName });
                    }
                    this.sessionId.complete();
                    let uid = this.getUserId();
                    observer.next({ status: 'Sign in with custom token' });
                    observer.complete();
                  }
                } else {
                  signInAnonymously(this.auth)
                    .then((newUser) => {
                      this.ngZone.run(async () => {
                        this.userEmail$.next(null);

                        if (newUser.user && newUser.user.uid) {
                          return this.apiService
                            .httpCall('/api/createUser', {
                              uuid: newUser.user.uid,
                              gclid: this.gclid,
                              googleClickId: this.googleClickId,
                              googleClientId: this.googleClientId,
                              ga_sessionId: this.ga_sessionId,
                              fbclid: this.fbclId,
                              anonymous: true,
                              campaign: this.campaign ? this.campaign : null,
                              hosting: window.location.hostname.includes(
                                'thetravelwhisperer'
                              )
                                ? 'ttw'
                                : window.location.hostname.includes(
                                    'mountainmanagement'
                                  )
                                ? 'mm'
                                : window.location.hostname,
                            })
                            .subscribe((result: any) => {
                              this.sessionId.next(result.session);
                              this.sessionId.complete();
                              if (this.campaign !== null) {
                                this.campaignsaved = true;
                              }

                              if (result.segment >= 0) {
                                this.userDataService.add({
                                  segment: result.segment,
                                });
                              }
                              observer.next({ status: 'New anonymous user' });
                              observer.complete();
                            });
                        }
                      });
                    })
                    .catch((err) => {
                      observer.error({ status: err });
                      observer.complete();
                      this.sessionId.next(null);
                      this.sessionId.complete();
                      console.error('signInAnonymously() error ' + err);
                    });
                }
              }); // this.unitService.getToken
          } catch (e) {
            observer.error({ status: e });
            observer.complete();
            this.sessionId.next(null);
            this.sessionId.complete();
            console.error('getToken error ' + e);
          }
        } else {
          // already authorized, just save session
          getRedirectResult(this.auth)
            .then((credential: UserCredential) => {
              this.ngZone.run(async () => {
                if (credential && credential.user && credential.user.email) {
                  const profile: User = credential.user;
                  this.userEmail$.next(credential.user.email);
                  this.fetchAndUpdate({
                    uuid: credential.user.uid,
                    email: credential.user.email,
                    firstName: profile.displayName ? profile.displayName : null,
                    phone: credential.user.phoneNumber,
                    photo: credential.user.photoURL,
                    ga_sessionId: this.ga_sessionId,
                    gclid: this.gclid,
                    fbclid: this.fbclId,
                    anonymous: false,
                  });
                } else {
                  this.userEmail$.pipe(take(2)).subscribe((email) => {
                    if (email !== null && this.authState.email !== null) {
                      this.fetchUserData(this.authState);
                    } else {
                      if (this.authState) {
                        this.fetchUserName(this.authState);
                      }
                    }
                  });
                }

                /* but create new session for any case, if this redirected or not */
                return this.apiService
                  .httpCall(
                    '/api/saveSession',
                    {
                      uuid: this.authState.uid,
                      gclid: this.gclid,
                      googleClickId: this.googleClickId,
                      googleClientId: this.googleClientId,
                      fbclid: this.fbclId,
                      campaign: this.campaign ? this.campaign : null,
                      loggedIn: this.authState.email ? true : false,
                      appversion: environment.version,
                      hosting: window.location.hostname.includes(
                        'thetravelwhisperer'
                      )
                        ? 'ttw'
                        : window.location.hostname.includes(
                            'mountainmanagement'
                          )
                        ? 'mm'
                        : window.location.hostname,
                      authenticatedBy: 'automatic',
                    },
                    null
                  )
                  .subscribe((result: any) => {
                    this.sessionId.next(result.session);
                    if (this.campaign !== null) {
                      this.campaignsaved = true;
                    }
                    this.sessionId.complete();
                    if (result.segment >= 0) {
                      this.userDataService.add({ segment: result.segment });
                    }
                    let uid = this.getUserId();
                    observer.next({ status: 'User authorized already' });
                    observer.complete();
                  });
              });
            })
            .catch((reason) => {
              if (reason.code === 'auth/credential-already-in-use') {
                console.error('auth/credential-already-in-use');
                /** google sign in is possible here */
              } else {
                if (reason.code === 'auth/web-storage-unsupported') {
                  this.status$.next({ error: 'auth/web-storage-unsupported' });
                  console.error('google sign in in private tab ' + reason.code);
                } else {
                  console.error(
                    'get redirect result failed with ' + reason.code
                  );
                }
              }
            });

          // end already authorized just save session
        }
      });
    }); // return new Observable(observer
  }

  getToken(): Promise<string> {
    if (this.authState) {
      return this.authState.getIdToken();
    } else {
      return null;
    }
  }

  async getHeader(): Promise<HttpHeaders> {
    let newToken = await this.getToken();
    const authHeader = 'User ' + newToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: authHeader,
    });
    return headers;
  }

  getFreshToken(): Promise<string> {
    if (this.authState) {
      return this.authState.getIdToken(true);
    } else {
      return null;
    }
  }

  public async forceHeaders(): Promise<HttpHeaders> {
    let newToken = await this.getFreshToken();
    const authHeader = 'User ' + newToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: authHeader,
    });
    return headers;
  }

  isVerified(): boolean {
    if (this.authState) {
      return this.authState.emailVerified;
    } else {
      return false;
    }
  }

  getUserId(): string {
    if (this.auth && this.auth.currentUser && this.auth.currentUser.uid) {
      return this.auth.currentUser.uid;
    } else {
      return null;
    }
  }

  fetchUserName(firebaseUser: User): Promise<any> {
    if (firebaseUser && firebaseUser.uid) {
      return new Promise<string>((resolve, reject) => {
        try {
          this.apiService
            .httpCall('/api/fetchUserData', {
              uuid: firebaseUser.uid,
              onlyName: true,
            })
            .subscribe((data: any) => {
              if (data.result && data.result.length > 0) {
                const user = data.result[0];
                let currentUser = this.userDataService.get();
                if (user.firstName) {
                  currentUser = { firstName: user.firstName, ...currentUser };
                }
                this.userDataService.update(currentUser);
                return resolve(data.result);
              } else {
                console.error(
                  'unable to fetch user name for uuid: ',
                  firebaseUser.uid
                );
                return reject();
              }
            });
        } catch (e) {
          console.error('fetchUserName failed with ' + e.toString());
          return reject(e);
        }
      });
    } else {
      return null;
    }
  }

  fetchUserData(firebaseUser: User): Promise<any> {
    if (firebaseUser.email !== null && firebaseUser.emailVerified) {
      return new Promise<string>((resolve, reject) => {
        try {
          this.apiService
            .httpCall('/api/fetchUserData', {
              uuid: firebaseUser.uid,
            })
            .subscribe((data: any) => {
              if (data.result && data.result.length > 0) {
                const user = data.result[0];
                let currentUser = this.userDataService.get();
                if (firebaseUser.email) {
                  currentUser = { email: firebaseUser.email, ...currentUser };
                }
                if (user.firstName) {
                  currentUser = { firstName: user.firstName, ...currentUser };
                }
                if (user.lastName) {
                  currentUser = { lastName: user.lastName, ...currentUser };
                }
                if (user.phone) {
                  currentUser = { phone: user.phone, ...currentUser };
                }
                if (user.addressLine1) {
                  currentUser = {
                    addressLine1: user.addressLine1,
                    ...currentUser,
                  };
                }
                if (user.addressLine2) {
                  currentUser = {
                    addressLine2: user.addressLine2,
                    ...currentUser,
                  };
                }
                if (user.city) {
                  currentUser = { city: user.city, ...currentUser };
                }
                if (user.state) {
                  currentUser = { state: user.state, ...currentUser };
                }
                if (user.country) {
                  currentUser = { country: user.country, ...currentUser };
                }

                if (user.country1) {
                  currentUser = { country1: user.country1, ...currentUser };
                }
                if (user.zip) {
                  currentUser = { zip: user.zip, ...currentUser };
                }
                if (user.segment) {
                  currentUser = { segment: user.segment, ...currentUser };
                }
                this.userDataService.update(currentUser);
                return resolve(data.result);
              } else {
                console.error(
                  'unable to fetch user data for uuid: ',
                  firebaseUser.uid
                );
                return reject();
              }
            });
        } catch (e) {
          console.error('fetchUserData failed with ' + e.toString());
          return reject(e);
        }
      });
    } else {
      return null;
    }
  }

  updateUserData(user: UpdateUserInterface, firebaseUser?: User): Promise<any> {
    if (user.email) {
      this.userDataService.add({ email: user.email });
    }
    if (user.firstName) {
      this.userDataService.add({ firstName: user.firstName });
    }
    if (user.lastName) {
      this.userDataService.add({ lastName: user.lastName });
    }
    if (user.city) {
      this.userDataService.add({ city: user.city });
    }
    if (user.phone) {
      this.userDataService.add({ phone: user.phone });
    }
    if (user.addressLine1) {
      this.userDataService.add({ addressLine1: user.addressLine1 });
    }
    if (user.addressLine2) {
      this.userDataService.add({ addressLine2: user.addressLine2 });
    }
    if (user.state) {
      this.userDataService.add({ state: user.state });
    }
    if (user.country) {
      this.userDataService.add({ country: user.country });
    }
    if (user.country1) {
      this.userDataService.add({ country1: user.country1 });
    }
    if (user.zip) {
      this.userDataService.add({ zip: user.zip });
    }
    if (user.segment) {
      this.userDataService.add({ segment: user.segment });
    }

    return new Promise<string>((resolve, reject) => {
      const fireUser: User = firebaseUser ? firebaseUser : this.authState;
      if (fireUser) {
        user.anonymous = fireUser.email ? false : true;
        this.apiService
          .httpCall('/api/updateUser', {
            uuid: user.uuid ? user.uuid : fireUser.uid,
            email: user.email ? user.email : null,
            firstName: user.firstName ? user.firstName : null,
            lastName: user.lastName ? user.lastName : null,
            phone: user.phone ? user.phone : null,
            photo: user.photo ? user.photo : null,
            gclid: this.gclid ? this.gclid : null,

            fbclid: this.fbclId ? this.fbclId : null,
            addressLine1: user.addressLine1 ? user.addressLine1 : null,
            addressLine2: user.addressLine2 ? user.addressLine2 : null,
            city: user.city ? user.city : null,
            state: user.state ? user.state : null,
            country: user.country ? user.country : null,
            country1: user.country1 ? user.country1 : null,
            zip: user.zip ? user.zip : null,
            sessionId: user.sessionId ? user.sessionId : null,
            anonymous: user.anonymous,
            updateHistoryFor: user.updateHistoryFor
              ? user.updateHistoryFor
              : null,
            segment: user.segment ? user.segment : null,
            verified: this.userVerified$.value.verified,
            authenticatedBy: user.authenticatedBy ? user.authenticatedBy : null,
          })
          .subscribe((result: any) => {
            console.log('updated');
          });
      } else {
        return resolve('User is not logged in');
      }
    });
  }

  sendSignInLink(guestEmail): Promise<any> {
    const actionCodeSettings = {
      url: 'https://' + environment.dynamicLinkDomain + '/guest/email-handler',
      handleCodeInApp: true,
      dynamicLinkDomain: environment.dynamicLinkDomain,
    };
    return new Promise<string>((resolve, reject) => {
      sendSignInLinkToEmail(this.auth, guestEmail, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem('emailForSignIn', guestEmail);
          return resolve('success');
        })
        .catch((error) => {
          console.error(
            'sendSignInLink failed for user ',
            guestEmail,
            ' errorCode = ',
            error.code
          );
          console.error('errorMessage = ', error.message);
          return reject(error.message);
        });
    });
  }

  isSignInWithEmailLink(href): boolean {
    return isSignInWithEmailLink(this.auth, href);
  }

  async signInWithEmailLink(
    emailUid,
    emailLink,
    emailInput,
    linkid
  ): Promise<any> {
    let email = emailInput ? emailInput.toLowerCase() : null;

    if (!email) {
      let result: any = await firstValueFrom(
        this.apiService.httpCall('/api/decodeEmail', {
          uid: emailUid,
        })
      );
      email = result && result.email ? result.email : null;
    } else {
      if (linkid) {
        this.apiService.httpCall('/api/signuplinkaccessed', {
          linkid: linkid,
          email: email,
        });
      }
    }
    if (!email) {
      return false;
    }

    let existing = await fetchSignInMethodsForEmail(this.auth, email);
    let requestPassword = true;

    if (existing && existing.length) {
      requestPassword =
        existing.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1;
    }
    if (
      this.authState &&
      this.authState.uid &&
      this.authState.email &&
      this.authState.email.toLowerCase() === email.toLowerCase()
    ) {
      //signed in already, don't need to sign up with the link
      return 'success';
    } else {
      if (
        this.authState &&
        this.authState.uid &&
        !this.authState.email &&
        !(existing && existing.length)
        // anonymous user
      ) {
        const previousUid = this.authState.uid;

        return new Promise<string>(async (resolve, reject) => {
          const credentials = EmailAuthProvider.credentialWithLink(
            email,
            emailLink
          );

          this.ngZone.run(() => {
            this.apiService
              .httpCall('/api/refreshSignIn', {
                uuid: this.authState.uid,
                email: email,
              })
              .subscribe({
                next: (result: any) => {
                  if (result.token) {
                    signInWithCustomToken(this.auth, result.token)
                      .then((user) => {
                        linkWithCredential(user.user, credentials)
                          .then(async (credential: UserCredential) => {
                            if (credential.user.email) {
                              this.userUID$.next(credential.user.uid);
                              this.userEmail$.next(credential.user.email);
                              this.userRole$.next('guest');
                              this.userVerified$.next({
                                verified: credential.user.emailVerified,
                                email: credential.user.email,
                              });
                              if (requestPassword) {
                                this.RequestNewPasswordMail(
                                  credential.user.email
                                );
                              }
                            }
                            if (credential.user.uid !== previousUid) {
                              //new user created, so we need new session. New session will be created in fetchAndUpdate
                              this.sessionId.next(null);
                            }
                            const session = this.sessionId.getValue();

                            this.fetchAndUpdate({
                              uuid: credential.user.uid,
                              email: credential.user.email,
                              phone: credential.user.phoneNumber,
                              photo: credential.user.photoURL,
                              gclid: this.gclid,
                              fbclid: this.fbclId,
                              anonymous: false,
                              sessionId: session,
                              authenticatedBy: 'EmailLink',
                            });
                            return resolve('success');
                          })
                          .catch((error) => {
                            // TODO correct error handling to show user

                            if (
                              error &&
                              error.code === 'auth/expired-action-code'
                            ) {
                              return reject({
                                error: 'auth/expired-action-code',
                                existing: existing && existing.length,
                              });
                            } else {
                              console.error(
                                'registration failed at linkWithCredential',
                                error,
                                ' for user ',
                                this.authState.uid,
                                ' email = ',
                                email
                              );
                              return reject({
                                error: error,
                                existing: existing && existing.length,
                              });
                            }
                          });
                      })
                      .catch((err) => {
                        //sign in with custom token exception
                        console.error(
                          'signInWithEmailLink: Sign in with custom token before sign up with email link exception for user' +
                            this.authState.uid +
                            'exception' +
                            err
                        );
                        return reject(err);
                      });
                  } else {
                    //no custom token in result
                    console.error(
                      'signInWithEmailLink: Cannot sign in with custom token for existing anonymous account for user ' +
                        this.authState.uid
                    );
                    return reject(
                      'Cannot sign in with custom token for existing anonymous account for user ' +
                        this.authState.uid
                    );
                  }
                },
                error: (err) =>
                  console.error(
                    'signInWithEmailLink: refreshSignIn error: Sign in with custom token before sign up with email exception for user' +
                      this.authState.uid +
                      'exception' +
                      err
                  ),
                complete: () => console.info('refreshSignIn complete'),
              });
          });
        });
      } else {
        // this.authState === null
        //  console.log('signInWithEmailLink: no existing authstate');
        return new Promise<string>((resolve, reject) => {
          signInWithEmailLink(this.auth, email, emailLink)
            .then((credential) => {
              this.ngZone.run(() => {
                if (credential.user.email) {
                  this.userUID$.next(credential.user.uid);
                  this.userEmail$.next(credential.user.email);
                  this.userRole$.next('guest');
                  this.userVerified$.next({
                    verified: credential.user.emailVerified,
                    email: credential.user.email,
                  });
                  if (requestPassword) {
                    this.RequestNewPasswordMail(credential.user.email);
                  }

                  // console.log('verified = ', credential.user.emailVerified);
                }
                //console.log(
                //  'signInWithEmailLink success, welcome new user ',
                //  credential.user.uid
                //);
                if (credential.user && credential.user.uid) {
                  this.fetchAndUpdate({
                    uuid: credential.user.uid,
                    email: credential.user.email,
                    phone: credential.user.phoneNumber,
                    photo: credential.user.photoURL,
                    gclid: this.gclid,
                    fbclid: this.fbclId,
                    anonymous: false,
                    sessionId: null,
                    authenticatedBy: 'EmailLink',
                  });
                  return resolve('success');
                }
              });
            })
            .catch((error) => {
              // TODO correct error handling to show user

              if (error && error.code === 'auth/expired-action-code') {
                return reject({
                  error: 'auth/expired-action-code',
                  existing: existing && existing.length,
                });
              } else {
                console.error(
                  'signInWithEmailLink:  failed when auth state is null',
                  error
                );
                return reject({
                  error: error,
                  existing: existing && existing.length,
                });
              }
            });
        });
      }
    }
  }

  signUpwithEmail(email, password): Promise<any> {
    if (this.authState && this.authState.uid) {
      return new Promise<string>(async (resolve, reject) => {
        const credentials: AuthCredential = EmailAuthProvider.credential(
          email,
          password
        );

        this.ngZone.run(() => {
          this.apiService
            .httpCall('/api/refreshSignIn', {
              uuid: this.authState.uid,
              email: email,
            })
            .subscribe(
              (result: any) => {
                if (result.token) {
                  signInWithCustomToken(this.auth, result.token)
                    .then((user) => {
                      linkWithCredential(user.user, credentials)
                        .then(async (credential: UserCredential) => {
                          if (credential.user.email) {
                            this.userEmail$.next(credential.user.email);
                          }

                          let verificationMailRes =
                            await this.SendVerificationMail(credential.user);
                          let user = this.authState;
                          user.getIdTokenResult().then((idTokenResult) => {
                            if (idTokenResult.claims.guest) {
                              this.userRole$.next('guest');
                              this.userEmail$.next(user.email);
                            } else if (idTokenResult.claims.ptd) {
                              this.userRole$.next('ptd');
                              this.userEmail$.next(user.email);
                            } else if (idTokenResult.claims.travelAgent) {
                              this.userRole$.next('travelAgent');
                              this.userEmail$.next(user.email);
                            } else {
                              if (user.email) {
                                if (user.emailVerified) {
                                  this.userRole$.next('guest');
                                } else {
                                  this.userRole$.next('guestnotverified');
                                }
                              } else {
                                this.userRole$.next('anonymous');
                              }
                              this.userEmail$.next(user.email);
                            }
                          });
                          const session = this.sessionId.getValue();
                          this.updateUserData({
                            uuid: credential.user.uid,
                            email: credential.user.email,
                            sessionId: session,
                            gclid: this.gclid,
                            fbclid: this.fbclId,
                            anonymous: false,
                            authenticatedBy: 'EmailPassword',
                          });
                          if (verificationMailRes === 'success') {
                            return resolve('success');
                          } else {
                            return resolve('verification_email_issue');
                          }
                        })
                        .catch((error) => {
                          // TODO correct error handling to show user
                          console.error(
                            'registration failed at linkWithCredential',
                            error,
                            ' for user ',
                            this.authState.uid,
                            ' email = ',
                            email
                          );
                          return reject(error);
                        });
                    })
                    .catch((err) => {
                      //sign in with custom token exception
                      console.error(
                        'Sign in with custom token before sign up with email exception for user' +
                          this.authState.uid +
                          'exception' +
                          err
                      );
                      return reject(err);
                    });
                } else {
                  //no custom token in result
                  console.error(
                    'Cannot sign in with custom token for existing anonymous account for user ' +
                      this.authState.uid
                  );
                  return reject(
                    'Cannot sign in with custom token for existing anonymous account for user ' +
                      this.authState.uid
                  );
                }
              },
              (err) => {
                console.error('refreshSignIn error', err);
                return reject(err);
              }
            );
        });
      });
    } else {
      /* this.authState === null */

      return new Promise<string>((resolve, reject) => {
        createUserWithEmailAndPassword(this.auth, email, password)
          .then((credential) => {
            this.ngZone.run(() => {
              if (credential.user.email) {
                this.userEmail$.next(credential.user.email);
              }

              this.SendVerificationMail(credential.user);

              if (credential.user && credential.user.uid) {
                this.apiService
                  .httpCall('/api/createUser', {
                    uuid: credential.user.uid,
                    gclid: this.gclid,
                    googleClickId: this.googleClickId,
                    googleClientId: this.googleClientId,
                    fbclid: this.fbclId,
                    email: credential.user.email,
                    anonymous: false,
                    campaign: this.campaign ? this.campaign : null,
                    authenticatedBy: 'EmailPassword',
                    appversion: environment.version,
                    hosting: window.location.hostname.includes(
                      'thetravelwhisperer'
                    )
                      ? 'ttw'
                      : window.location.hostname.includes('mountainmanagement')
                      ? 'mm'
                      : window.location.hostname,
                  })
                  .subscribe((result: any) => {
                    this.sessionId.next(result.session);
                    this.sessionId.complete();
                    if (result.segment >= 0) {
                      this.userDataService.add({ segment: result.segment });
                    }
                  });

                let user: UserData = this.userDataService.get();
                user = { email: credential.user.email, ...user };
                this.userDataService.update(user);
                return resolve('success');
              }
            });
          })
          .catch((error) => {
            // TODO correct error handling to show user
            console.error('registration failed when auth state is null', error);
            return reject(error);
          });
      });
    }
  }

  /*resetPassword(email: string): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      sendPasswordResetEmail(this.auth, email)
        .then((result) => {
          this.ngZone.run(() => {
            return resolve('success');
          });
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }*/

  resetPassword(email: string): Promise<string> {
    let emailLog: EmailLog = {
      email: email,
      status: null,
      template: 'ResetPasswordEmail',
      rejectReason: null,
      subject: 'Reset your password for The Travel Whisperer',
    };
    return new Promise<string>((resolve, reject) => {
      try {
        this.apiService
          .httpCall('/api/requestPasswordLink', {
            email: email,
            newPassword: false,
          })
          .subscribe({
            next: (result: any) => {
              if (result && result.status && result.status === 'sent') {
                emailLog.status = 'sent';
                this.saveEmailPG(emailLog).subscribe((result) => {});
                resolve('success');
              } else {
                emailLog.status = result.status ? result.status : 'failed';
                emailLog.rejectReason = result.reject_reason
                  ? result.reject_reason
                  : 'unknown';
                this.saveEmailPG(emailLog).subscribe((result) => {});
                console.error(
                  'resetPassword email is not sent for user ',
                  email
                );
                resolve('false');
              }
            },
            error: (err: any) => {
              console.error('resetPassword submission error', err.code);
              emailLog.status = 'failed';
              emailLog.rejectReason = 'unknown';
              this.saveEmailPG(emailLog).subscribe((result) => {});
              resolve('false');
            },
            complete: () => {
              //console.log('complete');
            },
          });
      } catch (e) {
        console.error('resetPassword failed with ' + e.code);
        return reject(e);
      }
    });
  }

  confirmPasswordReset(actionCode, newPassword): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      confirmPasswordReset(this.auth, actionCode, newPassword)
        .then((result) => {
          this.ngZone.run(() => {
            return resolve('success');
          });
        })
        .catch((error) => {
          this.ngZone.run(() => {
            console.error('password reset is not confirmed', error);
            return reject(error);
          });
        });
    });
  }

  // Send email verificaiton when new user sign up
  SendVerificationMail(user: User): Promise<string> {
    if (user && user.uid) {
      let emailLog: EmailLog = {
        email: user.email,
        status: null,
        template: 'VerificationEmail',
        rejectReason: null,
        subject: 'Verify your email for The Travel Whisperer',
      };
      return new Promise<string>((resolve, reject) => {
        try {
          this.apiService
            .httpCall('/api/sendVerificationEmail', {
              uuid: user.uid,
              email: user.email,
            })
            .subscribe({
              next: (result: any) => {
                if (result && result.status && result.status === 'sent') {
                  emailLog.status = 'sent';
                  this.saveEmailPG(emailLog).subscribe((result) => {});
                  resolve('success');
                } else {
                  emailLog.status = result.status ? result.status : 'failed';
                  emailLog.rejectReason = result.reject_reason
                    ? result.reject_reason
                    : 'unknown';
                  this.saveEmailPG(emailLog).subscribe((result) => {});
                  console.error(
                    'Verification email is not sent for user ',
                    user.email
                  );
                  resolve('false');
                }
              },
              error: (err: any) => {
                console.error('submission error', err.code);
                emailLog.status = 'failed';
                emailLog.rejectReason = 'unknown';
                this.saveEmailPG(emailLog).subscribe((result) => {});
                resolve('false');
              },
              complete: () => {
                //console.log('complete');
              },
            });
        } catch (e) {
          console.error('SendVerificationMail failed with ' + e.code);
          return reject(e);
        }
      });
    } else {
      console.error('SendVerificationMail requested with null authState');
    }
  }

  // Send Password Recovery Link decorated as set new password link for user
  RequestNewPasswordMail(email: string): Promise<string> {
    let emailLog: EmailLog = {
      email: email,
      status: null,
      template: 'NewPasswordLinkEmail',
      rejectReason: null,
      subject: 'Set your password for The Travel Whisperer',
    };
    return new Promise<string>((resolve, reject) => {
      try {
        this.apiService
          .httpCall('/api/requestPasswordLink', {
            email: email,
            newPassword: true,
          })
          .subscribe({
            next: (result: any) => {
              if (result && result.status && result.status === 'sent') {
                emailLog.status = 'sent';
                this.saveEmailPG(emailLog).subscribe((result) => {});
                resolve('success');
              } else {
                emailLog.status = result.status ? result.status : 'failed';
                emailLog.rejectReason = result.reject_reason
                  ? result.reject_reason
                  : 'unknown';
                this.saveEmailPG(emailLog).subscribe((result) => {});
                console.error(
                  'RequestNewPasswordMail email is not sent for user ',
                  email
                );
                resolve('false');
              }
            },
            error: (err: any) => {
              console.error('submission error', err.code);
              emailLog.status = 'failed';
              emailLog.rejectReason = 'unknown';
              this.saveEmailPG(emailLog).subscribe((result) => {});
              resolve('false');
            },
            complete: () => {
              //console.log('complete');
            },
          });
      } catch (e) {
        console.error('RequestNewPasswordMail failed with ' + e.code);
        return reject(e);
      }
    });
  }

  reSubmitVerificationMail(): Promise<string> {
    if (!this.authState) {
      throw new Error('verification requested for null authState');
    }
    return this.SendVerificationMail(this.authState);
  }

  saveEmailPG(newEmail: EmailLog): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.sessionId.getValue();

      if (sessionId) {
        const myUid = this.authState ? this.authState.uid : null;
        newEmail = {
          sender: myUid,
          sessionId: sessionId,
          ...newEmail,
        };
        this.apiService
          .httpCall('/api/saveEmailLog', {
            email: newEmail,
          })
          .subscribe((result) => {
            observer.next('done');
            observer.complete();
          });
      } else {
        this.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              const myUid = this.authState ? this.authState.uid : null;
              newEmail = {
                sender: myUid,
                sessionId: sessionIdNew,
                ...newEmail,
              };
              this.apiService
                .httpCall('/api/saveEmailLog', {
                  email: newEmail,
                })
                .subscribe((result) => {
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  logInwithEmail(email: string, password: string): Promise<any> {
    let anon_uid = null;
    if (this.authState) {
      anon_uid = this.authState.uid;
    }
    return new Promise<string>((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then((credential) => {
          this.ngZone.run(() => {
            if (credential.user.email) {
              this.userEmail$.next(credential.user.email);
              this.fetchUserData(credential.user);
            }

            const session = this.sessionId.getValue(); // FIXME what if it is not ready yet?
            this.updateUserData({
              uuid: credential.user.uid,
              sessionId: session,
              gclid: this.gclid,
              fbclid: this.fbclId,
              anonymous: false,
              updateHistoryFor: anon_uid,
            });
            return resolve('success');
          });
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  ngOnDestroy() {
    this.subRoute.unsubscribe();
  }

  signOut() {
    signOut(this.auth)
      .then((result) => {
        this.ngZone.run(async () => {
          this.userEmail$.next(null);
          this.userVerified$.next(null);
          this.router.navigate(['/']);
          this.userDataService.update(null);
          this.userUID$.next(null);
        });
      })
      .catch((error) => {
        console.error('sign out failed ' + error);
      });
  }

  handleVerifyEmail(oobCode: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      applyActionCode(this.auth, oobCode)
        .then(async (result) => {
          this.ngZone.run(async () => {
            if (this.authState) {
              await this.authState.reload();
              this.reloadrequired = false;
              let user = this.authState;
              user.getIdTokenResult().then((idTokenResult) => {
                if (idTokenResult.claims.guest) {
                  this.userRole$.next('guest');
                  this.userEmail$.next(user.email);
                } else if (idTokenResult.claims.ptd) {
                  this.userRole$.next('ptd');
                  this.userEmail$.next(user.email);
                } else if (idTokenResult.claims.travelAgent) {
                  this.userRole$.next('travelAgent');
                  this.userEmail$.next(user.email);
                } else {
                  if (user.email) {
                    if (user.emailVerified) {
                      this.userRole$.next('guest');
                      this.updateUserData({ verified: true });
                    } else {
                      this.userRole$.next('guestnotverified');
                    }
                  } else {
                    this.userRole$.next('anonymous');
                  }
                  this.userEmail$.next(user.email);
                }
              });

              this.userVerified$.next({
                verified: user.emailVerified,
                email: user.email,
              });
            } else {
              this.reloadrequired = true;
            }
            return resolve('verified');
          });
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  fetchAndUpdate(user: UpdateUserInterface): Promise<any> {
    if (user.email) {
      this.userDataService.add({ email: user.email });
    }
    if (user.firstName) {
      this.userDataService.add({ firstName: user.firstName });
    }
    if (user.lastName) {
      this.userDataService.add({ lastName: user.lastName });
    }
    return new Promise<string>((resolve, reject) => {
      if (this.authState) {
        user.anonymous = this.authState.email ? false : true;
        this.apiService
          .httpCall('/api/fetchAndUpdate', {
            uuid: user.uuid ? user.uuid : this.authState.uid,
            anonymous: false,
            email: user.email ? user.email : null,
            firstName: user.firstName ? user.firstName : null,
            lastName: user.lastName ? user.lastName : null,
            phone: user.phone ? user.phone : null,
            photo: user.photo ? user.photo : null,
            updateHistoryFor: user.updateHistoryFor
              ? user.updateHistoryFor
              : null,
            verified: this.userVerified$.value.verified,
            sessionId: user.sessionId,
            googleClickId: this.googleClickId,
            googleClientId: this.googleClientId,
            authenticatedBy: user.authenticatedBy ? user.authenticatedBy : null,
            hosting: window.location.hostname.includes('thetravelwhisperer')
              ? 'ttw'
              : window.location.hostname.includes('mountainmanagement')
              ? 'mm'
              : window.location.hostname,
          })
          .subscribe((updatedUser: any) => {
            if (!user.sessionId && updatedUser.sessionId) {
              this.sessionId.next(updatedUser.sessionId);
              this.sessionId.complete();
            }
            let currentUser = this.userDataService.get();
            if (updatedUser.firstName) {
              currentUser = {
                firstName: updatedUser.firstName,
                ...currentUser,
              };
            }
            if (updatedUser.lastName) {
              currentUser = {
                lastName: updatedUser.lastName,
                ...currentUser,
              };
            }
            if (updatedUser.phone) {
              currentUser = { phone: updatedUser.phone, ...currentUser };
            }
            if (updatedUser.addressLine1) {
              currentUser = {
                addressLine1: updatedUser.addressLine1,
                ...currentUser,
              };
            }
            if (updatedUser.addressLine2) {
              currentUser = {
                addressLine2: updatedUser.addressLine2,
                ...currentUser,
              };
            }
            if (updatedUser.city) {
              currentUser = { city: updatedUser.city, ...currentUser };
            }
            if (updatedUser.state) {
              currentUser = { state: updatedUser.state, ...currentUser };
            }
            if (updatedUser.country) {
              currentUser = { country: updatedUser.country, ...currentUser };
            }
            if (updatedUser.zip) {
              currentUser = { zip: updatedUser.zip, ...currentUser };
            }
            if (updatedUser.segment) {
              currentUser = { segment: updatedUser.segment, ...currentUser };
            }

            this.userDataService.update(currentUser);
          });
      } else {
        return resolve('User is not logged in');
      }
    });
  }

  getRole(): Role {
    return this.userRole$.getValue();
  }

  saveUserToFirestore(user) {
    const userRef = collection(this.firestore, 'user');
    const userDoc = doc(userRef, user.uid);
    setDoc(userDoc, {
      uid: user.uid,
    }).catch((error) => {
      console.error('user uid ', user.uid, ' is not saved to firestore', error);
    });
  }

  requestNewSignUpLink(data: {
    guestEmail: string;
    payment: string | null;
    lodgingQuote: boolean;
  }): Observable<any> {
    let emailLog: EmailLog = {
      email: data.guestEmail,
      status: null,
      template: 'SignUpLinkGuestRequested',
      rejectReason: null,
      subject: 'Sign Up Link for The Travel Whisperer',
    };
    return new Observable((observer) => {
      this.apiService
        .httpCall('/api/submitEmailLink', {
          to: data.guestEmail,
          payment: data.payment ? data.payment : null,
          lodgingQuote: data.lodgingQuote ? data.lodgingQuote : false,
        })
        .subscribe({
          next: (result: any) => {
            if (
              result &&
              result.length &&
              result[0].status &&
              result[0].status === 'sent'
            ) {
              emailLog.status = 'sent';
              this.saveEmailPG(emailLog).subscribe((result) => {});
            } else {
              emailLog.status = result.status ? result.status : 'failed';
              emailLog.rejectReason = result.reject_reason
                ? result.reject_reason
                : 'unknown';
              this.saveEmailPG(emailLog).subscribe((result) => {});
              console.error(
                'requestNewSignUpLink email is not sent for user ',
                data.guestEmail
              );
            }
          },
          error: (err: any) => {
            console.error('resetPassword submission error', err.code);
            emailLog.status = 'failed';
            emailLog.rejectReason = 'unknown';
            this.saveEmailPG(emailLog).subscribe((result) => {});
          },
          complete: () => {
            console.log('complete');
          },
        });
    });
  }
}
