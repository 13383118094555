import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { OverlayContainer } from '@angular/cdk/overlay';
import {
  newbrand,
  stagingwtlbl,
  mainsite,
  mm,
  staging,
  amanda,
  smartflyer,
  jettsettravel,
  packhappytravel,
  localhost,
} from './store/models/hostings';
import { FavoritesService } from './services/favorites.service';
import { GoogleMapsLoaderService } from './services/google-maps-loader.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

interface AppData {
  version?: string;
}
declare global {
  interface Window {
    dataLayer: any[];
  }
}
// Reworking authentication
@Component({
  selector: 'twbooking-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isMapsLoaded = false;

  constructor(
    public authService: AuthService,
    public favoriteService: FavoritesService,
    public overlayContainer: OverlayContainer,
    private googleMapsLoader: GoogleMapsLoaderService,
    private swUpdate: SwUpdate,
    private router: Router
  ) {
    this.checkForUpdates();
    if (
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(localhost) ||
      window.location.hostname.includes(staging)
    ) {
      this.onSetTheme('twbooking-theme');
      this.favoriteService.init();
      this.authService.anonymousLogin().subscribe((result) => {
        console.log('anonymous login status: ', result.status);
      });
    } else if (
      window.location.hostname.includes(newbrand) ||
      window.location.hostname.includes(stagingwtlbl)
    ) {
      this.onSetTheme('laurie-theme');
    } else if (window.location.hostname.includes(amanda)) {
      this.onSetTheme('amanda-theme');
    } else if (window.location.hostname.includes(smartflyer)) {
      this.onSetTheme('smartflyer-theme');
    } else if (window.location.hostname.includes(jettsettravel)) {
      this.onSetTheme('jettsettravel-theme');
    } else if (window.location.hostname.includes(packhappytravel)) {
      this.onSetTheme('packhappytravel-theme');
    } else {
      this.authService.anonymousLogin().subscribe((result) => {
        console.log('anonymous login status: ', result.status);
      });
    }
  }

  checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      // Using the 'versionUpdates' observable to listen for updates
      this.swUpdate.versionUpdates
        .pipe(
          filter(
            (event): event is VersionReadyEvent =>
              event.type === 'VERSION_READY'
          ),
          map((event) => ({
            type: event.type,
            currentVersion: event.currentVersion,
            latestVersion: event.latestVersion,
          }))
        )
        .subscribe((event) => {
          const currentApp = event.currentVersion.appData as AppData;
          console.log('current cached version = ', currentApp.version);

          const latestApp = event.latestVersion.appData as AppData;
          if (currentApp.version && latestApp.version) {
            const current = currentApp.version
              .split('.')
              .map((v) => parseInt(v, 10));
            const available = latestApp.version
              .split('.')
              .map((v) => parseInt(v, 10));
            console.log('up-to-date version = ', latestApp.version);
            // Check if major version has changed
            if (current[0] < available[0]) {
              console.log('major version is changed');
              window.location.reload();
              // Ensure 'version' variable is defined or retrieve it from a valid source
              console.log('reloaded version = ', latestApp.version);
            } else {
              console.log('major version is not changed');
            }
          } else {
            console.log('version is not detected');
          }
        });
    }
  }

  @HostBinding('class') componentCssClass;

  onSetTheme(theme) {
    this.overlayContainer.getContainerElement().classList.add(theme);

    this.componentCssClass = theme;
  }

  ngOnInit(): void {
    this.googleMapsLoader.loadScript().then(() => {
      this.isMapsLoaded = true;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'pageview',
          pagePath: event.urlAfterRedirects,
        });
      }
    });
  }
}
