<div class="unit-inquiry-form">
  <div class="marketing-column responsive-column new-brand-color">
    <div *ngIf="!isMobile" class="header-block">
      <img
        *ngIf="hostMode === 'MM'"
        src="{{ logoSrc }}"
        alt="{{ logoAlt }}"
        style="width: 80%"
      />
      <img
        *ngIf="hostMode === 'basic'"
        src="{{ logoSrc }}"
        alt="{{ logoAlt }}"
        style="width: 50%"
      />
      <h1 class="new-brand-color">Contact us</h1>
    </div>

    <p class="new-brand-color">{{ welcomeText }}</p>

    <div class="ptd-team">
      <div class="ptd-row">
        <div class="ptd-card">
          <img
            src="https://ttw-marketing-images.imgix.net/profilePhotos/kelly.jpg?w=151&h=151&fit=facearea&facepad=3&mask=ellipse&bg=transparent&fm=png"
            alt="Kelly Klett"
            class="ptd-avatar"
          />
          <div class="ptd-name">Kelly Klett</div>
          <a
            href="mailto:kelly@thetravelwhisperer.com"
            class="contact-button"
            *ngIf="isMobile"
            >Contact</a
          >
          <div class="margin-bottom-10" *ngIf="!isMobile"></div>
        </div>
        <div class="ptd-card">
          <img
            src="https://ttw-marketing-images.imgix.net/profilePhotos/Judi-Wilkinson-v2.png?w=151&h=151&fit=facearea&facepad=3&mask=ellipse&bg=transparent&fm=png"
            alt="Judi Wilkinson"
            class="ptd-avatar"
          />
          <div class="ptd-name">Judi Wilkinson</div>
          <a
            href="mailto:judi@thetravelwhisperer.com"
            class="contact-button"
            *ngIf="isMobile"
            >Contact</a
          >
          <div class="margin-bottom-10" *ngIf="!isMobile"></div>
        </div>
      </div>
      <div class="ptd-row">
        <div class="ptd-card">
          <img
            src="https://ttw-marketing-images.imgix.net/profilePhotos/paige_burton.jpeg?w=151&h=151&fit=facearea&facepad=3&mask=ellipse&bg=transparent&fm=png"
            alt="Paige Burton"
            class="ptd-avatar"
          />
          <div class="ptd-name">Paige Burton</div>
          <a
            href="mailto:paige@thetravelwhisperer.com"
            class="contact-button"
            *ngIf="isMobile"
            >Contact</a
          >
          <div class="margin-bottom-10" *ngIf="!isMobile"></div>
        </div>
        <div class="ptd-card">
          <img
            src="https://ttw-marketing-images.imgix.net/profilePhotos/Natalie_Gorak.jpg?w=151&h=151&fit=facearea&facepad=3&mask=ellipse&bg=transparent&fm=png"
            alt="Natalie Gorak"
            class="ptd-avatar"
          />
          <div class="ptd-name">Natalie Gorak</div>
          <a
            href="mailto:natalie@thetravelwhisperer.com"
            class="contact-button"
            *ngIf="isMobile"
            >Contact</a
          >
          <div class="margin-bottom-10" *ngIf="!isMobile"></div>
        </div>
      </div>
    </div>
    <div class="sections-divider">
      <mat-divider></mat-divider>
    </div>
    <div class="new-brand-color">
      <h2 class="new-brand-color">Live well, travel often</h2>
      <p class="new-brand-color">
        Call U.S.:
        <a class="brand-dark-color" href="tel:+19706885193">(970) 688-5193</a>
      </p>
      <p class="new-brand-color">
        Call U.K.:
        <a class="brand-dark-color" href="tel:+442037731134">(020) 3773 1134</a>
      </p>
      <p class="new-brand-color">
        Toll Free:
        <a class="brand-dark-color" href="tel:+18889603033">(888) 960-3033</a>
      </p>
      <p class="new-brand-color">
        Email:
        <a class="brand-dark-color" href="mailto:{{ contactEmail }}">{{
          contactEmail
        }}</a>
      </p>
    </div>
    <div class="sections-divider">
      <mat-divider></mat-divider>
    </div>
    <div class="new-brand-color">
      <h3>{{ reviewText }}</h3>
      <div class="stars-in-row">
        <mat-icon class="review-stars review-stars-large">star</mat-icon>
        <mat-icon class="review-stars review-stars-large">star</mat-icon>
        <mat-icon class="review-stars review-stars-large">star</mat-icon>
        <mat-icon class="review-stars review-stars-large">star</mat-icon>
        <mat-icon class="review-stars review-stars-large">star</mat-icon>
      </div>
      <p class="new-brand-color">
        {{ reviewAuthor }}
      </p>
    </div>
  </div>

  <div class="form-column responsive-column">
    <div *ngIf="isMobile" class="header-block">
      <img src="{{ logoSrc }}" alt="{{ logoAlt }}" style="width: 80%" />
      <h1 class="new-brand-color">Contact us</h1>
    </div>
    <form
      class="enquiry-dialog-form"
      [formGroup]="form"
      (ngSubmit)="handleSubmit(form.value, form.valid)"
      novalidate
    >
      <div class="form-section new-brand-color">
        <h4 class="new-brand-color">Personal info</h4>
        <mat-form-field class="inquiry-field">
          <input
            matInput
            required
            placeholder="First name"
            formControlName="firstnameField"
            name="firstnameField"
            (change)="userDataChanged('firstName', $event)"
          />
          <div
            *ngIf="
              !form.controls.firstnameField.valid &&
              (form.controls.firstnameField.touched || submitted)
            "
            class="alert-warning"
          >
            Please enter your first name
          </div>
        </mat-form-field>
        <mat-form-field class="inquiry-field">
          <input
            matInput
            required
            placeholder="Last name"
            formControlName="lastnameField"
            name="lastnameField"
            (change)="userDataChanged('lastName', $event)"
          />
          <div
            *ngIf="
              !form.controls.lastnameField.valid &&
              (form.controls.lastnameField.touched || submitted)
            "
            class="alert-warning"
          >
            Please enter your last name
          </div>
        </mat-form-field>
        <mat-form-field class="inquiry-field">
          <input
            matInput
            required
            type="email"
            placeholder="Email address"
            formControlName="emailField"
            name="emailField"
            (change)="userDataChanged('email', $event)"
          />
          <div
            *ngIf="
              !form.controls.emailField.valid &&
              (form.controls.emailField.touched || submitted)
            "
            class="alert-warning"
          >
            Please enter
            <span *ngIf="this.form.controls.emailField.errors.pattern"
              >a valid</span
            >
            <span *ngIf="this.form.controls.emailField.errors.required"
              >contact</span
            >
            email
          </div>
        </mat-form-field>
        <mat-form-field class="inquiry-field">
          <input
            matInput
            required
            placeholder="Phone number"
            formControlName="phoneField"
            name="phoneField"
            (change)="userDataChanged('phone', $event)"
          />

          <div
            *ngIf="
              !form.controls.phoneField.valid &&
              (form.controls.phoneField.touched || submitted)
            "
            class="alert-warning"
          >
            Please enter
            <span *ngIf="this.form.controls.phoneField.errors.pattern"
              >a valid</span
            >
            <span *ngIf="this.form.controls.phoneField.errors.required"
              >contact</span
            >
            phone
          </div>
        </mat-form-field>
      </div>

      <div class="form-section">
        <h4 class="new-brand-color">Where are you going?</h4>
        <mat-form-field *ngIf="mode === 'Inquiry'" class="inquiry-field">
          <input
            matInput
            placeholder="Property name"
            formControlName="propertyField"
            name="propertyField"
          />
        </mat-form-field>
        <mat-form-field class="inquiry-field">
          <mat-select
            panelClass="location-select"
            placeholder="Destination"
            formControlName="location"
            name="locationValue"
            class="date-input brand-dark-color"
            [compareWith]="compareLocation"
          >
            @for (group of locationGroups; track group.name) {
            <mat-optgroup [label]="group.name" [disabled]="group.disabled">
              @for (location of group.locations; track location.value) {
              <mat-option [value]="location.value">
                {{
                  location.additionalValue
                    ? location.additionalValue
                    : location.viewValue
                }}
              </mat-option>
              }
            </mat-optgroup>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-section">
        <h4 class="new-brand-color">Dates</h4>
        <mat-form-field class="inquiry-field">
          <input
            matInput
            readonly
            required
            [matDatepicker]="arrival"
            placeholder="Arrival date"
            [matDatepickerFilter]="filterFunction"
            formControlName="arrivalField"
            name="arrivalField"
            [min]="minDate"
            (click)="arrival.open()"
            (dateChange)="arrivalChanged($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="arrival"
          ></mat-datepicker-toggle>
          <mat-datepicker [touchUi]="isMobile" #arrival></mat-datepicker>
          <div
            *ngIf="
              !form.controls.arrivalField.valid &&
              (form.controls.arrivalField.touched || submitted)
            "
            class="alert-warning"
          >
            Please select arrival date.
          </div>
        </mat-form-field>
        <mat-form-field class="inquiry-field">
          <input
            matInput
            readonly
            required
            [matDatepicker]="departure"
            [matDatepickerFilter]="filterFunction"
            placeholder="Departure date"
            formControlName="departureField"
            name="departureField"
            [min]="maxDate"
            (click)="departure.open()"
            (dateChange)="departureChanged($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="departure"
          ></mat-datepicker-toggle>
          <mat-datepicker [touchUi]="isMobile" #departure></mat-datepicker>
          <div
            *ngIf="
              !form.controls.departureField.valid &&
              (form.controls.departureField.touched || submitted)
            "
            class="alert-warning"
          >
            Please select departure date.
          </div>
        </mat-form-field>

        <mat-slide-toggle formControlName="flexibilityField" value="primary">
          Are your dates flexible?
        </mat-slide-toggle>
      </div>

      <div class="form-section">
        <h4 class="new-brand-color">Who is coming?</h4>
        <twbooking-plus-minus-input
          fieldName="Adults"
          fieldHint="Ages 13 or above"
          min="1"
          max="50"
          formControlName="adults"
        >
        </twbooking-plus-minus-input>
        <twbooking-plus-minus-input
          fieldName="Children"
          fieldHint="Ages 2-12"
          min="0"
          max="50"
          formControlName="children"
        >
        </twbooking-plus-minus-input>
        <twbooking-plus-minus-input
          fieldName="Infants"
          fieldHint="Under 2"
          min="0"
          max="50"
          formControlName="infants"
        >
        </twbooking-plus-minus-input>
        <twbooking-plus-minus-input
          fieldName="Pets"
          min="0"
          max="10"
          formControlName="pets"
        >
        </twbooking-plus-minus-input>
        <twbooking-plus-minus-input
          fieldName="Number of bedrooms"
          min="0"
          max="10"
          formControlName="bedroomsField"
        >
        </twbooking-plus-minus-input>
      </div>
      <div class="form-section budget-section">
        <h4 class="new-brand-color">
          What do you wish to spend on your accommodation?
        </h4>
        <mat-slider
          class="inquiry-field"
          aria-label="price"
          [min]="minPrice"
          [max]="maxPrice"
          step="500"
        >
          <input
            matSliderThumb
            formControlName="priceAdditional"
            #slider
            (input)="priceSlided($event)"
          />
        </mat-slider>
        <div class="scale-indicator">
          <span class="scale-mark" *ngFor="let mark of scaleMarks">{{
            mark
          }}</span>
        </div>
        <div class="budget-wrapper hint-border">
          <div class="label-like hint-color">Max price</div>

          <div class="row-aligned">
            <span>$</span>
            <input
              matInput
              formControlName="priceField"
              name="priceField"
              twbookingCurrencyFormatter
              (change)="priceInputChanged($event)"
            />
          </div>
        </div>
      </div>
      <div class="form-section">
        <p class="new-brand-color">Additional questions or comments</p>

        <mat-form-field class="inquiry-field" [floatLabel]="'never'">
          <textarea
            matInput
            rows="3"
            type="text"
            formControlName="additionalField"
            name="additionalField"
            (click)="checkPlaceHolder()"
            (blur)="checkPlaceHolder()"
            placeholder=" {{ commentPlaceholder }} "
          ></textarea>
        </mat-form-field>
        <mat-form-field
          *ngIf="favorites && favorites.length"
          class="inquiry-field use-checkboxes"
          matTooltip="Please select the units from your favorites that you are interested in to help our personal travel designers find the best options for you"
        >
          <mat-select
            placeholder="Alternative options to check"
            formControlName="alternativeUnitsField"
            name="alternativeUnitsField"
            multiple
          >
            @for (favorite of favorites; track favorite.Name ){
            <mat-option [value]="favorite">
              {{ favorite.Name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <span
        *ngIf="mode !== 'Inquiry' && submitted && !form.valid && warningMessage"
      >
        <p class="alert-warning">{{ warningMessage }}</p>
        <br />
      </span>
      <button
        *ngIf="mode !== 'Inquiry'"
        mat-button
        class="new-brand-button fixed-content"
        type="submit"
      >
        Submit inquiry
      </button>
      <br />
    </form>
  </div>
</div>
