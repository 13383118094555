import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UnitsService } from '../../services/units.service';
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';

import { SearchParamsService } from 'src/app/services/search-params.service';
import { EventService } from 'src/app/services/event.service';
export const max = Number.MAX_VALUE;
import * as moment from 'moment';
import {
  amenities,
  isLocationExcluded,
} from '../../store/models/search-options';
import { ComplexSummary } from 'src/app/store/models/complex.model';
import { Title, Meta } from '@angular/platform-browser';
import { SearchDialogComponent } from 'src/app/shared/search-dialog/search-dialog.component';
import { trigger } from '@angular/animations';
import { fadeIn, fadeOut } from 'src/app/utils/animations/fade-animations';
import { SearchEventParamsGA } from '../../store/models/events-ga.model';
import { PageEvent } from '@angular/material/paginator';
import { ViewportScroller } from '@angular/common';
import { columnNumByBreakpoint } from 'src/app/store/models/responsiveness.model';

@Component({
  selector: 'twbooking-unit-search-listing',
  templateUrl: './unit-search-listing.component.html',
  styleUrls: ['./unit-search-listing.component.scss'],
  animations: [trigger('fadeOut', fadeOut()), trigger('fadeIn', fadeIn())],
})
export class UnitSearchListingComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild('pageStart') pageStart: ElementRef;
  units: any[] = [];
  columnNum: number;
  mapsAvailable = false;

  shouldScroll = false;

  pageSubscription: Subscription;

  pageIndex = 0;
  complexPageSize = 2;
  pageEvent: BehaviorSubject<PageEvent>;

  rowHeightFixed = null;

  done = false;

  savedSearch: number | null = null;

  myStay = 0;

  subMedia: Subscription;
  params: any;

  unitListFiltered: any[] = [];
  unitListFilteredNotMm: any[] = [];

  unitDisplay: Observable<any> | null;

  sleepsCount = 0;
  bedroomCount = 0;

  searchQuery: any = {};
  complexQuery: any = {};

  idList: any[] = [];
  totalCount: number;
  pageSize = 12;
  unitLimit = 0;
  alreadyRequested = 0;

  complexTotal = -1; //total number of complexes. -1 means that it is not loaded yet.

  filteredListLength: number;

  mmOnly = false;
  loadingFeaturedComplexes = true;
  loadingHomes = true;
  noResult = false;
  featuredComplexList: ComplexSummary[] = [null, null, null, null];
  enableComplexes = true;
  complexLimit = 4; //shuld be >=4 to have all preloadings working
  complexOffset = 0;
  includeComplexes = true;

  // Submit data from search tom property view details
  searchQueryEmitter: EventEmitter<any> = new EventEmitter(null);
  readonly amenities = amenities;
  selectedAmenities = [];
  locationMode: 'classic' | 'mm' = 'classic';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private unitService: UnitsService,
    private cdref: ChangeDetectorRef,
    private searchService: SearchParamsService,
    public dialog: MatDialog,
    private eventService: EventService,
    private titleService: Title,
    private metaTagService: Meta,
    private viewportScroller: ViewportScroller
  ) {
    if (window.location.hostname.includes('mountainmanagement.com')) {
      this.mmOnly = true;
      this.locationMode = 'mm';
    }
    this.columnNum = 2;
    this.pageEvent = new BehaviorSubject<PageEvent>({
      pageIndex: 0,
      previousPageIndex: 0,
      pageSize: this.columnNum,
      length: 10000,
    });
    this.subMedia = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        for (const breakpoint of Object.keys(columnNumByBreakpoint)) {
          if (result.breakpoints[breakpoint]) {
            this.columnNum = columnNumByBreakpoint[breakpoint];
            break;
          }
        }
        this.pageEvent.next({
          pageIndex: 0,
          previousPageIndex: 0,
          pageSize: this.columnNum,
          length: 10000,
        });
      });
  }

  getMoreUnits() {
    if (!this.done) {
      this.getMore();
    }
  }

  findAmenity(selectedAmenity) {
    return amenities.find((amenity) => amenity.value === selectedAmenity);
  }

  ngOnInit() {
    this.done = false;
    this.savedSearch = null;
    this.enableComplexes = true;
    if (window.location.hostname.includes('thetravelwhisperer')) {
      this.titleService.setTitle('The Travel Whisperer');
      this.metaTagService.updateTag({
        name: 'description',
        content: 'The Travel Whisperer',
      });
      this.mmOnly = false;
    } else if (window.location.hostname.includes('mountainmanagement.com')) {
      this.titleService.setTitle('Mountain Management');
      this.metaTagService.updateTag({
        name: 'description',
        content: 'Mountain Management',
      });
      this.mmOnly = true;
    } else if (window.location.hostname.includes('vacationsbylaurie')) {
      this.titleService.setTitle('Vacations by Laurie');
      this.metaTagService.updateTag({
        name: 'description',
        content: 'Vacations by Laurie',
      });
      this.mmOnly = false;
    }
    this.featuredComplexList = [null, null, null, null];
    this.columnNum = this.columnNum || 2;

    this.route.queryParams.subscribe((params: Params) => {
      this.done = false;
      this.mapsAvailable = false;

      this.featuredComplexList = [null, null, null, null];
      this.params = params;
      // Defaults to 0 if no query param provided.
      this.searchQuery = {};
      this.sleepsCount = 0;
      this.totalCount = 0;
      this.unitLimit = 0;
      this.alreadyRequested = 0;
      this.unitDisplay = null;

      let gaSearch: SearchEventParamsGA = {};

      if (Object.keys(params).length === 0) {
      } else {
        this.selectedAmenities = [];
        for (const key in params) {
          if (key.startsWith('amenity')) {
            this.searchQuery[key] = params[key];
            let amenity = this.findAmenity(params[key]);
            this.selectedAmenities.push({ key: key, ...amenity });
          }
        }

        gaSearch = {};

        if (params.UnitName) {
          this.searchQuery = { UnitName: params.UnitName, ...this.searchQuery };
        }
        if (params.Adults) {
          this.searchQuery = { Adults: params.Adults, ...this.searchQuery };
          this.sleepsCount += +params.Adults;
        }
        if (params.Children) {
          this.searchQuery = { Children: params.Children, ...this.searchQuery };
          this.sleepsCount += +params.Children;
        }
        if (this.sleepsCount > 0) {
          this.searchQuery = {
            SleepsCount: this.sleepsCount,
            ...this.searchQuery,
          };
        }

        if (params.rateTop && params.rateBottom) {
          this.searchQuery = { rateTop: params.rateTop, ...this.searchQuery };
          this.searchQuery = {
            rateBottom: params.rateBottom,
            ...this.searchQuery,
          };
        }

        if (params.Country) {
          this.searchQuery = { Country: params.Country, ...this.searchQuery };
        }

        if (params.Village) {
          this.searchQuery = { Village: params.Village, ...this.searchQuery };
          this.mapsAvailable = true;
        }

        if (params.StartDate && params.EndDate) {
          const today = moment.utc(new Date());
          const arrival = moment.utc(params.StartDate, 'YYYY-MM-DD');
          const departure = moment.utc(params.EndDate, 'YYYY-MM-DD');
          if (arrival.isAfter(today) && departure.isAfter(arrival)) {
            this.myStay = moment.duration(departure.diff(arrival)).asDays();
            this.searchQuery = {
              StartDate: params.StartDate,
              EndDate: params.EndDate,
              myStay: this.myStay,
              ...this.searchQuery,
            };
          }
        }
        if (params.Bedrooms) {
          this.bedroomCount = params.Bedrooms;
          this.searchQuery = { Bedrooms: params.Bedrooms, ...this.searchQuery };
        }
        if (params.PetFriendly) {
          this.searchQuery = {
            PetFriendly: params.PetFriendly,
            ...this.searchQuery,
          };
        }

        if (params.DisplayRates) {
          this.searchQuery = {
            DisplayRates: params.DisplayRates,
            ...this.searchQuery,
          };
        }

        if (params.PropertyType) {
          if (params.PropertyType !== 'All') {
            this.searchQuery = {
              PropertyType: params.PropertyType,
              ...this.searchQuery,
            };
          }
        }
        if (params.PropertyCollection) {
          if (params.PropertyCollection !== 'All') {
            this.searchQuery = {
              PropertyCollection: params.PropertyCollection,
              ...this.searchQuery,
            };
          }
        }

        gaSearch = { ...this.searchQuery };
        if (params.City) {
          this.searchQuery = { City: params.City, ...this.searchQuery };
        }
        if (params.State) {
          this.searchQuery = { State: params.State, ...this.searchQuery };
        }

        if (params.Destination) {
          const tmp = params.Destination.split(', ');
          if (tmp && tmp.length && tmp.length > 1) {
            const City = params.Destination.split(', ')[0];
            const State = params.Destination.split(', ')[1];
            this.searchQuery = {
              City: City,
              State: State,
              ...this.searchQuery,
            };
          }
        }
        if (this.searchQuery.City && this.searchQuery.State) {
          this.mapsAvailable = !isLocationExcluded(
            this.searchQuery.City,
            this.searchQuery.State
          );
          gaSearch = {
            Destination: this.searchQuery.City + ', ' + this.searchQuery.State,
            ...gaSearch,
          };
        } else {
          this.mapsAvailable = false;
        }
        //Do not include amenities and MarketingHeadline and WholesalePartner at the google search event

        if (params.includeComplexes == 'false') {
          this.includeComplexes = false;
        } else {
          this.includeComplexes = true;
        }

        if (params.HotTub) {
          this.searchQuery = {
            HotTub: true,
            ...this.searchQuery,
          };
        }
        if (params.SkiIn) {
          this.searchQuery = {
            SkiIn: true,
            ...this.searchQuery,
          };
        }
        if (params.MarketingHeadline) {
          this.searchQuery = {
            MarketingHeadline: params.MarketingHeadline,
            ...this.searchQuery,
          };
        }
        if (params.WholesalePartner) {
          this.searchQuery = {
            WholesalePartner: params.WholesalePartner,
            ...this.searchQuery,
          };
        }

        this.eventService.sendSearchEventGA(gaSearch);
      }

      this.complexQuery = {
        isComplex: true,
        ...this.searchQuery,
      };

      //save search to ga

      this.searchService.update(this.searchQuery);
      if (params.searchId) {
        this.savedSearch = params.searchId;
      } else {
        this.savedSearch = null;
      }
      this.shouldScroll = true;

      if (
        params.enableComplexes === false ||
        params.enableComplexes === 'false' ||
        this.savedSearch
      ) {
        this.loadingFeaturedComplexes = false;
        this.enableComplexes = false;
        this.unitSearch();
      } else {
        this.loadingHomes = true;
        this.loadingFeaturedComplexes = true;
        this.enableComplexes = true;
        if (!this.mmOnly && this.includeComplexes) {
          this.complexTotal = -1;
          this.featuredComplexList = [null, null, null, null];
          this.pageEvent.next({
            pageIndex: 0,
            previousPageIndex: 0,
            pageSize: this.columnNum,
            length: 10000,
          });
          this.complexSearch(this.complexLimit, this.complexOffset); //NO complex for MM
        } else {
          this.loadingFeaturedComplexes = false;
          this.complexTotal = 0;
          this.pageEvent.next({
            pageIndex: 0,
            previousPageIndex: 0,
            pageSize: this.columnNum,
            length: 0,
          });
        }
        this.unitSearch();
      }
    });

    this.pageSubscription = this.pageEvent.subscribe((pageevent) => {
      if (pageevent.length > 0) {
        if (this.pageIndex + 1 == pageevent.pageIndex) {
          this.nextFeaturedComplexes();
        } else if (this.pageIndex - 1 > pageevent.pageIndex) {
          this.prevFeaturedComplexes(pageevent.pageIndex);
        } else if (this.pageIndex !== pageevent.pageIndex) {
          this.loadSpecificComplexPage(
            pageevent.pageIndex * pageevent.pageSize
          );
        }
        this.pageIndex = pageevent.pageIndex;
        this.complexPageSize = pageevent.pageSize;
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this.shouldScroll) {
      console.log('Scrolling to top using ViewportScroller');

      // Wait for the DOM to settle before scrolling
      setTimeout(() => {
        this.viewportScroller.scrollToPosition([0, 0]); // Scroll to top [x, y] = [0, 0]
        console.log('Scrolled to top');
      }, 100); // Adjust the delay as necessary to ensure content is fully loaded

      this.shouldScroll = false; // Reset the scroll flag
    }
  }

  loadSpecificComplexPage(offset) {
    if (offset > this.complexOffset) {
      this.complexSearch(this.complexLimit, offset);
    }
  }

  preloadNextComplexes() {
    this.complexOffset = this.complexOffset + this.complexLimit;
    if (this.complexOffset < this.complexTotal) {
      let preloadedAlready = this.checkIfPreloaded(this.complexOffset); //Could be preloaded if user requested specific page
      if (!preloadedAlready) {
        this.complexSearch(this.complexLimit, this.complexOffset);
      }
    }
  }

  checkIfPreloaded(offset): boolean {
    let preloaded = true;
    for (
      let i = offset;
      i < offset + this.complexLimit && i < this.complexTotal;
      i++
    ) {
      if (!this.featuredComplexList[i]) {
        preloaded = false;
        break;
      }
    }

    return preloaded;
  }

  complexSearch(limit: number, offset: number) {
    let adjustedQuery = this.parseAmenities(this.searchQuery);
    this.unitService
      .complexSearch(
        { featured: true, ...adjustedQuery }, //Featured is always true. It is legacy code, before we had Featured complexes at the top, and not featured at the bottom of search results
        this.mmOnly,
        limit,
        offset
      )
      .subscribe((resp: any) => {
        if (resp) {
          this.loadingFeaturedComplexes = false;

          if (resp.result && resp.result.startsWith('NO')) {
            if (offset === 0) {
              this.featuredComplexList = [];
              this.complexTotal = 0;
              this.pageEvent.next({
                pageIndex: 0,
                previousPageIndex: 0,
                pageSize: this.columnNum,
                length: 0,
              });
            }
            return;
          }
          if (resp.error && resp.error === 'TooManyRequests') {
            console.log('TooManyRequests!');
            throw new Error('TooManyRequests error in search listing');
          }
          if (offset === 0 && resp.total >= 0) {
            this.complexTotal = resp.total;
            this.pageEvent.next({
              pageIndex: 0,
              previousPageIndex: 0,
              pageSize: this.columnNum,
              length: this.complexTotal,
            });
          }

          if (offset === 0 && resp.complexes && !resp.total) {
            this.complexTotal = resp.complexes.length;
            this.pageEvent.next({
              pageIndex: 0,
              previousPageIndex: 0,
              pageSize: this.columnNum,
              length: this.complexTotal,
            });

            console.error(
              `Error in complexSearch -> complexTotalCount for ${adjustedQuery} mm=${this.mmOnly}`
            );
          }

          if (resp.complexes) {
            this.addComplexes(resp.complexes, offset);
          }
        }
      });
  }

  addComplexes(complexes: ComplexSummary[], offset: number) {
    /* This function adds complexes to the featuredComplexList based on the offset value. */
    if (offset === 0) {
      this.featuredComplexList = [];
      for (let i = 0; i < this.complexTotal; i++) {
        this.featuredComplexList.push(null);
      }

      this.populateFeaturedList(complexes, offset);
    } else {
      this.populateFeaturedList(complexes, offset);
    }
  }

  populateFeaturedList(complexes: ComplexSummary[], offset: number) {
    for (let i = 0; i < complexes.length; i++) {
      this.featuredComplexList[offset + i] = complexes[i];
    }
  }

  parseAmenities(params: any): any {
    let resultQuery = {};
    const amenities: string[] = [];

    for (const key in params) {
      if (params.hasOwnProperty(key) && key.startsWith('amenity')) {
        amenities.push(params[key]);
      } else {
        resultQuery[key] = params[key];
      }
    }
    if (amenities && amenities.length > 0) {
      resultQuery['amenities'] = amenities;
    }

    return resultQuery;
  }

  unitSearch() {
    if (this.savedSearch) {
      this.enableComplexes = false;
      this.unitService
        .openSearch(this.savedSearch, false, this.pageSize, this.unitLimit)
        .subscribe((resp: any) => {
          this.loadingHomes = false;
          this.parseResp(resp);
        });
    } else {
      if (this.searchQuery === undefined) {
        this.searchQuery = {};
        throw new Error('Undefined this.searchQuery in unit search listing ');
      }

      let adjustedQuery: any = {};
      if (Object.keys(this.searchQuery).length === 0) {
        adjustedQuery = {};
      } else {
        adjustedQuery = this.parseAmenities(this.searchQuery);
        let queryForAnalytics = { ...adjustedQuery, mapSearch: false };
        this.eventService.triggerSearchEvent(queryForAnalytics, this.mmOnly);
      }

      this.unitService
        .unitSearch(
          adjustedQuery,
          this.mmOnly,
          this.router.url,
          this.pageSize,
          this.unitLimit
        )
        .subscribe((resp: any) => {
          this.loadingHomes = false;
          this.parseResp(resp);
        });
    }
  }

  parseResp(resp) {
    if (resp) {
      if (resp.result && resp.result.startsWith('NO')) {
        this.noResult = true;

        this.done = true;

        return;
      }

      if (resp.error && resp.error === 'TooManyRequests') {
        console.log('TooManyRequests!');
        throw new Error('TooManyRequests error in search listing');
      }
      this.unitLimit += this.pageSize;
      if (resp.units) {
        this.totalCount = resp.units.length;
        this.noResult = false;
      }

      if (this.totalCount <= 0) {
        this.noResult = true;

        this.done = true;
      }

      if (this.totalCount < this.pageSize) {
        this.done = true;
      }

      if (this.unitDisplay) {
        let unitList: any[];
        this.unitDisplay.subscribe((units) => {
          unitList = _.union(units, resp.units);
        });
        this.unitDisplay = of(unitList);
      } else {
        this.unitDisplay = of(resp.units);
      }
      if (this.totalCount === 1) {
        const unitId = resp.units[0].UnitSlug__c;
        if (resp.units[0].isComplex) {
          this.router.navigate(['/complex-details', unitId], {
            queryParams: this.searchQuery,
          });
        } else {
          this.router.navigate(['/unit-details', unitId], {
            queryParams: this.searchQuery,
          });
        }
      }
      this.cdref.detectChanges();
      return;
    }
  }

  getMore() {
    if (this.alreadyRequested === this.unitLimit) {
      console.log(`this page ${this.unitLimit} is requested already. skipping`);
      return;
    }
    this.alreadyRequested = this.unitLimit;
    console.log('requesting ', this.alreadyRequested);
    if (this.savedSearch) {
      this.unitService
        .openSearch(this.savedSearch, false, this.pageSize, this.unitLimit)
        .subscribe((resp: any) => {
          if (resp) {
            if (resp.error && resp.error === 'TooManyRequests') {
              console.log('TooManyRequests!');
              throw new Error('TooManyRequests error in search listing');
            }
            if (resp.units) {
              this.unitLimit += this.pageSize;
              if (this.unitDisplay) {
                let unitList: any[];
                this.unitDisplay.subscribe((units) => {
                  unitList = _.union(units, resp.units);
                });
                this.unitDisplay = of(unitList);
              } else {
                this.unitDisplay = of(resp.units);
              }
            } else {
              this.done = true;
            }
            this.cdref.detectChanges();
          }
          return;
        });
    } else {
      let adjustedQuery = this.parseAmenities(this.searchQuery);
      this.unitService
        .unitSearch(
          adjustedQuery,
          this.mmOnly,
          this.router.url,
          this.pageSize,
          this.unitLimit
        )
        .subscribe((resp: any) => {
          if (resp) {
            if (resp.error && resp.error === 'TooManyRequests') {
              console.log('TooManyRequests!');
              throw new Error('TooManyRequests error in search listing');
            }
            if (resp.units) {
              this.unitLimit += this.pageSize;
              if (this.unitDisplay) {
                let unitList: any[];
                this.unitDisplay.subscribe((units) => {
                  unitList = _.union(units, resp.units);
                });
                this.unitDisplay = of(unitList);
              } else {
                this.unitDisplay = of(resp.units);
              }
            } else {
              this.done = true;
            }
            this.cdref.detectChanges();
          }
          return;
        });
    }
  }

  AfterViewInit() {
    this.cdref.detectChanges();
    this.searchQueryEmitter.emit(this.searchQuery);
  }

  ngOnDestroy() {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
    if (this.pageSubscription) {
      this.pageSubscription.unsubscribe();
    }
  }

  navigateToMaps() {
    this.router.navigate(['map-search'], { queryParams: this.searchQuery });
  }

  onScroll(e) {
    if (!this.done) {
      this.getMore();
    }
  }

  scrollToElement($element): void {
    console.log('scrollToElement');
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  removeAmenity(key: string) {
    const index = this.selectedAmenities.findIndex(
      (amenity) => amenity.key === key
    );
    if (index !== -1) {
      this.selectedAmenities.splice(index, 1);
    }
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {},
      panelClass: 'search-dialog-container',
      maxWidth: '96vw',
      maxHeight: '85vh',
      width: 'auto',
    });
  }

  identifyUnit(index: number, unit: any) {
    return unit ? unit.externalId : null;
  }

  nextFeaturedComplexes() {
    this.preloadNextComplexes();
  }

  prevFeaturedComplexes(pageIndex) {
    this.loadSpecificComplexPage(pageIndex * this.pageEvent.value.pageSize);
  }
}
