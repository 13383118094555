export const max = Number.MAX_VALUE;

export interface Villages {
  value: string;
  viewValue: string;
  location: string;
}

export const amenities = [{ value: 'skiin', viewValue: 'Ski-in Ski-out' }];

export const amenitiesFull = [
  { value: 'gamesroom', viewValue: 'Games room' }, //todo check Games Room
  { value: 'pool', viewValue: 'Pool' },
  { value: 'bar', viewValue: 'Bar' },
  { value: 'ac', viewValue: 'Air Conditioning' },
  { value: 'gym', viewValue: 'Gym' },
  { value: 'skiin', viewValue: 'Ski-in Ski-out' },
  { value: 'view', viewValue: 'Mountain Views' },
  { value: 'cinema', viewValue: 'Cinema' },
  { value: 'hottub', viewValue: 'Hot Tub' }, //TODO check Hot tub
  { value: 'fireplace', viewValue: 'Fireplace' },
  { value: 'bbq', viewValue: 'BBQ Grill' },
  { value: 'shuttle', viewValue: 'Shuttle to Lifts' },
  { value: 'jacuzzi', viewValue: 'Jacuzzi Tub' },
  { value: 'washer', viewValue: 'Washer/Dryer' },
  { value: 'walk', viewValue: 'Walk to Lifts' },
  { value: 'sauna', viewValue: 'Sauna' },
  { value: 'steam', viewValue: 'Steam Room' },
  { value: 'tennis', viewValue: 'Tennis Court' },
  { value: 'spa', viewValue: 'Day Spa' },
  { value: 'dining', viewValue: 'On-Site Dining' },
  { value: 'parking', viewValue: 'Valet Parking' },
];

export const VillageOptions: Villages[] = [
  { value: '', viewValue: 'All Villages', location: '' },
  {
    value: 'Breckenridge',
    viewValue: 'Breckenridge',
    location: 'Breckenridge',
  },
  {
    value: 'Beaver Creek',
    viewValue: 'Beaver Creek',
    location: 'Beaver Creek',
  },
  {
    value: 'Cordillera',
    viewValue: 'Cordillera',
    location: 'Beaver Creek',
  },
  {
    value: 'Bachelor Gulch',
    viewValue: 'Bachelor Gulch',
    location: 'Beaver Creek',
  },
  { value: 'Avon', viewValue: 'Avon', location: 'Beaver Creek' },
  {
    value: 'Arrowhead',
    viewValue: 'Arrowhead',
    location: 'Beaver Creek',
  },
  {
    value: 'Mountain Village',
    viewValue: 'Mountain Village',
    location: 'Telluride',
  },
  {
    value: 'Telluride',
    viewValue: 'Telluride',
    location: 'Telluride',
  },
  {
    value: 'Snowmass',
    viewValue: 'Snowmass Village',
    location: 'Aspen Snowmass',
  },
  {
    value: 'Buttermilk',
    viewValue: 'Buttermilk Village',
    location: 'Aspen Snowmass',
  },
  {
    value: 'Aspen Highlands',
    viewValue: 'Aspen Highlands',
    location: 'Aspen Snowmass',
  },
  {
    value: 'Aspen Mountain',
    viewValue: 'Aspen Mountain',
    location: 'Aspen Snowmass',
  },
  { value: 'Vail', viewValue: 'Vail', location: 'Vail' },
  { value: 'Lionshead', viewValue: 'Lionshead', location: 'Vail' },
  {
    value: 'Crested Butte',
    viewValue: 'Crested Butte',
    location: 'Crested Butte',
  },
  {
    value: 'Mt. Crested Butte',
    viewValue: 'Mt. Crested Butte',
    location: 'Crested Butte',
  },
  { value: 'Canyons', viewValue: 'Canyons', location: 'Park City' },
  { value: 'Park City', viewValue: 'Park City', location: 'Park City' },
  {
    value: 'Mountain Village',
    viewValue: 'Mountain Village',
    location: 'Park City',
  },
  { value: 'Snow Park', viewValue: 'Snow Park', location: 'Deer Valley' },
  { value: 'Empire Pass', viewValue: 'Empire Pass', location: 'Deer Valley' },
  { value: 'Silver Lake', viewValue: 'Silver Lake', location: 'Deer Valley' },
];

export const locations: string[] = [
  'Beaver Creek, Colorado',
  'Vail, Colorado',
  'Breckenridge, Colorado',
  'Park City, Utah',
  'Deer Valley, Utah',
  'Aspen Snowmass, Colorado',
  'Steamboat Springs, Colorado',
  'Telluride, Colorado',
  'Crested Butte, Colorado',
];

export const locationsUnified: { City: string; State: string }[] = [
  { State: 'Colorado', City: 'Beaver Creek' },
  { State: 'Colorado', City: 'Vail' },
  { State: 'Colorado', City: 'Breckenridge' },
  { State: 'Colorado', City: 'Aspen Snowmass' },
  { State: 'Colorado', City: 'Snowmass' },
  { State: 'Colorado', City: 'Steamboat Springs' },
  { State: 'Colorado', City: 'Telluride' },
  { State: 'Colorado', City: 'Crested Butte' },
  { State: 'Utah', City: 'Park City' },
  { State: 'Utah', City: 'Deer Valley' },
  { State: 'Montana', City: 'Big Sky' },
  { State: 'Wyoming', City: 'Jackson Hole' },
  { State: 'Canada', City: 'Whistler Blackcomb' },
];

export interface LocationGroup {
  name: string;
  value: string;
  locations: {
    value: { State: string; City: string };
    viewValue: string;
    additionalValue?: string;
  }[];
}

export interface LocationGroupVillages {
  name: string;

  villages: {
    value: string;
    viewValue: string;
  }[];
}

export const beaverCreekGroups: LocationGroup[] = [
  {
    name: 'ALL LOCATIONS',
    value: '',
    locations: [
      {
        value: { State: '', City: '' },
        viewValue: 'All Locations',
        additionalValue: "Haven't decided yet",
      },
    ],
  },
  {
    name: 'COLORADO',
    value: 'CO',
    locations: [
      {
        value: { State: 'Colorado', City: 'Beaver Creek' },
        viewValue: 'Beaver Creek, Colorado',
      },
    ],
  },
];

export const beaverCreekVillages: LocationGroupVillages[] = [
  {
    name: 'Beaver Creek, Colorado',
    villages: [
      { value: '', viewValue: 'All Villages' },
      {
        value: 'Beaver Creek',
        viewValue: 'Beaver Creek',
      },
      {
        value: 'Cordillera',
        viewValue: 'Cordillera',
      },
      {
        value: 'Bachelor Gulch',
        viewValue: 'Bachelor Gulch',
      },
      {
        value: 'Avon',
        viewValue: 'Avon',
      },
      {
        value: 'Arrowhead',
        viewValue: 'Arrowhead',
      },
    ],
  },
];

export const locationGroups: LocationGroup[] = [
  {
    name: 'ALL LOCATIONS',
    value: '',
    locations: [
      {
        value: { State: '', City: '' },
        viewValue: 'All Locations',
        additionalValue: "Haven't decided yet",
      },
    ],
  },

  {
    name: 'COLORADO',
    value: 'CO',
    locations: [
      {
        value: { State: 'Colorado', City: 'Beaver Creek' },
        viewValue: 'Beaver Creek, Colorado',
      },
      {
        value: { State: 'Colorado', City: 'Vail' },
        viewValue: 'Vail, Colorado',
      },
      {
        value: { State: 'Colorado', City: 'Breckenridge' },
        viewValue: 'Breckenridge, Colorado',
      },
      {
        value: { State: 'Colorado', City: 'Aspen Snowmass' },
        viewValue: 'Aspen Snowmass, Colorado',
      },
      {
        value: { State: 'Colorado', City: 'Steamboat Springs' },
        viewValue: 'Steamboat Springs, Colorado',
      },
      {
        value: { State: 'Colorado', City: 'Telluride' },
        viewValue: 'Telluride, Colorado',
      },
      {
        value: { State: 'Colorado', City: 'Crested Butte' },
        viewValue: 'Crested Butte, Colorado',
      },
    ],
  },
  {
    name: 'UTAH',
    value: 'UT',
    locations: [
      {
        value: { State: 'Utah', City: 'Park City' },
        viewValue: 'Park City, Utah',
      },
      {
        value: { State: 'Utah', City: 'Deer Valley' },
        viewValue: 'Deer Valley, Utah',
      },
    ],
  },
  {
    name: 'MONTANA',
    value: 'MONTANA',
    locations: [
      {
        value: { State: 'Montana', City: 'Big Sky' },
        viewValue: 'Big Sky, Montana',
      },
    ],
  },
  {
    name: 'WYOMING',
    value: 'WYOMING',
    locations: [
      {
        value: { State: 'Wyoming', City: 'Jackson Hole' },
        viewValue: 'Jackson Hole, Wyoming',
      },
    ],
  },
  {
    name: 'CANADA',
    value: 'CANADA',
    locations: [
      {
        value: { State: 'Canada', City: 'Whistler Blackcomb' },
        viewValue: 'Whistler Blackcomb, Canada',
      },
    ],
  },
];

export const mapExclusionList = [
  'Whistler Blackcomb, Canada',
  'Crested Butte, Colorado',
];

export function isLocationExcluded(city: string, state: string): boolean {
  if (city && state) {
    const location = `${city}, ${state}`;
    return mapExclusionList.includes(location);
  } else {
    return false;
  }
}

export const bedroomOptions = [
  { value: '1', viewValue: '1+' },
  { value: '2', viewValue: '2+' },
  { value: '3', viewValue: '3+' },
  { value: '4', viewValue: '4+' },
  { value: '5', viewValue: '5+' },
  { value: '6', viewValue: '6+' },
  { value: '7', viewValue: '7+' },
  { value: '8', viewValue: '8+' },
  { value: '9', viewValue: '9+' },
];

export const ratesOptions = [
  { value: { rateBottom: 0, rateTop: 500 }, viewValue: '$0 - $500' },
  { value: { rateBottom: 500, rateTop: 1000 }, viewValue: '$500 - $1000' },
  { value: { rateBottom: 1000, rateTop: 2500 }, viewValue: '$1000 - $2500' },
  { value: { rateBottom: 2500, rateTop: 5000 }, viewValue: '$2500 - $5000' },
  {
    value: { rateBottom: 5000, rateTop: 10000 },
    viewValue: '$5000 - $10000',
  },
  { value: { rateBottom: 10000, rateTop: max }, viewValue: '$10000+' },
];

export const propertyTypeOptions = [
  { value: 'All', viewValue: 'All' },
  { value: 'Condo', viewValue: 'Condo' },
  { value: 'Townhouse', viewValue: 'Townhouse' },
  { value: 'House', viewValue: 'House' },
  { value: 'Hotel', viewValue: 'Hotel' },
  // { value: 'Cabin', viewValue: 'Cabin' },
  { value: 'SingleFamilyHome', viewValue: 'Single Family Home' },
];

export const villagesDescription = [
  {
    value: 'Beaver Creek',
    description: 'The heartbeat of the Beaver Creek Resort',
  },
  {
    value: 'Bachelor Gulch',
    description:
      'Beaver Creek Resort’s most centrally located village is Bachelor Gulch',
  },
  {
    value: 'Arrowhead',
    description: 'Arrowhead is the westernmost gateway to Beaver Creek Resort',
  },
  {
    value: 'Vail',
    description:
      'Vail Village is home to dozens of hotels, shops, restaurants, bars and après hot spots',
  },
  {
    value: 'Lionshead',
    description:
      'Originally built in the late 60s, Lionshead Vail is completely closed off to cars',
  },
  /*{
    value: 'Golden Peak',
    description:
      'Golden Peak is a peaceful and convenient spot just 5 minutes walk east of Vail Village'
  },*/
  {
    value: 'Breckenridge',
    description: 'The old mining town of Breckenridge is a truly unique place',
  },
  {
    value: 'Park City',
    description:
      'The historic mining town of Park City is one of America’s unique mountain resorts',
  },
  {
    value: 'Canyons',
    description:
      'A family-style, guest-centric village that offers world-class accommodation',
  },
  {
    value: 'Snowmass',
    description: 'Snowmass is a great destination for both groups and families',
  },
  {
    value: 'Buttermilk',
    description: 'Buttermilk is actually Aspen Snowmass’ quieter mountain',
  },
];

export const propertyCollectionOptions = [
  { value: 'All', viewValue: 'All' },
  { value: 'Ultimate Collection', viewValue: 'Ultimate Collection' },
  {
    value: 'Friends and Family Collection',
    viewValue: 'Friends & Family Collection',
  },
  { value: 'Cozy Condo Collection', viewValue: 'Cozy Condo Collection' },
];

export const ourCollections = [
  {
    value: 'Ultimate Collection',
    viewValue: 'Ultimate Collection',
    ImageLink__c:
      'https://images.thetravelwhisperer.com/9PPQlyr8328AVqfdQKN5Ap7xHVY=/435x350/storage.googleapis.com/ttw-image-poc/MainPage/UltimateCollection.jpg',
    Description__c:
      'Our finest, most indulgent ski homes offering an elite, luxury skiing experience. Enjoy first class amenities, plenty of space and high-end finishes.',
  },
  {
    value: 'Friends and Family Collection',
    viewValue: 'Friends & Family Collection',
    ImageLink__c:
      'https://images.thetravelwhisperer.com/xlucrqkGsrsQf8o46EUPN_YmAXA=/435x350/storage.googleapis.com/ttw-image-poc/MainPage/FamilyFriendsCollection.jpg',
    Description__c:
      "Enjoy the delights of the mountains with those closest to you. Whether it's a family reunion or a get-together with friends from school or college, we'll find you the perfect home.",
  },
  {
    value: 'Cozy Condo Collection',
    viewValue: 'Cozy Condo Collection',
    ImageLink__c:
      'https://images.thetravelwhisperer.com/ZLY9POxPQUldORFhwWKVlRlhg50=/435x350/storage.googleapis.com/ttw-image-poc/MainPage/CozyCondoCollection.jpg',
    Description__c:
      ' Ideal for those traveling as a small group, our condos and apartments are ideal for those looking for a base with which to explore our resorts and beyond.',
  },
];

export interface SavedSearch {
  searchName: string; // saved search name
  ptdEmail: string;
  created: Date;
  lead?: string; // salesforce if of the lead
  opportunity?: string; // salesforce id of the opportunity
  start?: Date;
  end?: Date;
  myStay?: number;
  name?: string;
  bedrooms?: number;
  sleeps?: number;
  adults?: number;
  children?: number;
  minStay?: number;
  rateMin?: number;
  rateMax?: number;
  destination?: string;
  village?: string;
  dogsAllowed?: boolean;
  petsAllowed?: boolean;
  unitType?: string;
  propertyCollection?: string;
  propertyManager?: string;
  skiIn?: boolean;
  hotTub?: boolean;
  WholesalePartner?: string;
  MarketingHeadline?: string;
}
