import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { ApiService } from '../api.service';
import { environment } from '../../environments/environment';
import { takeWhile, take } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
declare let fbq: Function;
import { localhost, mainsite, mm, staging } from '../store/models/hostings';
import { DialogReplica } from 'src/app/store/models/chat.models';
import {
  BeginCheckoutParamsGA,
  BookingPaymentStatusGA,
  BookingSubmitPaymentGA,
  LeadSubmitParamsGA,
  QuoteParamsGA,
  SearchEventParamsGA,
  UserInfoGA,
  ViewItemParamsGA,
} from '../store/models/events-ga.model';
import { PurchaseParamsGA } from 'functions/src/ga4';
declare let gtag: Function;

export interface UserQuote {
  sessionId?: number;
  unit: string;
  arrivalDate: Date;
  departureDate: Date;
  adults: number;
  children: number;
  infants?: number;
  guests?: number;
  nights?: number;
  TotalCost: number;
  Rent: number;
  DiscountedRent?: number;
  Fees: number;
  Taxes: number;
  RefundableDeposit: number;
  explicit: boolean;
}

export class UserSearch {
  sessionId?: number;
  StartDate?: Date;
  EndDate?: Date;
  Adults?: number;
  Children?: number;
  Infants?: number;
  guests?: number;
  Destination?: string;
  rateTop?: number;
  rateBottom?: number;
  Bedrooms?: number;
  PetFriendly?: boolean;
  PropertyType?: string;
  PropertyCollection?: string;
  UnitName?: string;
  City?: string;
  State?: string;
  Village?: string;
  amenities?: string[];
  mmOnly: boolean;
  count?: number; //only for map search
  mapSearch?: boolean;
}

export class UserPropertyView {
  sessionId?: number;
  unit: string;
}

export class PaymentInitiated {
  sessionId?: number;
  paymentId: string;
  bookingId: string;
  amount: number;
  Name?: string;
}

export class UserComplexView {
  sessionId?: number;
  complex: string;
}

export class FavoritesView {
  sessionId?: number;
  sharelink: string;
}

export class GuestPortalView {
  sessionId?: number;
  page: string;
  bookingId?: string;
}

export class InitiateCheckout {
  sessionId?: number;
  unit?: string;
  unitType?: string;
  propertyCollection?: string;
  arrivalDate?: Date;
  departureDate?: Date;
  adults?: number;
  children?: number;
  infants?: number;
  pets?: number;
  guests?: number;
  nights?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  TotalCost?: number;
  Rent?: number;
  DiscountedRent?: number;
  Fees?: number;
  Taxes?: number;
  RefundableDeposit?: number;
  submitted: boolean;
}

export class Inquiry {
  sessionId?: number;
  unit?: string;
  arrivalDate?: Date;
  departureDate?: Date;
  adults?: number;
  children?: number;
  infants?: number;
  pets?: number;
  preferredDestination?: string;
  guests?: number;
  nights?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  flexibility: number;
  bedrooms: number;
  description: string;
  InquiryDate: Date;
  AlternativeUnits?: string;
  InquirySource__c: string;
  Budget__c: string;
}

export class Chat {
  sessionId?: number;
  chatSession?: string;
  link?: string;
  linkDescription?: string;
  replica: string;
  author: string; //User/Bot/PTD
}

export class PortalComponentRequest {
  sessionId: number;
  component: string;
  action: 'Requested' | 'Edited' | 'Cancelled';
  bookingId?: string;
  Name: string;
}

export class UserPartyMemberChange {
  sessionId: number;
  action: 'Added' | 'Edited' | 'Cancelled';
  bookingId?: string;
  Name: string;
}

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy {
  private quote: BehaviorSubject<UserQuote>;
  private search: BehaviorSubject<UserSearch>;
  private propertyView: BehaviorSubject<UserPropertyView>;
  private complexView: BehaviorSubject<UserComplexView>;
  private initiateCheckout: BehaviorSubject<InitiateCheckout>;
  private updateCheckout: BehaviorSubject<InitiateCheckout>;
  private initiateLodgingQuote: BehaviorSubject<InitiateCheckout>;
  private updateLodgingQuote: BehaviorSubject<InitiateCheckout>;
  private inquiry: BehaviorSubject<Inquiry>;
  private checkoutId: BehaviorSubject<number>;
  private chatReplica: BehaviorSubject<Chat>;
  private guestPortalView: BehaviorSubject<GuestPortalView>;
  private userInitiatePayment$: BehaviorSubject<PaymentInitiated>;
  private favoritesView$: BehaviorSubject<FavoritesView>;
  private portalComponentRequest: BehaviorSubject<PortalComponentRequest>;
  private userPartyMemberChange: BehaviorSubject<UserPartyMemberChange>;
  userSetGA = false;

  private testPixel;
  newsiteMode = false;
  matcher: MediaQueryList;

  constructor(
    public authService: AuthService,
    private apiService: ApiService,
    private mediaMatcher: MediaMatcher
  ) {
    this.quote = new BehaviorSubject<UserQuote>(null);
    this.search = new BehaviorSubject<UserSearch>(null);
    this.propertyView = new BehaviorSubject<UserPropertyView>(null);
    this.complexView = new BehaviorSubject<UserComplexView>(null);
    this.initiateCheckout = new BehaviorSubject<InitiateCheckout>(null);
    this.updateCheckout = new BehaviorSubject<InitiateCheckout>(null);
    this.initiateLodgingQuote = new BehaviorSubject<InitiateCheckout>(null);
    this.updateLodgingQuote = new BehaviorSubject<InitiateCheckout>(null);
    this.inquiry = new BehaviorSubject<Inquiry>(null);
    this.checkoutId = new BehaviorSubject<number>(null);
    this.chatReplica = new BehaviorSubject<Chat>(null);
    this.guestPortalView = new BehaviorSubject<GuestPortalView>(null);
    this.userInitiatePayment$ = new BehaviorSubject<PaymentInitiated>(null);
    this.favoritesView$ = new BehaviorSubject<FavoritesView>(null);
    this.portalComponentRequest = new BehaviorSubject<PortalComponentRequest>(
      null
    );
    this.userPartyMemberChange = new BehaviorSubject<UserPartyMemberChange>(
      null
    );
    this.testPixel = true;
    this.userSetGA = false;

    this.newsiteMode = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );

    if (!this.newsiteMode) {
      this.matcher = this.mediaMatcher.matchMedia('(display-mode: standalone)');
      if (this.matcher.matches) {
        this.savePWAPG().subscribe((result) => {
          console.log('PWA mode saved');
        });
      }

      try {
        this.matcher.addEventListener('change', (e) => {
          if (e.matches) {
            this.savePWAPG().subscribe((result) => {
              console.log('PWA mode saved');
            });
          }
        });
      } catch (e) {
        try {
          this.matcher.addListener(this.myListener);
        } catch (err) {
          console.error('matcher addListener error');
        }
      }

      this.quote.subscribe((newQuote) => {
        if (newQuote !== null) {
          this.saveQuotePG(newQuote).subscribe((result) => {});
          if (environment.sentryEnv === 'production' || this.testPixel) {
            this.fbqQuotePixel(newQuote).subscribe((result) => {
              console.log('Quote pixel with result ', result);
            });
          }
        }
      });

      this.search.subscribe((newSearch) => {
        if (newSearch !== null) {
          this.saveSearchPG(newSearch).subscribe((result) => {
            console.log('Search saved with result ', result);
          });

          if (environment.sentryEnv === 'production' || this.testPixel) {
            this.fbqSearchPixel(newSearch)
              .pipe(take(1))
              .subscribe((result) => {
                console.log('Search pixel added with result ', result);
              });
          }
        }
      });

      this.propertyView.subscribe((newView) => {
        if (newView !== null) {
          this.savePropertyViewPG(newView).subscribe((result) => {
            console.log('property view success ');
          });
        }
      });

      this.complexView.subscribe((newView) => {
        if (newView !== null) {
          this.saveComplexViewPG(newView).subscribe((result) => {
            console.log('complex view success ');
          });
        }
      });

      this.guestPortalView.subscribe((newView) => {
        if (newView !== null) {
          this.saveGuestPortalViewPG(newView).subscribe((result) => {
            return;
          });
        }
      });

      this.userInitiatePayment$.subscribe((newInitiate: PaymentInitiated) => {
        if (newInitiate !== null) {
          this.savePaymentInitiatedPG(newInitiate).subscribe((result) => {
            return;
          });
        }
      });

      this.favoritesView$.subscribe((newView: FavoritesView) => {
        if (newView !== null) {
          this.saveFavoritesViewPG(newView).subscribe((result) => {
            return;
          });
        }
      });

      this.inquiry.subscribe((newInquiry) => {
        if (newInquiry !== null) {
          this.saveInquiryPG(newInquiry).subscribe((result) => {
            console.log('Inquiry saved');
          });
        }
      });

      this.initiateCheckout.subscribe((newCheckout) => {
        if (newCheckout !== null) {
          this.checkoutId.next(null);
          if (environment.sentryEnv === 'production' || this.testPixel) {
            this.fbqInitiateCheckoutPixel(newCheckout).subscribe((result) => {
              console.log('initiate checkout pixel saved', result);
            });
          }
          this.saveInitiateCheckoutPG(newCheckout).subscribe((result) => {
            console.log('Checkout saved');
          });
        }
      });

      this.updateCheckout.subscribe((updateCheckout) => {
        if (updateCheckout !== null) {
          this.saveUpdateCheckoutPG(updateCheckout).subscribe((result) => {
            console.log('checkout updated');
          });

          if (
            updateCheckout.submitted &&
            updateCheckout.submitted === true &&
            (environment.sentryEnv === 'production' || this.testPixel)
          ) {
            this.fbqPurchasePixel(updateCheckout).subscribe((result) => {
              console.log('purchase pixel submitted ', result);
            });
          }
        }
      });

      this.initiateLodgingQuote.subscribe((newCheckout) => {
        if (newCheckout !== null) {
          this.checkoutId.next(null);
          if (environment.sentryEnv === 'production' || this.testPixel) {
            this.fbqInitiateCheckoutPixel(newCheckout).subscribe((result) => {
              console.log('initiate checkout pixel saved', result);
            });
          }
          this.saveInitiateLodgingQuotePG(newCheckout).subscribe((result) => {
            console.log('Lodging Quote view is saved');
          });
        }
      });

      this.updateLodgingQuote.subscribe((updateCheckout) => {
        if (updateCheckout !== null) {
          this.saveUpdateLodgingQuotePG(updateCheckout).subscribe((result) => {
            console.log('lodging quote updated');
          });

          if (
            updateCheckout.submitted &&
            updateCheckout.submitted === true &&
            (environment.sentryEnv === 'production' || this.testPixel)
          ) {
            this.fbqPurchasePixel(updateCheckout).subscribe((result) => {
              console.log('purchase pixel submitted ', result);
            });
          }
        }
      });

      this.chatReplica.subscribe((newLine) => {
        if (newLine !== null) {
          this.saveChatPG(newLine).subscribe((result) => {
            return;
          });
        }
      });

      this.portalComponentRequest.subscribe((newRequest) => {
        if (newRequest !== null) {
          this.savePortalComponentRequestPG(newRequest).subscribe((result) => {
            return;
          });
        }
      });

      this.userPartyMemberChange.subscribe((newPartyMember) => {
        if (newPartyMember !== null) {
          this.savePartyMemberChangePG(newPartyMember).subscribe((result) => {
            return;
          });
        }
      });
    } // end if not laurie mode
  } // end of the constructor

  /******* GA4 events *******/
  setUser() {
    /* if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      let uuid = this.authService.getUserId();
      gtag('set', { user_properties: { uuid: uuid } });
      this.userSetGA = true;
    }*/
  }

  public sendSearchEventGA(params: SearchEventParamsGA) {
    /*if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!this.userSetGA) {
        this.setUser();
      }
      gtag('event', 'search', params);
    }*/
  }

  public sendViewItemEventGA(params: ViewItemParamsGA) {
    /*if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!this.userSetGA) {
        this.setUser();
      }
      gtag('event', 'view_item', params);
    }*/
  }

  public sendQuoteEventGA(params: QuoteParamsGA, explicit: boolean) {
    /*if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!this.userSetGA) {
        this.setUser();
      }
      if (explicit) {
        gtag('event', 'quote', params);
      } else {
        gtag('event', 'implicit_quote', params);
      }
    }*/
  }

  public sendLeadSubmitGA(params: LeadSubmitParamsGA, userdata: UserInfoGA) {
    /*if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!this.userSetGA) {
        this.setUser();
      }
      if (userdata && userdata.phone) {
        if (this.checkPhone(userdata.phone) === 'submitall') {
          gtag('set', 'user_data', {
            email: userdata.email,
            phone: userdata.phone,
          });
        }
      }
      gtag('event', 'generate_lead', params);
    }*/
  }

  public sendPurchaseGA(params: PurchaseParamsGA, userdata: UserInfoGA) {
    /*if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!this.userSetGA) {
        this.setUser();
      }
      if (userdata && userdata.phone) {
        if (this.checkPhone(userdata.phone) === 'submitall') {
          gtag('set', 'user_data', {
            email: userdata.email,
            phone: userdata.phone,
          });
        }
        gtag('event', 'purchase', params);
      }
    }*/
  }

  checkPhone(phone: string): 'submitall' | 'submitleadonly' {
    if (!phone.startsWith('+')) {
      // if no country code is supplied then we assume that it is US and still submit user data without checking numbers
      return 'submitall';
    } else {
      // if no country code is supplied then submit only for US and Canada and Mexico
      const usCanadaCode = '+1';
      const mexicoCode = '+52';
      const australiaCode = '+61';
      const panamaCode = '+507';
      const brazilCode = '+55';

      if (
        phone &&
        (phone.startsWith(usCanadaCode) ||
          phone.startsWith(mexicoCode) ||
          phone.startsWith(australiaCode) ||
          phone.startsWith(panamaCode) ||
          phone.startsWith(brazilCode))
      ) {
        return 'submitall';
      }
      return 'submitleadonly';
    }
  }

  public sendBookingSubmitPaymentGA(params: BookingSubmitPaymentGA) {
    /*if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!this.userSetGA) {
        this.setUser();
      }
      gtag('event', 'booking_submit_payment', params);
    }*/
  }

  public sendBookingPaymentSuccessGA(params: BookingPaymentStatusGA) {
    /* if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!this.userSetGA) {
        this.setUser();
      }
      gtag('event', 'booking_payment_success', params);
    }*/
  }

  public sendBookingPaymentFailGA(params: BookingPaymentStatusGA) {
    /* if (
      !window.location.hostname.includes('stage') &&
      !window.location.hostname.includes('localhost')
    ) {
      if (!this.userSetGA) {
        this.setUser();
      }
      gtag('event', 'booking_payment_fail', params);
    }*/
  }

  /******* GA4 events end *******/

  public triggerPortalComponentRequestEvent(
    newRequest: PortalComponentRequest
  ) {
    this.portalComponentRequest.next(newRequest);
  }

  public triggerPartyMemberChangeEvent(
    partyMemberChange: UserPartyMemberChange
  ) {
    this.userPartyMemberChange.next(partyMemberChange);
  }

  public triggerInitiateCheckoutEvent(checkout: InitiateCheckout) {
    this.initiateCheckout.next(checkout);
  }

  public triggerUpdateCheckoutEvent(checkout: InitiateCheckout) {
    this.updateCheckout.next(checkout);
  }

  public triggerInitiateLodgingQuoteEvent(checkout: InitiateCheckout) {
    this.initiateLodgingQuote.next(checkout);
  }

  public triggerUpdateLodgingQuoteEvent(checkout: InitiateCheckout) {
    this.updateLodgingQuote.next(checkout);
  }

  public triggerSaveInquiryEvent(newInquiry: Inquiry) {
    this.inquiry.next(newInquiry);
  }

  public triggerQuoteEvent(quote: UserQuote) {
    this.quote.next(quote);
  }

  public triggerChatEvent(newLine: DialogReplica) {
    let replica: Chat = {
      chatSession: newLine.chatSessionId,
      link: newLine.link,
      linkDescription: newLine.linkDescription,
      replica: newLine.replica,
      author: newLine.author,
    };
    this.chatReplica.next(replica);
  }

  public triggerSearchEvent(search: UserSearch, mmOnly: boolean) {
    let guests = 0;
    if (search.Adults) {
      guests += +search.Adults;
    }
    if (search.Children) {
      guests += +search.Children;
    }

    search = {
      ...search,
      mmOnly: mmOnly,
      guests: guests > 0 ? guests : null,
    };
    this.search.next(search);
  }

  public triggerPropertyViewedEvent(view: UserPropertyView) {
    this.propertyView.next(view);
  }

  public triggerComplexViewedEvent(view: UserComplexView) {
    this.complexView.next(view);
  }

  public triggerGuestPortalViewEvent(view: GuestPortalView) {
    this.guestPortalView.next(view);
  }

  public triggerUserInitiatePaymentEvent(payment: PaymentInitiated) {
    this.userInitiatePayment$.next(payment);
  }

  public triggerFavoritesViewEvent(view: FavoritesView) {
    this.favoritesView$.next(view);
  }

  myListener(e) {
    if (e.matches) {
      this.savePWAPG().subscribe((result) => {
        console.log('PWA mode saved');
      });
    }
  }

  savePWAPG(): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId) {
        this.apiService
          .httpCall('/api/savePWAEvent', {
            sessionId: sessionId,
          })
          .subscribe((result) => {
            observer.next('done');
            observer.complete();
          });
      } else {
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              this.apiService
                .httpCall('/api/savePWAEvent', {
                  sessionId: sessionIdNew,
                })
                .subscribe((result) => {
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  saveQuotePG(quote: UserQuote): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && quote) {
        quote.sessionId = sessionId;

        this.apiService
          .httpCall('/api/saveQuoteEvent', {
            sessionId: quote.sessionId,
            unit: quote.unit,
            arrivalDate: quote.arrivalDate,
            departureDate: quote.departureDate,
            adults: quote.adults,
            children: quote.children,
            infants: quote.infants,
            guests: quote.adults + quote.children,
            nights: quote.nights,
            TotalCost: quote.TotalCost,
            Rent: quote.Rent,
            DiscountedRent: quote.DiscountedRent ? quote.DiscountedRent : null,
            Fees: quote.Fees,
            Taxes: quote.Taxes,
            RefundableDeposit: quote.RefundableDeposit,
            explicit: quote.explicit,
          })
          .subscribe((result) => {});
        observer.next('done'); // TODO: check, if this should be moved under /api/saveQuoteEvent subscription
        observer.complete();
      } else {
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              quote.sessionId = sessionIdNew;
              quote.sessionId = sessionId;

              this.apiService
                .httpCall('/api/saveQuoteEvent', {
                  sessionId: quote.sessionId,
                  unit: quote.unit,
                  arrivalDate: quote.arrivalDate,
                  departureDate: quote.departureDate,
                  adults: quote.adults,
                  children: quote.children,
                  infants: quote.infants ? quote.infants : 0,
                  guests: quote.adults + quote.children,
                  nights: quote.nights,
                  TotalCost: quote.TotalCost,
                  Rent: quote.Rent,
                  DiscountedRent: quote.DiscountedRent
                    ? quote.DiscountedRent
                    : null,
                  Fees: quote.Fees,
                  Taxes: quote.Taxes,
                  RefundableDeposit: quote.RefundableDeposit,
                  explicit: quote.explicit,
                })
                .subscribe((result) => {});
              observer.next('done');
              observer.complete();
            }
          });
      }
    });
  }

  saveSearchPG(search: UserSearch): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && search) {
        search.sessionId = sessionId;
        console.log('save amenities = ', search.amenities);
        this.apiService
          .httpCall('/api/saveSearchEvent', {
            sessionId: search.sessionId,
            arrivalDate: search.StartDate ? search.StartDate : null,
            departureDate: search.EndDate ? search.EndDate : null,
            adults: search.Adults ? search.Adults : null,
            children: search.Children ? search.Children : null,
            infants: search.Infants ? search.Infants : null,
            guests: search.guests ? search.guests : null,
            destination: search.Destination ? search.Destination : null,
            rateMin: search.rateTop ? search.rateTop : null,
            rateMax: search.rateBottom ? search.rateBottom : null,
            bedrooms: search.Bedrooms ? search.Bedrooms : null,
            petsAllowed: search.PetFriendly ? search.PetFriendly : null,
            unitType: search.PropertyType ? search.PropertyType : null,
            propertyCollection: search.PropertyCollection
              ? search.PropertyCollection
              : null,
            unitName: search.UnitName ? search.UnitName : null,
            City: search.City ? search.City : null,
            State: search.State ? search.State : null,
            village: search.Village ? search.Village : null,
            amenities: search.amenities ? search.amenities : null,
            mmOnly: search.mmOnly,
            mapSearch: search.mapSearch ? search.mapSearch : false,
            count: search.count ? search.count : null,
          })
          .subscribe((result) => {
            observer.next('done');
            observer.complete();
          });
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              search.sessionId = sessionIdNew;
              console.log('save amenities = ', search.amenities);
              this.apiService
                .httpCall('/api/saveSearchEvent', {
                  sessionId: search.sessionId,
                  arrivalDate: search.StartDate ? search.StartDate : null,
                  departureDate: search.EndDate ? search.EndDate : null,
                  adults: search.Adults ? search.Adults : null,
                  children: search.Children ? search.Children : null,
                  infants: search.Infants ? search.Infants : null,
                  guests: search.guests ? search.guests : null,
                  destination: search.Destination ? search.Destination : null,
                  rateMin: search.rateTop ? search.rateTop : null,
                  rateMax: search.rateBottom ? search.rateBottom : null,
                  bedrooms: search.Bedrooms ? search.Bedrooms : null,
                  petsAllowed: search.PetFriendly ? search.PetFriendly : null,
                  unitType: search.PropertyType ? search.PropertyType : null,
                  propertyCollection: search.PropertyCollection
                    ? search.PropertyCollection
                    : null,
                  unitName: search.UnitName ? search.UnitName : null,
                  City: search.City ? search.City : null,
                  State: search.State ? search.State : null,
                  village: search.Village ? search.Village : null,
                  amenities: search.amenities ? search.amenities : null,
                  mmOnly: search.mmOnly,
                })
                .subscribe((result) => {
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  savePropertyViewPG(view: UserPropertyView): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && view) {
        view.sessionId = sessionId;
        this.apiService
          .httpCall('/api/savePropertyViewed', {
            sessionId: view.sessionId,
            unit: view.unit,
          })
          .subscribe((result) => console.log('property view success'));
        observer.next('done');
        observer.complete();
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              view.sessionId = sessionIdNew;
              this.apiService
                .httpCall('/api/savePropertyViewed', {
                  sessionId: view.sessionId,
                  unit: view.unit,
                })
                .subscribe((result) => {
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  saveComplexViewPG(view: UserComplexView): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && sessionId !== undefined && view) {
        view.sessionId = sessionId;
        this.apiService
          .httpCall('/api/saveComplexViewed', {
            sessionId: view.sessionId,
            complex: view.complex,
          })
          .subscribe((result) => console.log('complex view success'));
        observer.next('done');
        observer.complete();
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              view.sessionId = sessionIdNew;
              this.apiService
                .httpCall('/api/saveComplexViewed', {
                  sessionId: view.sessionId,
                  complex: view.complex,
                })
                .subscribe((result) => {
                  observer.next('complex view success');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  saveFavoritesViewPG(view: FavoritesView): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && view) {
        view.sessionId = sessionId;
        this.apiService
          .httpCall('/api/saveFavoritesView', {
            sessionId: view.sessionId,
            sharelink: view.sharelink,
          })
          .subscribe((result) => {
            console.log('favorites view success');
            observer.next('done');
            observer.complete();
          });
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              view.sessionId = sessionIdNew;
              this.apiService
                .httpCall('/api/saveFavoritesView', {
                  sessionId: view.sessionId,
                  sharelink: view.sharelink,
                })
                .subscribe((result) => {
                  console.log('favorites view success');
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  savePaymentInitiatedPG(payment: PaymentInitiated): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && payment) {
        payment.sessionId = sessionId;
        this.apiService
          .httpCall('/api/saveUserInitiatePayment', {
            sessionId: payment.sessionId,
            paymentId: payment.paymentId,
            bookingId: payment.bookingId,
            amount: payment.amount,
            Name: payment.Name ? payment.Name : null,
          })
          .subscribe((result) => console.log('property view success'));
        observer.next('done');
        observer.complete();
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              payment.sessionId = sessionIdNew;
              this.apiService
                .httpCall('/api/saveUserInitiatePayment', {
                  sessionId: payment.sessionId,
                  paymentId: payment.paymentId,
                  bookingId: payment.bookingId,
                  amount: payment.amount,
                  Name: payment.Name ? payment.Name : null,
                })
                .subscribe((result) => {
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  saveGuestPortalViewPG(view: GuestPortalView): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && view) {
        view.sessionId = sessionId;
        this.apiService
          .httpCall('/api/saveGuestPortalView', {
            sessionId: view.sessionId,
            page: view.page,
            bookingId: view.bookingId ? view.bookingId : null,
          })
          .subscribe((result) => console.log('property view success'));
        observer.next('done');
        observer.complete();
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              view.sessionId = sessionIdNew;
              this.apiService
                .httpCall('/api/saveGuestPortalView', {
                  sessionId: view.sessionId,
                  page: view.page,
                })
                .subscribe((result) => {
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  savePartyMemberChangePG(
    partyMemberChange: UserPartyMemberChange
  ): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && sessionId !== undefined && partyMemberChange) {
        partyMemberChange = {
          ...partyMemberChange,
          sessionId: sessionId,
        };
        this.apiService
          .httpCall('/api/savePartyMemberChange', {
            partyMemberChange: partyMemberChange,
          })
          .subscribe((result) => console.log('component request success'));
        observer.next('done');
        observer.complete();
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              partyMemberChange = {
                ...partyMemberChange,
                sessionId: sessionIdNew,
              };
              this.apiService
                .httpCall('/api/savePartyMemberChange', {
                  partyMemberChange: partyMemberChange,
                })
                .subscribe((result) => {
                  observer.next('complex view success');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  savePortalComponentRequestPG(
    componentRequest: PortalComponentRequest
  ): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && sessionId !== undefined && componentRequest) {
        componentRequest = {
          ...componentRequest,
          sessionId: sessionId,
        };
        this.apiService
          .httpCall('/api/savePortalComponentRequest', {
            componentRequest: componentRequest,
          })
          .subscribe((result) => console.log('component request success'));
        observer.next('done');
        observer.complete();
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              componentRequest = {
                ...componentRequest,
                sessionId: sessionIdNew,
              };
              this.apiService
                .httpCall('/api/savePortalComponentRequest', {
                  componentRequest: componentRequest,
                })
                .subscribe((result) => {
                  observer.next('complex view success');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  saveInquiryPG(newInquiry: Inquiry): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && newInquiry) {
        newInquiry.sessionId = sessionId;
        this.apiService
          .httpCall('/api/saveInquiry', {
            inquiry: newInquiry,
          })
          .subscribe((result) => console.log('inquiry saved ', result));
        observer.next('done');
        observer.complete();
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              newInquiry.sessionId = sessionIdNew;
              this.apiService
                .httpCall('/api/saveInquiry', {
                  inquiry: newInquiry,
                })
                .subscribe((result) => {
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  saveInitiateCheckoutPG(checkout: InitiateCheckout): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && checkout) {
        checkout.sessionId = sessionId;
        this.apiService
          .httpCall('/api/saveInitiateCheckout', {
            checkout: checkout,
            checkoutId: null,
          })
          .subscribe((result: any) => {
            if (result && result.checkoutId) {
              this.checkoutId.next(result.checkoutId);
            } else {
              this.checkoutId.next(0);
              console.error('Checkout Id undefined, set 0');
            }
            observer.next('done');
            observer.complete();
          }); // end subscribe on /api/saveInitiateCheckout result
      } // end if (sessionId && checkout)
    }); // end of return new Observable(observer => {
  }

  saveUpdateCheckoutPG(checkout: InitiateCheckout): Observable<any> {
    return new Observable((observer) => {
      this.checkoutId
        .pipe(
          takeWhile(
            (sessionIdCheck) =>
              sessionIdCheck === null || sessionIdCheck === undefined,
            true
          )
        )
        .subscribe((checkoutId) => {
          if (checkoutId) {
            const sessionId = this.authService.sessionId.getValue();
            checkout.sessionId = sessionId;
            this.apiService
              .httpCall('/api/saveInitiateCheckout', {
                checkout: checkout,
                checkoutId: checkoutId,
              })
              .subscribe((result: any) => {
                observer.next('done');
                observer.complete();
              });
          }
        });
    });
  }

  saveInitiateLodgingQuotePG(checkout: InitiateCheckout): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && checkout) {
        checkout.sessionId = sessionId;
        this.apiService
          .httpCall('/api/saveLodgingQuoteView', {
            checkout: checkout,
            checkoutId: null,
          })
          .subscribe((result: any) => {
            if (result && result.checkoutId) {
              this.checkoutId.next(result.checkoutId);
            } else {
              this.checkoutId.next(0);
              console.error('Checkout Id undefined, set 0');
            }
            observer.next('done');
            observer.complete();
          }); // end subscribe on /api/saveInitiateCheckout result
      } // end if (sessionId && checkout)
    }); // end of return new Observable(observer => {
  }

  saveUpdateLodgingQuotePG(checkout: InitiateCheckout): Observable<any> {
    return new Observable((observer) => {
      this.checkoutId
        .pipe(
          takeWhile(
            (sessionIdCheck) =>
              sessionIdCheck === null || sessionIdCheck === undefined,
            true
          )
        )
        .subscribe((checkoutId) => {
          if (checkoutId) {
            const sessionId = this.authService.sessionId.getValue();
            checkout.sessionId = sessionId;
            this.apiService
              .httpCall('/api/saveLodgingQuoteView', {
                checkout: checkout,
                checkoutId: checkoutId,
              })
              .subscribe((result: any) => {
                observer.next('done');
                observer.complete();
              });
          }
        });
    });
  }

  saveChatPG(replica: Chat): Observable<any> {
    return new Observable((observer) => {
      const sessionId = this.authService.sessionId.getValue();
      if (sessionId && replica) {
        replica.sessionId = sessionId;
        this.apiService
          .httpCall('/api/saveChatEvent', {
            replica: replica,
          })
          .subscribe((result) => {});
        observer.next('done');
        observer.complete();
      } else {
        let havesessionId = false;
        this.authService.sessionId
          .pipe(
            takeWhile(
              (sessionIdCheck) =>
                sessionIdCheck === null || sessionIdCheck === undefined,
              true
            )
          )
          .subscribe((sessionIdNew: number) => {
            if (sessionIdNew) {
              havesessionId = true;
              replica.sessionId = sessionIdNew;
              this.apiService
                .httpCall('/api/saveChatEvent', {
                  replica: replica,
                })
                .subscribe((result) => {
                  observer.next('done');
                  observer.complete();
                });
            }
          });
      }
    });
  }

  fbqQuotePixel(quote: UserQuote): Observable<any> {
    return new Observable((observer) => {
      fbq('trackCustom', 'initiateQuote', {
        unit: quote.unit,
        arrival: quote.arrivalDate,
        departure: quote.departureDate,
        adults: quote.adults,
        children: quote.children,
        TotalCost: quote.TotalCost,
      });
      observer.next('ready');
      observer.complete();
    });
  }

  fbqSearchPixel(searchParams: UserSearch): Observable<any> {
    return new Observable((observer) => {
      fbq('track', 'Search', {
        Location:
          searchParams.City && searchParams.State
            ? searchParams.City + ', ' + searchParams.State
            : searchParams.Destination
            ? searchParams.Destination
            : null,
        Village: searchParams.Village ? searchParams.Village : null,
        StartDate: searchParams.StartDate ? searchParams.StartDate : null,
        EndDate: searchParams.EndDate ? searchParams.EndDate : null,
        UnitName: searchParams.UnitName ? searchParams.UnitName : null,
        Bedrooms: searchParams.Bedrooms ? searchParams.Bedrooms : null,
        rateBottom: searchParams.rateBottom ? searchParams.rateBottom : null,
        rateTop: searchParams.rateTop ? searchParams.rateTop : null,
        Adults: searchParams.Adults ? searchParams.Adults : null,
        Children: searchParams.Children ? searchParams.Children : null,
        PetFriendly: searchParams.PetFriendly ? true : null,
        Type: searchParams.PropertyType ? searchParams.PropertyType : null,
        Collection: searchParams.PropertyCollection
          ? searchParams.PropertyCollection
          : null,
      });
      observer.next('ready');
      observer.complete();
    });
  }

  fbqInitiateCheckoutPixel(checkout: InitiateCheckout): Observable<any> {
    return new Observable((observer) => {
      fbq('trackCustom', 'InitiateCheckout', {
        Unit: checkout.unit,
        Type: checkout.unitType,
        Collection: checkout.propertyCollection,
        value: checkout.TotalCost,
        currency: 'USD',
      });
      observer.next('ready');
      observer.complete();
    });
  }

  fbqPurchasePixel(checkout: InitiateCheckout): Observable<any> {
    return new Observable((observer) => {
      fbq('track', 'Purchase', {
        value: checkout.TotalCost,
        currency: 'USD',
        unit: checkout.unit,
        type: checkout.unitType,
        collection: checkout.propertyCollection,
      });
      observer.next('ready');
      observer.complete();
    });
  }

  ngOnDestroy() {
    this.quote.unsubscribe();
    this.search.unsubscribe();
    this.propertyView.unsubscribe();
    this.complexView.unsubscribe();
    this.initiateCheckout.unsubscribe();
    this.updateCheckout.unsubscribe();
    this.initiateLodgingQuote.unsubscribe();
    this.updateLodgingQuote.unsubscribe();
    this.checkoutId.unsubscribe();
    this.guestPortalView.unsubscribe();
    this.chatReplica.unsubscribe();
    this.userPartyMemberChange.unsubscribe();
    this.portalComponentRequest.unsubscribe();
  }
}
